// ==================================
// 物標表示関連処理
// ==================================
// leaflet Library
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import leafletImgMarkerIcon2x from 'leaflet/dist/images/marker-icon-2x.png'
import leafletImgMarkerIcon from 'leaflet/dist/images/marker-icon.png'
import leafletImgMarkerShadow from 'leaflet/dist/images/marker-shadow.png'

// assets icons object
import iconsAccidentIcon from '@/assets/icons/accidentIcon.png'
import iconsBlackIcon from '@/assets/icons/blackIcon.png'
import iconsBlackSIcon from '@/assets/icons/blackSIcon.png'
import iconsBlackTIcon from '@/assets/icons/blackTIcon.png'
import iconsBlueIcon from '@/assets/icons/blueIcon.png'
import iconsBlueSIcon from '@/assets/icons/blueSIcon.png'
import iconsBlueTIcon from '@/assets/icons/blueTIcon.png'
import iconsCsvDownloadIcon from '@/assets/icons/csvDownloadIcon.png'
import iconsGreenIcon from '@/assets/icons/greenIcon.png'
import iconsGreenSIcon from '@/assets/icons/greenSIcon.png'
import iconsGreenTIcon from '@/assets/icons/greenTIcon.png'
import iconsLightblueIcon from '@/assets/icons/lightblueIcon.png'
import iconsLightblueSIcon from '@/assets/icons/lightblueSIcon.png'
import iconsLightblueTIcon from '@/assets/icons/lightblueTIcon.png'
import iconsLightgreenIcon from '@/assets/icons/lightgreenIcon.png'
import iconsLightgreenSIcon from '@/assets/icons/lightgreenSIcon.png'
import iconsLightgreenTIcon from '@/assets/icons/lightgreenTIcon.png'
import iconsOrangeIcon from '@/assets/icons/orangeIcon.png'
import iconsOrangeSIcon from '@/assets/icons/orangeSIcon.png'
import iconsOrangeTIcon from '@/assets/icons/orangeTIcon.png'
import iconsPinkIcon from '@/assets/icons/pinkIcon.png'
import iconsPinkSIcon from '@/assets/icons/pinkSIcon.png'
import iconsPinkTIcon from '@/assets/icons/pinkTIcon.png'
import iconsPurpleIcon from '@/assets/icons/purpleIcon.png'
import iconsPurpleSIcon from '@/assets/icons/purpleSIcon.png'
import iconsPurpleTIcon from '@/assets/icons/purpleTIcon.png'
import iconsRedIcon from '@/assets/icons/redIcon.png'
import iconsRedSIcon from '@/assets/icons/redSIcon.png'
import iconsRedTIcon from '@/assets/icons/redTIcon.png'
import iconsWineIcon from '@/assets/icons/wineIcon.png'
import iconsWineSIcon from '@/assets/icons/wineSIcon.png'
import iconsWineTIcon from '@/assets/icons/wineTIcon.png'
import iconsYellowIcon from '@/assets/icons/yellowIcon.png'
import iconsYellowSIcon from '@/assets/icons/yellowSIcon.png'
import iconsYellowTIcon from '@/assets/icons/yellowTIcon.png'
// assets map abject 
import imgMapAccident from '@/assets/img/map/accident.png'
import imgObjectQuestionSelect from '@/assets/img/map/question_select.svg'
// assets image marker
import imgMarkerIcon2xRed from '@/assets/img/marker/marker-icon-2x-red.png'
import imgMarkerIcon2xViolet from '@/assets/img/marker/marker-icon-2x-violet.png'
import imgMarkerIconRed from '@/assets/img/marker/marker-icon-red.png'
// assets image object
import imgObjectAmbulance from '@/assets/img/object/ambulance.svg'
import imgObjectBicycle from '@/assets/img/object/bicycle.svg'
import imgObjectBike from '@/assets/img/object/bike.svg'
import imgObjectBus from '@/assets/img/object/bus.svg'
import imgObjectCar from '@/assets/img/object/car.svg'
import imgObjectDirection from '@/assets/img/object/direction.svg'
import imgObjectHuman from '@/assets/img/object/human.svg'
import imgObjectQuestion from '@/assets/img/object/question.svg'
import imgObjectSteering from '@/assets/img/object/steering.svg'
import imgObjectTrain from '@/assets/img/object/train.svg'


// leaflet Icon定義
interface Icon {
  iconUrl: string;
  iconRetinaUrl: string;
  shadowUrl: string;
  iconSize: [number, number];
  iconAnchor: [number, number];
  shadowSize: [number, number];
}
// 各種アイコン
export const POLE_ICON: Icon = {
  iconUrl: leafletImgMarkerIcon,
  iconRetinaUrl: leafletImgMarkerIcon2x,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [25, 41],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const NEAR_MISS_ICON: Icon = {
  iconUrl: iconsBlueIcon,
  iconRetinaUrl: iconsBlueIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [10, 10],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const ACCIDENT_ICON: Icon = {
  iconUrl: iconsAccidentIcon,
  iconRetinaUrl: iconsAccidentIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [10, 10],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const GREEN_ICON: Icon = {
  iconUrl: iconsGreenIcon,
  iconRetinaUrl: iconsGreenIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const BLUE_ICON: Icon = {
  iconUrl: iconsBlueIcon,
  iconRetinaUrl: iconsBlueIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const RED_ICON: Icon = {
  iconUrl: iconsRedIcon,
  iconRetinaUrl: iconsRedIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const YELLOW_ICON: Icon = {
  iconUrl: iconsYellowIcon,
  iconRetinaUrl: iconsYellowIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const LIGHTGREEN_ICON: Icon = {
  iconUrl: iconsLightgreenIcon,
  iconRetinaUrl: iconsLightgreenIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const LIGHTBLUE_ICON: Icon = {
  iconUrl: iconsLightblueIcon,
  iconRetinaUrl: iconsLightblueIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const PINK_ICON: Icon = {
  iconUrl: iconsPinkIcon,
  iconRetinaUrl: iconsPinkIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const ORANGE_ICON: Icon = {
  iconUrl: iconsOrangeIcon,
  iconRetinaUrl: iconsOrangeIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const WINE_ICON: Icon = {
  iconUrl: iconsWineIcon,
  iconRetinaUrl: iconsWineIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const BLACK_ICON: Icon = {
  iconUrl: iconsBlackIcon,
  iconRetinaUrl: iconsBlackIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const PURPLE_ICON: Icon = {
  iconUrl: iconsPurpleIcon,
  iconRetinaUrl: iconsPurpleIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const GREEN_T_ICON: Icon = {
  iconUrl: iconsGreenTIcon,
  iconRetinaUrl: iconsGreenTIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const BLUE_T_ICON: Icon = {
  iconUrl: iconsBlueTIcon,
  iconRetinaUrl: iconsBlueTIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const RED_T_ICON: Icon = {
  iconUrl: iconsRedTIcon,
  iconRetinaUrl: iconsRedTIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const YELLOW_T_ICON: Icon = {
  iconUrl: iconsYellowTIcon,
  iconRetinaUrl: iconsYellowTIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const LIGHTGREEN_T_ICON: Icon = {
  iconUrl: iconsLightgreenTIcon,
  iconRetinaUrl: iconsLightgreenTIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const LIGHTBLUE_T_ICON: Icon = {
  iconUrl: iconsLightblueTIcon,
  iconRetinaUrl: iconsLightblueTIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const PINK_T_ICON: Icon = {
  iconUrl: iconsPinkTIcon,
  iconRetinaUrl: iconsPinkTIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const ORANGE_T_ICON: Icon = {
  iconUrl: iconsOrangeTIcon,
  iconRetinaUrl: iconsOrangeTIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const WINE_T_ICON: Icon = {
  iconUrl: iconsWineTIcon,
  iconRetinaUrl: iconsWineTIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const BLACK_T_ICON: Icon = {
  iconUrl: iconsBlackTIcon,
  iconRetinaUrl: iconsBlackTIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const PURPLE_T_ICON: Icon = {
  iconUrl: iconsPurpleTIcon,
  iconRetinaUrl: iconsPurpleTIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const GREEN_S_ICON: Icon = {
  iconUrl: iconsGreenSIcon,
  iconRetinaUrl: iconsGreenSIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const BLUE_S_ICON: Icon = {
  iconUrl: iconsBlueSIcon,
  iconRetinaUrl: iconsBlueSIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const RED_S_ICON: Icon = {
  iconUrl: iconsRedSIcon,
  iconRetinaUrl: iconsRedSIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const YELLOW_S_ICON: Icon = {
  iconUrl: iconsYellowSIcon,
  iconRetinaUrl: iconsYellowSIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const LIGHTGREEN_S_ICON: Icon = {
  iconUrl: iconsLightgreenSIcon,
  iconRetinaUrl: iconsLightgreenSIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const LIGHTBLUE_S_ICON: Icon = {
  iconUrl: iconsLightblueSIcon,
  iconRetinaUrl: iconsLightblueSIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const PINK_S_ICON: Icon = {
  iconUrl: iconsPinkSIcon,
  iconRetinaUrl: iconsPinkSIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const ORANGE_S_ICON: Icon = {
  iconUrl: iconsOrangeSIcon,
  iconRetinaUrl: iconsOrangeSIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const WINE_S_ICON: Icon = {
  iconUrl: iconsWineSIcon,
  iconRetinaUrl: iconsWineSIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const BLACK_S_ICON: Icon = {
  iconUrl: iconsBlackSIcon,
  iconRetinaUrl: iconsBlackSIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [20, 20],
  iconAnchor: [0, 0],
  shadowSize: [0, 0],
}

export const PURPLE_S_ICON: Icon = {
  iconUrl: iconsPurpleSIcon,
  iconRetinaUrl: iconsPurpleSIcon,
  shadowUrl: leafletImgMarkerShadow,
  iconAnchor: [0, 0],
  iconSize: [20, 20],
  shadowSize: [0, 0],
}

// 各物標関連の画像データ
export const DIRECTION_ICON = imgObjectDirection
export const ACCIDENT_MAP_ICON = imgMapAccident
export const STEERING_ICON = imgObjectSteering

// CSVダウンロード画像データ
export const CSV_DOWNLOAD_ICON = iconsCsvDownloadIcon

// 選択済みマーカーアイコン
export const SELECT_MARKER_ICON: Icon = {
  iconUrl: imgMarkerIconRed,
  iconRetinaUrl: imgMarkerIcon2xRed,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  shadowSize: [0, 0],
}
// 初期設定マーカーアイコン
export const DEFAULT_MARKER_ICON: Icon = {
  iconUrl: leafletImgMarkerIcon,
  iconRetinaUrl: leafletImgMarkerIcon2x,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  shadowSize: [0, 0],
}
// LED設定マーカーアイコン
export const LED_MARKER_ICON: Icon = {
  iconUrl: imgMarkerIcon2xViolet,
  iconRetinaUrl: imgMarkerIcon2xViolet,
  shadowUrl: leafletImgMarkerShadow,
  iconSize: [27, 43],
  iconAnchor: [20, 41],
  shadowSize: [0, 0],
}

/**
 * マップ(地図)オブジェクト情報取得
 * @param map - マップオブジェクト
 * @param maxZoom - 最大拡大率
 * @param maxNativeZoom - 背景地図の既存zoomレベル
 * @returns マップ(地図)オブジェクト情報
 */
export const getTileLayer = (map: any, maxZoom: number, maxNativeZoom: number | undefined = undefined): any => {
  const OpenStreetMapTileLayer = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  const OpenStreetMapAttribution = 'Map data © <a href="http://openstreetmap.org/">OpenStreetMap</a>'

  const GeospatialInformationAuthorityOfJapanMapTileLayer = 'https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png'
  const GeospatialInformationAuthorityOfJapanPhotographTileLayer = 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg'
  const GeospatialInformationAuthorityOfJapanAttribution = 'Map data © <a href="http://www.gsi.go.jp/kikakuchousei/kikakuchousei40182.html">国土地理院</a>'
  return {
    OpenStreetMap: L.tileLayer(OpenStreetMapTileLayer, {
      attribution: OpenStreetMapAttribution,
      maxZoom: maxZoom,
      maxNativeZoom: maxNativeZoom,
    }).addTo(map),
    '国土地理院(地図)': L.tileLayer(GeospatialInformationAuthorityOfJapanMapTileLayer, {
      attribution: GeospatialInformationAuthorityOfJapanAttribution,
      maxZoom: maxZoom,
      maxNativeZoom: maxNativeZoom,
    }),
    '国土地理院(写真)': L.tileLayer(GeospatialInformationAuthorityOfJapanPhotographTileLayer, {
      attribution: GeospatialInformationAuthorityOfJapanAttribution,
      maxZoom: maxZoom,
      maxNativeZoom: maxNativeZoom,
    }),
  }
}
// 物標マーカー情報オブジェクトリスト
const MARKER_LIST = [
  {
    kind: 0,
    objectMarker: imgObjectCar,
    mapMarker: {
      select: imgObjectCar,
      default: imgObjectCar,
    },
  },
  {
    kind: 1,
    objectMarker: imgObjectCar,
    mapMarker: {
      select: imgObjectCar,
      default: imgObjectCar,
    },
  },
  {
    kind: 2,
    objectMarker: imgObjectCar,
    mapMarker: {
      select: imgObjectCar,
      default: imgObjectCar,
    },
  },
  {
    kind: 3,
    objectMarker: imgObjectBike,
    mapMarker: {
      select: imgObjectBike,
      default: imgObjectBike,
    },
  },
  {
    kind: 4,
    objectMarker: imgObjectBicycle,
    mapMarker: {
      select: imgObjectBicycle,
      default: imgObjectBicycle,
    },
  },
  {
    kind: 5,
    objectMarker: imgObjectQuestion,
    mapMarker: {
      select: imgObjectQuestion,
      default: imgObjectQuestion,
    },
  },
  {
    kind: 6,
    objectMarker: imgObjectHuman,
    mapMarker: {
      select: imgObjectHuman,
      default: imgObjectHuman,
    },
  },
  {
    kind: 7,
    objectMarker: imgObjectTrain,
    mapMarker: {
      select: imgObjectTrain,
      default: imgObjectTrain,
    },
  },
  {
    kind: 15,
    objectMarker: imgObjectQuestion,
    mapMarker: {
      select: imgObjectQuestion,
      default: imgObjectQuestion,
    },
  },
]
const AMBULANCE_MARKER = {
  kind: 1,
  objectMarker: imgObjectAmbulance,
  mapMarker: imgObjectAmbulance,
}

// LED表示板表示種別
export const LED_DISP_KIND = {
  // 安全運転支援
  safeDrivingSupportDispKind: '001',
  // 安全運転支援（法令順守・長期行動変容）
  safeDrivingSupportLawDispKind: '003',
  // 災害
  disasterDispKind: '007',
  // ブランク
  blank: '000',
}

// LED表示板マーカー情報オブジェクトリスト
const LED_MARKER_LIST = {
  // 安全運転支援
  safeDrivingSupport: [
    {
      kind: '-01',
      objectMarker: imgObjectQuestion,
      definition: '種別不明',
    },
    {
      kind: '001',
      objectMarker: imgObjectHuman,
      definition: '歩行者',
    },
    {
      kind: '002',
      objectMarker: imgObjectBicycle,
      definition: '自転車',
    },
    {
      kind: '003',
      objectMarker: imgObjectBike,
      definition: '自動二輪車',
    },
    {
      kind: '004',
      objectMarker: imgObjectCar,
      definition: '自動車',
    },
    {
      kind: '005',
      objectMarker: imgObjectCar,
      definition: '自動運転車両',
    },
    {
      kind: '006',
      objectMarker: imgObjectBus,
      definition: 'バス',
    },
    {
      kind: '100',
      objectMarker: imgObjectAmbulance,
      definition: '緊急車両',
    },
    {
      kind: '-10',
      objectMarker: '',
      definition: 'なし',
    },
  ],
  safeDrivingSupportOthers: {
    objectMarker: '',
    definition: 'その他：予約',
  },
  // 災害危機情報通知
  // 2023/07 時点では制御部から対象のデータは送られないとのことなので
  // アイコン未定義の設定にしている
  disaster: [
    {
      kind: '199',
      objectMarker: imgObjectQuestion,
      definition: '種別不明',
    },
    {
      kind: '101',
      objectMarker: imgObjectQuestion,
      definition: '地震',
    },
    {
      kind: '102',
      objectMarker: imgObjectQuestion,
      definition: '津波',
    },
    {
      kind: '103',
      objectMarker: imgObjectQuestion,
      definition: '火山',
    },
    {
      kind: '104',
      objectMarker: imgObjectQuestion,
      definition: '台風',
    },
    {
      kind: '105',
      objectMarker: imgObjectQuestion,
      definition: '大雨',
    },
    {
      kind: '106',
      objectMarker: imgObjectQuestion,
      definition: '竜巻',
    },
    {
      kind: '107',
      objectMarker: imgObjectQuestion,
      definition: 'J アラート （弾道ミサイル）',
    },
    {
      kind: '-10',
      objectMarker: '',
      definition: 'なし',
    },
  ],
  disasterOthers: {
    objectMarker: '',
    definition: 'その他：予約',
  },
}

/**
 * マップ(地図上の)マーカー情報取得
 * @param id - 選択済みアイコンの識別ID
 * @param markers - マップ(地図上の)マーカーオブジェクト情報
 * @returns マーカー情報
 */
export const getMarkerInfo = (id: number, markers: any): any => {
  let markerInfo = undefined
  for (const item of markers) {
    if (id == item.id) {
      markerInfo = item
      break
    }
  }
  return markerInfo
}

/**
 * 物標アイコン画像データオブジェクト取得
 * @param sizeKind - 物標アイコンサイズ種別ID
 * @param roleKind - 物標アイコン用途種別ID
 * @param select - 選択フラグ
 * @returns 物標アイコン画像データオブジェクト
 */
export const getMapMarker = (sizeKind: number, roleKind: number, select: boolean): any => {
  let marker
  if (select) {
    marker = imgObjectQuestionSelect
  } else {
    marker = imgObjectQuestion
  }
  // 緊急車両
  if (roleKind == AMBULANCE_MARKER.kind) {
    const mapMarker = AMBULANCE_MARKER.mapMarker
    return mapMarker
  }
  for (const item of MARKER_LIST) {
    if (sizeKind == item.kind) {
      const mapMarker = item.mapMarker
      if (select) {
        marker = mapMarker.select
      } else {
        marker = mapMarker.default
      }
      break
    }
  }
  return marker
}

/**
 * 物標アイコンオブジェクト取得
 * @param sizeKind - 物標アイコンサイズ種別ID
 * @param roleKind - 物標アイコン用途種別ID
 * @returns 物標アイコンオブジェクト
 */
export const getObjectMarker = (sizeKind: number, roleKind: number): any => {
  let marker = imgObjectQuestion
  // 緊急車両
  if (roleKind == AMBULANCE_MARKER.kind) {
    marker = AMBULANCE_MARKER.objectMarker
    return marker
  }
  for (const item of MARKER_LIST) {
    if (sizeKind == item.kind) {
      marker = item.objectMarker
      break
    }
  }
  return marker
}

/**
 * LED表示板アイコンオブジェクト取得
 * @param dispKind - LED表示内容種別
 * @param objKind - LED表示板アイコン種別
 * @returns LED表示板アイコンオブジェクト
 */
export const getLedObjectMarker = (dispKind: string, objKind: string): typeof imgObjectQuestion => {
  let marker = null
  let targetList = null
  if (dispKind == LED_DISP_KIND.safeDrivingSupportDispKind) {
    targetList = LED_MARKER_LIST.safeDrivingSupport
  } else if (dispKind == LED_DISP_KIND.disasterDispKind) {
    targetList = LED_MARKER_LIST.disaster
  }
  if (targetList != null) {
    for (const item of targetList) {
      if (objKind == item.kind) {
        marker = item.objectMarker
        break
      }
    }
  }
  return marker
}

/**
 * マーカーアイコンオブジェクト取得
 * @param select - 選択フラグ
 * @returns マーカーアイコンオブジェクト
 */
export const getMarkerIcon = (select: boolean): object => {
  if (select) {
    return SELECT_MARKER_ICON
  } else {
    return DEFAULT_MARKER_ICON
  }
}

/**
 * LEDマーカーアイコンオブジェクト取得
 * @returns マーカーアイコンオブジェクト
 */
export const getLedMarkerIcon = (): object => {
  return LED_MARKER_ICON
}

interface poleNameInterface {
  address: string;
  name: string;
}

/**
 * ポールの住所と名称を取得する
 * @param addressList - 住所分類一覧
 * @returns ポール住所、名称
 */
export const setPoleName = (addressList: any): poleNameInterface => {
  let address = ''
  let name = ''
  address += addressList.city !== void 0 ? addressList.city : ''
  address += addressList.county !== void 0 ? addressList.county : ''
  address += addressList.hamlet !== void 0 ? addressList.hamlet : ''
  address += addressList.suburb !== void 0 ? addressList.suburb : ''
  address += addressList.neighbourhood !== void 0 ? addressList.neighbourhood : ''
  address += addressList.road !== void 0 ? addressList.road : ''
  address += addressList.man_made !== void 0 ? addressList.man_made : ''
  name = addressList.neighbourhood !== void 0 ? addressList.neighbourhood : ''
  return {
    address: address,
    name: name
  }
}
