import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from 'vue'

import SelectSensor from '@/components/parts/common/SelectSensor.vue'
import VirtualMap from '@/components/parts/common/VirtualMap.vue'

import { SensorIdData } from '@/types/Interfaces'

// ==================================
// interface
// ==================================
interface Emits {
  (e: 'select-sensor-list-hand-over', value: SensorIdData[]): void;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'VirtualVideo',
  emits: ["select-sensor-list-hand-over"],
  setup(__props, { expose: __expose, emit: __emit }) {

/**
 * VirtualVideo.vue
 * バーチャル映像コンポーネント
 * 
 * 親コンポーネント
 * @/views/RealTime.vue
 */
// ==================================
// import
// ==================================
const selectSensorList = ref<SensorIdData[]>([])

const selectSensorComponent = ref()
const virtualMapComponent = ref()

// ==================================
// computed
// ==================================
/**
 * 選択済みのセンサーを更新
 * @param value - 選択済みセンサー情報
 */
const updateSelectSensorList = (value: SensorIdData[]) => {
  selectSensorList.value = value
  selectSensorListHandOver(selectSensorList.value)
}

// ==================================
// method
// ==================================
const emit = __emit
/**
 * 選択済みセンサー情報を親コンポーネントへ渡す
 * @param sensorlist - 選択済みセンサー情報
 */
const selectSensorListHandOver = (sensorlist: SensorIdData[]) => {
  emit('select-sensor-list-hand-over', sensorlist)
}

/**
 * センサー初期設定
 */
const initSensorList = () => {
  selectSensorComponent.value.initSensorList()
}
/**
 * ポールマーカー設定
 * @param lat - 緯度
 * @param lng - 経度
 */
const addPoleMarker = (lat: number, lng: number) => {
  virtualMapComponent.value.addPoleMarker(lat, lng)
}
/**
 * 物標を更新
 */
const updatePosObject = () => {
  virtualMapComponent.value.updatePosObject()
}
/**
 * マップ表示の中央位置を設定
 * @param latlng - 緯度経度
 */
const setCenter = (latlng: number[]) => {
  virtualMapComponent.value.setCenter(latlng)
}

__expose({
  initSensorList,
  addPoleMarker,
  updatePosObject,
  setCenter,
})

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "virtual-video",
    elevation: "5",
    outlined: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, { class: "virtual-video__title py-0 d-flex" }, {
        default: _withCtx(() => [
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "py-5" }, "VIRTUAL映像", -1)),
          _createVNode(SelectSensor, {
            ref_key: "selectSensorComponent",
            ref: selectSensorComponent,
            onSelectSensorListHandOver: updateSelectSensorList
          }, null, 512)
        ]),
        _: 1
      }),
      _createVNode(_component_v_divider),
      _createVNode(VirtualMap, {
        ref_key: "virtualMapComponent",
        ref: virtualMapComponent,
        "select-sensor-list": selectSensorList.value
      }, null, 8, ["select-sensor-list"])
    ]),
    _: 1
  }))
}
}

})