import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from 'vue'

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'Logout',
  setup(__props, { expose: __expose }) {

/**
 * Logout.vue
 * ログアウト用ダイアログ
 * 
 * 親コンポーネント
 * @/components/parts/common/TitleHeader.vue
 */
// ==================================
// import
// ==================================
const dialog = ref<boolean>(false)

const resolve = ref<any>(false)

const reject = ref<any>(null)

// ==================================
// method
// ==================================
/**
 * ダイアログを開く(親コンポーネントからの呼び出し)
 */
const open = () => {
  dialog.value = true
  return new Promise((res, rej) => {
    // resolveとrejectのメソッドをステートオブジェクトに格納する
    resolve.value = res
    reject.value = rej
  })
}

/**
 * OKボタンをクリック
 */
const agree = () => {
  // 親コンポーネントへログアウト処理を伝達する
  resolve.value(true)
  dialog.value = false
}

/**
 * キャンセルボタンをクリック
 */
const cancel = () => {
  resolve.value(false)
  dialog.value = false
}

__expose({
  open
})

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, { justify: "center" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_dialog, {
        modelValue: dialog.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dialog).value = $event)),
        persistent: "",
        "max-width": "15vw"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            elevation: "5",
            outlined: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("ログアウト")
                ])),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("ログアウトしますか？")
                ])),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "blue-grey",
                    variant: "elevated",
                    density: "default",
                    dark: "",
                    onClick: agree
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" OK ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    color: "blue-grey",
                    variant: "elevated",
                    density: "default",
                    dark: "",
                    onClick: cancel
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode(" NO ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}
}

})