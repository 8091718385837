import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, ref } from 'vue'

import Loading from '@/components/parts/common/Loading.vue'

import { DIALOG_ERROR_INFO } from '@/mixins/commonFunction'
import { createGroupInfo } from '@/mixins/communicationFunction'

// ==================================
// interface
// ==================================
interface Props {
  dialog: {
    isDialog: boolean;
  }
}

interface Emits {
  (e: 'show-error-dialog', result: boolean, title: string, message: string): void;
  (e: 'dialogClose'): void;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'GroupCreateDialog',
  props: {
    dialog: {}
  },
  emits: ["show-error-dialog", "dialogClose"],
  setup(__props: any, { emit: __emit }) {

/**
 * GroupCreateDialog.vue
 * グループの作成ダイアログ
 * 
 * 親コンポーネント
 * @/views/GroupSetting.vue
 */
// ==================================
// import
// ==================================
const props = __props

// ローディングアイコン表示判定
const isLoading = ref<boolean>(false)

// 作成ボタンの無効化判定
const isDisable = ref<boolean>(false)

const groupname = ref<string>('')

const rules = ref({
  /**
   * グループ名のバリデーションチェック
   * @param value - フォーム入力値
   * @returns エラー文字列、成功判定
   */
  checkInValidGroupName: (value: string | null): string | boolean => {
    if (!value) {
      // 値が空ではないこと
      return '値を入力してください'
    } else if (value.length > 50) {
      // グループ名文字数が1~50の範囲内であること
      return 'グループ名が長すぎます'
    }
    return true
  }
})

// ダイアログの表示判定用
const isDialog = computed(() => {
  return props.dialog.isDialog
})

// ==================================
// method
// ==================================
const emit = __emit

/**
 * 作成ボタン活性/非活性の判定
 */
const isValidation = ()  => {
  // グループ名がNULLではなく50文字以内かどうか
  isDisable.value = groupname.value !== null && groupname.value.length <= 50
  return isDisable.value
}

/**
 * グループ情報作成処理
 */
const onClickCreate = () => {
  isLoading.value = true
  // グループ名をパラメータとしてグループ作成のAPIを呼び出す
  let promise = createGroupInfo(groupname.value)
  promise
    .then(() => {
      isLoading.value = false
      emit('show-error-dialog', true, 'グループ作成成功', 'グループを作成しました: \n' + groupname.value)
    })
    .catch(() => {
      isLoading.value = false
      // エラーメッセージを設定する
      let title = DIALOG_ERROR_INFO.title.createError
      let message = DIALOG_ERROR_INFO.message.createErrorGroupInfo
      emit('show-error-dialog', false, title, message)
    })
}

/**
 * ダイアログ閉じる処理
 */
const dialogClose = () => {
  emit('dialogClose')
}

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: isDialog.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((isDialog).value = $event)),
    class: "group-create-dialog",
    width: "500px",
    persistent: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, {
                class: "group-create-dialog__title",
                nav: "",
                denseity: "compact"
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" グループ作成 ")
                ])),
                _: 1
              }),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_form, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "3" }, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createElementVNode("span", null, "グループ名", -1)
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "9" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                modelValue: groupname.value,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((groupname).value = $event)),
                                rules: [rules.value.checkInValidGroupName],
                                placeholder: "グループ名を入力してください。",
                                clearable: ""
                              }, null, 8, ["modelValue", "rules"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    disabled: !isValidation(),
                    class: "group-create-dialog__create-button",
                    color: "primary darken-2",
                    onClick: onClickCreate
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode(" 作成 ")
                    ])),
                    _: 1
                  }, 8, ["disabled"]),
                  _createVNode(_component_v_btn, {
                    color: "grey darken-2",
                    dark: "",
                    onClick: dialogClose
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode(" キャンセル ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _withDirectives(_createVNode(Loading, null, null, 512), [
        [_vShow, isLoading.value]
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})