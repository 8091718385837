import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]

import { computed } from 'vue'

import { ErrorDialogInfo } from '@/types/Interfaces'

// ==================================
// interface
// ==================================
interface Props {
  errorDialog: ErrorDialogInfo;
}

interface Emits {
  (e: 'on-click-close-error-dialog'): void;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorDialog',
  props: {
    errorDialog: {}
  },
  emits: ["on-click-close-error-dialog"],
  setup(__props: any, { emit: __emit }) {

/**
 * ErrorDialog.vue
 * 共通エラーダイアログ
 * 
 * 親コンポーネント
 * @/components/parts/groupSetting/GroupDeleteDialog.vue
 * @/components/parts/groupSetting/GroupUpdateDialog.vue 
 * @/components/parts/groupSetting/PoleGroupUpdateDialog.vue
 * @/components/parts/macroAnalysis/MacroAnalysisTable.vue
 * @/views/Accident.vue
 * @/views/Aggregation.vue
 * @/views/Area.vue
 * @/views/Collections.vue
 * @/views/GroupSetting.vue
 * @/views/HistoryList.vue
 * @/views/Login.vue
 * @/views/MacroAnalysis.vue
 * @/views/MacroAnalysisInfoDataDownload.vue
 * @/views/MultiCamera.vue
 * @/views/RealTime.vue
 * @/views/Virtual.vue
 * @/views/Watch.vue
 * 
 */
// ==================================
// import
// ==================================
const props = __props

// ==================================
// computed
// ==================================
const isShowDialog = computed(() => {
  return props.errorDialog.isShow
})

const showTitle = computed(() => {
  return props.errorDialog.title
})

const showMessage = computed(() => {
  return props.errorDialog.message
})

// ==================================
// method
// ==================================
const emit = __emit
/**
 * エラーダイアログを閉じる
 */
const closeDialog = () => {
  emit('on-click-close-error-dialog')
}

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: isShowDialog.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isShowDialog).value = $event)),
    class: "error-dialog",
    nav: "",
    dense: "",
    width: "20vw",
    persistent: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "error-dialog__title" }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    textContent: _toDisplayString(showTitle.value)
                  }, null, 8, _hoisted_1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_card_text, { class: "error-dialog__message" }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    textContent: _toDisplayString(showMessage.value)
                  }, null, 8, _hoisted_2)
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    variant: "flat",
                    color: "blue-grey",
                    dark: "",
                    rounded: "",
                    onClick: closeDialog
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode(" Close ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})