<script lang="ts" setup>
/**
 * Aggregation.vue
 * 集計グラフ
 */
// ==================================
// import
// ==================================
import { onBeforeMount, watch, ref } from 'vue'

import AggreMain from '@/components/parts/aggregation/AggreMain.vue'
import PoleListMini from '@/components/parts/aggregation/PoleListMini.vue'
import TargetMap from '@/components/parts/aggregation/TargetMap.vue'
import Button from '@/components/parts/common/Button.vue'
import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'

import {
  useAggregationFiltersStore,
  usePoleListStore,
  usePoleInfoStore,
  useSelectPoleStore
} from '@/store/app'

import { getPoleList } from '@/mixins/communicationFunction'

import { ErrorDialogInfo, PoleListStore, SelectPoleStore, TitleInfo } from '@/types/Interfaces'

import { MENU } from '@/setting/setting'

import * as log from 'loglevel'
import { DateTime } from 'luxon'

// ==================================
// data
// ==================================
const aggreMainComponent = ref()

const aggregationFilterStore = useAggregationFiltersStore()
const poleListStore = usePoleListStore()
const poleInfoStore = usePoleInfoStore()
const selectPoleStore = useSelectPoleStore()

const titleinfo = ref<TitleInfo>({
  title: '',
  pointList: [],
  menuList: MENU.aggregation,
  show: {
    logout: true,
    point: false,
    menu: true,
  },
})

const dialogMessage = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false,
})

const poleData = ref<SelectPoleStore>({
  poleId: 0,
  areaId: 0,
  name: '',
  latlng: {lat: 0, lng: 0},
  sensorList: []
})

const isLoading = ref<boolean>(false)

const sideMenuOpened = ref([0, 1])

const selectedCategory = ref(0)

// ==================================
// watch
// ==================================
watch(() => selectedCategory.value, (newval, oldval) => {
  // 集計対象のラジオボタンが初回選択の時だけ、Piniaストアの内容を更新する
  if (oldval === 0) {
    const span = aggregationFilterStore.$state.mode.span
    aggregationFilterStore.setMode(newval, span)
  }
})

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  initialize()
})

// ==================================
// method
// ==================================
/**
 * 初期処理
 */
const initialize = () => {
  aggregationFilterStore.setMode(selectedCategory.value, 0)
  poleData.value = selectPoleStore.$state
  titleinfo.value.title = poleData.value.name + ' 集計'
}

/**
 * ローディング画面の表示切替
 * @param flag - 表示フラグ値
 */
const setLoading = (flag: boolean) => {
  isLoading.value = flag
}

/**
 * ポール情報の更新
 * @param poleId - ポールID
 */
const updatePoleData = (poleId: number) => {
  // Piniaストアからポール一覧を取得する
  const poleList = poleListStore.$state.poleList
  // ポール一覧が存在する場合、表示対象のポール情報を取得する
  if (poleList !== void 0 && poleList !== null && poleList.length > 0) {
    const target = poleList.find((pole: PoleListStore) => pole.poleId === poleId)
    if (target !== void 0) {
      selectPoleStore.setData (
        target.poleId,
        target.areaId,
        target.poleName,
        { lat: target.latitude, lng: target.longitude },
        target.sensorList
      )
      poleData.value = selectPoleStore.$state
      return
    }
  }
  // ポール一覧が存在しない場合、新規でポール情報を取得する
  const currentDate = DateTime.now().toFormat('yyyy/MM/dd HH:mm:ss')
  const getPoleFunc = getPoleList(null, poleId, currentDate, true)
  getPoleFunc
    .then(() => {
      // 取得したポール情報を選択対象のデータとして設定する
      const poleInfo = poleInfoStore.$state
      selectPoleStore.setData(
        poleInfo.poleId,
        poleInfo.areaId,
        poleInfo.poleName,
        { lat: poleInfo.latitude, lng: poleInfo.longitude },
        poleInfo.sensorList
      )
      log.info(selectPoleStore.$state)
    })
}

/**
 * 検索実行時処理
 */
const setupAggregation = () => {
  if (selectedCategory.value === 0) {
    dialogMessage.value = {
      isShow: true,
      title: '集計対象未入力',
      message: '集計対象を選択してください'
    }
    return
  }
  const span = aggregationFilterStore.mode.span
  if (span === 0) {
    dialogMessage.value = {
      isShow: true,
      title: '集計期間未入力',
      message: '集計期間（時間別、日別、月別）を選択してください'
    }
    return    
  }
  aggregationFilterStore.setMode(selectedCategory.value, span)
  updateAggregation(true)
}

/**
 * 子コンポーネントのメソッドを呼び出す
 * @param flag - 集計ボタン押下状態判別フラグ
 */
const updateAggregation = (flag: boolean) => {
  aggreMainComponent.value.updateAggregation(flag)
}

/**
 * ダイアログ表示内容設定
 * @param flag - エラー有無
 * @param title - エラータイトル
 * @param message - エラーメッセージ
 */
const setDialog = (flag: boolean, title: string, message: string) => {
  dialogMessage.value = {
    isShow: flag,
    title: title,
    message: message    
  }
  setLoading(false)
}

/**
 * エラーダイアログを閉じる
 */
const closeErrorDialog = () => {
  dialogMessage.value = {
    isShow: false,
    title: '',
    message: ''
  }
}

</script>
<template>
  <div class="aggregation">
    <TitleHeader :title-info="titleinfo" />
    <v-container fluid>
      <v-row>
        <v-col
          class="aggregation__side-bar"
          md="3"
        >
          <v-row
            class="aggregation__map"
            no-gutters
          >
            <v-col>
              <TargetMap :pole-data="poleData" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-expansion-panels
                v-model="sideMenuOpened"
                theme="dark"
                focusable
                multiple
              >
                <v-expansion-panel>
                  <v-expansion-panel-title>ポール一覧</v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <PoleListMini @update-pole-data="updatePoleData" />
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-title>集計対象</v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <v-radio-group
                      v-model="selectedCategory"
                      class="aggregation__radio-group"
                      theme="light"
                    >
                      <v-radio
                        label="ヒヤリハット"
                        :value="1"
                      />
                      <v-radio
                        label="通過台数"
                        :value="2"
                      />
                      <v-radio
                        label="侵入速度"
                        :value="3"
                      />
                    </v-radio-group>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <Button
                label="検索"
                :disabled="false"
                @click="setupAggregation"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <AggreMain
            ref="aggreMainComponent"
            :pole-data="poleData"
            :selected-category="selectedCategory"
            @loading-chart-data="setLoading"
            @set-dialog="setDialog"
          />
        </v-col>
      </v-row>
    </v-container>
    <Loading v-show="isLoading" />
    <ErrorDialog
      :error-dialog="dialogMessage"
      @on-click-close-error-dialog="closeErrorDialog"
    />
  </div>
</template>
<style lang="scss" scoped>
  .aggregation {
    height: calc(100vh - 48px);
    &__side-bar {
      height: calc(100vh - 88px);
      overflow-y: auto;
      position: relative;
      margin-left: 8px;
      margin-top: 8px;
    }
    &__map {
      margin: 8px 0;
    }
    &__radio-group {
      padding: 5px 10px;
    }
  }
  .v-expansion-panel-title {
    background-color: #0041c0;
    padding: 8px;
    min-height: auto;
  }
  .v-expansion-panel-title--active {
    min-height: auto !important;
  }
  .v-expansion-panel-text {
    background-color: #eee;
    color: #333;
  }
  .v-expansion-panel-text__wrap {
    padding: 0;
  }
</style>
