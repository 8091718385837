import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted, ref } from 'vue'

import { useSelectAreaStore, usePoleListStore } from '@/store/app'

import {
  DEFAULT_MARKER_ICON,
  SELECT_MARKER_ICON,
  getTileLayer,
  getMarkerInfo,
} from '@/mixins/mapFunction'

import { PoleListStore } from '@/types/Interfaces'

import { SELECT_AREA } from '@/setting/setting'

// leaflet Library
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

// ==================================
// interface
// ==================================
interface MarkerInfo {
  id: number;
  name: string;
  latlng: [number, number];
  marker: any;
} 

// ==================================
// data
// ==================================
// 選択エリア情報ストア

export default /*@__PURE__*/_defineComponent({
  __name: 'SelectArea',
  setup(__props, { expose: __expose }) {

/**
 * SelectArea.vue
 * 地域選択画面で表示する地図コンポーネント
 * 
 * 親コンポーネント
 * @/views/Area.vue
 */
// ==================================
// import
// ==================================
const selectAreaStore = useSelectAreaStore()

const poleListStore = usePoleListStore()

const markers = ref<MarkerInfo[]>([])

const selectId = ref<number | undefined>()

const map = ref<any>()

// ==================================
// hook
// ==================================
onMounted(() => {
  // マップ生成
  L.Icon.Default.mergeOptions(DEFAULT_MARKER_ICON)
  map.value = L.map('area-map', {
    dragging: true,
    touchZoom: true,
    scrollWheelZoom: true,
    doubleClickZoom: true,
    boxZoom: true,
    tap: true,
    keyboard: true,
    zoomControl: true,
    minZoom: SELECT_AREA.zoom.min,
    maxZoom: SELECT_AREA.zoom.max,
  }).setView(
    [SELECT_AREA.center.latitude, SELECT_AREA.center.longitude],
    SELECT_AREA.zoom.default
  )
  L.control
    .layers(getTileLayer(map.value, SELECT_AREA.zoom.max, undefined))
    .addTo(map.value)
})

// ==================================
// method
// ==================================
/**
 * ポール一覧からマーカーを設定する
 * @returns 実行完了フラグ
 */
const addAreaMarker = (): boolean => {
   poleListStore.poleList.forEach((pole: PoleListStore) => {
    if (pole.latlng != undefined && pole.address != undefined) {
    addMarker(pole.latlng, pole.address, pole.poleId, L.icon(DEFAULT_MARKER_ICON))
    }
  })
  return true
}

/**
 * 地点リスト選択時画面中央に表示
 * @param data - 地点リスト
 */
const setCenter = (data: any) => {
  // 具体的な場所を見やすくするために拡大
  let zoomLevel = map.value.getZoom();
  if (zoomLevel < 14) {
    zoomLevel = 14;
  }
  // 地図データの生成
  map.value.setView(data.latlng, zoomLevel)
  if (selectId.value !== void 0) {
    let marker = getMarkerInfo(selectId.value, markers.value)
    map.value.removeLayer(marker.marker)
    addMarker(
      marker.latlng,
      marker.name,
      marker.id,
      L.icon(DEFAULT_MARKER_ICON)
    )
  }

  // マーカーを生成
  let markerInfo = getMarkerInfo(data.poleId, markers.value)
  map.value.removeLayer(markerInfo.marker)
  addMarker(
    markerInfo.latlng,
    markerInfo.name,
    markerInfo.id,
    L.icon(SELECT_MARKER_ICON)
  )
  selectId.value = data.poleId
}

/**
 * マーカー設定
 * @param latlng - 緯度経度
 * @param name - ポール住所
 * @param poleId - ポールID
 * @param icon - アイコン情報
 */
const addMarker = (latlng: [number, number], name: string, poleId: number, icon: any) => {
  let marker = L.marker(latlng, {
    title: name,
    icon: icon,
  }).addTo(map.value)

  markers.value.push({
    id: poleId,
    name: name,
    latlng: latlng,
    marker: marker,
  })
}

/**
 * エリア情報設定
 * @param data - 地点リスト
 */
const enterPoint = (data: any) => {
  selectAreaStore.setData(data.id, data.name, data.latlng)
}

__expose({
  addAreaMarker,
  setCenter,
  enterPoint
})

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_v_main = _resolveComponent("v-main")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", {
      id: "area-map",
      class: "select-area"
    }, null, -1)),
    _createVNode(_component_v_main, { class: "select-area-router" }, {
      default: _withCtx(() => [
        _createVNode(_component_RouterView)
      ]),
      _: 1
    })
  ], 64))
}
}

})