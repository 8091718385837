import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "target-map" }

import { onBeforeMount, onMounted, watch } from 'vue'

import { useSelectAccidentStore } from '@/store/app'

import { getMarkerIcon, getTileLayer } from '@/mixins/mapFunction'

import { SelectPoleStore, SensorInfo, LatLng } from '@/types/Interfaces'

import { VIRTUAL_MAP } from '@/setting/setting'

import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-semicircle'
import 'leaflet-rotatedmarker'

// ==================================
// interface
// ==================================
interface Props {
  poleData: SelectPoleStore;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'TargetMap',
  props: {
    poleData: {}
  },
  setup(__props: any) {

/**
 * TargetMap.vue
 * 集計対象地図
 * 
 * 親コンポーネント
 * @/views/Aggregation.vue
 */
// ==================================
// import
// ==================================
const selectAccidentStore = useSelectAccidentStore()

const props = __props

const state = {
  sensorList: [],
  accidentPoint: undefined,
  map: {},
  poleMarkers: []
} as {
  sensorList: SensorInfo[],
  accidentPoint?: [number, number],
  map: any,
  poleMarkers: any[]
}

// ==================================
// watch
// ==================================
watch(() => props.poleData, () => {
  updateMap()
})

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  state.accidentPoint = selectAccidentStore.$state.latlng
})

onMounted(() => {
  setMapData()
})

// ==================================
// method
// ==================================
/**
 * 地図を表示する
 */
const setMapData = () => {
  state.sensorList = props.poleData.sensorList
  let latlng = props.poleData.latlng
  let zoom = VIRTUAL_MAP.zoom.default
  let zoom_min = VIRTUAL_MAP.zoom.min
  let zoom_max = VIRTUAL_MAP.zoom.max
  let zoom_max_native = VIRTUAL_MAP.zoom.maxNative
  state.map = L.map('target_map', {
    dragging: true,
    touchZoom: true,
    scrollWheelZoom: true,
    doubleClickZoom: true,
    boxZoom: true,
    tap: true,
    keyboard: true,
    zoomControl: true,
    minZoom: zoom_min,
    maxZoom: zoom_max
  }).setView(latlng, zoom)
  L.Icon.Default.mergeOptions(getMarkerIcon(false))
  L.control.layers(getTileLayer(state.map, zoom_max, zoom_max_native)).addTo(state.map)
  addPoleMarker(latlng)
}

/**
 * 指定されたポールの場所に地図を動かす
 */
const updateMap = () => {
  state.map.setView(props.poleData.latlng, VIRTUAL_MAP.zoom.default)
  L.Icon.Default.mergeOptions(getMarkerIcon(false))
  addPoleMarker(props.poleData.latlng)
}

/**
 * ポールのある場所にマーカーを追加する
 * @param latlng - 緯度経度
 */
 const addPoleMarker = (latlng: LatLng) => {
  state.poleMarkers = L.marker(latlng).addTo(state.map)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", {
      id: "target_map",
      class: "target-map__map"
    }, null, -1)
  ])))
}
}

})