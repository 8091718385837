import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'

import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'
import HistoryListTable from '@/components/parts/historyList/HistoryListTable.vue'

import { useSelectPoleStore } from '@/store/app'

import { ErrorDialogInfo, TitleInfo } from '@/types/Interfaces'

import { MENU } from '@/setting/setting'

// ==================================
// data
// ==================================
// ルーター定義

export default /*@__PURE__*/_defineComponent({
  __name: 'HistoryList',
  setup(__props) {

/**
 * HistoryList.vue
 * 過去データ一覧
 */
// ==================================
// import
// ==================================
const route = useRoute()

const selectPoleStore = useSelectPoleStore()

// タイトル
const titleinfo = ref<TitleInfo>({
  title: '',
  pointList: [],
  menuList: [],
  show: {
    menu: true,
  }
});

// エラーダイアログ
const errorDialog = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false,
});

// 他画面内ステータス
const poleid = ref<number>()
const directTransit = ref<any>(route.query.direct)
const isLoading = ref<boolean>(true)

// ==================================
// hook
// ==================================
 onBeforeMount(() => {
  initialize()
})

// ==================================
// method
// ==================================
/**
 * 値初期化処理
 */
const initialize = () => {
  directTransit.value = route.query.direct

  if (directTransit.value != 'false') {
    titleinfo.value.title = '過去データ(事故データ)一覧 最新20件'
    titleinfo.value.menuList = MENU.historyListDirect
  } else {
    titleinfo.value.title = '過去データ(事故データ)一覧 ' + selectPoleStore.$state.name
    titleinfo.value.menuList = MENU.historyList
    poleid.value = selectPoleStore.$state.poleId
  }
}

/**
 * エラーダイアログオープン処理
 * @param title - エラータイトル
 * @param message - エラーメッセージ
 */
const openErrorDialog = (title: string, message: string) => {
  errorDialog.value.title = title
  errorDialog.value.message = message
  errorDialog.value.isShow = true
}

/**
 * エラーダイアログクローズ処理
 */
const onClickCloseErrorDialog = () => {
  errorDialog.value.isShow = false
}

/**
 * ローディング処理開始
 */
const startLoading = () => {
  isLoading.value = true
}
/**
 * ローディング処理停止
 */
const stopLoading = () => {
  isLoading.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TitleHeader, { "title-info": titleinfo.value }, null, 8, ["title-info"]),
    _createVNode(_component_v_container, { "fill-height": "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              id: "history_list",
              cols: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(HistoryListTable, {
                  ref: "historyListTableComponent",
                  "pole-id": poleid.value,
                  "is-loading": isLoading.value,
                  onOpenErrorDialog: openErrorDialog,
                  onStartLoading: startLoading,
                  onStopLoading: stopLoading
                }, null, 8, ["pole-id", "is-loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _withDirectives(_createVNode(Loading, null, null, 512), [
      [_vShow, isLoading.value]
    ]),
    _createVNode(ErrorDialog, {
      "error-dialog": errorDialog.value,
      onOnClickCloseErrorDialog: onClickCloseErrorDialog
    }, null, 8, ["error-dialog"])
  ], 64))
}
}

})