import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "aggregation" }

import { onBeforeMount, watch, ref } from 'vue'

import AggreMain from '@/components/parts/aggregation/AggreMain.vue'
import PoleListMini from '@/components/parts/aggregation/PoleListMini.vue'
import TargetMap from '@/components/parts/aggregation/TargetMap.vue'
import Button from '@/components/parts/common/Button.vue'
import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'

import {
  useAggregationFiltersStore,
  usePoleListStore,
  usePoleInfoStore,
  useSelectPoleStore
} from '@/store/app'

import { getPoleList } from '@/mixins/communicationFunction'

import { ErrorDialogInfo, PoleListStore, SelectPoleStore, TitleInfo } from '@/types/Interfaces'

import { MENU } from '@/setting/setting'

import * as log from 'loglevel'
import { DateTime } from 'luxon'

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'Aggregation',
  setup(__props) {

/**
 * Aggregation.vue
 * 集計グラフ
 */
// ==================================
// import
// ==================================
const aggreMainComponent = ref()

const aggregationFilterStore = useAggregationFiltersStore()
const poleListStore = usePoleListStore()
const poleInfoStore = usePoleInfoStore()
const selectPoleStore = useSelectPoleStore()

const titleinfo = ref<TitleInfo>({
  title: '',
  pointList: [],
  menuList: MENU.aggregation,
  show: {
    logout: true,
    point: false,
    menu: true,
  },
})

const dialogMessage = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false,
})

const poleData = ref<SelectPoleStore>({
  poleId: 0,
  areaId: 0,
  name: '',
  latlng: {lat: 0, lng: 0},
  sensorList: []
})

const isLoading = ref<boolean>(false)

const sideMenuOpened = ref([0, 1])

const selectedCategory = ref(0)

// ==================================
// watch
// ==================================
watch(() => selectedCategory.value, (newval, oldval) => {
  // 集計対象のラジオボタンが初回選択の時だけ、Piniaストアの内容を更新する
  if (oldval === 0) {
    const span = aggregationFilterStore.$state.mode.span
    aggregationFilterStore.setMode(newval, span)
  }
})

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  initialize()
})

// ==================================
// method
// ==================================
/**
 * 初期処理
 */
const initialize = () => {
  aggregationFilterStore.setMode(selectedCategory.value, 0)
  poleData.value = selectPoleStore.$state
  titleinfo.value.title = poleData.value.name + ' 集計'
}

/**
 * ローディング画面の表示切替
 * @param flag - 表示フラグ値
 */
const setLoading = (flag: boolean) => {
  isLoading.value = flag
}

/**
 * ポール情報の更新
 * @param poleId - ポールID
 */
const updatePoleData = (poleId: number) => {
  // Piniaストアからポール一覧を取得する
  const poleList = poleListStore.$state.poleList
  // ポール一覧が存在する場合、表示対象のポール情報を取得する
  if (poleList !== void 0 && poleList !== null && poleList.length > 0) {
    const target = poleList.find((pole: PoleListStore) => pole.poleId === poleId)
    if (target !== void 0) {
      selectPoleStore.setData (
        target.poleId,
        target.areaId,
        target.poleName,
        { lat: target.latitude, lng: target.longitude },
        target.sensorList
      )
      poleData.value = selectPoleStore.$state
      return
    }
  }
  // ポール一覧が存在しない場合、新規でポール情報を取得する
  const currentDate = DateTime.now().toFormat('yyyy/MM/dd HH:mm:ss')
  const getPoleFunc = getPoleList(null, poleId, currentDate, true)
  getPoleFunc
    .then(() => {
      // 取得したポール情報を選択対象のデータとして設定する
      const poleInfo = poleInfoStore.$state
      selectPoleStore.setData(
        poleInfo.poleId,
        poleInfo.areaId,
        poleInfo.poleName,
        { lat: poleInfo.latitude, lng: poleInfo.longitude },
        poleInfo.sensorList
      )
      log.info(selectPoleStore.$state)
    })
}

/**
 * 検索実行時処理
 */
const setupAggregation = () => {
  if (selectedCategory.value === 0) {
    dialogMessage.value = {
      isShow: true,
      title: '集計対象未入力',
      message: '集計対象を選択してください'
    }
    return
  }
  const span = aggregationFilterStore.mode.span
  if (span === 0) {
    dialogMessage.value = {
      isShow: true,
      title: '集計期間未入力',
      message: '集計期間（時間別、日別、月別）を選択してください'
    }
    return    
  }
  aggregationFilterStore.setMode(selectedCategory.value, span)
  updateAggregation(true)
}

/**
 * 子コンポーネントのメソッドを呼び出す
 * @param flag - 集計ボタン押下状態判別フラグ
 */
const updateAggregation = (flag: boolean) => {
  aggreMainComponent.value.updateAggregation(flag)
}

/**
 * ダイアログ表示内容設定
 * @param flag - エラー有無
 * @param title - エラータイトル
 * @param message - エラーメッセージ
 */
const setDialog = (flag: boolean, title: string, message: string) => {
  dialogMessage.value = {
    isShow: flag,
    title: title,
    message: message    
  }
  setLoading(false)
}

/**
 * エラーダイアログを閉じる
 */
const closeErrorDialog = () => {
  dialogMessage.value = {
    isShow: false,
    title: '',
    message: ''
  }
}


return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TitleHeader, { "title-info": titleinfo.value }, null, 8, ["title-info"]),
    _createVNode(_component_v_container, { fluid: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              class: "aggregation__side-bar",
              md: "3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, {
                  class: "aggregation__map",
                  "no-gutters": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, null, {
                      default: _withCtx(() => [
                        _createVNode(TargetMap, { "pole-data": poleData.value }, null, 8, ["pole-data"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panels, {
                          modelValue: sideMenuOpened.value,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((sideMenuOpened).value = $event)),
                          theme: "dark",
                          focusable: "",
                          multiple: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_expansion_panel, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_expansion_panel_title, null, {
                                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                                    _createTextVNode("ポール一覧")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_v_expansion_panel_text, null, {
                                  default: _withCtx(() => [
                                    _createVNode(PoleListMini, { onUpdatePoleData: updatePoleData })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_expansion_panel, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_expansion_panel_title, null, {
                                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                                    _createTextVNode("集計対象")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_v_expansion_panel_text, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_radio_group, {
                                      modelValue: selectedCategory.value,
                                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedCategory).value = $event)),
                                      class: "aggregation__radio-group",
                                      theme: "light"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_radio, {
                                          label: "ヒヤリハット",
                                          value: 1
                                        }),
                                        _createVNode(_component_v_radio, {
                                          label: "通過台数",
                                          value: 2
                                        }),
                                        _createVNode(_component_v_radio, {
                                          label: "侵入速度",
                                          value: 3
                                        })
                                      ]),
                                      _: 1
                                    }, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, null, {
                      default: _withCtx(() => [
                        _createVNode(Button, {
                          label: "検索",
                          disabled: false,
                          onClick: setupAggregation
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createVNode(AggreMain, {
                  ref_key: "aggreMainComponent",
                  ref: aggreMainComponent,
                  "pole-data": poleData.value,
                  "selected-category": selectedCategory.value,
                  onLoadingChartData: setLoading,
                  onSetDialog: setDialog
                }, null, 8, ["pole-data", "selected-category"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _withDirectives(_createVNode(Loading, null, null, 512), [
      [_vShow, isLoading.value]
    ]),
    _createVNode(ErrorDialog, {
      "error-dialog": dialogMessage.value,
      onOnClickCloseErrorDialog: closeErrorDialog
    }, null, 8, ["error-dialog"])
  ]))
}
}

})