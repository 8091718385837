import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "collections" }

import { onBeforeMount, ref } from 'vue'

import PassingObjectCollection from '@/components/parts/collections/PassingObjectsCollection.vue'
import StopObjectsCollection from '@/components/parts/collections/StopObjectsCollection.vue'
import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'

import { ErrorDialogInfo, TitleInfo } from '@/types/Interfaces'

import { MENU } from '@/setting/setting'

// ==================================
// data
// ==================================
// タイトル情報

export default /*@__PURE__*/_defineComponent({
  __name: 'Collections',
  setup(__props) {

/**
 * Collections.vue
 * 集計画面
 */
// ==================================
// import
// ==================================
const titleInfo = ref<TitleInfo>({
  title: '集計画面',
  show: {
    logout: true,
    point: false,
    menu: true
  },
  pointList: [],
  menuList: []
})

// ダイアログ表示内容
const dialogMessage = ref<ErrorDialogInfo>({
  isShow: false,
  title: '',
  message: ''
})

// 集計画面メニュータブ
const collectionTabMenu = ref([
  { tab: '通過物標件数', disabled: false },
  { tab: '一時停止件数', disabled: false },
  { tab: 'ヒヤリハット件数', disabled: true }
])

const selectedTabName = ref('')

// ローディングアイコン表示フラグ
const isLoading = ref<boolean>(false)

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  // 右サイドメニューを取得する
  titleInfo.value.menuList = MENU.collection
})

// ==================================
// method
// ==================================
/**
 * ローディングアイコン表示を切り替える
 * @param flag - 表示フラグ
 */
const setLoading = (flag: boolean) => {
  isLoading.value = flag
}

/**
 * タイトル、メッセージを設定してダイアログを表示する
 * @param title - タイトル
 * @param message - メッセージ
 */
const displayDialog = (title: string, message: string) => {
  dialogMessage.value.title = title
  dialogMessage.value.message = message
  dialogMessage.value.isShow = true
}

/**
 * エラーダイアログを閉じる
 */
const onClickCloseErrorDialog = () => {
  dialogMessage.value.isShow = false
}

return (_ctx: any,_cache: any) => {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_tabs_window_item = _resolveComponent("v-tabs-window-item")!
  const _component_v_tabs_window = _resolveComponent("v-tabs-window")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TitleHeader, { "title-info": titleInfo.value }, null, 8, ["title-info"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_container, { class: "collections__container" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_tabs, {
                    modelValue: selectedTabName.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedTabName).value = $event)),
                    "bg-color": "#eef",
                    color: "#06c"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(collectionTabMenu.value, (item) => {
                        return (_openBlock(), _createBlock(_component_v_tab, {
                          key: item.tab,
                          disabled: item.disabled,
                          class: "collections__tab-text py-0",
                          "base-color": "#036"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.tab), 1)
                          ]),
                          _: 2
                        }, 1032, ["disabled"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_v_tabs_window, {
                    modelValue: selectedTabName.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedTabName).value = $event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_tabs_window_item, null, {
                        default: _withCtx(() => [
                          _createVNode(PassingObjectCollection, {
                            onSetLoading: setLoading,
                            onSetError: displayDialog
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_tabs_window_item, null, {
                        default: _withCtx(() => [
                          _createVNode(StopObjectsCollection, {
                            onSetLoading: setLoading,
                            onSetError: displayDialog
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_tabs_window_item, null, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode("ヒヤリハット件数一覧 ダミー")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(ErrorDialog, {
      "error-dialog": dialogMessage.value,
      onOnClickCloseErrorDialog: onClickCloseErrorDialog
    }, null, 8, ["error-dialog"]),
    _withDirectives(_createVNode(Loading, null, null, 512), [
      [_vShow, isLoading.value]
    ])
  ], 64))
}
}

})