import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, onMounted, onBeforeUnmount } from 'vue'

import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'
import InformationInTheIntersection from '@/components/parts/virtual/InformationInTheIntersection.vue'
import LedDisplayDataInformation from '@/components/parts/virtual/LedDisplayDataInformation.vue'
import VirtualVideo from '@/components/parts/virtual/VirtualVideo.vue'

import { useSelectPoleStore, usePoleInfoStore } from '@/store/app'

import { DIALOG_ERROR_INFO } from '@/mixins/commonFunction'
import { getPoleList, updatePosListReal, updatePosListWithPole } from '@/mixins/communicationFunction'
import { timerFunction } from '@/mixins/timerFunction'

import { ErrorDialogInfo, LatLng, SensorIdData, SensorInfo, TitleInfo } from '@/types/Interfaces'

import { MENU, POSUP_DATE_INTERVAL_REAL,POS_UPDATE_INTERVAL } from '@/setting/setting'

import * as log from 'loglevel'
import { DateTime } from 'luxon'

// ==================================
// data
// ==================================
// 選択ポール情報ストア
enum PlayMode {
  REALTIME = 'realTime',
  VIRTUAL = 'virtual',
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Virtual',
  setup(__props) {

/**
 * Virtual.vue
 * バーチャルモニター
 */
// ==================================
// import
// ==================================
const selectPoleStore = useSelectPoleStore()
// ポール情報ストア
const poleInfoStore = usePoleInfoStore()

// バーチャル映像コンポーネント
const virtualVideoComponent = ref()
// 交差点物標情報
const informationInTheIntersectionComponent = ref()
// LED表示板
const ledDisplayDataInformationComponent = ref()

// タイトル
const titleinfo = ref<TitleInfo>({
  title: selectPoleStore.name + ' バーチャルモニター',
  pointList: [],
  menuList: MENU.realtime,
  show: {
    realtime: true,
    multicamera: true,
    menu: true,
  },
})

// エラーダイアログ情報
const errorDialog = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false,
})

// 画面項目制御
const isLoading = ref<boolean>(true)

// 選択済みセンサー情報
const selectSensorList = ref<SensorIdData[]>([])

// 再生モード状況
const mode = ref<PlayMode>(PlayMode.REALTIME)

// ==================================
// hook
// ==================================
onMounted(async () => {
  // ポール設定
  addPoleMarker(selectPoleStore.latlng.lat, selectPoleStore.latlng.lng)
  // リアルタイム再生を実行
  play()
})

// 画面破棄前
onBeforeUnmount(() => {
  // 連続更新処理を止める
  timerFunction.methods.stop()
})

// ==================================
// method
// ==================================
/**
 * エラーダイアログを閉じる
 */
const onClickCloseErrorDialog = () => {
  errorDialog.value.isShow = false
}

/**
 * 選択済みのセンサーを更新
 * @param value - 選択されたセンサー情報
 */
const updateSelectSensorList = (value: SensorIdData[]) => {
  selectSensorList.value = value
}

/**
 * 一時停止
 */
const playPause = () => {
  // 連続更新処理を止める
  timerFunction.methods.stop()
}

/**
 * リアルタイムデータ取得
 */
const playRealtimeMode = async() => {
  isLoading.value = true
  mode.value = PlayMode.REALTIME
  // ポールIDに紐づくセンサー情報を取得
  getPoleList(null, selectPoleStore.poleId, null, true)
    // 成功時
    .then(() => {
      // センサー情報、LED表示板情報を初期化する
      initSensorList()
      initLedList()
      // 連続更新処理を開始する
      timerFunction.methods.start(selectPoleStore.poleId, POSUP_DATE_INTERVAL_REAL, timeoutPosListRealTimer)
      // ローディングアイコンを消す
      isLoading.value = false
    })
    // 失敗時
    .catch((err: number) => {
      // エラーをダイアログに表示
      log.error(err)
      updateErrorDialog(DIALOG_ERROR_INFO.title.getError, DIALOG_ERROR_INFO.message.getErrorSensorInfo)
      isLoading.value = false
    })
}

/**
 * 物標定期更新処理(時刻指定なし)
 * @param poleId - ポールID
 */
const timeoutPosListRealTimer = async (poleId: number) => {
  // センサーIDを一覧として取得
  let sensorIds: number[] = poleInfoStore.sensorList.map((obj: SensorInfo) => obj.sensorId)
  // リアルタイム更新処理
  updatePosListReal(poleId, sensorIds)
    .then(() => {
      // 各表の内容を更新する
      updatePosObject()
      intersectionUpdateList()
      letUpdateList()
    })
    .catch((err: number) => {
      log.error(err)
    })
}

/**
 * 過去データ取得
 * @param time - 開始日時
 */
const playVirtualMode = async(time: Date) => {
  // 過去表示モードに切り替える
  mode.value = PlayMode.VIRTUAL
  isLoading.value = true
  // 開始日時を文字列に変換
  const tempDatetime: string = DateTime.fromJSDate(time).plus({
    seconds: POS_UPDATE_INTERVAL/1000,
    }).toFormat('yyyy/MM/dd HH:mm:ss')
    updatePosListWithPole(selectPoleStore.poleId, tempDatetime,false,true)
    .then(() => {
      initSensorList()
      initLedList()
      updatePosObject()
      intersectionUpdateList()
      letUpdateList()
      timerFunction.methods.start(selectPoleStore.poleId, POS_UPDATE_INTERVAL, timeoutPosListWithPoleTimer)
      isLoading.value = false
    })
    .catch((err: number) => {
      isLoading.value = false
      log.error(err)
    })
}

/**
 * 物標定期更新処理(時刻指定あり)
 * @param poleId - ポールID
 */
const timeoutPosListWithPoleTimer = async (poleId: number) => {
  // 再生日時選択欄に入力した日時から1秒更新する
  let time = updateTime(POS_UPDATE_INTERVAL/1000)
  // 関数の戻り値にNULL、未定義が返ってくる場合があるため、分岐処理を追加
  if (time !== void 0 && time !== null) {
    // 日時を文字列に変換
    const tempDatetime: string = DateTime.fromJSDate(time).plus({
    seconds: POS_UPDATE_INTERVAL/1000,
    }).toFormat('yyyy/MM/dd HH:mm:ss')
  updatePosListWithPole(poleId, tempDatetime,false,false)
    .then(() => {
      // 各表の内容を更新する
      updatePosObject()
      intersectionUpdateList()
      letUpdateList()
    })
    .catch((err: number) => {
      log.error(err)
    })
  }
}

/**
 * エラーダイアログを表示する
 * @param title - タイトル
 * @param message - メッセージ
 */
const updateErrorDialog = (title: string, message: string): void => {
  errorDialog.value.title = title
  errorDialog.value.message = message
  errorDialog.value.isShow = true
}

/**
 * ポールマーカー追加
 * @param lat - 緯度
 * @param lng - 経度
 */
const addPoleMarker = (lat: number, lng: number) => {
  virtualVideoComponent.value.addPoleMarker(lat, lng)
}

/**
 * 物標情報更新
 */
const updatePosObject = () => {
  virtualVideoComponent.value.updatePosObject()
}
// 
/**
 * 交差点情報一覧の物標選択時マップ中央に表示
 * @param latlng - 緯度経度
 */
const setCenter = (latlng: LatLng) => {
  virtualVideoComponent.value.setCenter(latlng)
}

/**
 * 日付更新
 * @param updateSecondValue - 更新秒数
 */
const updateTime = (updateSecondValue: number): Date | null | undefined => {
  return virtualVideoComponent.value.updateTime(updateSecondValue)
}

/**
 * 画面の再生開始
 */
const play = () => {
  virtualVideoComponent.value.play()
}


/**
 * 交差点情報一覧更新
 */
const intersectionUpdateList = () => {
  informationInTheIntersectionComponent.value.updateList()
}

/**
 * センサーリスト初期化
 */
const initSensorList = () => {
  virtualVideoComponent.value.initSensorList()
}

/**
 * LED一覧初期化
 */
const initLedList = () => {
  ledDisplayDataInformationComponent.value.initLedList()
}

/**
 * LED一覧更新
 */
const letUpdateList = () => {
  ledDisplayDataInformationComponent.value.updateList()
}

return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TitleHeader, { "title-info": titleinfo.value }, null, 8, ["title-info"]),
    _createVNode(_component_v_container, {
      fluid: "",
      "fill-height": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { dense: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "8",
              class: "fill-height"
            }, {
              default: _withCtx(() => [
                _createVNode(VirtualVideo, {
                  ref_key: "virtualVideoComponent",
                  ref: virtualVideoComponent,
                  onSelectSensorListHandOver: updateSelectSensorList,
                  onPlayPouse: playPause,
                  onPlayRealtimeMode: playRealtimeMode,
                  onPlayVirtualMode: playVirtualMode,
                  onSetErrorDialog: updateErrorDialog
                }, null, 512)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { cols: "4" }, {
              default: _withCtx(() => [
                _createVNode(InformationInTheIntersection, {
                  ref_key: "informationInTheIntersectionComponent",
                  ref: informationInTheIntersectionComponent,
                  "select-sensor-list": selectSensorList.value,
                  onSelectObject: setCenter
                }, null, 8, ["select-sensor-list"]),
                _createVNode(LedDisplayDataInformation, {
                  ref_key: "ledDisplayDataInformationComponent",
                  ref: ledDisplayDataInformationComponent,
                  mode: mode.value,
                  "select-sensor-list": selectSensorList.value
                }, null, 8, ["mode", "select-sensor-list"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _withDirectives(_createVNode(Loading, null, null, 512), [
          [_vShow, isLoading.value]
        ]),
        _createVNode(ErrorDialog, {
          "error-dialog": errorDialog.value,
          onOnClickCloseErrorDialog: onClickCloseErrorDialog
        }, null, 8, ["error-dialog"])
      ]),
      _: 1
    })
  ], 64))
}
}

})