import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "select-pole-menu" }
const _hoisted_2 = { class: "select-pole-menu__pole-name" }
const _hoisted_3 = { key: 0 }

import { ref, watch } from 'vue'

import { useAreaListStore, useSelectAreaStore, useSelectPoleStore } from '@/store/app'

import { AreaInfo, PoleListStore } from '@/types/Interfaces'

import { POLE_SUB_MENU } from '@/setting/setting'

import router from '@/router'

// ==================================
// interface
// ==================================
interface Work {
  areaId: number;
  name: string;
  latitude: number;
  longitude: number;
  latlng: [number, number];
  notificationFlag: boolean;
  poleList: PoleListStore[];
  poleNumber: number;
}

interface Props {
  areaPoleList: Work[];
}

interface Emits {
  (e: 'pole-selected', pole: PoleListStore): void;
}

// ==================================
// data
// ==================================
// エリア一覧Piniaストア

export default /*@__PURE__*/_defineComponent({
  __name: 'SelectPoleMenu',
  props: {
    areaPoleList: {}
  },
  emits: ["pole-selected"],
  setup(__props: any, { emit: __emit }) {

/**
 * SelectPoleMenu.vue
 * ポール一覧メニュー
 * 
 * 親コンポーネント
 * @/views/Area.vue
 */
// ==================================
// import
// ==================================
const areaListStore = useAreaListStore()

const selectAreaStore = useSelectAreaStore()

// 選択されたポール情報Piniaストア
const selectPoleStore = useSelectPoleStore()

const props = __props

// メニュー表示フラグ
let isMenuOpen = false

// マーカーアイコン色設定
const iconColors: string[] = ['#999', '#0c0', '#F00']

const subMenu = ref(POLE_SUB_MENU)

const isMenuOpened = ref<number>(0)

// ==================================
// watch
// ==================================
watch(() => props.areaPoleList, val => {
  // 親コンポーネントからポール一覧を取得したらメニューを表示する
  if (val !== void 0 && val.length > 0) {
    isMenuOpen = true
  }
})

// ==================================
// method
// ==================================
const emit = __emit

/**
 * クリックした項目に合わせて、移動するページを設定する
 * @param action - 遷移ページ名
 */
const moveToNextPage = (action: string) => {
  switch (action) {
    // 現在データ
    case 'realTimeData':
      router.push({ path: '/realtime' })
      break
    // 過去データ
    case 'historyData':
      router.push({ path: '/historylist' })
      break
    // ヒヤリハット
    case 'accidentData':
      router.push({ path: '/accident' })
      break
    // 集計
    case 'collections':
      router.push({ path: '/collection' })
      break
    default:
  }
}

/**
 * ポール一覧から項目が選択された際に、ダイアログを表示させる
 * @param pole - ポール情報
 */
const poleSelected = (pole: PoleListStore) => {
  // 選択されたポールのあるエリアを登録する
  const areaList = areaListStore.$state.areaList
  const targetArea = areaList.find((area: AreaInfo) => area.areaId === pole.areaId)
  if (targetArea !== void 0) {
    selectAreaStore.setData(targetArea.areaId, targetArea.name, {lat: targetArea.latitude, lng: targetArea.longitude})
  }
  // ポールの位置情報を設定する
  const latlng = {
    lat: pole.latitude,
    lng: pole.longitude
  }
  selectPoleStore.setData(pole.poleId, pole.areaId, pole.poleName, latlng, pole.sensorList)
  emit('pole-selected', pole)
}

return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "select-pole-menu__title" }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" ポール一覧 ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_unref(isMenuOpen))
      ? (_openBlock(), _createBlock(_component_v_expansion_panels, {
          key: 0,
          modelValue: isMenuOpened.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isMenuOpened).value = $event)),
          variant: "accordion",
          theme: "dark",
          flat: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, { color: "#000" }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createElementVNode("span", { class: "select-pole-menu__subtitle" }, "エリア", -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, { class: "select-pole-menu__panel" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_expansion_panels, {
                      variant: "accordion",
                      theme: "dark",
                      flat: ""
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.areaPoleList, (item) => {
                          return (_openBlock(), _createBlock(_component_v_expansion_panel, {
                            key: item.areaId,
                            rounded: "false"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_expansion_panel_title, {
                                color: "#000",
                                "hide-actions": ""
                              }, {
                                default: _withCtx(({ expanded }) => [
                                  _createVNode(_component_v_row, {
                                    "no-gutters": "",
                                    dense: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, { md: "8" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("span", _hoisted_2, _toDisplayString(item.name), 1)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_v_spacer),
                                      _createVNode(_component_v_col, {
                                        class: "select-pole-menu__pole-count",
                                        md: "4"
                                      }, {
                                        default: _withCtx(() => [
                                          (!expanded)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "(" + _toDisplayString(item.poleNumber) + ")", 1))
                                            : _createCommentVNode("", true),
                                          expanded
                                            ? (_openBlock(), _createBlock(_component_v_icon, {
                                                key: 1,
                                                icon: "mdi-chevron-up"
                                              }))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_v_expansion_panel_text, { class: "select-pole-menu__panel" }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.poleList, (item2) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: item2.poleId,
                                      class: "select-pole-menu__menu-frame"
                                    }, [
                                      _createVNode(_component_v_btn, {
                                        variant: "plain",
                                        theme: "dark",
                                        "prepend-icon": "mdi-map-marker",
                                        class: "select-pole-menu__menu-button",
                                        onClick: ($event: any) => (poleSelected(item2))
                                      }, {
                                        prepend: _withCtx(() => [
                                          (item2.status != undefined)
                                            ? (_openBlock(), _createBlock(_component_v_icon, {
                                                key: 0,
                                                color: iconColors[item2.status]
                                              }, null, 8, ["color"]))
                                            : _createCommentVNode("", true)
                                        ]),
                                        default: _withCtx(() => [
                                          _createTextVNode(" " + _toDisplayString(item2.poleName !== '' ? item2.poleName : item2.address) + " ", 1),
                                          _createVNode(_component_v_menu, {
                                            activator: "parent",
                                            location: "end"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list, { class: "select-pole-menu__sub-menu" }, {
                                                default: _withCtx(() => [
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subMenu.value, (item3) => {
                                                    return (_openBlock(), _createBlock(_component_v_list_item, {
                                                      key: item3.action
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_list_item_title, { class: "select-pole-menu__sub-menu-title" }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_v_btn, {
                                                              variant: "plain",
                                                              theme: "dark",
                                                              class: "select-pole-menu__sub-menu-button",
                                                              onClick: ($event: any) => (moveToNextPage(item3.action))
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString(item3.name), 1)
                                                              ]),
                                                              _: 2
                                                            }, 1032, ["onClick"])
                                                          ]),
                                                          _: 2
                                                        }, 1024)
                                                      ]),
                                                      _: 2
                                                    }, 1024))
                                                  }), 128))
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"])
                                    ]))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ]))
}
}

})