import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted, ref } from 'vue'

import { useSelectPoleStore, useAccidentListStore, useNearMissListStore } from '@/store/app'

import { POLE_ICON, NEAR_MISS_ICON, ACCIDENT_ICON, getTileLayer } from '@/mixins/mapFunction'

import { VIRTUAL_MAP } from '@/setting/setting'

import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

// ==================================
// interface
// ==================================
interface MarkerList {
  poleMarkers: Array<any>;
  nearMissMarker: Array<any>;
  accidentMarker: Array<any>;
  markers: Array<any>;
}

// ==================================
// data
// ==================================
// 選択ポール情報ストア

export default /*@__PURE__*/_defineComponent({
  __name: 'VirtualVideo',
  setup(__props, { expose: __expose }) {

/**
 * VirtualVideo.vue
 * 検索エリア
 * 
 * 親コンポーネント
 * @/views/Accident.vue
 */
// ==================================
// import
// ==================================
const selectPoleStore = useSelectPoleStore()

// ヒヤリハット情報一覧
const nearMissListStore = useNearMissListStore()

// 事故情報一覧ストア
const accidentListStore = useAccidentListStore()

const markerList = ref<MarkerList>({
  poleMarkers: [],
  nearMissMarker: [],
  accidentMarker: [],
  markers: []
})

const map = ref<any>()

// ==================================
// hook
// ==================================
onMounted(() => {
  // マップ生成
  L.Icon.Default.mergeOptions(POLE_ICON)
  map.value = L.map('accident-map', {
    dragging: true,
    touchZoom: true,
    scrollWheelZoom: true,
    doubleClickZoom: true,
    boxZoom: true,
    tap: true,
    keyboard: true,
    zoomControl: true,
    minZoom: VIRTUAL_MAP.zoom.min,
    maxZoom: VIRTUAL_MAP.zoom.max,
  }).setView([selectPoleStore.latlng.lat, selectPoleStore.latlng.lng], VIRTUAL_MAP.zoom.default)
  L.control.layers(getTileLayer(map.value, VIRTUAL_MAP.zoom.max, undefined)).addTo(map.value)
})

// ==================================
// method
// ==================================
/**
 * ポールマーカー設定
 * @param lat - 緯度
 * @param lng - 経度
 */
const addPoleMarker = (lat: number, lng: number) => {
  removePoleMarker()
  markerList.value.poleMarkers.push(
    L.marker([lat, lng], {
      icon: L.icon(POLE_ICON),
    }).addTo(map.value)
  )
}

/**
 * ポールマーカー設定削除
 */
const removePoleMarker = () => {
  for (const item of markerList.value.poleMarkers) {
    map.value.removeLayer(item)
  }
  markerList.value.poleMarkers.splice(0)
}

/**
 * ヒヤリハットマーカー設定
 */
const addNearMissMarker = () => {
  removeNearMissMarker()
  for (const item of nearMissListStore.nearMissList) {
    markerList.value.nearMissMarker.push(
      L.marker([item.latitude, item.longitude], {
        icon: L.icon(NEAR_MISS_ICON),
      }).addTo(map.value)
    )
  }
}

/**
 * ヒヤリハットマーカー削除
 */
const removeNearMissMarker = () => {
  for (const item of markerList.value.nearMissMarker) {
    map.value.removeLayer(item)
  }
  markerList.value.nearMissMarker.splice(0)
}

/**
 * 事故情報マーカー設定
 */
const addAccidentMarker = () => {
  removeAccidentMarker()
  for (const item of accidentListStore.accidentList) {
    markerList.value.accidentMarker.push(
      L.marker([item.latitude, item.longitude], {
        icon: L.icon(ACCIDENT_ICON),
      })
        .bindPopup(
          `<ul>
							<li>事故ID: ${item.accidentId}</li>
							<li>事故発生時刻: ${item.accidentTime}</li>
						</ul>`
        )
        .addTo(map.value)
    )
  }
}

/**
 * 事故情報マーカー設定削除
 */
const removeAccidentMarker = () => {
  for (const item of markerList.value.accidentMarker) {
    map.value.removeLayer(item)
  }
  markerList.value.accidentMarker.splice(0)
}

__expose({
  addPoleMarker,
  addNearMissMarker,
  addAccidentMarker,
})

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "virtual-video",
    elevation: "1",
    outlined: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, { class: "virtual-video__header py-0 d-flex" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("span", { class: "py-5" }, "VIRTUAL映像", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_v_divider),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "virtual-video__body pt-0" }, [
        _createElementVNode("div", {
          id: "accident-map",
          class: "virtual-video__accident-map"
        })
      ], -1))
    ]),
    _: 1
  }))
}
}

})