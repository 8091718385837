import { defineComponent as _defineComponent } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'HistoryData',
  setup(__props) {

    //

return (_ctx: any,_cache: any) => {
  return " HistoryData "
}
}

})