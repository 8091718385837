import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "title-header__title" }
const _hoisted_2 = ["textContent"]

import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'

import SelectAreaList from '@/components/parts/area/SelectAreaList.vue'
import Logout from '@/components/parts/common/Logout.vue'
import MenuList from '@/components/parts/common/MenuList.vue'

import { useUserInfoStore, useAreaListStore, usePoleListStore, useSelectAreaStore, useSelectPoleStore } from '@/store/app'

import { MenuInfo, TitleInfo } from '@/types/Interfaces'

import { Auth } from 'aws-amplify'

// ==================================
// interface
// ==================================
interface Props {
  titleInfo: TitleInfo;
}

interface Emits {
  (e: 'on-select-point', value: any): void;
  (e: 'on-enter-point', value: any): void;
}

interface PoleList {
  poleId: number;
  name: string;
  path: string;
  nearMissPath: string;
}
// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'TitleHeader',
  props: {
    titleInfo: {}
  },
  emits: ["on-select-point", "on-enter-point"],
  setup(__props: any, { emit: __emit }) {

/**
 * TitleHeader.vue
 * 共通タイトルヘッダー
 * 
 * 親コンポーネント
 * @/views/Accident.vue
 * @/views/Aggregation.vue 
 * @/views/Area.vue
 * @/views/Collections.vue
 * @/views/GroupSetting.vue
 * @/views/HistoryList.vue
 * @/views/Login.vue
 * @/views/MacroAnalysis.vue
 * @/views/MacroAnalysisInfoDataDownload.vue
 * @/views/MultiCamera.vue
 * @/views/RealTime.vue
 * @/views/Virtual.vue
 * @/views/Watch.vue
 */
// ==================================
// import
// ==================================
const router = useRouter()

const userInfoStore = useUserInfoStore()

const areaListStore = useAreaListStore()

const poleListStore = usePoleListStore()

const selectAreaStore = useSelectAreaStore()

const selectPoleStore = useSelectPoleStore()

const props = __props

const logoutComponent = ref()

const isPoint = ref(false)

const isMenu = ref(false)

// ==================================
// computed
// ==================================
// タイトル表示
const showTitle = computed(() => {
  return props.titleInfo.title
})
// 地点リスト表示
const isShowPoint = computed(() => {
  return props.titleInfo.show.point
})
const pointList = computed(() => {
  return props.titleInfo.pointList
})

// メニューリスト表示
const menuList = computed(() => {
  return props.titleInfo.menuList
})

// ==================================
// method
// ==================================
const emit = __emit
/**
 * 地点リスト選択時画面中央に表示
 * @param data - 地点リスト
 */
const selectPoint = (data: any) => {
  emit('on-select-point', data)
}
/**
 * エリア情報設定
 * @param data - 地点リスト
 */
const enterPoint = (data: any) => {
  emit('on-enter-point', data)
}

/**
 * 地点リストアイコン押下
 */
const onClickPoint = () => {
  isPoint.value = !isPoint.value
  // 地点リスト以外はすべて閉じる
  if (isPoint.value) {
    isMenu.value = false
  }
}

/**
 * メニューリストアイコン押下
 */
const onClickMenu = () => {
  isMenu.value = !isMenu.value
  // メニュー以外はすべて閉じる
  if (isMenu.value) {
    isPoint.value = false
  }
}

/**
 * メニュータブから画面遷移
 * @param data - 選択したメニュー情報
 */
const selectMenu = (data: MenuInfo) => {
  isMenu.value = false
  switch (data.item) {
    case 'selectArea':
      router.push('/selectarea')
      break
    case 'selectPole':
      router.push('/selectpole')
      break
    case 'accidents':
      router.push('/accident')
      break
    case 'parking':
      router.push('/parking')
      break
    case 'summary':
      router.push('/summaries')
      break
    case 'aggregation':
      router.push('/aggregation')
      break
    case 'dashboard':
      router.push('/dashboard')
      break
    case 'history':
      router.push('/historylist')
      break
    case 'macroAnalysis':
      router.push('/macroAnalysis')
      break
    case 'macroAnalysisInfoDataDownload':
      router.push('/macroAnalysisInfoDataDownload')
      break
    case 'watching':
      router.push('/watching')
      break
    case 'collection': 
      router.push('/collection')
      break
    case 'groupSetting':
      router.push('/groupsetting')
      break
    case 'logout':
      openLogoutDialog()
    break
      
    default:
  }
}

/**
 * ログアウト処理
 */
const openLogoutDialog = async() => {
  const result = await logoutComponent.value.open()
  // ログアウトした場合、AWSのサインアウトと、エリア、ポール情報をクリアーにする
  if (result) {
    Auth.signOut()
    userInfoStore.clearData()
    areaListStore.clearData()
    poleListStore.clearData()
    selectAreaStore.clearData()
    selectPoleStore.clearData()
    router.push('/')
  }
}

/**
 * メニューMULTI CAMERAボタンの操作
 */
const onClickMultiCamera = () =>{
  router.push('/multicamera')
}
/**
 * メニューVIRTUALボタンの操作
 */
const onClickVirtual = () =>{
  router.push('/virtual')
}
/**
 * メニューREALTIMEボタンの操作
 */
const onClickRealtime = () =>{
  router.push('/realtime')
}
/**
 * メニュー前に戻るボタンの操作
 */
const onClickBack = () =>{
  router.go(-1)
}

/**
 * GRAFANAボタン押下時処理
 */
const onClickGrafana = () => {
  // 対象ポールの場合、Grafanaへの画面遷移
  const pId = props.titleInfo.poleId
  if (props.titleInfo.poleList !== undefined) {
    const poleList = props.titleInfo.poleList
    poleList.forEach((pole: PoleList) => {
      if (pId == pole.poleId) {
        window.open(pole.path, '_blank')
      }
    });
  }
}

return (_ctx: any,_cache: any) => {
  const _component_v_app_bar_title = _resolveComponent("v-app-bar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_app_bar, {
      app: "",
      color: "#0041c0",
      dark: "",
      nav: "",
      dense: "",
      density: "comfortable",
      class: "title-header"
    }, {
      append: _withCtx(() => [
        (_ctx.titleInfo.show.grafana == true)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 0,
              onClick: onClickGrafana
            }, {
              default: _withCtx(() => [
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "title-header__icon-text" }, "GRAFANA", -1)),
                _createVNode(_component_v_icon, { color: "white" }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" mdi-arrange-send-backward ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.titleInfo.show.realtime == true)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 1,
              onClick: onClickRealtime
            }, {
              default: _withCtx(() => [
                _cache[5] || (_cache[5] = _createElementVNode("span", { class: "title-header__icon-text" }, "REALTIME", -1)),
                _createVNode(_component_v_icon, { color: "white" }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" mdi-arrange-send-backward ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.titleInfo.show.virtual == true)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 2,
              onClick: onClickVirtual
            }, {
              default: _withCtx(() => [
                _cache[7] || (_cache[7] = _createElementVNode("span", { class: "title-header__icon-text" }, "VIRTUAL", -1)),
                _createVNode(_component_v_icon, { color: "white" }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode(" mdi-arrange-send-backward ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.titleInfo.show.multicamera == true)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 3,
              onClick: onClickMultiCamera
            }, {
              default: _withCtx(() => [
                _cache[9] || (_cache[9] = _createElementVNode("span", { class: "title-header__icon-text" }, "MULTI CAMERA", -1)),
                _createVNode(_component_v_icon, { color: "white" }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode(" mdi-arrange-send-backward ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.titleInfo.show.back == true)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 4,
              onClick: onClickBack
            }, {
              default: _withCtx(() => [
                _cache[11] || (_cache[11] = _createElementVNode("span", null, "前に戻る", -1)),
                _createVNode(_component_v_icon, { color: "white" }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode(" mdi-arrange-send-backward ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (isShowPoint.value == true && isPoint.value == false)
          ? (_openBlock(), _createBlock(_component_v_app_bar_nav_icon, {
              key: 5,
              onClick: onClickPoint
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { color: "white" }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode(" mdi-view-list ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (isShowPoint.value == true && isPoint.value == true)
          ? (_openBlock(), _createBlock(_component_v_app_bar_nav_icon, {
              key: 6,
              onClick: onClickPoint
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { color: "white" }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode(" mdi-view-list ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_v_spacer),
        (_ctx.titleInfo.show.menu == true && isMenu.value == false)
          ? (_openBlock(), _createBlock(_component_v_app_bar_nav_icon, {
              key: 7,
              onClick: onClickMenu
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { color: "white" }, {
                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createTextVNode(" mdi-menu ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.titleInfo.show.menu == true && isMenu.value == true)
          ? (_openBlock(), _createBlock(_component_v_app_bar_nav_icon, {
              key: 8,
              onClick: onClickMenu
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { color: "white" }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createTextVNode(" mdi-menu ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_app_bar_title, null, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, [
              _createElementVNode("span", {
                textContent: _toDisplayString(showTitle.value)
              }, null, 8, _hoisted_2)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_v_spacer)
      ]),
      _: 1
    }),
    _createVNode(_component_v_navigation_drawer, {
      modelValue: isPoint.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isPoint).value = $event)),
      absolute: "",
      theme: "dark",
      permanent: "",
      location: "right",
      width: "350"
    }, {
      default: _withCtx(() => [
        _createVNode(SelectAreaList, {
          "point-list": pointList.value,
          onOnSelectPoint: selectPoint,
          onOnEnterPoint: enterPoint
        }, null, 8, ["point-list"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_navigation_drawer, {
      modelValue: isMenu.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((isMenu).value = $event)),
      absolute: "",
      theme: "dark",
      permanent: "",
      location: "right",
      width: "350"
    }, {
      default: _withCtx(() => [
        _createVNode(MenuList, {
          "menu-list": menuList.value,
          onOnSelectMenu: selectMenu
        }, null, 8, ["menu-list"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(Logout, {
      ref_key: "logoutComponent",
      ref: logoutComponent
    }, null, 512)
  ], 64))
}
}

})