import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

import { computed } from 'vue'

// ==================================
// interface
// ==================================
interface Props {
  videoId: string;
  isPlay: boolean;
  videoStream: MediaStream;
  class: string;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'AutoPlayVideo',
  props: {
    videoId: {},
    isPlay: { type: Boolean },
    videoStream: {},
    class: {}
  },
  setup(__props: any) {

/**
 * AutoPlayVideo.vue
 * 映像自動再生コンポーネント
 * 
 * 親コンポーネント
 * @/components/parts/common/KinesisVideoStreamsWebrtc.vue
 */
// ==================================
// import
// ==================================
const props = __props

// ==================================
// computed
// ==================================
const isPlay = computed(() => {
  return props.isPlay
})

const videoStream = computed(() => {
  return props.videoStream
})

const videoId = computed(() => {
  return props.videoId
})

const divClass = computed(() => {
  return props.class
})

return (_ctx: any,_cache: any) => {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(divClass.value)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(divClass.value + '__spinner')
    }, [
      _withDirectives(_createVNode(_component_v_progress_circular, {
        size: 70,
        color: "red",
        indeterminate: ""
      }, null, 512), [
        [_vShow, !videoStream.value && isPlay.value == true]
      ])
    ], 2),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(divClass.value + '__comment')
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(divClass.value + '__comment__notplay')
      }, " 再生できません ", 2)
    ], 2), [
      [_vShow, isPlay.value == false]
    ]),
    _withDirectives(_createElementVNode("video", {
      id: videoId.value,
      autoplay: "true",
      controls: "true"
    }, null, 8, _hoisted_1), [
      [_vShow, videoStream.value]
    ])
  ], 2))
}
}

})