import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/csvDownloadIcon.png'


const _hoisted_1 = { class: "passing-objects-collection" }
const _hoisted_2 = { class: "passing-objects-collection__table-unit" }

import { onBeforeMount, mergeProps, ref } from 'vue'

import { useSelectPoleStore, usePassingObjectsCollectionListStore, useVdiParamListStore } from '@/store/app'

import { getPassingObjectCollectionData, getVdiParametersList } from '@/mixins/communicationFunction'

import { PassingObjectInfo, SelectPoleStore } from '@/types/Interfaces'

import { DIRECTIONS, OBJECT_INFO_LIST_FOR_COLLECTION } from '@/setting/setting'

import { DateTime } from 'luxon'

// ==================================
// interface
// ==================================
interface Emits {
  (e: 'set-loading', flag: boolean): void;
  (e: 'set-error', title: string, message: string): void;
}

interface DatePicked {
  start: boolean;
  end: boolean;
}

interface CollectionSpan {
  start?: Date;
  end?: Date;
}

interface DisplaySpan {
  start: string;
  end: string;
}

// ==================================
// data
// ==================================
const DATE_FORMAT = 'yyyy-MM-dd'

// ポール情報

export default /*@__PURE__*/_defineComponent({
  __name: 'PassingObjectsCollection',
  emits: ["set-loading", "set-error"],
  setup(__props, { emit: __emit }) {

/**
 * PassingObjectCollection.vue 
 * 通過物標件数集計画面
 * 
 * 親コンポーネント
 * @/views/Collections.vue
 */
// ==================================
// import
// ==================================
const selectPoleStore = useSelectPoleStore()

const passingObjectStore = usePassingObjectsCollectionListStore()

const vdiParamListStore = useVdiParamListStore()

const poleData = ref<SelectPoleStore>({
  poleId: 0,
  areaId: 0,
  latlng: {lat: 0, lng: 0},
  name: '',
  sensorList: []
})

// 一覧タイトル(ポール名を追加する)
const collectionTitle = ref('')

// 通過物標件数データ
const passingObjectCollectionList = ref<PassingObjectInfo[]>([])

// 表示対象となる方位名リスト
const vdiParamList = ref<string[]>([])

// 一覧表表示フラグ
const collectionsEnabled = ref<boolean>(false)

// 一覧表ヘッダー
const collectionListHeader = ref<any[]>([])
const csvHeader = ref('')

// 一覧表ボディ
const collectionData = ref<any>([])

// 一覧表オプション
const perPage = ref<number>(10)
const perPageOptions = ref([
  {value: 5, title: '5'},
  {value: 10, title: '10'},
  {value: 20, title: '20'},
  {value: -1, title: '$vuetify.dataFooter.itemsPerPageAll'}
])

// 集計用一覧テンプレート
const collectionDataTemplate = ref<any>({})

// デートピッカー表示フラグ
const datePicked = ref<DatePicked>({
  start: false,
  end: false
})

// 取得日付範囲
const collectedSpan = ref<CollectionSpan>({
  start: undefined,
  end: undefined
})

// テキストフィールド表示用取得日付範囲
const displayedSpan = ref<DisplaySpan>({
  start: '',
  end: ''
})

// 入力フォームのバリデーションルール
const validateRules = ref({
  // 終了日
  startLimit: (value: string): boolean | string => {
    const target = DateTime.fromFormat(value, DATE_FORMAT)
    if (DateTime.now().diff(target, 'days').days < 1) {
      return '開始日は前日以前の日付を入力してください。'
    } else {
      return true
    }
  },
  // 終了日
  endLimit: (value: string): boolean | string => {
    const target = DateTime.fromFormat(value, DATE_FORMAT)
    if (DateTime.now().diff(target, 'days').days < 1) {
      return '終了日は前日以前の日付を入力してください。'
    } else {
      return true
    }
  },
  // 開始、終了日の整合性
  diffDays: (value: string): boolean | string => {
    const start = DateTime.fromFormat(value, DATE_FORMAT)
    const end = DateTime.fromFormat(displayedSpan.value.end, DATE_FORMAT)
    if (start.diff(end, 'days').days > 0) {
      return '開始日は終了日の前の日を入力してください。'
    } else {
      return true
    }
  }
})

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  // ポール情報を取得する
  poleData.value = selectPoleStore.getSelectPole
  collectionTitle.value = '通過物標件数　' + poleData.value.name
  // 前回表示したポール情報と同一の場合、最後に選択した範囲で日付を設定する
  if (poleData.value.poleId === passingObjectStore.$state.poleId) {
    displayedSpan.value = {
      start: passingObjectStore.$state.startDate,
      end: passingObjectStore.$state.endDate
    }
    collectedSpan.value = {
      start: DateTime.fromFormat(passingObjectStore.$state.startDate, DATE_FORMAT).toJSDate(),
      end: DateTime.fromFormat(passingObjectStore.$state.endDate, DATE_FORMAT).toJSDate(),
    }
  } else {
    // 取得期間として、10日前から前日までに設定する
    collectedSpan.value = {
      start: DateTime.now().minus({ days: 10 }).toJSDate(),
      end: DateTime.now().minus({ days: 1 }).toJSDate()
    }
    displayedSpan.value = {
      start: collectedSpan.value.start !== void 0 ? DateTime.fromJSDate(collectedSpan.value.start).toFormat(DATE_FORMAT) : '',
      end: collectedSpan.value.end !== void 0 ? DateTime.fromJSDate(collectedSpan.value.end).toFormat(DATE_FORMAT): ''
    }
  }
  // 前回表示したポール情報と同一の場合、Piniaストアに保存済みの方位リストを設定する
  if (poleData.value.poleId === vdiParamListStore.$state.poleId) {
    vdiParamList.value = vdiParamListStore.$state.vdiParamList
  }
  // 通過物標件数を取得する
  setPassingObjectCollection()
})

// ==================================
// method
// ==================================
const emit = __emit

/**
 * 通過物標件数データの取得
 */
const setPassingObjectCollection = () => {
  emit('set-loading', true)
  // 表示用の一覧表データをリセットする
  collectionListHeader.value = []
  collectionData.value = []

  // APIからデータを取得する
  let promise = undefined
  if (collectedSpan.value.start !== void 0 && collectedSpan.value.end !== void 0) {
    const startDate = DateTime.fromJSDate(collectedSpan.value.start)
    const endDate = DateTime.fromJSDate(collectedSpan.value.end)
    // 初期状態の日付範囲の場合、ポールIDのみ指定して呼び出す
    if (startDate.diff(DateTime.now().minus({ days: 10 }), 'days').days === 0 && endDate.diff(DateTime.now().minus({ days: 1 }), 'days').days === 0) {
      promise = getPassingObjectCollectionData(null, null, poleData.value.poleId)
    } else {
      promise = getPassingObjectCollectionData(startDate.toFormat(DATE_FORMAT), endDate.toFormat(DATE_FORMAT), poleData.value.poleId)
    }
  } else {
    return
  }
  promise
    .then(res => {
      // 戻り値に通過物標データが含まれているかをチェック
      if (res !== void 0 && res !== null && res.objects.length > 0) {
        // ステートを更新する
        passingObjectCollectionList.value = res.objects
        setVdiParamList()
      } else {
        // 取得内容が空値の場合、例外を発生させてエラーメッセージを表示する
        throw new Error('500')
      }
    })
    .catch((error) => {
      if (error === '500' || error === 500) {
        emit('set-error', '通過物標件数なし', '通過物標件数の登録はありませんでした。')
      } else {
        emit('set-error', '通過物標件数取得異常', '通過物標件数取得中に異常が発生しました。: ' + error)
      }
      emit('set-loading', false)
    })
}

/**
 * 方路方位リストの取得
 */
const setVdiParamList = () => {
  // リストをリセットする
  vdiParamList.value = []
  const promise = getVdiParametersList(poleData.value.poleId)
  promise
    .then(res => {
      if (res !== void 0 && res !== null && res.direction.length > 0) {
        vdiParamList.value = res.direction
        createList()
      } else {
        throw new Error('500')
      }
    })
    .catch((error) => {
      if (error === '500' || error === 500) {
        emit('set-error', '方位リストなし', '方位リストの登録はありませんでした。')
      } else {
        emit('set-error', '方位リスト取得異常', '方位リスト取得中に異常が発生しました。')
      }
      emit('set-loading', false)
    })
}

/**
 * 一覧表を生成する
 */
const createList = () => {
  // 一覧のヘッダーを生成する
  collectionListHeader.value = [
    { title: '日付', value: 'collectDate', minWidth: '120px', align: 'center', sortable: true, class: 'passing-objects-collection__header-text' },
    { title: '総数', value: 'total', minWidth: '80px', align: 'right', sortable: true, class: 'passing-objects-collection__header-text' }
  ]
  // CSVのカラム行を生成する
  csvHeader.value = '日付, 総数'
  // 方位と物標の組み合わせでカラム名を生成し、ヘッダーに追加する
  for (let vdi of vdiParamList.value) {
    let dirDesc = '不明'
    const targetDir = DIRECTIONS.find(dir => dir.name === vdi)
    if (targetDir !== void 0) {
      dirDesc = targetDir.desc
      // 集計データを保存するオブジェクトのテンプレートを生成する
      collectionDataTemplate.value[vdi] = {}
    // 物標は、あらかじめ取得しうる項目をすべて含める
    OBJECT_INFO_LIST_FOR_COLLECTION.forEach(obj => {
      collectionDataTemplate.value[vdi][obj.kind.toString()] = 0
      collectionListHeader.value.push({
        title: dirDesc + ' / ' + obj.name,
        value: targetDir.name + '_' + obj.kind,
        minWidth: '160px',
        align: 'right',
        sortable: true
      })
      csvHeader.value += ', ' + dirDesc + ' / ' + obj.name
    })        
    }
  }
  // 通過物標件数一覧表の生成
  let targetDate = displayedSpan.value.start
  do {
    // 日単位の集計表をテンプレートから設定する
    let work = JSON.parse(JSON.stringify(collectionDataTemplate.value))
    let outputData = {
      collectDate: '',
      total: 0
    } as any
    // 対象の日のデータを抽出する
    const targetData = passingObjectCollectionList.value.filter(obj => obj.date.substring(0, 10) === targetDate)
    if (targetData.length !== 0) {
      // 対象の日のデータが存在する場合、各項目の件数を抽出し、合計を算出する
      let total = 0
      // 取得した通過物標件数リストを日付単位に抽出する
      for (let data of targetData) {
        work[data.accessroad][data.vehiclesizeclassification] = data.vehiclecount
        total += data.vehiclecount
      }
      // 一覧表示用の行データを設定する
      outputData = {
        collectDate: targetDate,
        total: total
      }
      for (let direction in work) {
        for (let vehicleCode in work[direction]) {
          // 方位名、物標コードのキーから、一覧表用のカラム名を設定する
          const numberKey = direction + '_' + vehicleCode
          // 対象の方位、物標の件数を設定する
          outputData[numberKey] = work[direction][vehicleCode]
        }
      }
    } else {
      // 対象の日のデータが存在しない場合、合計、各項目の値としてハイフンを設定する
      outputData = {
        collectDate: targetDate,
        total: '-'
      }
      for (let key1 in work) {
        for (let key2 in work[key1]) {
          const numberKey = key1 + '_' + key2
          outputData[numberKey] = '-'
        }
      }
    }
    // 対象の日付分のデータを追加する
    collectionData.value.push(outputData)
    // 対象の日付を1日進める
    targetDate = DateTime.fromFormat(targetDate, DATE_FORMAT).plus({ 'days': 1 }).toFormat(DATE_FORMAT)
  } while (DateTime.fromFormat(targetDate, DATE_FORMAT).diff(DateTime.fromFormat(displayedSpan.value.end, DATE_FORMAT), 'days').days <= 0)

  // 一覧表データを追加したら表示させる
  emit('set-loading', false)
  collectionsEnabled.value = true
}

/**
 * デートピッカーで選択された値から、取得開始日を更新する
 * @param date - 選択された日付
 */
const updateCollectedSpanStart = (date: Date) => {
  collectedSpan.value.start = date
  displayedSpan.value.start = DateTime.fromJSDate(date).toFormat(DATE_FORMAT)
  // 更新したらデートピッカーを閉じる
  datePicked.value.start = false
  validateCollectionSpan()
}

/**
 * デートピッカーで選択された値から、取得終了日を更新する
 * @param date - 選択された日付
 */
const updateCollectedSpanEnd = (date: Date) => {
  collectedSpan.value.end = date
  displayedSpan.value.end = DateTime.fromJSDate(date).toFormat(DATE_FORMAT)
  datePicked.value.end = false
  validateCollectionSpan()
}

/**
 * 入力された取得期間のバリデーションチェックを行う
 */
const validateCollectionSpan = () => {
  const start = DateTime.fromFormat(displayedSpan.value.start, DATE_FORMAT)
  const end = DateTime.fromFormat(displayedSpan.value.end, DATE_FORMAT)
  // 取得開始日がアクセス時の前日化をチェック
  if (DateTime.now().diff(start, 'days').days < 1) {
    emit('set-error', '不正な開始日', '開始日は前日以前の日付を入力してください。')
    return
  }
  // 取得終了日がアクセス時の前日以前かをチェック
  if (DateTime.now().diff(end, 'days').days < 1) {
    emit('set-error', '不正な終了日', '終了日は前日以前の日付を入力してください。')
    return
  }
  // 開始日が終了日の後になっていないかをチェック
  if (start.diff(end, 'days').days > 0) {
    emit('set-error', '日付指定の不正', '開始日は終了日の前の日を入力してください。')
    return
  }
  // バリデーションに問題がなければ通過物標件数データを再取得する
  setPassingObjectCollection()
}

/**
 * CSVファイルを生成して自動ダウンロードさせる
 */
const createCsv = () => {
  // 通過物標データがない場合は、エラーを出して終了する
  if (collectionData.value === void 0 || collectionData.value.length === 0) {
    emit('set-error', '通過物標データなし', '出力するデータはありません。')
    return
  }
  let csvBody = ''
  // 画面表示用の一覧データからCSVボディ部分を生成する
  collectionData.value.forEach((data: any) => {
    for (let key in data) {
      // 合計の値が0の場合、ハイフンを設定する
      if (key === 'total') {
        const outputTotal = data[key] === 0 ? '-' : data[key]
        csvBody += outputTotal + ', '
      } else {
        csvBody += data[key] + ', '
      }
    }
    csvBody = csvBody.slice(0, -2)
    csvBody += '\n'
  })
  // CSVファイルの生成
  const csv = csvHeader.value + '\n' + csvBody
  const bom = new Uint8Array([0xef, 0xbb, 0xbf])
  const blob = new Blob([bom, csv], { type: 'text/csv' })
  // 画面内にリンクを生成して、自動でクリックすることでダウンロードを実施させる
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  // ファイル名は、ポールID(16進数表記)、選択期間を付与する形で設定する
  link.download =
    '通過物標件数集計一覧' +
    '_' +
    toHexNumber(poleData.value.poleId) +
    '_' +
    displayedSpan.value.start.replace(/\//g, '_') +
    '～' +
    displayedSpan.value.end.replace(/\//g, '_') +
    '.csv'
  link.click()
}

/**
 * 対象の数値を16進表記にする
 * @param val - 数値
 * @returns 16進表記文字列
 */
const toHexNumber = (val: number): string => {
  return '0x' + val.toString(16).padStart(8, '0')
}

return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_date_picker = _resolveComponent("v-date-picker")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_layout, { style: {"position":"related","height":"70px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              sm: "1",
              class: "passing-objects-collection__search-title"
            }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode(" 選択期間 ")
              ])),
              _: 1
            }),
            _createVNode(_component_v_col, {
              sm: "3",
              md: "2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_menu, {
                  modelValue: datePicked.value.start,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((datePicked.value.start) = $event)),
                  "return-value": collectedSpan.value.start,
                  "onUpdate:returnValue": _cache[4] || (_cache[4] = ($event: any) => ((collectedSpan.value.start) = $event)),
                  "close-on-content-click": false,
                  transition: "scale-transition",
                  "offset-y": "",
                  "min-width": "auto"
                }, {
                  activator: _withCtx(({ props: menu }) => [
                    _createVNode(_component_v_text_field, _mergeProps({
                      modelValue: displayedSpan.value.start,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((displayedSpan.value.start) = $event)),
                      label: "開始日",
                      "prepend-inner-icon": "mdi-calendar",
                      readonly: "",
                      rules: [validateRules.value.startLimit, validateRules.value.diffDays]
                    }, mergeProps(menu), {
                      "onClick:prependInner": _cache[1] || (_cache[1] = ($event: any) => (datePicked.value.start = true))
                    }), null, 16, ["modelValue", "rules"])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_date_picker, {
                      modelValue: collectedSpan.value.start,
                      "onUpdate:modelValue": [
                        _cache[2] || (_cache[2] = ($event: any) => ((collectedSpan.value.start) = $event)),
                        updateCollectedSpanStart
                      ]
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["modelValue", "return-value"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              sm: "3",
              md: "2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_menu, {
                  modelValue: datePicked.value.end,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((datePicked.value.end) = $event)),
                  "return-value": collectedSpan.value.end,
                  "onUpdate:returnValue": _cache[9] || (_cache[9] = ($event: any) => ((collectedSpan.value.end) = $event)),
                  "close-on-content-click": false,
                  transition: "scale-transition",
                  "offset-y": "",
                  "min-width": "auto"
                }, {
                  activator: _withCtx(({ props: menu }) => [
                    _createVNode(_component_v_text_field, _mergeProps({
                      modelValue: displayedSpan.value.end,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((displayedSpan.value.end) = $event)),
                      label: "終了日",
                      "prepend-inner-icon": "mdi-calendar",
                      readonly: "",
                      rules: [validateRules.value.endLimit]
                    }, mergeProps(menu), {
                      "onClick:prependInner": _cache[6] || (_cache[6] = ($event: any) => (datePicked.value.end = true))
                    }), null, 16, ["modelValue", "rules"])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_date_picker, {
                      modelValue: collectedSpan.value.end,
                      "onUpdate:modelValue": [
                        _cache[7] || (_cache[7] = ($event: any) => ((collectedSpan.value.end) = $event)),
                        updateCollectedSpanEnd
                      ]
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["modelValue", "return-value"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { sm: "1" }, {
              default: _withCtx(() => [
                _createElementVNode("button", { onClick: createCsv }, _cache[12] || (_cache[12] = [
                  _createElementVNode("img", {
                    src: _imports_0,
                    class: "passing-objects-collection__csv-icon"
                  }, null, -1)
                ]))
              ]),
              _: 1
            }),
            _createVNode(_component_v_spacer)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_layout, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "passing-objects-collection__table-frame" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, {
                  outlined: "",
                  elevation: "5"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, { class: "passing-objects-collection__title-text py-0" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(collectionTitle.value), 1)
                      ]),
                      _: 1
                    }),
                    (collectionsEnabled.value)
                      ? (_openBlock(), _createBlock(_component_v_card_text, { key: 0 }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_2, [
                              _createVNode(_component_v_data_table, {
                                "items-per-page": perPage.value,
                                "onUpdate:itemsPerPage": _cache[10] || (_cache[10] = ($event: any) => ((perPage).value = $event)),
                                headers: collectionListHeader.value,
                                items: collectionData.value,
                                "items-per-page-options": perPageOptions.value,
                                class: "elevation-1"
                              }, null, 8, ["items-per-page", "headers", "items", "items-per-page-options"])
                            ])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})