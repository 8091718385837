import { defineComponent as _defineComponent } from 'vue'
import { vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "macro-analysis-table" }
const _hoisted_2 = { colspan: "2" }
const _hoisted_3 = { colspan: "2" }
const _hoisted_4 = { colspan: "2" }
const _hoisted_5 = { colspan: "2" }
const _hoisted_6 = { class: "macro-analysis-table__period-a" }
const _hoisted_7 = { class: "macro-analysis-table__period-b" }
const _hoisted_8 = { class: "macro-analysis-table__period-a" }
const _hoisted_9 = { class: "macro-analysis-table__period-b" }
const _hoisted_10 = { class: "macro-analysis-table__period-a" }
const _hoisted_11 = { class: "macro-analysis-table__period-b" }
const _hoisted_12 = { class: "macro-analysis-table__period-a" }
const _hoisted_13 = { class: "macro-analysis-table__period-b" }
const _hoisted_14 = { class: "macro-analysis-table__period-a" }
const _hoisted_15 = { class: "macro-analysis-table__period-b" }
const _hoisted_16 = { class: "macro-analysis-table__period-a" }
const _hoisted_17 = { class: "macro-analysis-table__period-b" }
const _hoisted_18 = { class: "macro-analysis-table__period-a" }
const _hoisted_19 = { class: "macro-analysis-table__period-b" }
const _hoisted_20 = { class: "macro-analysis-table__period-a" }
const _hoisted_21 = { class: "macro-analysis-table__period-b" }
const _hoisted_22 = { class: "macro-analysis-table__period-a" }
const _hoisted_23 = { class: "macro-analysis-table__period-b" }
const _hoisted_24 = { class: "macro-analysis-table__period-a" }
const _hoisted_25 = { class: "macro-analysis-table__period-b" }
const _hoisted_26 = { class: "macro-analysis-table__period-a" }
const _hoisted_27 = { class: "macro-analysis-table__period-b" }
const _hoisted_28 = { class: "macro-analysis-table__period-a" }
const _hoisted_29 = { class: "macro-analysis-table__period-b" }
const _hoisted_30 = { class: "macro-analysis-table__period-a" }
const _hoisted_31 = { class: "macro-analysis-table__period-b" }
const _hoisted_32 = { class: "macro-analysis-table__period-a" }
const _hoisted_33 = { class: "macro-analysis-table__period-b" }
const _hoisted_34 = { class: "macro-analysis-table__period-a" }
const _hoisted_35 = { class: "macro-analysis-table__period-b" }
const _hoisted_36 = { class: "macro-analysis-table__period-a" }
const _hoisted_37 = { class: "macro-analysis-table__period-b" }
const _hoisted_38 = { class: "macro-analysis-table__period-a" }
const _hoisted_39 = { class: "macro-analysis-table__period-b" }
const _hoisted_40 = { class: "macro-analysis-table__period-a" }
const _hoisted_41 = { class: "macro-analysis-table__period-b" }
const _hoisted_42 = { class: "macro-analysis-table__period-a" }
const _hoisted_43 = { class: "macro-analysis-table__period-b" }
const _hoisted_44 = { class: "macro-analysis-table__period-a" }
const _hoisted_45 = { class: "macro-analysis-table__period-b" }
const _hoisted_46 = { class: "macro-analysis-table__period-a" }
const _hoisted_47 = { class: "macro-analysis-table__period-b" }
const _hoisted_48 = { class: "macro-analysis-table__period-a" }
const _hoisted_49 = { class: "macro-analysis-table__period-b" }
const _hoisted_50 = { class: "macro-analysis-table__period-a" }
const _hoisted_51 = { class: "macro-analysis-table__period-b" }
const _hoisted_52 = { class: "macro-analysis-table__period-a" }
const _hoisted_53 = { class: "macro-analysis-table__period-b" }
const _hoisted_54 = { class: "macro-analysis-table__period-a" }
const _hoisted_55 = { class: "macro-analysis-table__period-b" }
const _hoisted_56 = { class: "macro-analysis-table__period-a" }
const _hoisted_57 = { class: "macro-analysis-table__period-b" }
const _hoisted_58 = { class: "macro-analysis-table__period-a" }
const _hoisted_59 = { class: "macro-analysis-table__period-b" }
const _hoisted_60 = { class: "macro-analysis-table__period-a" }
const _hoisted_61 = { class: "macro-analysis-table__period-b" }
const _hoisted_62 = {
  colspan: "2",
  class: "macro-analysis-table__period-a"
}
const _hoisted_63 = {
  colspan: "2",
  class: "macro-analysis-table__period-a"
}
const _hoisted_64 = {
  colspan: "2",
  class: "macro-analysis-table__period-a"
}
const _hoisted_65 = {
  colspan: "2",
  class: "macro-analysis-table__period-a"
}
const _hoisted_66 = { class: "macro-analysis-table__period-a" }
const _hoisted_67 = { class: "macro-analysis-table__period-b" }
const _hoisted_68 = { class: "macro-analysis-table__period-a" }
const _hoisted_69 = { class: "macro-analysis-table__period-b" }
const _hoisted_70 = { class: "macro-analysis-table__period-a" }
const _hoisted_71 = { class: "macro-analysis-table__period-b" }
const _hoisted_72 = { class: "macro-analysis-table__period-a" }
const _hoisted_73 = { class: "macro-analysis-table__period-b" }
const _hoisted_74 = {
  colspan: "2",
  class: "macro-analysis-table__period-a"
}
const _hoisted_75 = {
  colspan: "2",
  class: "macro-analysis-table__period-a"
}
const _hoisted_76 = {
  colspan: "2",
  class: "macro-analysis-table__period-a"
}
const _hoisted_77 = {
  colspan: "2",
  class: "macro-analysis-table__period-a"
}

import { shallowRef, ref } from 'vue'

import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import MacroAnalysisDetailInfoTable from '@/components/parts/macroAnalysis/MacroAnalysisDetailInfoTable.vue'
import MacroAnalysisHistgram from '@/components/parts/macroAnalysis/MacroAnalysisHistgram.vue'
import ModalMacroAnalysisHistgram from '@/components/parts/macroAnalysis/ModalMacroAnalysisHistgram.vue'

import { useMacroAnalysisInfoStore } from '@/store/app'

import { DIALOG_ERROR_INFO } from '@/mixins/commonFunction'
import { updateMacroAnalysisInfo, updateMacroAnalysisDetaiInfo } from '@/mixins/communicationFunction'

import { ErrorDialogInfo } from '@/types/Interfaces'

// ==================================
// interface
// ==================================
interface MacroTermData {
  number: number;
  avgSpeed: number;
  maxSpeed: number;
  mode: number;
  median: number;
  std: number;
  avgSpeedHist: number[];
  maxSpeedHist: number[];
}
interface MacroDiffData {
  avgSpeedHist: number[];
  maxSpeedHist: number[];
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'MacroAnalysisTable',
  setup(__props, { expose: __expose }) {

/**
 * MacroAnalysisTable.vue
 * マクロ分析一覧画面
 * 
 * 親コンポーネント
 * @/views/MacroAnalysis.vue
 */
// ==================================
// import
// ==================================
const macroAnalysisInfoStore = useMacroAnalysisInfoStore()

/**
 * モーダル表示用コンポーネント
 */
const shallowState = shallowRef({
  prefix: {
    component: null,
    props: {}
  } as {
    component: any,
    props: any
  }
})

const dialog = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false
})

const sendData = ref({
  poleId: 0 as number,
  address: '' as string,
  aggregationPeriod: {
    start: '' as string,
    end: '' as string
  },
  comparisonPeriod: {
    start: '' as string,
    end: '' as string
  },
  directionValue: '' as string,
  ledValue: '' as string,
  aggregationTargetValue: '' as string,
  aggregationMethodValue: '' as string
})

const isViewDetailInfo = ref<boolean>(false)

const isLoading = ref<boolean>(false)

const isDisplay = ref<boolean>(false)

const isViewGraph = ref<boolean>(false)

// 期間Aデータ
const termA = ref({
  enter: {
    number: 0,
    avgSpeed: 0,
    maxSpeed: 0,
    mode: 0,
    median: 0,
    std: 0,
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroTermData,
  stopLine: {
    number: 0,
    avgSpeed: 0,
    maxSpeed: 0,
    mode: 0,
    median: 0,
    std: 0,
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroTermData,
  stopLineMentalStopLine: {
    number: 0,
    avgSpeed: 0,
    maxSpeed: 0,
    mode: 0,
    median: 0,
    std: 0,
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroTermData,
  mentalStopLine: {
    number: 0,
    avgSpeed: 0,
    maxSpeed: 0,
    mode: 0,
    median: 0,
    std: 0,
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroTermData
})
// 期間Bデータ
const termB = ref({
  enter: {
    number: 0,
    avgSpeed: 0,
    maxSpeed: 0,
    mode: 0,
    median: 0,
    std: 0,
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroTermData,
  stopLine: {
    number: 0,
    avgSpeed: 0,
    maxSpeed: 0,
    mode: 0,
    median: 0,
    std: 0,
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroTermData,
  stopLineMentalStopLine: {
    number: 0,
    avgSpeed: 0,
    maxSpeed: 0,
    mode: 0,
    median: 0,
    std: 0,
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroTermData,
  mentalStopLine: {
    number: 0,
    avgSpeed: 0,
    maxSpeed: 0,
    mode: 0,
    median: 0,
    std: 0,
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroTermData
})

// 差分データ
const diff = ref({
  enter: {
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroDiffData,
  stopLine: {
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroDiffData,
  stopLineMentalStopLine: {
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroDiffData,
  mentalStopLine: {
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroDiffData
})

// グラフ表示オプション
const avgSpeedGraphSetting = ref({
  xAxes: {
    scaleLabelDisplay: true,
    labelString: '平均速度'
  },
  yAxes: {
    scaleLabelDisplay: true,
    labelString: '台数'
  },
  termA: {
    backgroundColor: 'rgba(65, 158, 143, 0.8)'
  },
  termB: {
    backgroundColor: 'rgba(160, 158, 34, 0.8)'
  }
})
const avgSpeedHistGraphSetting = ref({
  xAxes: {
    scaleLabelDisplay: true,
    labelString: '平均速度'
  },
  yAxes: {
    scaleLabelDisplay: true,
    labelString: '差分'
  },
  title: {
    display: true,
    text: '期間Aから期間Bを引いた差分値'
  },
  backgroundColor: 'rgba(236, 97, 4, 0.8)'
})
const maxSpeedGraphSetting = ref({
  xAxes: {
    scaleLabelDisplay: true,
    labelString: '最高速度'
  },
  yAxes: {
    scaleLabelDisplay: true,
    labelString: '台数'
  },
  termA: {
    backgroundColor: 'rgba(65, 158, 143, 0.8)'
  },
  termB: {
    backgroundColor: 'rgba(160, 158, 34, 0.8)'
  }
})
const maxSpeedHistGraphSetting = ref({
  xAxes: {
    scaleLabelDisplay: true,
    labelString: '最高速度'
  },
  yAxes: {
    scaleLabelDisplay: true,
    labelString: '差分'
  },
  title: {
    display: true,
    text: '期間Aから期間Bを引いた差分値'
  },
  backgroundColor: 'rgba(236, 97, 4, 0.8)'
})
const sectionMaxBorder = ref({
  avgSpeedHist: 1,
  maxSpeedHist: 1,
  diff: {
    avg: {
      suggestedMax: 1,
      suggestedMin: 0,
      maxTicksLimit: 2
    },
    max: {
      suggestedMax: 1,
      suggestedMin: 0,
      maxTicksLimit: 2
    }
  }
})

// ==================================
// watch
// ==================================
// ==================================
// computed
// ==================================
// ==================================
// hook
// ==================================
// ==================================
// method
// ==================================
/**
 * 速度グラフの最大値を取得する
 * @param array1 - 速度時系列リスト
 * @returns 最大速度
 */
const getBorderMaxData = (array1: number[][]): number => {
  let tempArray = []
  for (const data of array1)
    for (const item of data) {
      tempArray.push(item)
    }
  return Math.max(...tempArray)
}

/**
 * ヒストグラムの境界値を取得する
 * @param array1 - 速度時系列リスト
 * @returns 境界値リスト
 */
const getDiffBorderData = (array1: number[][]): any => {
  let tempArray = [];
  for (const data of array1)
    for (const item of data) {
      tempArray.push(item)
    }
  let max = Math.max(...tempArray)
  let min = Math.min(...tempArray)

  // 初期値設定
  let suggestedMaxValue = 1
  let suggestedMinValue = 0
  let maxTicksLimit = 2

  // boarder範囲の作成
  if (max >= 1 && min >= 0) {
    suggestedMaxValue = max
    suggestedMinValue = 0
  } else if (max <= 0 && min < 0) {
    suggestedMaxValue = 0
    suggestedMinValue = min
  } else if (max == 0 && min == 0) {
    suggestedMaxValue = 1
    suggestedMinValue = 0
    maxTicksLimit = 2
  } else {
    suggestedMaxValue = max
    suggestedMinValue = min
    maxTicksLimit = 3
  }
  return {
    suggestedMax: suggestedMaxValue,
    suggestedMin: suggestedMinValue,
    maxTicksLimit: maxTicksLimit
  }
}

/**
 * フォームで指定されたパラメータをもとに、マクロ分析データを取得する
 * @param sendParams - パラメータ群
 */
const getData = async(sendParams: any) => {
  sendData.value = sendParams
  isLoading.value = true
  isViewGraph.value = false
  let promise = updateMacroAnalysisInfo(
    sendData.value.poleId,
    sendData.value.aggregationPeriod.start.replaceAll('-', '/') + ' 00:00:00',
    sendData.value.aggregationPeriod.end.replaceAll('-', '/') + ' 00:00:00',
    sendData.value.comparisonPeriod.start.replaceAll('-', '/') + ' 00:00:00',
    sendData.value.comparisonPeriod.end.replaceAll('-', '/') + ' 00:00:00',
    sendData.value.directionValue,
    sendData.value.ledValue,
    sendData.value.aggregationTargetValue,
    sendData.value.aggregationMethodValue
  )
  promise
    .then(() => {
      // データが存在するか判定
      if (macroAnalysisInfoStore.$state.macroAnalysisInfo.enter.number.length !== 0) {
        setData()
      } else {
        // 存在しない場合は空のデータをセットする
        clearData()
        dialog.value.title = ''
        dialog.value.message = DIALOG_ERROR_INFO.message.getErrorMacroAnalysisInfoNoContent
        dialog.value.isShow = true
      }
    })
    .then(() => {
      isViewGraph.value = true
      isLoading.value = false
    })
    .catch(() => {
      // 取得に失敗したらエラーメッセージをダイアログで表示する
      isViewGraph.value = true
      isLoading.value = false
      dialog.value.title = DIALOG_ERROR_INFO.title.getError
      dialog.value.message = DIALOG_ERROR_INFO.message.getErrorMacroAnalysisInfo
      dialog.value.isShow = true
    })
}

/**
 * 分析用データを設定する
 */
const setData = () => {
  // 入力元データをオブジェクトとして設定
  const enter = macroAnalysisInfoStore.$state.macroAnalysisInfo.enter
  const stopLine = macroAnalysisInfoStore.$state.macroAnalysisInfo.stopLine
  const stopLineMentalStopLine = macroAnalysisInfoStore.$state.macroAnalysisInfo.stopLineMentalStopLine
  const mentalStopLine = macroAnalysisInfoStore.$state.macroAnalysisInfo.mentalStopLine
  // データA
  termA.value = {
    // 進入路
    enter: {
      number: enter.number[0],
      avgSpeed: enter.avgSpeed[0],
      maxSpeed: enter.maxSpeed[0],
      mode: enter.mode[0],
      median: enter.median[0],
      std: enter.std[0],
      avgSpeedHist: Object.values(enter.avgHist[0]),
      maxSpeedHist: Object.values(enter.maxHist[0])
    },
    // 停止線データ
    stopLine: {
      number: stopLine.number[0],
      avgSpeed: stopLine.avgSpeed[0],
      maxSpeed: stopLine.maxSpeed[0],
      mode: stopLine.mode[0],
      median: stopLine.median[0],
      std: stopLine.std[0],
      avgSpeedHist: Object.values(stopLine.avgHist[0]),
      maxSpeedHist: Object.values(stopLine.maxHist[0])       
    },
    // 停止線～心的停止データ
    stopLineMentalStopLine: {
      number: stopLineMentalStopLine.number[0],
      avgSpeed: stopLineMentalStopLine.avgSpeed[0],
      maxSpeed: stopLineMentalStopLine.maxSpeed[0],
      mode: stopLineMentalStopLine.mode[0],
      median: stopLineMentalStopLine.median[0],
      std: stopLineMentalStopLine.std[0],
      avgSpeedHist: Object.values(stopLineMentalStopLine.avgHist[0]),
      maxSpeedHist: Object.values(stopLineMentalStopLine.maxHist[0]) 
    },
    // 心的停止データ
    mentalStopLine: {
      number: mentalStopLine.number[0],
      avgSpeed: mentalStopLine.avgSpeed[0],
      maxSpeed: mentalStopLine.maxSpeed[0],
      mode: mentalStopLine.mode[0],
      median: mentalStopLine.median[0],
      std: mentalStopLine.std[0],
      avgSpeedHist: Object.values(mentalStopLine.avgHist[0]),
      maxSpeedHist: Object.values(mentalStopLine.maxHist[0])       
    }
  }

  // データB
  termB.value = {
    // 進入路
    enter: {
      number: enter.number[1],
      avgSpeed: enter.avgSpeed[1],
      maxSpeed: enter.maxSpeed[1],
      mode: enter.mode[1],
      median: enter.median[1],
      std: enter.std[1],
      avgSpeedHist: Object.values(enter.avgHist[1]),
      maxSpeedHist: Object.values(enter.maxHist[1])
    },
    // 停止線データ
    stopLine: {
      number: stopLine.number[1],
      avgSpeed: stopLine.avgSpeed[1],
      maxSpeed: stopLine.maxSpeed[1],
      mode: stopLine.mode[1],
      median: stopLine.median[1],
      std: stopLine.std[1],
      avgSpeedHist: Object.values(stopLine.avgHist[1]),
      maxSpeedHist: Object.values(stopLine.maxHist[1])       
    },
    // 停止線～心的停止データ
    stopLineMentalStopLine: {
      number: stopLineMentalStopLine.number[1],
      avgSpeed: stopLineMentalStopLine.avgSpeed[1],
      maxSpeed: stopLineMentalStopLine.maxSpeed[1],
      mode: stopLineMentalStopLine.mode[1],
      median: stopLineMentalStopLine.median[1],
      std: stopLineMentalStopLine.std[1],
      avgSpeedHist: Object.values(stopLineMentalStopLine.avgHist[1]),
      maxSpeedHist: Object.values(stopLineMentalStopLine.maxHist[1]) 
    },
    // 心的停止データ
    mentalStopLine: {
      number: mentalStopLine.number[1],
      avgSpeed: mentalStopLine.avgSpeed[1],
      maxSpeed: mentalStopLine.maxSpeed[1],
      mode: mentalStopLine.mode[1],
      median: mentalStopLine.median[1],
      std: mentalStopLine.std[1],
      avgSpeedHist: Object.values(mentalStopLine.avgHist[1]),
      maxSpeedHist: Object.values(mentalStopLine.maxHist[1])       
    }
  }

  // 差分データ
  diff.value = {
    enter: {
      avgSpeedHist: Object.values(enter.avgHist[2]),
      maxSpeedHist: Object.values(enter.maxHist[2])      
    },
    stopLine: {
      avgSpeedHist: Object.values(stopLine.avgHist[2]),
      maxSpeedHist: Object.values(stopLine.maxHist[2])
    },
    stopLineMentalStopLine: {
      avgSpeedHist: Object.values(stopLineMentalStopLine.avgHist[2]),
      maxSpeedHist: Object.values(stopLineMentalStopLine.maxHist[2])      
    },
    mentalStopLine: {
      avgSpeedHist: Object.values(mentalStopLine.avgHist[2]),
      maxSpeedHist: Object.values(mentalStopLine.maxHist[2])      
    }
  }

  // border設定
  sectionMaxBorder.value.avgSpeedHist = getBorderMaxData([
    termA.value.enter.avgSpeedHist,
    termB.value.enter.avgSpeedHist,
    termA.value.stopLine.avgSpeedHist,
    termB.value.stopLine.avgSpeedHist,
    termA.value.stopLineMentalStopLine.avgSpeedHist,
    termB.value.stopLineMentalStopLine.avgSpeedHist,
    termA.value.mentalStopLine.avgSpeedHist,
    termB.value.mentalStopLine.avgSpeedHist
  ])

  sectionMaxBorder.value.maxSpeedHist = getBorderMaxData([
    termA.value.enter.maxSpeedHist,
    termB.value.enter.maxSpeedHist,
    termA.value.stopLine.maxSpeedHist,
    termB.value.stopLine.maxSpeedHist,
    termA.value.stopLineMentalStopLine.maxSpeedHist,
    termB.value.stopLineMentalStopLine.maxSpeedHist,
    termA.value.mentalStopLine.maxSpeedHist,
    termB.value.mentalStopLine.maxSpeedHist
  ])

  sectionMaxBorder.value.diff.avg = getDiffBorderData([
    diff.value.enter.avgSpeedHist,
    diff.value.stopLine.avgSpeedHist,
    diff.value.stopLineMentalStopLine.avgSpeedHist,
    diff.value.mentalStopLine.avgSpeedHist
  ])

  sectionMaxBorder.value.diff.max = getDiffBorderData([
    diff.value.enter.maxSpeedHist,
    diff.value.stopLine.maxSpeedHist,
    diff.value.stopLineMentalStopLine.maxSpeedHist,
    diff.value.mentalStopLine.maxSpeedHist
  ])
}

/**
 * 分析用データを初期化する
 */
const clearData = () => {
  // 初期化データ
  const defaultDataSet: MacroTermData = {
    number: 0,
    avgSpeed: 0,
    maxSpeed: 0,
    mode: 0,
    median: 0,
    std: 0,
    avgSpeedHist: [],
    maxSpeedHist: []    
  }
  const defaultDiffDataSet: MacroDiffData = {
    avgSpeedHist: [],
    maxSpeedHist: []     
  }

  termA.value = {
    enter: defaultDataSet,
    stopLine: defaultDataSet,
    stopLineMentalStopLine: defaultDataSet,
    mentalStopLine: defaultDataSet
  }
  termB.value = {
    enter: defaultDataSet,
    stopLine: defaultDataSet,
    stopLineMentalStopLine: defaultDataSet,
    mentalStopLine: defaultDataSet
  }
  diff.value = {
    enter: defaultDiffDataSet,
    stopLine: defaultDiffDataSet,
    stopLineMentalStopLine: defaultDiffDataSet,
    mentalStopLine: defaultDiffDataSet
  }
}

/**
 * クリックしたグラフをモーダル表示させる
 * @param component - グラフ表示コンポーネント
 * @param props - グラフ表示プロパティ
 */
const showModal = (component: any, props: any) => {
  if (component === 'MacroAnalysisHistgram') {
    shallowState.value.prefix.component = MacroAnalysisHistgram
  }
  shallowState.value.prefix.props = props
  isDisplay.value = true
}

/**
 * 対象のデータセクションのグラフを表示する
 * @param section - セクション名
 */
const viewSectionDetail = (section: string): void => {
  // データが未定義の場合はエラーメッセージを出して終了する
  if (sendData.value === void 0) {
    dialog.value = {
      title: '',
      message: 'データ読込が行われていません。\r\nデータ読込を行ってください',
      isShow: true
    }
    return
  }
  // データが存在していない場合はエラーメッセージを出して終了する
  if (termA.value.enter.number === void 0) {
    dialog.value = {
      title: '',
      message: '現在のパラメータ設定ではデータが存在していません \r\n再度パラメータを設定後 \r\nデータ読込を行ってください',
      isShow: true
    }
    return
  }
  // 分析詳細データを取得する
  isLoading.value = true
  const promise = updateMacroAnalysisDetaiInfo(
    section,
    sendData.value.poleId,
    sendData.value.aggregationPeriod.start.replaceAll('-', '/') + ' 00:00:00',
    sendData.value.aggregationPeriod.end.replaceAll('-', '/') + ' 00:00:00',
    sendData.value.comparisonPeriod.start.replaceAll('-', '/') + ' 00:00:00',
    sendData.value.comparisonPeriod.end.replaceAll('-', '/') + ' 00:00:00',
    sendData.value.directionValue,
    sendData.value.ledValue,
    sendData.value.aggregationTargetValue,
    sendData.value.aggregationMethodValue
  )
  promise
    .then(() => {
      isLoading.value = false
      // データが存在するか判定
      const detailInfo = macroAnalysisInfoStore.$state.macroAnalysisDetaiInfo
      // if (this.$store.macroAnalysisDetaiInfo.value.termA.number.length != 0) {
      if (detailInfo.termA.number.length !== 0) {
        // データが存在する場合マクロ分析詳細表示
        isViewDetailInfo.value = true
      } else {
        isViewDetailInfo.value = false
        dialog.value = {
          title: '',
          message: DIALOG_ERROR_INFO.message.getErrorMacroAnalysisDetailInfoNoContent,
          isShow: true
        }
      }
    })
    .catch(() => {
      isLoading.value = false
      isViewDetailInfo.value = false
      dialog.value = {
        title:  DIALOG_ERROR_INFO.title.getError,
        message: DIALOG_ERROR_INFO.message.getErrorMacroAnalysisDetailInfo,
        isShow: true
      }
    })
}

/**
 * 分析詳細データ画面を消去する
 */
const closeSectionDetail = () => {
  isViewDetailInfo.value = false
}

__expose({
  getData
})

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_table = _resolveComponent("v-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(Loading, null, null, 512), [
      [_vShow, isLoading.value]
    ]),
    _createVNode(_component_v_card, { class: "macro-analysis-table__card-table" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_table, null, {
          default: _withCtx(() => [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _cache[13] || (_cache[13] = _createElementVNode("th", { class: "macro-analysis-table__sub-label" }, " 区間 ", -1)),
                _createElementVNode("th", _hoisted_2, [
                  _cache[6] || (_cache[6] = _createTextVNode(" 進入路 ")),
                  _createVNode(_component_v_icon, {
                    large: "",
                    color: "white",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (viewSectionDetail('enter')))
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode(" mdi-table-arrow-up ")
                    ])),
                    _: 1
                  })
                ]),
                _createElementVNode("th", _hoisted_3, [
                  _cache[8] || (_cache[8] = _createTextVNode(" 停止線 ")),
                  _createVNode(_component_v_icon, {
                    large: "",
                    color: "white",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (viewSectionDetail('stopLine')))
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode(" mdi-table-arrow-up ")
                    ])),
                    _: 1
                  })
                ]),
                _createElementVNode("th", _hoisted_4, [
                  _cache[10] || (_cache[10] = _createTextVNode(" 停止線~心的停止線 ")),
                  _createVNode(_component_v_icon, {
                    large: "",
                    color: "white",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (viewSectionDetail('stopLineMentalStopLine')))
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode(" mdi-table-arrow-up ")
                    ])),
                    _: 1
                  })
                ]),
                _createElementVNode("th", _hoisted_5, [
                  _cache[12] || (_cache[12] = _createTextVNode(" 心的停止線 ")),
                  _createVNode(_component_v_icon, {
                    large: "",
                    color: "white",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (viewSectionDetail('mentalStopLine')))
                  }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode(" mdi-table-arrow-up ")
                    ])),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("tbody", null, [
              _cache[24] || (_cache[24] = _createElementVNode("tr", null, [
                _createElementVNode("td", { class: "macro-analysis-table__empty" }),
                _createElementVNode("td", { class: "macro-analysis-table__period-a" }, " 期間A "),
                _createElementVNode("td", { class: "macro-analysis-table__period-b" }, " 期間B "),
                _createElementVNode("td", { class: "macro-analysis-table__period-a" }, " 期間A "),
                _createElementVNode("td", { class: "macro-analysis-table__period-b" }, " 期間B "),
                _createElementVNode("td", { class: "macro-analysis-table__period-a" }, " 期間A "),
                _createElementVNode("td", { class: "macro-analysis-table__period-b" }, " 期間B "),
                _createElementVNode("td", { class: "macro-analysis-table__period-a" }, " 期間A "),
                _createElementVNode("td", { class: "macro-analysis-table__period-b" }, " 期間B ")
              ], -1)),
              _createElementVNode("tr", null, [
                _cache[14] || (_cache[14] = _createElementVNode("td", null, "走行台数[台]", -1)),
                _createElementVNode("td", _hoisted_6, _toDisplayString(termA.value.enter.number), 1),
                _createElementVNode("td", _hoisted_7, _toDisplayString(termB.value.enter.number), 1),
                _createElementVNode("td", _hoisted_8, _toDisplayString(termA.value.stopLine.number), 1),
                _createElementVNode("td", _hoisted_9, _toDisplayString(termB.value.stopLine.number), 1),
                _createElementVNode("td", _hoisted_10, _toDisplayString(termA.value.stopLineMentalStopLine.number), 1),
                _createElementVNode("td", _hoisted_11, _toDisplayString(termB.value.stopLineMentalStopLine.number), 1),
                _createElementVNode("td", _hoisted_12, _toDisplayString(termA.value.mentalStopLine.number), 1),
                _createElementVNode("td", _hoisted_13, _toDisplayString(termB.value.mentalStopLine.number), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[15] || (_cache[15] = _createElementVNode("td", null, "平均速度[km/h]", -1)),
                _createElementVNode("td", _hoisted_14, _toDisplayString(termA.value.enter.avgSpeed), 1),
                _createElementVNode("td", _hoisted_15, _toDisplayString(termB.value.enter.avgSpeed), 1),
                _createElementVNode("td", _hoisted_16, _toDisplayString(termA.value.stopLine.avgSpeed), 1),
                _createElementVNode("td", _hoisted_17, _toDisplayString(termB.value.stopLine.avgSpeed), 1),
                _createElementVNode("td", _hoisted_18, _toDisplayString(termA.value.stopLineMentalStopLine.avgSpeed), 1),
                _createElementVNode("td", _hoisted_19, _toDisplayString(termB.value.stopLineMentalStopLine.avgSpeed), 1),
                _createElementVNode("td", _hoisted_20, _toDisplayString(termA.value.mentalStopLine.avgSpeed), 1),
                _createElementVNode("td", _hoisted_21, _toDisplayString(termB.value.mentalStopLine.avgSpeed), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[16] || (_cache[16] = _createElementVNode("td", null, "最高速度[km/h]", -1)),
                _createElementVNode("td", _hoisted_22, _toDisplayString(termA.value.enter.maxSpeed), 1),
                _createElementVNode("td", _hoisted_23, _toDisplayString(termB.value.enter.maxSpeed), 1),
                _createElementVNode("td", _hoisted_24, _toDisplayString(termA.value.stopLine.maxSpeed), 1),
                _createElementVNode("td", _hoisted_25, _toDisplayString(termB.value.stopLine.maxSpeed), 1),
                _createElementVNode("td", _hoisted_26, _toDisplayString(termA.value.stopLineMentalStopLine.maxSpeed), 1),
                _createElementVNode("td", _hoisted_27, _toDisplayString(termB.value.stopLineMentalStopLine.maxSpeed), 1),
                _createElementVNode("td", _hoisted_28, _toDisplayString(termA.value.mentalStopLine.maxSpeed), 1),
                _createElementVNode("td", _hoisted_29, _toDisplayString(termB.value.mentalStopLine.maxSpeed), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[17] || (_cache[17] = _createElementVNode("td", null, "最頻値", -1)),
                _createElementVNode("td", _hoisted_30, _toDisplayString(termA.value.enter.mode), 1),
                _createElementVNode("td", _hoisted_31, _toDisplayString(termB.value.enter.mode), 1),
                _createElementVNode("td", _hoisted_32, _toDisplayString(termA.value.stopLine.mode), 1),
                _createElementVNode("td", _hoisted_33, _toDisplayString(termB.value.stopLine.mode), 1),
                _createElementVNode("td", _hoisted_34, _toDisplayString(termA.value.stopLineMentalStopLine.mode), 1),
                _createElementVNode("td", _hoisted_35, _toDisplayString(termB.value.stopLineMentalStopLine.mode), 1),
                _createElementVNode("td", _hoisted_36, _toDisplayString(termA.value.mentalStopLine.mode), 1),
                _createElementVNode("td", _hoisted_37, _toDisplayString(termB.value.mentalStopLine.mode), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[18] || (_cache[18] = _createElementVNode("td", null, "中央値", -1)),
                _createElementVNode("td", _hoisted_38, _toDisplayString(termA.value.enter.median), 1),
                _createElementVNode("td", _hoisted_39, _toDisplayString(termB.value.enter.median), 1),
                _createElementVNode("td", _hoisted_40, _toDisplayString(termA.value.stopLine.median), 1),
                _createElementVNode("td", _hoisted_41, _toDisplayString(termB.value.stopLine.median), 1),
                _createElementVNode("td", _hoisted_42, _toDisplayString(termA.value.stopLineMentalStopLine.median), 1),
                _createElementVNode("td", _hoisted_43, _toDisplayString(termB.value.stopLineMentalStopLine.median), 1),
                _createElementVNode("td", _hoisted_44, _toDisplayString(termA.value.mentalStopLine.median), 1),
                _createElementVNode("td", _hoisted_45, _toDisplayString(termB.value.mentalStopLine.median), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[19] || (_cache[19] = _createElementVNode("td", null, "標準偏差[km/h]", -1)),
                _createElementVNode("td", _hoisted_46, _toDisplayString(termA.value.enter.std), 1),
                _createElementVNode("td", _hoisted_47, _toDisplayString(termB.value.enter.std), 1),
                _createElementVNode("td", _hoisted_48, _toDisplayString(termA.value.stopLine.std), 1),
                _createElementVNode("td", _hoisted_49, _toDisplayString(termB.value.stopLine.std), 1),
                _createElementVNode("td", _hoisted_50, _toDisplayString(termA.value.stopLineMentalStopLine.std), 1),
                _createElementVNode("td", _hoisted_51, _toDisplayString(termB.value.stopLineMentalStopLine.std), 1),
                _createElementVNode("td", _hoisted_52, _toDisplayString(termA.value.mentalStopLine.std), 1),
                _createElementVNode("td", _hoisted_53, _toDisplayString(termB.value.mentalStopLine.std), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[20] || (_cache[20] = _createElementVNode("td", { class: "macro-analysis-table__sub-label" }, " 平均速度グラフ ", -1)),
                _createElementVNode("td", _hoisted_54, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "label-name": "平均速度",
                            "x-axes": avgSpeedGraphSetting.value.xAxes,
                            "y-axes": avgSpeedGraphSetting.value.yAxes,
                            "background-color": avgSpeedGraphSetting.value.termA.backgroundColor,
                            data: termA.value.enter.avgSpeedHist,
                            max: sectionMaxBorder.value.avgSpeedHist,
                            "is-suggested": true,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "data", "max"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("td", _hoisted_55, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": avgSpeedGraphSetting.value.xAxes,
                            "y-axes": avgSpeedGraphSetting.value.yAxes,
                            "background-color": avgSpeedGraphSetting.value.termB.backgroundColor,
                            data: termB.value.enter.avgSpeedHist,
                            max: sectionMaxBorder.value.avgSpeedHist,
                            "is-suggested": true,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "data", "max"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("td", _hoisted_56, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": avgSpeedGraphSetting.value.xAxes,
                            "y-axes": avgSpeedGraphSetting.value.yAxes,
                            "background-color": avgSpeedGraphSetting.value.termA.backgroundColor,
                            data: termA.value.stopLine.avgSpeedHist,
                            max: sectionMaxBorder.value.avgSpeedHist,
                            "is-suggested": true,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "data", "max"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("td", _hoisted_57, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": avgSpeedGraphSetting.value.xAxes,
                            "y-axes": avgSpeedGraphSetting.value.yAxes,
                            "background-color": avgSpeedGraphSetting.value.termB.backgroundColor,
                            data: termB.value.stopLine.avgSpeedHist,
                            max: sectionMaxBorder.value.avgSpeedHist,
                            "is-suggested": true,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "data", "max"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("td", _hoisted_58, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": avgSpeedGraphSetting.value.xAxes,
                            "y-axes": avgSpeedGraphSetting.value.yAxes,
                            "background-color": avgSpeedGraphSetting.value.termA.backgroundColor,
                            data: termA.value.stopLineMentalStopLine.avgSpeedHist,
                            max: sectionMaxBorder.value.avgSpeedHist,
                            "is-suggested": true,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "data", "max"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("td", _hoisted_59, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": avgSpeedGraphSetting.value.xAxes,
                            "y-axes": avgSpeedGraphSetting.value.yAxes,
                            "background-color": avgSpeedGraphSetting.value.termB.backgroundColor,
                            data: termB.value.stopLineMentalStopLine.avgSpeedHist,
                            max: sectionMaxBorder.value.avgSpeedHist,
                            "is-suggested": true,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "data", "max"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("td", _hoisted_60, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": avgSpeedGraphSetting.value.xAxes,
                            "y-axes": avgSpeedGraphSetting.value.yAxes,
                            "background-color": avgSpeedGraphSetting.value.termA.backgroundColor,
                            data: termA.value.mentalStopLine.avgSpeedHist,
                            max: sectionMaxBorder.value.avgSpeedHist,
                            "is-suggested": true,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "data", "max"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("td", _hoisted_61, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": avgSpeedGraphSetting.value.xAxes,
                            "y-axes": avgSpeedGraphSetting.value.yAxes,
                            "background-color": avgSpeedGraphSetting.value.termB.backgroundColor,
                            data: termB.value.mentalStopLine.avgSpeedHist,
                            max: sectionMaxBorder.value.avgSpeedHist,
                            "is-suggested": true,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "data", "max"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("tr", null, [
                _cache[21] || (_cache[21] = _createElementVNode("td", null, "平均速度ヒストグラム", -1)),
                _createElementVNode("td", _hoisted_62, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": avgSpeedHistGraphSetting.value.xAxes,
                            "y-axes": avgSpeedHistGraphSetting.value.yAxes,
                            "background-color": avgSpeedHistGraphSetting.value.backgroundColor,
                            title: avgSpeedHistGraphSetting.value.title,
                            data: diff.value.enter.avgSpeedHist,
                            "is-suggested": true,
                            max: sectionMaxBorder.value.diff.avg.suggestedMax,
                            min: sectionMaxBorder.value.diff.avg.suggestedMin,
                            "max-ticks-limit": sectionMaxBorder.value.diff.avg.maxTicksLimit,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "title", "data", "max", "min", "max-ticks-limit"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("td", _hoisted_63, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": avgSpeedHistGraphSetting.value.xAxes,
                            "y-axes": avgSpeedHistGraphSetting.value.yAxes,
                            "background-color": avgSpeedHistGraphSetting.value.backgroundColor,
                            title: avgSpeedHistGraphSetting.value.title,
                            data: diff.value.stopLine.avgSpeedHist,
                            "is-suggested": true,
                            max: sectionMaxBorder.value.diff.avg.suggestedMax,
                            min: sectionMaxBorder.value.diff.avg.suggestedMin,
                            "max-ticks-limit": sectionMaxBorder.value.diff.avg.maxTicksLimit,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "title", "data", "max", "min", "max-ticks-limit"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("td", _hoisted_64, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": avgSpeedHistGraphSetting.value.xAxes,
                            "y-axes": avgSpeedHistGraphSetting.value.yAxes,
                            "background-color": avgSpeedHistGraphSetting.value.backgroundColor,
                            title: avgSpeedHistGraphSetting.value.title,
                            data: diff.value.stopLineMentalStopLine.avgSpeedHist,
                            "is-suggested": true,
                            max: sectionMaxBorder.value.diff.avg.suggestedMax,
                            min: sectionMaxBorder.value.diff.avg.suggestedMin,
                            "max-ticks-limit": sectionMaxBorder.value.diff.avg.maxTicksLimit,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "title", "data", "max", "min", "max-ticks-limit"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("td", _hoisted_65, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": avgSpeedHistGraphSetting.value.xAxes,
                            "y-axes": avgSpeedHistGraphSetting.value.yAxes,
                            "background-color": avgSpeedHistGraphSetting.value.backgroundColor,
                            title: avgSpeedHistGraphSetting.value.title,
                            data: diff.value.mentalStopLine.avgSpeedHist,
                            "is-suggested": true,
                            max: sectionMaxBorder.value.diff.avg.suggestedMax,
                            min: sectionMaxBorder.value.diff.avg.suggestedMin,
                            "max-ticks-limit": sectionMaxBorder.value.diff.avg.maxTicksLimit,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "title", "data", "max", "min", "max-ticks-limit"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("tr", null, [
                _cache[22] || (_cache[22] = _createElementVNode("td", null, "最高速度グラフ", -1)),
                _createElementVNode("td", _hoisted_66, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": maxSpeedGraphSetting.value.xAxes,
                            "y-axes": maxSpeedGraphSetting.value.yAxes,
                            "background-color": maxSpeedGraphSetting.value.termA.backgroundColor,
                            data: termA.value.enter.maxSpeedHist,
                            max: sectionMaxBorder.value.maxSpeedHist,
                            "is-suggested": true,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "data", "max"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("td", _hoisted_67, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": maxSpeedGraphSetting.value.xAxes,
                            "y-axes": maxSpeedGraphSetting.value.yAxes,
                            "background-color": maxSpeedGraphSetting.value.termB.backgroundColor,
                            data: termB.value.enter.maxSpeedHist,
                            max: sectionMaxBorder.value.maxSpeedHist,
                            "is-suggested": true,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "data", "max"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("td", _hoisted_68, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": maxSpeedGraphSetting.value.xAxes,
                            "y-axes": maxSpeedGraphSetting.value.yAxes,
                            "background-color": maxSpeedGraphSetting.value.termA.backgroundColor,
                            data: termA.value.stopLine.maxSpeedHist,
                            max: sectionMaxBorder.value.maxSpeedHist,
                            "is-suggested": true,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "data", "max"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("td", _hoisted_69, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": maxSpeedGraphSetting.value.xAxes,
                            "y-axes": maxSpeedGraphSetting.value.yAxes,
                            "background-color": maxSpeedGraphSetting.value.termB.backgroundColor,
                            data: termB.value.stopLine.maxSpeedHist,
                            max: sectionMaxBorder.value.maxSpeedHist,
                            "is-suggested": true,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "data", "max"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("td", _hoisted_70, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": maxSpeedGraphSetting.value.xAxes,
                            "y-axes": maxSpeedGraphSetting.value.yAxes,
                            "background-color": maxSpeedGraphSetting.value.termA.backgroundColor,
                            data: termA.value.stopLineMentalStopLine.maxSpeedHist,
                            max: sectionMaxBorder.value.maxSpeedHist,
                            "is-suggested": true,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "data", "max"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("td", _hoisted_71, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": maxSpeedGraphSetting.value.xAxes,
                            "y-axes": maxSpeedGraphSetting.value.yAxes,
                            "background-color": maxSpeedGraphSetting.value.termB.backgroundColor,
                            data: termB.value.stopLineMentalStopLine.maxSpeedHist,
                            max: sectionMaxBorder.value.maxSpeedHist,
                            "is-suggested": true,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "data", "max"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("td", _hoisted_72, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": maxSpeedGraphSetting.value.xAxes,
                            "y-axes": maxSpeedGraphSetting.value.yAxes,
                            "background-color": maxSpeedGraphSetting.value.termA.backgroundColor,
                            data: termA.value.mentalStopLine.maxSpeedHist,
                            max: sectionMaxBorder.value.maxSpeedHist,
                            "is-suggested": true,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "data", "max"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("td", _hoisted_73, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": maxSpeedGraphSetting.value.xAxes,
                            "y-axes": maxSpeedGraphSetting.value.yAxes,
                            "background-color": maxSpeedGraphSetting.value.termB.backgroundColor,
                            data: termB.value.mentalStopLine.maxSpeedHist,
                            max: sectionMaxBorder.value.maxSpeedHist,
                            "is-suggested": true,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "data", "max"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("tr", null, [
                _cache[23] || (_cache[23] = _createElementVNode("td", null, "最高速度ヒストグラム", -1)),
                _createElementVNode("td", _hoisted_74, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": maxSpeedHistGraphSetting.value.xAxes,
                            "y-axes": maxSpeedHistGraphSetting.value.yAxes,
                            "background-color": maxSpeedHistGraphSetting.value.backgroundColor,
                            title: maxSpeedHistGraphSetting.value.title,
                            data: diff.value.enter.maxSpeedHist,
                            "is-suggested": true,
                            max: sectionMaxBorder.value.diff.max.suggestedMax,
                            min: sectionMaxBorder.value.diff.max.suggestedMin,
                            "max-ticks-limit": sectionMaxBorder.value.diff.max.maxTicksLimit,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "title", "data", "max", "min", "max-ticks-limit"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("td", _hoisted_75, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": maxSpeedHistGraphSetting.value.xAxes,
                            "y-axes": maxSpeedHistGraphSetting.value.yAxes,
                            "background-color": maxSpeedHistGraphSetting.value.backgroundColor,
                            title: maxSpeedHistGraphSetting.value.title,
                            data: diff.value.stopLine.maxSpeedHist,
                            "is-suggested": true,
                            max: sectionMaxBorder.value.diff.max.suggestedMax,
                            min: sectionMaxBorder.value.diff.max.suggestedMin,
                            "max-ticks-limit": sectionMaxBorder.value.diff.max.maxTicksLimit,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "title", "data", "max", "min", "max-ticks-limit"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("td", _hoisted_76, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": maxSpeedHistGraphSetting.value.xAxes,
                            "y-axes": maxSpeedHistGraphSetting.value.yAxes,
                            "background-color": maxSpeedHistGraphSetting.value.backgroundColor,
                            title: maxSpeedHistGraphSetting.value.title,
                            data: diff.value.stopLineMentalStopLine.maxSpeedHist,
                            "is-suggested": true,
                            max: sectionMaxBorder.value.diff.max.suggestedMax,
                            min: sectionMaxBorder.value.diff.max.suggestedMin,
                            "max-ticks-limit": sectionMaxBorder.value.diff.max.maxTicksLimit,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "title", "data", "max", "min", "max-ticks-limit"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("td", _hoisted_77, [
                  _createVNode(_component_v_card, { class: "macro-analysis-table__card-graph" }, {
                    default: _withCtx(() => [
                      (isViewGraph.value)
                        ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                            key: 0,
                            "x-axes": maxSpeedHistGraphSetting.value.xAxes,
                            "y-axes": maxSpeedHistGraphSetting.value.yAxes,
                            "background-color": maxSpeedHistGraphSetting.value.backgroundColor,
                            title: maxSpeedHistGraphSetting.value.title,
                            data: diff.value.mentalStopLine.maxSpeedHist,
                            "is-suggested": true,
                            max: sectionMaxBorder.value.diff.max.suggestedMax,
                            min: sectionMaxBorder.value.diff.max.suggestedMin,
                            "max-ticks-limit": sectionMaxBorder.value.diff.max.maxTicksLimit,
                            onShowModal: showModal
                          }, null, 8, ["x-axes", "y-axes", "background-color", "title", "data", "max", "min", "max-ticks-limit"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ]),
          _: 1
        }),
        (isViewDetailInfo.value)
          ? (_openBlock(), _createBlock(MacroAnalysisDetailInfoTable, {
              key: 0,
              onCloseSectionDetail: closeSectionDetail
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(ModalMacroAnalysisHistgram, {
      value: isDisplay.value,
      text: "errorText",
      prefix: shallowState.value.prefix,
      class: "macro-analysis-table__modal-histgram",
      onInput: _cache[4] || (_cache[4] = ($event: any) => (isDisplay.value = $event))
    }, null, 8, ["value", "prefix"]),
    _createVNode(ErrorDialog, { "error-dialog": dialog.value }, null, 8, ["error-dialog"])
  ]))
}
}

})