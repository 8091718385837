import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted, computed, watch, ref } from 'vue'

import { useAggregationDataStore } from '@/store/app'

import { ChartData, SummaryReportElement } from '@/types/Interfaces'

// chart.js Library
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js'
import { DateTime } from 'luxon'
import 'chartjs-adapter-luxon'
import { Line } from 'vue-chartjs'
// ChartJSのコンポーネント登録
interface Props {
  label: string[];
  toDate: DateTime;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'NearMissWeeklyChart',
  props: {
    label: {},
    toDate: {}
  },
  setup(__props: any) {

/**
 * NearMissWeeklyChart.vue
 * 日別ヒヤリハットグラフ
 * 
 * 親コンポーネント
 * @/components/parts/aggregation/charts/weekly/AggreWeekly.vue
 */
// ==================================
// import
// ==================================
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale)

// ==================================
// interface
// ==================================
const dataStore = useAggregationDataStore()

const props = __props

// グラフデータ
const chartData = ref<ChartData>({
  data: {
    labels: [],
    datasets: []
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: 'ヒヤリハット件数'
      }
    }
  }
})

const chartContent = ref<SummaryReportElement>({
  current: [],
  currentTotal: 0,
  prev: [],
  prevTotal: 0
})

const isShowChart = ref<boolean>(false)

const graphData = computed(() => {
  return chartData.value.data
})

// ==================================
// watch
// ==================================
watch(() => chartContent.value, () => {
  // チャートデータを取得したらグラフの更新を開始する
  updateChartData()
})

// ==================================
// hook
// ==================================
onMounted(() => {
  // 日別のヒヤリハットデータの1件目を取得する
  chartContent.value = dataStore.$state.aggregationData.weekly.nearMissReportList[0]
})


// ==================================
// method
// ==================================
/**
 * グラフデータの更新
 */
const updateChartData = () => {
  isShowChart.value = false
  chartData.value.data.labels = props.label
  chartData.value.data.datasets = [
    {
      title: '当週件数',
      label: '当週件数: ' + chartContent.value.currentTotal + '件',
      borderColor: '#c00',
      fill: false,
      data: chartContent.value.current,
      tension: 0
    },
    {
      title: '前週件数',
      label: '前週件数: ' + chartContent.value.prevTotal + '件',
      borderColor: '#393',
      fill: false,
      data: chartContent.value.prev,
      tension: 0
    }
  ]
  // グラフを更新したらグラフを表示する
  isShowChart.value = true
}

return (_ctx: any,_cache: any) => {
  return (isShowChart.value)
    ? (_openBlock(), _createBlock(_unref(Line), {
        key: 0,
        data: graphData.value,
        options: chartData.value.options
      }, null, 8, ["data", "options"]))
    : _createCommentVNode("", true)
}
}

})