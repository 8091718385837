import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, onMounted, onBeforeUnmount } from 'vue'

import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'
import CameraFootage from '@/components/parts/realTime/CameraFootage.vue'
import InformationInTheIntersection from '@/components/parts/realTime/InformationInTheIntersection.vue'
import LedDisplayDataInformation from '@/components/parts/realTime/LedDisplayDataInformation.vue'
import VirtualVideo from '@/components/parts/realTime/VirtualVideo.vue'

import { useSelectPoleStore, usePoleInfoStore } from '@/store/app'

import { DIALOG_ERROR_INFO } from '@/mixins/commonFunction'
import {
  getPoleList,
  // updatePoleListAsPoleId,
  updatePosListReal,
} from '@/mixins/communicationFunction'
import { timerFunction } from '@/mixins/timerFunction'

import { ErrorDialogInfo, GrafanaPoleList, SensorIdData, SensorInfo, TitleInfo } from '@/types/Interfaces'

import { MENU, POSUP_DATE_INTERVAL_REAL, POLE_ON_GRAFANA } from '@/setting/setting'

import * as log from 'loglevel'

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'RealTime',
  setup(__props) {

/**
 * RealTime.vue
 * リアルタイムモニター
 */
// ==================================
// import
// ==================================
const selectPoleStore = useSelectPoleStore()
const poleInfoStore = usePoleInfoStore()

const cameraFootageComponent = ref()
const virtualVideoComponent = ref()
const informationInTheIntersectionComponent = ref()
const ledDisplayDataInformationComponent = ref()

// タイトル
const titleinfo = ref<TitleInfo>({
  title: selectPoleStore.name + ' リアルタイムモニター',
  pointList: [],
  menuList: MENU.realtime,
  show: {
    grafana: false,
    multicamera: true,
    virtual: true,
    menu: true,
  },
  poleId: undefined,
  poleList: undefined,
})

// エラーダイアログ情報
const errorDialog = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false,
})

// 画面項目制御
const isLoading = ref<boolean>(true)

// 選択済みセンサー情報
const selectSensorList = ref<SensorIdData[]>([])

// ==================================
// hook
// ==================================
onMounted(async () => {
  // ポール設定
  /*
  console.log(selectPoleStore.latlng)
  console.log(selectPoleStore.poleId)
  console.log(typeof selectPoleStore.poleId)
  */
  addPoleMarker(selectPoleStore.latlng.lat, selectPoleStore.latlng.lng)
  // ポールIDに紐づくセンサー情報を取得
  // await updatePoleListAsPoleId(selectPoleStore.poleId)
  initShowGrafana()
  await getPoleList(null, selectPoleStore.poleId, null, true)
    .then(() => {
      initCameraList()
      initSensorList()
      initLedList()
      timerFunction.methods.start(
        selectPoleStore.poleId,
        POSUP_DATE_INTERVAL_REAL,
        timeoutPosListRealTimer
      )
      isLoading.value = false
    })
    .catch((err) => {
      log.error(err)
      errorDialog.value.title = DIALOG_ERROR_INFO.title.getError
      errorDialog.value.message = DIALOG_ERROR_INFO.message.getErrorSensorInfo
      errorDialog.value.isShow = true
      isLoading.value = false
    })
})

onBeforeUnmount(() => {
  timerFunction.methods.stop()
})

// ==================================
// method
// ==================================
/**
 * ポール情報設定
 * @param lat - 緯度
 * @param lng - 経度
 */
const addPoleMarker = (lat: number, lng: number) => {
  virtualVideoComponent.value.addPoleMarker(lat, lng)
}

/**
 * Grafanaボタン表示初期化処理
 */
const initShowGrafana = () => {
  titleinfo.value.poleId = selectPoleStore.poleId
  titleinfo.value.poleList = POLE_ON_GRAFANA.poleInfo
  titleinfo.value.poleList.forEach((pole: GrafanaPoleList) => {
    if (titleinfo.value.poleId == pole.poleId) {
      // 対象ポールの場合、GRAFANAボタンを表示する
      titleinfo.value.show.grafana = true
    }
  })
}

/**
 * カメラリスト初期化
 */
const initCameraList = () => {
  cameraFootageComponent.value.initCameraList()
}

/**
 * センサーリスト初期化
 */
const initSensorList = () => {
  virtualVideoComponent.value.initSensorList()
}

/**
 * LED情報取得
 */
const initLedList = () => {
  ledDisplayDataInformationComponent.value.initLedList()
}

/**
 * 物標定期更新処理
 * @param poleId - ポールID
 */
const timeoutPosListRealTimer = async (poleId: number) => {
  let sensorIds = poleInfoStore.sensorList.map((obj: SensorInfo) => obj.sensorId)
  updatePosListReal(poleId, sensorIds)
    .then(() => {
      updatePosObject()
      intersectionUpdateList()
      letUpdateList()
    })
    .catch((err: Promise<void>) => {
      log.error(err)
    })
}

/**
 * 物標情報更新
 */
const updatePosObject = () => {
  virtualVideoComponent.value.updatePosObject()
}

/**
 * 交差点情報一覧更新
 */
const intersectionUpdateList = () => {
  informationInTheIntersectionComponent.value.updateList()
}

/**
 * LED情報一覧更新
 */
const letUpdateList = () => {
  ledDisplayDataInformationComponent.value.updateList()
}

/**
 * エラーダイアログを閉じる
 */
const onClickCloseErrorDialog = () => {
  errorDialog.value.isShow = false
}

/**
 * 選択済みのセンサーを更新
 * @param value - 選択済みセンサー情報
 */
const updateSelectSensorList = (value: SensorIdData[]) => {
  selectSensorList.value = value
}

/**
 * 交差点情報一覧の物標選択時マップ中央に表示
 * @param latlng - 緯度経度
 */
const setCenter = (latlng: number[]) => {
  virtualVideoComponent.value.setCenter(latlng)
}

return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TitleHeader, { "title-info": titleinfo.value }, null, 8, ["title-info"]),
    _createVNode(_component_v_container, {
      fluid: "",
      "fill-height": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { dense: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "6" }, {
              default: _withCtx(() => [
                _createVNode(CameraFootage, {
                  ref_key: "cameraFootageComponent",
                  ref: cameraFootageComponent
                }, null, 512)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { cols: "6" }, {
              default: _withCtx(() => [
                _createVNode(VirtualVideo, {
                  ref_key: "virtualVideoComponent",
                  ref: virtualVideoComponent,
                  onSelectSensorListHandOver: updateSelectSensorList
                }, null, 512)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { dense: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "6" }, {
              default: _withCtx(() => [
                _createVNode(InformationInTheIntersection, {
                  ref_key: "informationInTheIntersectionComponent",
                  ref: informationInTheIntersectionComponent,
                  "select-sensor-list": selectSensorList.value,
                  onSelectObject: setCenter
                }, null, 8, ["select-sensor-list"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { cols: "6" }, {
              default: _withCtx(() => [
                _createVNode(LedDisplayDataInformation, {
                  ref_key: "ledDisplayDataInformationComponent",
                  ref: ledDisplayDataInformationComponent,
                  "select-sensor-list": selectSensorList.value
                }, null, 8, ["select-sensor-list"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _withDirectives(_createVNode(Loading, null, null, 512), [
          [_vShow, isLoading.value]
        ]),
        _createVNode(ErrorDialog, {
          "error-dialog": errorDialog.value,
          onOnClickCloseErrorDialog: onClickCloseErrorDialog
        }, null, 8, ["error-dialog"])
      ]),
      _: 1
    })
  ], 64))
}
}

})