import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "py-2 ml-5" }
const _hoisted_2 = { class: "pt-0" }

import { ref, watch } from 'vue'

import SelectSensor from '@/components/parts/common/SelectSensor.vue'
import VirtualMap from '@/components/parts/common/VirtualMap.vue'

import { LatLng, SensorIdData } from '@/types/Interfaces'

import { DateTime } from 'luxon'

// ==================================
// interface
// ==================================
interface Emits {
  (e: 'select-sensor-list-hand-over', value: any): void;
  (e: 'play-pouse'): void;
  (e: 'play-realtime-mode'): void;
  (e: 'play-virtual-mode', time: Date): void;
  (e: 'set-error-dialog', title: string, message: string): void;
}
// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'VirtualVideo',
  emits: ["select-sensor-list-hand-over", "play-pouse", "play-realtime-mode", "play-virtual-mode", "set-error-dialog"],
  setup(__props, { expose: __expose, emit: __emit }) {

/**
 * VirtualVideo.vue
 * バーチャル映像コンポーネント
 * 
 * 親コンポーネント
 * @/views/Virtual.vue
 * 
 */
// ==================================
// import
// ==================================
const selectSensorList = ref<SensorIdData[]>([])

const selectSensorComponent = ref()
const virtualMapComponent = ref()

const datetime = ref<Date | undefined | null>()

const isDataAcquisition = ref<boolean>(true)

// ==================================
// watch
// ==================================
watch(() => datetime.value, val => {
  // 再生日時に任意の日時が入力されたら、バリデーションチェックを実行する
  if (val !== void 0 && val !== null) {
    checkDateTime(val)
  }
})

// ==================================
// method
// ==================================
const emit = __emit

/**
 * 再生日時のチェック
 * @param date - 入力された再生日時
 */
const checkDateTime = (date: Date): void => {
  const now = DateTime.now()
  const target = DateTime.fromJSDate(date)
  // アクセス時点よりも未来の日時を指定したらエラーを返す
  if (now.diff(target).milliseconds < 0) {
    emit('set-error-dialog', '不正な再生日時', '再生日時に未来の日時を指定できません')
  }
}

/**
 * 日付更新
 * @param updateScondValue - 更新秒数
 * @return 更新した日付データ
 */
const updateTime = (updateScondValue: number): Date | null | undefined => {
  // 入力した再生日時が存在する場合
  if (datetime.value !== null && datetime.value !== undefined) {
    // 指定された秒数だけ日時を更新する
    const tempDatetime: any = DateTime.fromJSDate(datetime.value).plus({
      seconds: updateScondValue,
    })
    // Date型に変換し再生日時を更新する
    datetime.value = new Date(tempDatetime.ts)
  }
  // 更新した日付を返す
  return datetime.value
}

/**
 * センサー一覧を初期化する
 */
const initSensorList = () => {
  selectSensorComponent.value.initSensorList()
}

/**
 * ポールマーカーを追加する
 * @param lat - 緯度
 * @param lng - 経度
 */
const addPoleMarker = (lat: number, lng: number) => {
  virtualMapComponent.value.addPoleMarker(lat, lng)
}

/**
 * 物標情報を更新
 */
const updatePosObject = () => {
  virtualMapComponent.value.updatePosObject()
}
/**
 * 地図の中心位置を更新する
 * @param latlng - 緯度経度情報
 */
const setCenter = (latlng: LatLng) => {
  virtualMapComponent.value.setCenter(latlng)
}

/**
 * 選択済みのセンサーを更新
 * @param value - 選択したセンサー一覧
 */
const updateSelectSensorList = (value: SensorIdData[]) => {
  selectSensorList.value = value
  // 親コンポーネントに伝達
  selectSensorListHandOver(selectSensorList.value)
}

/**
 * 親画面にセンサー一覧を送る
 * @param sensorlist - 選択済みセンサー情報
 */
const selectSensorListHandOver = (sensorlist: SensorIdData[]) => {
  emit('select-sensor-list-hand-over', sensorlist)
}

/**
 * 再生を実行
 */
const play = async () => {
  // 停止ボタンを表示
  isDataAcquisition.value = true
  // 再生日時が選択されていた過去データを再生する
  if (datetime.value !== null && datetime.value !== undefined) {
    playVirtualMode(datetime.value)
  } else {
    // 選択されてない場合はリアルタイムデータを再生する
    playRealtimeMode()
  }
}

/**
 * 過去のモニター内容を再生する
 * @param time - 再生日時
 */
const playVirtualMode = (time: Date) => {
  emit('play-virtual-mode', time)
}

/**
 * リアルタイム再生を実行する
 */
const playRealtimeMode = () => {
  emit('play-realtime-mode')
}

/**
 * 一時停止
 */
const pause = () => {
  // 再生ボタンを表示
  isDataAcquisition.value = false
  playPause()
}

/**
 * 再生を停止する
 */
const playPause = () => {
  emit('play-pouse')
}

/**
 * 10秒巻き戻し
 */
const rewind = () => {
  // 再生日時が入力済みの場合、10秒戻す
  if (datetime.value !== null && datetime.value !== undefined) {
    const tempDatetime: any = DateTime.fromJSDate(datetime.value).minus({
      seconds: 10,
    })
    datetime.value = new Date(tempDatetime.ts)
  }
}

__expose({
  initSensorList,
  addPoleMarker,
  updatePosObject,
  setCenter,
  updateTime,
  play
})

return (_ctx: any,_cache: any) => {
  const _component_vue_datepicker_next = _resolveComponent("vue-datepicker-next")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "virtual-video",
    elevation: "10",
    outlined: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, { class: "virtual-video__title py-0 d-flex" }, {
        default: _withCtx(() => [
          _cache[7] || (_cache[7] = _createElementVNode("span", { class: "py-5" }, "VIRTUAL映像", -1)),
          _createElementVNode("span", _hoisted_1, [
            _createVNode(_component_vue_datepicker_next, {
              value: datetime.value,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((datetime).value = $event)),
              class: "virtual-video__date",
              type: "datetime",
              placeholder: "再生日時選択",
              format: "YYYY/MM/DD HH:mm:ss",
              formatted: "YYYY/MM/DD HH:mm:ss",
              disabled: isDataAcquisition.value
            }, null, 8, ["value", "disabled"]),
            (!isDataAcquisition.value)
              ? (_openBlock(), _createBlock(_component_v_tooltip, {
                  key: 0,
                  top: ""
                }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_btn, _mergeProps({
                      variant: "plain",
                      class: "ml-5",
                      icon: "mdi-play",
                      size: "dense"
                    }, props, {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (play()))
                    }), null, 16)
                  ]),
                  default: _withCtx(() => [
                    _cache[4] || (_cache[4] = _createElementVNode("span", null, "再生開始", -1))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (isDataAcquisition.value)
              ? (_openBlock(), _createBlock(_component_v_tooltip, {
                  key: 1,
                  top: ""
                }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_btn, _mergeProps({
                      variant: "plain",
                      class: "ml-5",
                      icon: "mdi-stop",
                      size: "dense"
                    }, props, {
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (pause()))
                    }), null, 16)
                  ]),
                  default: _withCtx(() => [
                    _cache[5] || (_cache[5] = _createElementVNode("span", null, "停止", -1))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_tooltip, { top: "" }, {
              activator: _withCtx(({ props }) => [
                _createVNode(_component_v_btn, _mergeProps({
                  variant: "plain",
                  class: "ml-5",
                  icon: "mdi-rewind-10",
                  size: "dense"
                }, props, {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (rewind()))
                }), null, 16)
              ]),
              default: _withCtx(() => [
                _cache[6] || (_cache[6] = _createElementVNode("span", null, "10秒巻き戻し", -1))
              ]),
              _: 1
            })
          ]),
          _createVNode(SelectSensor, {
            ref_key: "selectSensorComponent",
            ref: selectSensorComponent,
            onSelectSensorListHandOver: updateSelectSensorList
          }, null, 512)
        ]),
        _: 1
      }),
      _createVNode(_component_v_divider),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(VirtualMap, {
          ref_key: "virtualMapComponent",
          ref: virtualMapComponent,
          "select-sensor-list": selectSensorList.value
        }, null, 8, ["select-sensor-list"])
      ])
    ]),
    _: 1
  }))
}
}

})