import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onBeforeMount, computed, ref } from 'vue'

import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'

import { DIALOG_ERROR_INFO } from '@/mixins/commonFunction'
import { updateGroupInfo } from '@/mixins/communicationFunction'
import { toHexNumber } from '@/utils/math'

import { ErrorDialogInfo, GroupListStore } from '@/types/Interfaces'

// ==================================
// interface
// ==================================
interface Props {
  dialog: {
    isDialog: boolean;
    item: GroupListStore;
  }
}

interface Emits {
  (e: 'show-error-dialog', result: boolean, title: string, message: string): void;
  (e: 'dialogClose'): void;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'GroupUpdateDialog',
  props: {
    dialog: {}
  },
  emits: ["show-error-dialog", "dialogClose"],
  setup(__props: any, { emit: __emit }) {

/**
 * GroupUpdateDialog.vue
 * グループの更新ダイアログ
 * 
 * 親コンポーネント
 * @/views/GroupSetting.vue
 */
// ==================================
// import
// ==================================
const props = __props

// ローディングアイコン表示
const isLoading = ref<boolean>(false)

// 元々のグループ名
const originalGroupName = ref<string>('')

// グループ情報
const groupInfo = ref<GroupListStore>({
  groupid: 0,
  groupname: ''
})

const errDialog = ref<ErrorDialogInfo>({
  isShow: false,
  title: '',
  message: ''
})

const rules = ref({
  /**
   * グループ名のバリデーションチェック
   * @param value - フォーム入力値
   * @returns エラー文字列、成功判定
   */
  checkInValidGroupName: (value: string | null): string | boolean => {
    if (!value) {
      // 値が空ではないこと
      return '値を入力してください'
    } else if (value == originalGroupName.value) {
      // 古い値ではないこと
      return '新しいグループ名を入力してください'
    } else if (value.length > 50) {
      // グループ名文字数が1~50の範囲内であること
      return 'グループ名が長すぎます'
    }
    return true
  }
})

// ==================================
// computed
// ==================================
// ダイアログの表示判定用
const isDialog = computed(() => {
  return props.dialog.isDialog
})

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  initialize()
})

// ==================================
// method
// ==================================
const emit = __emit

/**
 * 初期処理
 */
const initialize = () => {
  // 親コンポーネントから送られたデータを設定する
  groupInfo.value.groupid = props.dialog.item.groupid
  groupInfo.value.groupname = props.dialog.item.groupname
  originalGroupName.value = props.dialog.item.groupname
}

/**
 * 変更ボタン活性/非活性の判定
 */
const isValidation = () => {
  // 入力されたグループ名がNULLではなく、50文字以内で、元々のグループ名と重複しないかで判定
  return (groupInfo.value.groupname !== null && groupInfo.value.groupname.length <= 50 && originalGroupName.value !== groupInfo.value.groupname)
}

/**
 * グループ情報更新処理
 */
const onClickUpdate = async() => {
  isLoading.value = true
  // グループ更新APIを呼び出す
  let promise = updateGroupInfo(groupInfo.value.groupid, groupInfo.value.groupname)
  promise
    .then(() => {
      isLoading.value = false
      emit('show-error-dialog', true, 'グループ更新成功','グループを更新しました。\nグループID: ' + toHexNumber(groupInfo.value.groupid) + '、グループ名: ' + groupInfo.value.groupname)
    })
    .catch(() => {
      isLoading.value = false
      // エラーメッセージを設定する
      let title = DIALOG_ERROR_INFO.title.updateError
      let message = DIALOG_ERROR_INFO.message.updateErrorGroupInfo
      emit('show-error-dialog', false, title, message)
    })
}

/**
 * ダイアログ閉じる処理
 */
const dialogClose = () => {
  emit('dialogClose')
}

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: isDialog.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((isDialog).value = $event)),
    class: "group-update-dialog",
    width: "500px",
    persistent: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, {
                class: "group-update-dialog__title",
                nav: "",
                density: "compact"
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" グループ編集 ")
                ])),
                _: 1
              }),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_form, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "3" }, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createElementVNode("span", null, "グループID", -1)
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "9" }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(_unref(toHexNumber)(groupInfo.value.groupid)), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "3" }, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createElementVNode("span", null, "グループ名", -1)
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "9" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                modelValue: groupInfo.value.groupname,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((groupInfo.value.groupname) = $event)),
                                rules: [rules.value.checkInValidGroupName],
                                clearable: ""
                              }, null, 8, ["modelValue", "rules"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    disabled: !isValidation(),
                    color: "primary darken-2",
                    class: "group-update-dialog__update-btn",
                    onClick: onClickUpdate
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode(" 変更 ")
                    ])),
                    _: 1
                  }, 8, ["disabled"]),
                  _createVNode(_component_v_btn, {
                    color: "grey darken-2",
                    dark: "",
                    onClick: dialogClose
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode(" キャンセル ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(ErrorDialog, { "error-dialog": errDialog.value }, null, 8, ["error-dialog"]),
      _withDirectives(_createVNode(Loading, null, null, 512), [
        [_vShow, isLoading.value]
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})