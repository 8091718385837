import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "aggre-daily" }

import { onMounted, watch, ref } from 'vue'

import AxlDailyChart from '@/components/parts/aggregation/charts/daily/AxlDailyChart.vue'
import NearMissDailyChart from '@/components/parts/aggregation/charts/daily/NearMissDailyChart.vue'
import TrafficDailyChart from '@/components/parts/aggregation/charts/daily/TrafficDailyChart.vue'

import { useAggregationFiltersStore } from '@/store/app'

import { DateTime } from 'luxon'

// ==================================
// interface
// ==================================
interface Props {
  toDate: DateTime;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'AggreDaily',
  props: {
    toDate: {}
  },
  setup(__props: any) {

/**
 * AggreDaily.vue
 * 時間別チャート本体
 * 
 * 親コンポーネント
 * @/components/parts/aggregation/AggreMain.vue
 */
// ==================================
// import
// ==================================
const filterStore = useAggregationFiltersStore()

const props = __props

const dailyLabel = ref<string[]>([])

// ==================================
// watch
// ==================================
watch(() => props.toDate, val => {
  console.log(val)
})

// ==================================
// hook
// ==================================
onMounted(() => {
  // 日次データのグラフラベルを生成する
  dailyLabel.value = setDailyChartLabel(props.toDate)
})

// ==================================
// method
// ==================================
/**
 * 日別チャート用のラベルを生成する
 * @param targetDate - 対象日
 * @returns ラベル文字列
 */
const setDailyChartLabel = (targetDate: DateTime): string[] => {
  let label: string[] = []
  const toDateString = '-' + targetDate.toFormat('MM/dd')
  let work = targetDate.set({
    hour: 0,
    minute: 0,
    second: 0
  })
  for (let i = 0; i < 12; i++) {
    const segment = i == 11 ? work.minus({ hour: 2 }).toFormat('yyyy/MM/dd') + toDateString + ' 00:00' : work.minus({ hour: 2 }).toFormat('HH:mm')
    label.unshift(segment)
  }
  return label
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(filterStore).$state.mode.category === 1)
      ? (_openBlock(), _createBlock(NearMissDailyChart, {
          key: 0,
          "to-date": _ctx.toDate,
          label: dailyLabel.value
        }, null, 8, ["to-date", "label"]))
      : _createCommentVNode("", true),
    (_unref(filterStore).$state.mode.category === 2)
      ? (_openBlock(), _createBlock(TrafficDailyChart, {
          key: 1,
          "to-date": _ctx.toDate,
          label: dailyLabel.value
        }, null, 8, ["to-date", "label"]))
      : _createCommentVNode("", true),
    (_unref(filterStore).$state.mode.category === 3)
      ? (_openBlock(), _createBlock(AxlDailyChart, {
          key: 2,
          "to-date": _ctx.toDate,
          label: dailyLabel.value
        }, null, 8, ["to-date", "label"]))
      : _createCommentVNode("", true)
  ]))
}
}

})