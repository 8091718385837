// '0[AWS本番] / 1[AWS開発] / 2[cloud API] / 3[ローカル] AWS開発の場合baseUrlに/developを設定',
export const SERVER = 2
// ベースURL
export const BASE_URL = ''
// 過去物標情報更新間隔(ms)
export const POS_UPDATE_INTERVAL = 1000
// 最新物標情報更新間隔(ms)
export const POSUP_DATE_INTERVAL_REAL = 1000
// 過去事故映像URLトークン取得間隔
export const SELECT_MOVIE_INTERVAL = 2000
// 物標情報を何秒前のデータから取得するか指定する為の変数(3秒以上にする場合はbackendの処理も直すこと)
export const POSLIST_DIFFTIME = 3
// 過去事故映像差分秒数
export const HISTRY_DIFFTIME = 60
// 物標オブジェクト一覧
export const OBJECT_INFO_LIST = [
  { kind: 0, name: '大型自動車' },
  { kind: 1, name: '中型自動車' },
  { kind: 2, name: '普通自動車' },
  { kind: 3, name: '自動二輪車' },
  { kind: 4, name: '自転車' },
  { kind: 5, name: '軽車両' },
  { kind: 6, name: '歩行者' },
  { kind: 7, name: '電車' },
  { kind: 15, name: 'その他' },
]
// 緊急車両オブジェクト
export const AMBULANCE_OBJECT_INFO = {
  kind: 1, name: '緊急自動車'
}
//車両のシフトポジションオブジェクト一覧
export const SHIFT_NAME_LIST = [
  { state: 0, name: 'N' },
  { state: 1, name: 'P' },
  { state: 2, name: 'D' },
  { state: 3, name: 'R' },
  { state: 7, name: '-' },
]
//センサー機器種別オブジェクト一覧
export const SENSOR_LIST = [
  { kind: 1, name: '制御部', color: 'coral' },
  { kind: 2, name: 'LiDAR', color: 'orange' },
  { kind: 3, name: 'フュージョンセンサー', color: 'orangered' },
  { kind: 4, name: 'ITS無線', color: 'darkorange' },
  { kind: 5, name: 'CMOS', color: 'crimson' },
  { kind: 6, name: 'FIR', color: 'salmon' },
  { kind: 15, name: 'BT 4.x', color: 'darkred' },
  { kind: 16, name: 'BT 5.x', color: 'sandybrown' },
  { kind: 17, name: 'RF-ID', color: 'tomato' },
  { kind: 21, name: '表示板', color: 'sienna' },
  { kind: 22, name: 'WiFi（WiGig）', color: 'maroon' },
  { kind: 23, name: 'GNSS', color: 'brown' },
  { kind: 24, name: '画像解析', color: 'palegreen' },
]
// カメラ機器種別オブジェクト一覧
export const CAMERA_LIST = [
  { kind: 18, name: '交差点カメラ' },
  { kind: 19, name: '路側カメラ' },
  { kind: 20, name: '車載カメラ' },
]
// 映像種別オブジェクト一覧
export const MOVIE_LIST = [
  { kind: 1, name: '交差点カメラ' },
  { kind: 2, name: '路側カメラ' },
  { kind: 3, name: '車載カメラ' },
]

// サマリー速度表記オブジェクト一覧
export const SUMMARY_SPEED = {
  '1': '0-10',
  '2': '10-20',
  '3': '20-30',
  '4': '30-40',
  '5': '40-50',
  '6': '50-60',
}

// エリア選択画面表示時のマップの初期設定用オブジェクト
export const SELECT_AREA = {
  zoom: {
    default: 5,
    min: 5,
    max: 22,
  },
  center: {
    latitude: 30.092662,
    longitude: 132.990909,
  },
}
// ポール選択画面表示時のマップの初期設定用オブジェクト
export const SELECT_POLE = {
  zoom: {
    default: 14,
    min: 5,
    max: 22,
    maxNative: 18,
  },
}

// ポール選択時のサブメニュー項目
export const POLE_SUB_MENU = [
  {
    name: '現在データ',
    action: 'realTimeData'
  },
  {
    name: '過去データ',
    action: 'historyData'
  },
  {
    name: 'ヒヤリハット',
    action: 'accidentData'
  },
  {
    name: '集計',
    action: 'collections'
  }
]

// バーチャル画面表示時のマップの初期設定用オブジェクト
export const VIRTUAL_MAP = {
  zoom: {
    default: 18,
    min: 5,
    max: 19,
    maxNative: 18,
  },
}

//LED表示情報一覧の TableType
export const LED_INFOTABLE_TYPE = {
  listHeader: [
    { title: 'センサーID', key: 'id', sortable: true },
    { title: '情報', key: 'dispLevel', sortable: false },
    { title: 'マーク１', key: 'mark1Object', sortable: false },
    { title: '方路１', key: 'mark1directionStyle', sortable: false },
    { title: 'マーク２', key: 'mark2Object', sortable: false },
    { title: '方路２', key: 'mark2directionStyle', sortable: false },
  ],
}

//リアルタイム画面のlocationInfoTableType
export const LOCATION_INFOTABLE_TYPE1 = {
  checkBoxes: [
    { label: 'マーク', value: true },
    { label: 'センサーID', value: true },
    { label: 'センサー種別', value: true },
    { label: '物標', value: true },
    { label: '座標', value: false },
    { label: '速度', value: true },
    { label: '方位', value: true },
    { label: 'シフト', value: false },
    { label: '舵角', value: false },
    { label: '位置情報日時', value: true },
  ],
  listHeader: [
    { title: 'マーク', key: 'mark', shown: true },
    { title: 'センサーID', key: 'sensor', shown: true },
    { title: 'センサー種別', key: 'kind', shown: true },
    { title: '物標', key: 'name', shown: true },
    { title: '座標', key: 'coordinate', shown: false },
    { title: '速度', key: 'speed', shown: true },
    { title: '方位', key: 'direction', shown: true },
    { title: 'SIFT', key: 'shift', shown: false },
    { title: '舵角', key: 'angle', shown: false },
    { title: '日時', key: 'time', shown: true },
  ],
}

// バーチャル画面のlocationInfoTableType
export const LOCATION_INFOTABLE_TYPE2 = {
  checkBoxes: [
    { label: 'マーク', value: true },
    { label: 'センサーID', value: false },
    { label: 'センサー種別', value: true },
    { label: '物標', value: true },
    { label: '速度', value: true },
    { label: '方位', value: true },
    {
      label: 'シフト',
      value: false,
    },
  ],
  listHeader: [
    { title: 'マーク', key: 'mark', shown: true },
    { title: 'センサーID', key: 'sensor', shown: false },
    { title: 'センサー種別', key: 'kind', shown: true },
    { title: '物標', key: 'name', shown: true },
    { title: '速度', key: 'speed', shown: true },
    { title: '方位', key: 'direction', shown: true },
    { title: 'SIFT', key: 'shift', shown: false },
  ],
}
// 過去事故情報一覧テーブル定義オブジェクト
export const HISTORY_LIST_HEADER = [
  { title: '', key: 'icon' },
  { title: '事故ID', key: 'accidentId' },
  { title: 'ポールID', key: 'poleId' },
  { title: '事故日時', key: 'accidentTime', align: 'start' },
  { title: '事故地点', key: 'location', align: 'start' },
  { title: '加速度(m/s^2)', key: 'acceleration' },
]
// ヒヤリハット情報一覧テーブル定義オブジェクト
export const NEAR_MISSES_LIST_HEADER = [
  { title: 'ID', key: 'nearMissId', sortable: true },
  { title: '判定エリア', key: 'area', sortable: true },
  { title: '車両1 ID', key: 'vehicleId_1', sortable: true },
  { title: '車両1 物標種別', key: 'vehicleId_1_Kind', sortable: true },
  { title: '車両1 方路', key: 'vehicleId_1_direction', sortable: true },
  { title: '車両2 ID', key: 'vehicleId_2', sortable: true },
  { title: '車両2 物標種別', key: 'vehicleId_2_kind', sortable: true },
  { title: '車両2 方路', key: 'vehicleId_2_direction', sortable: true },
  { title: '緯度', key: 'latitude', sortable: true },
  { title: '経度', key: 'longitude', sortable: true },
  { title: '発生時刻', key: 'nearMisstime', sortable: true },
  { title: 'TimeGap', key: 'timeGap', sortable: true },
  { title: '再生', key: 'videoPath', sortable: false },
  { title: 'ダウンロード', key: 'downloadVidePath', sortable: false },
  { title: '詳細', key: 'nearMissinfo', sortable: false },
]
// 事故発生一覧テーブル定義オブジェクト
export const ACCIDENT_LIST_HEADER = [
  { title: 'ID', key: 'accidentId' },
  { title: '車両1 ID', key: 'vehicleId_1' },
  { title: '車両2 ID', key: 'vehicleId_2' },
  { title: '緯度', key: 'latitude' },
  { title: '経度', key: 'longitude' },
  { title: '事故発生時刻', key: 'accidentTime' },
]
// リアルタイムのカメラ映像(KVSチャネル)呼び出し時の非同期処理間隔を設定するオブジェクト
export const REAL_VIDEO = { waitInterval: 2000 }

// 集計画面上の方向と角度
export const DIRECTIONS = [
  { name: 'N', desc: '北', deg: 0 },
  { name: 'NE', desc: '北東', deg: 45 },
  { name: 'E', desc: '東', deg: 90 },
  { name: 'SE', desc: '南東', deg: 135 },
  { name: 'S', desc: '南', deg: 180 },
  { name: 'SW', desc: '南西', deg: 225 },
  { name: 'W', desc: '西', deg: 270 },
  { name: 'NW', desc: '北西', deg: 315 }
] as {
  name: string
  desc: string
  deg: number
}[]

export const OBJECT_INFO_LIST_FOR_COLLECTION = [
  { kind: 2, name: '自動車' },
  { kind: 3, name: '自動二輪車' },
  { kind: 4, name: '自転車' },
  { kind: 6, name: '歩行者' }
] as {
  kind: number
  name: string
}[]

// 各画面への遷移する為のオブジェクト一覧
// TODO 画面作成時にコメントを外す
export const MENU = {
  selectArea: [
    { item: 'history', name: '過去データ一覧(最新20件)' },
    { item: 'groupSetting', name: 'グループ設定' },
    { item: 'logout', name: 'ログアウト' },
  ],
  selectPole: [
    { item: 'selectArea', name: '検証一覧' },
    { item: 'history', name: '過去データ一覧(最新20件)' },
    { item: 'logout', name: 'ログアウト' },
  ],
  groupSetting: [
    { item: 'selectArea', name: '検証一覧' },
    { item: 'logout', name: 'ログアウト' }
  ],
  realtime: [
    { item: 'selectArea', name: '検証一覧' },
    // { item: 'selectPole', name: 'ポール選択' },
    { item: 'accidents', name: 'ヒヤリハット・事故情報表示' },
    { item: 'collection', name: '集計' },
    { item: 'aggregation', name: '集計グラフ' },
    // { item: 'parking', name: '駐停車状況' },
    // { item: 'summary', name: 'サマリー' },
    // { item: 'dashboard', name: 'ダッシュボード' },
    { item: 'macroAnalysis', name: 'マクロ分析' },
    // {
    //   item: 'macroAnalysisInfoDataDownload',
    //   name: 'マクロ分析データダウンロード',
    // },
    { item: 'history', name: '過去データ一覧(最新20件)' },
    { item: 'groupSetting', name: 'グループ設定' },
    { item: 'logout', name: 'ログアウト' },
  ],
  historyList: [
    { item: 'selectArea', name: '検証一覧' },
    // { item: 'selectPole', name: 'ポール選択' },
    { item: 'logout', name: 'ログアウト' },
  ],
  historyListDirect: [
    { item: 'selectArea', name: '検証一覧' },
    { item: 'logout', name: 'ログアウト' },
  ],
  historyDataDirect: [
    { item: 'selectArea', name: '検証一覧' },
    { item: 'history', name: '過去データ一覧(最新20件)' },
    { item: 'logout', name: 'ログアウト' },
  ],
  accidents: [
    { item: 'selectArea', name: '検証一覧' },
    // { item: 'selectPole', name: 'ポール選択' },
    { item: 'collection', name: '集計' },
    // { item: 'parking', name: '駐停車状況' },
    // { item: 'summary', name: 'サマリー' },
    // { item: 'dashboard', name: 'ダッシュボード' },
    { item: 'macroAnalysis', name: 'マクロ分析' },
    {
       item: 'macroAnalysisInfoDataDownload',
       name: 'マクロ分析データダウンロード',
    },
    { item: 'history', name: '過去データ一覧(最新20件)' },
    { item: 'logout', name: 'ログアウト' },
  ],
  aggregation: [
    { item: 'selectArea', name: '検証一覧' },
    { item: 'collection', name: '集計' },
    // { item: 'parking', name: '駐停車状況' },
    // { item: 'summary', name: 'サマリー' },
    // { item: 'dashboard', name: 'ダッシュボード' },
    { item: 'macroAnalysis', name: 'マクロ分析' },
    {
      item: 'macroAnalysisInfoDataDownload',
      name: 'マクロ分析データダウンロード',
    },    
    { item: 'logout', name: 'ログアウト' }
  ],
  parking: [
    { item: 'selectArea', name: '検証一覧' },
    // { item: 'selectPole', name: 'ポール選択' },
    // { item: 'accidents', name: 'ヒヤリハット・事故情報表示' },
    // { item: 'summary', name: 'サマリー' },
    // { item: 'dashboard', name: 'ダッシュボード' },
    { item: 'macroAnalysis', name: 'マクロ分析' },
    {
      item: 'macroAnalysisInfoDataDownload',
      name: 'マクロ分析データダウンロード',
    },
    { item: 'history', name: '過去データ一覧(最新20件)' },
    { item: 'logout', name: 'ログアウト' },
  ],
  summary: [
    { item: 'selectArea', name: '検証一覧' },
    // { item: 'selectPole', name: 'ポール選択' },
    // { item: 'accidents', name: 'ヒヤリハット・事故情報表示' },
    // { item: 'parking', name: '駐停車状況' },
    // { item: 'dashboard', name: 'ダッシュボード' },
    { item: 'macroAnalysis', name: 'マクロ分析' },
    {
      item: 'macroAnalysisInfoDataDownload',
      name: 'マクロ分析データダウンロード',
    },
    { item: 'history', name: '過去データ一覧(最新20件)' },
    { item: 'logout', name: 'ログアウト' },
  ],
  macroAnalysis: [
    { item: 'selectArea', name: '検証一覧' },
    // { item: 'selectPole', name: 'ポール選択' },
    { item: 'accidents', name: 'ヒヤリハット・事故情報表示' },
    // { item: 'summary', name: 'サマリー' },
    // { item: 'parking', name: '駐停車状況' },
    // { item: 'dashboard', name: 'ダッシュボード' },
    {
      item: 'macroAnalysisInfoDataDownload',
      name: 'マクロ分析データダウンロード',
    },
    { item: 'history', name: '過去データ一覧(最新20件)' },
    { item: 'logout', name: 'ログアウト' },
  ],
  macroAnalysisInfoDataDownload: [
    { item: 'selectArea', name: '検証一覧' },
    // { item: 'selectPole', name: 'ポール選択' },
    { item: 'accidents', name: 'ヒヤリハット・事故情報表示' },
    // { item: 'summary', name: 'サマリー' },
    // { item: 'parking', name: '駐停車状況' },
    // { item: 'dashboard', name: 'ダッシュボード' },
    { item: 'macroAnalysis', name: 'マクロ分析' },
    { item: 'history', name: '過去データ一覧(最新20件)' },
    { item: 'logout', name: 'ログアウト' },
  ],
  watch: [
    { item: 'selectArea', name: '検証一覧' },
    { item: 'logout', name: 'ログアウト' },
  ],
  collection: [
    { item: 'selectArea', name: '検証一覧' },
    { item: 'aggregation', name: '集計グラフ', extended: true },      
    { item: 'logout', name: 'ログアウト' }
  ]
}
// リアルタイムのカメラ映像(KVSチャネル)の応答確認及び再生通知非同期処理間隔を設定するオブジェクト
export const STREAMING = { respChkInterval: 1000, notifyInterval: 5000 }

//LED表示板の表示レベル
export const LED_DISPLAY_LEVEL = {
  safeDrivingSupport: [
    { kind: '000', displayName: '', definition: '予約' },
    { kind: '001', displayName: '危険', definition: '存在通知' },
    { kind: '002', displayName: '注意', definition: '注意喚起' },
  ],
  safeDrivingSupportOthers: {
    displayName: '',
    definition: 'その他：予約',
  },
  safeDrivingSupportLaw: [
    { kind: '030', displayName: '', definition: '予約' },
    { kind: '031', displayName: '1 変 注意', definition: '注意1' },
    { kind: '032', displayName: '2 変 注意', definition: '注意2' },
    { kind: '033', displayName: '3 変 注意', definition: '注意3' },
    { kind: '034', displayName: '1 変 謝辞', definition: '謝辞1' },
    { kind: '035', displayName: '2 変 謝辞', definition: '謝辞2' },
    { kind: '036', displayName: '3 変 謝辞', definition: '謝辞3' },
  ],
  safeDrivingSupportLawOthers: {
    displayName: '',
    definition: 'その他：予約',
  },
  disaster: [
    {
      kind: '100',
      displayName: '予約',
      definition: '予約',
    },
    { kind: '101', displayName: '避難準備', definition: '避難準備（発射）' },
    { kind: '102', displayName: '避難勧告', definition: '避難勧告（通過）' },
    { kind: '103', displayName: '避難指示', definition: '避難指示（落下）' },
    { kind: '199', displayName: '無し', definition: '無し' },
  ],
  disasterOthers: { displayName: '', definition: 'その他：予約' },
  safeDrivingSupportLawDirection: [
    { direction: '000', displayName: 'パターン①', definition: 'パターン①' },
    { direction: '045', displayName: 'パターン②', definition: 'パターン②' },
    { direction: '090', displayName: 'パターン③', definition: 'パターン③' },
    { direction: '135', displayName: 'パターン④', definition: 'パターン④' },
    { direction: '180', displayName: 'パターン⑤', definition: 'パターン⑤' },
    { direction: '225', displayName: 'パターン⑥', definition: 'パターン⑥' },
    { direction: '270', displayName: 'パターン⑦', definition: 'パターン⑦' },
    { direction: '315', displayName: 'パターン⑧', definition: 'パターン⑧' },
    { direction: '-01', displayName: 'パターン⑨', definition: 'パターン⑨' },
    { direction: '-10', displayName: 'パターン⑩', definition: 'パターン⑩' },
  ],
}

// Grafanaボタン対象ポール情報
export const POLE_ON_GRAFANA = {
  poleInfo: [
    {
      poleId: 589370378,
      name: '大規模実証_豊栄北',
      path: 'https://g-339943402d.grafana-workspace.ap-northeast-1.amazonaws.com/dashboards/f/avppKU2Ik/5aSn6KaP5qih5a6f6Ki8X-ixiuaghOWMlw',
      nearMissPath: 'zbi90XKSk/1-2-aws-1707963639441?orgId=1'
    },
    {
      poleId: 589370379,
      name: '大規模実証_豊栄町南',
      path: 'https://g-339943402d.grafana-workspace.ap-northeast-1.amazonaws.com/dashboards/f/FmQFUSTSk/5aSn6KaP5qih5a6f6Ki8X-ixiuaghOeUuuWNlw',
      nearMissPath: 'L7yLdwKSz/1-2-aws-1708405584148?orgId=1'
    },
    {
      poleId: 589370380,
      name: '大規模実証_大林1001',
      path: 'https://g-339943402d.grafana-workspace.ap-northeast-1.amazonaws.com/dashboards/f/PPw58STSk/1001',
      nearMissPath: '_xdjAXFIz/1-2-aws-1708410726344?orgId=1'
    },
    {
      poleId: 589370381,
      name: '大規模実証_大林15',
      path: 'https://g-339943402d.grafana-workspace.ap-northeast-1.amazonaws.com/dashboards/f/rAst8SoIk/15',
      nearMissPath: 'wEq9AXFSk/1-2-aws-1708411334125?orgId=1'
    },
    {
      poleId: 589370382,
      name: '大規模実証_浄水04a',
      path: 'https://g-339943402d.grafana-workspace.ap-northeast-1.amazonaws.com/dashboards/f/KShh8ITSz/04a',
      nearMissPath: '11x9AXFIz/1-2-aws-1708411585731?orgId=1'
    },
  ]
}
