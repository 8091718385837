import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

import { onBeforeMount, onMounted, ref } from 'vue'
import 'leaflet/dist/leaflet.css'
import 'leaflet-arrowheads'

import Button from '@/components/parts/common/Button.vue'
import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'

import { useSelectAreaStore, useSelectPoleStore } from '@/store/app'

import { updatePoleListAsAreaId, updateWatchingData } from '@/mixins/communicationFunction'
import { getTileLayer } from '@/mixins/mapFunction'
import { getInitFromDateTime, getDateTimeNow } from '@/mixins/timerFunction'
import {
    poleIcon,
    blueIcon,
    redIcon,
    greenIcon,
    yellowIcon,
    lightblueIcon,
    pinkIcon,
    lightgreenIcon,
    orangeIcon,
    blackIcon,
    purpleIcon,
    wineIcon,
    blueTIcon,
    redTIcon,
    greenTIcon,
    yellowTIcon,
    lightblueTIcon,
    pinkTIcon,
    lightgreenTIcon,
    orangeTIcon,
    blackTIcon,
    purpleTIcon,
    wineTIcon,
    blueSIcon,
    redSIcon,
    greenSIcon,
    yellowSIcon,
    lightblueSIcon,
    pinkSIcon,
    lightgreenSIcon,
    orangeSIcon,
    blackSIcon,
    purpleSIcon,
    wineSIcon
  } from '@/utils/mapIcon'

import { DistanceFromPoleToVehicle, DistanceFromPoleToVehicleByAreaId, ErrorDialogInfo, PoleInfo, TitleInfo } from '@/types/Interfaces'

import { MENU, VIRTUAL_MAP, SELECT_POLE } from '@/setting/setting'

import L from 'leaflet'

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'Watch',
  setup(__props) {

/**
 * Watch.vue
 * 見守り画面
 */
// ==================================
// import
// ==================================
const selectAreaStore = useSelectAreaStore()
const selectPoleStore = useSelectPoleStore()

// タイトル
const titleinfo = ref<TitleInfo>({
  title: selectAreaStore.name + ' 見守り画面',
  pointList: [],
  menuList: MENU.watch,
  show: {
    multicamera: false,
    virtual: false,
    menu: true,
  },
})

// エラーダイアログ情報
const errorDialog = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false,
})

const isLoading = ref<boolean>(false)

const areaId = ref(selectPoleStore.$state.areaId)

const actionHistoryTableHeader = ref([
  {
    value: 'detectedTime'
  },
  { 
    value: 'vehicleId'
  },
  { 
    value: 'status'
  }
])

const lineColors = ref([
  '#3d46cd',
  '#ed161f',
  '#1cb24b',
  '#f7f700',
  '#99d9ea',
  '#ffaec9',
  '#b6e717',
  '#ff7f27',
  '#000000',
  '#a447a5',
  '#880015',
  '#3d46cd',
  '#ed161f',
  '#1cb24b',
  '#f7f700',
  '#99d9ea',
  '#ffaec9',
  '#b6e717',
  '#ff7f27',
  '#000000',
  '#a447a5',
  '#880015',
  '#3d46cd',
  '#ed161f',
  '#1cb24b',
  '#f7f700',
  '#99d9ea',
  '#ffaec9',
  '#b6e717',
  '#ff7f27',
  '#000000',
  '#a447a5',
  '#880015'
])

const idIconColors = ref<any[]>([])

const iconList = ref([
  poleIcon,
  blueIcon,
  redIcon,
  greenIcon,
  yellowIcon,
  lightblueIcon,
  pinkIcon,
  lightgreenIcon,
  orangeIcon,
  blackIcon,
  purpleIcon,
  wineIcon,
  blueTIcon,
  redTIcon,
  greenTIcon,
  yellowTIcon,
  lightblueTIcon,
  pinkTIcon,
  lightgreenTIcon,
  orangeTIcon,
  blackTIcon,
  purpleTIcon,
  wineTIcon,
  blueSIcon,
  redSIcon,
  greenSIcon,
  yellowSIcon,
  lightblueSIcon,
  pinkSIcon,
  lightgreenSIcon,
  orangeSIcon,
  blackSIcon,
  purpleSIcon,
  wineSIcon    
])

const searchConditions = ref({
  fromDate: '',
  toDate: '',
  fromTime: '',
  toTime: '',
  targetvehicleId: [] as number[]
})

const editingData = ref({
  areaName: '',
  mapLatlng: selectPoleStore.$state.latlng,
  sensorList: [] as {
    poleId: number
    latlng: [number, number]
  }[],
  poleMarkers: [] as any[],
  markerLatlng: [0, 0],
  idList: [] as number[],
  actionHistories: [] as DistanceFromPoleToVehicleByAreaId,
  actionHistoryList: [] as {
    detectedTime: string
    vehicleId: number
    status: string
  }[],
  targetVehicleList: [] as DistanceFromPoleToVehicleByAreaId[],
  poleInfoList: [] as {
    vehicleId: string
    poleId: number
    detectedTime: string
    status: string
  }[]
})

const map = ref<any>({})

const filter = ref<boolean>(false)

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  initialize()
})

onMounted(() => {
  setPoleList(areaId.value)
})

// ==================================
// method
// ==================================
/**
 * 初期処理
 */
const initialize = () => {
  const from = getInitFromDateTime()
  const to = getDateTimeNow()
  searchConditions.value = {
    fromDate: from[0],
    fromTime: from[1],
    toDate: to[0],
    toTime: to[1],
    targetvehicleId: []
  }
}

/**
 * ポール一覧情報を取得
 * @param areaId - エリアID
 */
const setPoleList = async(areaId: number) => {
  isLoading.value = true
  const promise = updatePoleListAsAreaId(areaId)
  promise
    .then((response: PoleInfo[]) => {
      for (let pole of response) {
        editingData.value.sensorList.push({
          poleId: pole.poleId,
          latlng: [pole.latitude, pole.longitude]
        })
      }
    })
    .then(() => {
      search()
    })
    .catch(() => {
      isLoading.value = false
      errorDialog.value.title = 'ポール一覧取得失敗'
      errorDialog.value.message = 'ポール一覧情報の取得に失敗しました。'
      errorDialog.value.isShow = true
    })
}

/**
 * 見守り情報検索
 */
const search = async() => {
  editingData.value.actionHistories = []
  editingData.value.actionHistoryList = []
  editingData.value.poleInfoList = []
  // フォーム上の指定日時を取得する
  const from = searchConditions.value.fromDate + ' ' + searchConditions.value.fromTime
  const to = searchConditions.value.toDate + ' ' + searchConditions.value.toTime
  isLoading.value = true
  // 見守り情報を取得する
  const promise = updateWatchingData(areaId.value, from.replaceAll('-', '/'), to.replaceAll('-', '/'))
  promise
    .then(val => {
      isLoading.value = false
      editingData.value.actionHistories = val
      // actionHistoriesのキーを抽出して整数配列としてidListに追加する
      const keys = Object.keys(editingData.value.actionHistories)
      for (let key of keys) {
        // キーが整数以外の場合はスキップする
        if (!isNaN(parseInt(key))) {
          editingData.value.idList.push(parseInt(key))
        }
      }
    })
    .then(() => {
      // 見守りデータが空の場合、初期値を登録する
      const data: DistanceFromPoleToVehicleByAreaId = editingData.value.actionHistories
      for (let i = 0; i < editingData.value.idList.length; i++) {
        const key = editingData.value.idList[i]
        for (let j = 0; j < data[key].length; j++) {
          if (data[key][j].poleId === null){
            data[key][j].poleId = 0
          }
          if (data[key][j].latitude === null) {
            data[key][j].latitude = 0
          }
          if (data[key][j].longitude === null) {
            data[key][j].longitude = 0
          }
          if (data[key][j].vehicleStat === null) {
            data[key][j].vehicleStat = 2
          }
          if (data[key][j].detectedTime === null) {
            data[key][j].detectedTime = 'データなし'
          }
        }
      }      
    })
    .then(() => {
      let i = 0
      // アイコンの色リストを取得する
      editingData.value.idList.forEach(id => {
        idIconColors.value[id] = iconList.value[i & 31]
        i++
      })
      editingData.value.poleMarkers = editingData.value.sensorList[0].latlng
    })
    .then(() => {
      showInfoByVehicleId((filter.value = true))
    })
    .catch(() => {
      isLoading.value = false
    })
}

/**
 * フィルタリングのフォーム情報をもとに地図表示を更新する
 * @param filter - フィルタリング判定
 */
const showInfoByVehicleId = (filter: boolean) => {
  // フィルタリングがない場合は処理を行わない
  if (!filter) return
  editingData.value.targetVehicleList = []
  const filterList: DistanceFromPoleToVehicleByAreaId = {}
  for (let key of searchConditions.value.targetvehicleId) {
    if (editingData.value.actionHistories[key]) {
      filterList[key] = editingData.value.actionHistories[key]
    }
  }
  editingData.value.targetVehicleList.push(filterList)
  setMonitorMap(editingData.value.targetVehicleList[0])
  setActionHistoryList(editingData.value.targetVehicleList[0])
}

/**
 * エリア地図の生成
 * @param data - 見守り情報
 */
const setMonitorMap = (data: DistanceFromPoleToVehicleByAreaId) => {
  let latlng = selectAreaStore.$state.latlng
  let zoom = SELECT_POLE.zoom.default
  let zoom_min = VIRTUAL_MAP.zoom.min
  let zoom_max = VIRTUAL_MAP.zoom.max
  if (Object.keys(map.value).length === 0) {
    const lMap = L.map('monitorMap', {
      dragging: true,
      touchZoom: true,
      scrollWheelZoom: true,
      doubleClickZoom: true,
      boxZoom: true,
      tap: true,
      keyboard: true,
      zoomControl: true,
      minZoom: zoom_min,
      maxZoom: zoom_max
    }).setView(latlng, zoom)
    map.value = lMap
  } else {
    map.value.remove()
    const lMap = L.map('monitorMap', {
      dragging: true,
      touchZoom: true,
      scrollWheelZoom: true,
      doubleClickZoom: true,
      boxZoom: true,
      tap: true,
      keyboard: true,
      zoomControl: true,
      minZoom: zoom_min,
      maxZoom: zoom_max
    }).setView(latlng, zoom)
    map.value = lMap
  }
  L.control.layers(getTileLayer(map.value, VIRTUAL_MAP.zoom.max, undefined)).addTo(map.value)

  let polePoint: any = ''
  let bindPopup: any = ''
  let list: any = ''

  // センサー情報をもとに地図上にマーカーを追加する
  if (editingData.value.sensorList.length !== 0) {
    for (let sensorList of editingData.value.sensorList) {
      editingData.value.poleMarkers = sensorList.latlng
      polePoint = L.marker(editingData.value.poleMarkers, { icon: poleIcon })
      if (editingData.value.poleInfoList.length !== 0) {
        bindPopup = document.createElement('ul')
          for (let poleInfo of editingData.value.poleInfoList) {          
          if (sensorList.poleId === poleInfo.poleId) {
            list = document.createElement('li')
            list.textContent = `${poleInfo.vehicleId} 
                      ${poleInfo.detectedTime} 
                        ${poleInfo.status}`
            bindPopup.appendChild(list)
          }
        }
        if (bindPopup.innerText !== '') {
          polePoint.bindPopup(bindPopup)
        } else {
          polePoint.bindPopup('接近情報はありません')
        }
      } else {
        polePoint.bindPopup('接近情報はありません')
      }
      polePoint.addTo(map.value)
    }
  }

  // 地図上に物標情報を追加する
  if (editingData.value.actionHistories) {
    let targetVehiclePoint: [number, number][] = []
    let i = 0
    for (let id of editingData.value.idList) {
      if (data[id]) {
        for (let val of data[id]) {
          targetVehiclePoint.push([val.latitude, val.longitude])
          L.marker([val.latitude, val.longitude], { icon: idIconColors.value[id] }).addTo(map.value)
        }
        L.polyline(targetVehiclePoint, {
          color: lineColors.value[i & 31],
          weight: 3,
          dashArray: '10, 10',
          dashOffset: 1
        })
        .arrowheads({ size: '20px', fill: true })
        .addTo(map.value)
      }
      targetVehiclePoint = []
      i++
    }
  }
}

/**
 * 物標の移動履歴を生成
 * @param actionHistoryList - 移動履歴一覧
 */
const setActionHistoryList = (actionHistoryList: DistanceFromPoleToVehicleByAreaId) => {
  editingData.value.actionHistoryList = []
  editingData.value.poleInfoList = []
  const targetStatus = [
    'から 離脱',
    '付近 接近',
    'へのデータなし'
  ]
  const status = [
    '離脱',
    '接近',
    'データなし'
  ]
  // 移動履歴のキー単位で、移動履歴とポール一覧を登録する
  Object.keys(actionHistoryList).forEach(key => {
    const targetKey = parseInt(key)
    actionHistoryList[targetKey].forEach((data: DistanceFromPoleToVehicle) => {
      // 移動履歴とポール情報を抽出する
      const tableData = {
        detectedTime: data.detectedTime,
        vehicleId: targetKey,
        status: `ポールID${data.poleId}${targetStatus[data.vehicleStat]}`
      }

      const poleInfo = {
        vehicleId: key,
        poleId: data.poleId,
        detectedTime: data.detectedTime,
        status: `${status[data.vehicleStat]}`
      }
      // 一覧に追加する
      editingData.value.actionHistoryList.push(tableData);
      editingData.value.poleInfoList.push(poleInfo);
    })
  })
  // 時系列ごとにソートする
  editingData.value.actionHistoryList.sort((a, b) => {
    if (a.detectedTime !== b.detectedTime) {
      return new Date(b.detectedTime).getTime() - new Date(a.detectedTime).getTime()
    }
    if (a.vehicleId !== b.vehicleId) {
      return (b.vehicleId - a.vehicleId) * -1
    }
    return 0
  })
}

/**
 * エラーダイアログを閉じる
 */
const onClickCloseErrorDialog = () => {
  errorDialog.value.isShow = false
}

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TitleHeader, { "title-info": titleinfo.value }, null, 8, ["title-info"]),
    _createVNode(_component_v_container, { class: "watch" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "7" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, { elevation: "10" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, { class: "py-0 watch__window-title" }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createElementVNode("span", { class: "py-0" }, "VIRTUAL映像", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_card_text, { class: "dataScreen cols-7" }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createElementVNode("div", {
                          id: "monitorMap",
                          class: "monitorMap"
                        }, null, -1)
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { cols: "5" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_container, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { md: "12" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card, { elevation: "3" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_title, { class: "py-0 watch__window-title" }, {
                                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                                    _createElementVNode("span", { class: "py-0" }, "日付(表示範囲)", -1)
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_v_card_text, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_container, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_row, { justify: "center" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_spacer),
                                            _createVNode(_component_v_col, { md: "3" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_text_field, {
                                                  modelValue: searchConditions.value.fromDate,
                                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchConditions.value.fromDate) = $event)),
                                                  type: "date"
                                                }, null, 8, ["modelValue"])
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_v_col, { md: "2" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_text_field, {
                                                  modelValue: searchConditions.value.fromTime,
                                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((searchConditions.value.fromTime) = $event)),
                                                  type: "time"
                                                }, null, 8, ["modelValue"])
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_v_col, null, {
                                              default: _withCtx(() => _cache[10] || (_cache[10] = [
                                                _createElementVNode("p", {
                                                  class: "pt-3",
                                                  style: {"text-align":"center"}
                                                }, " ～ ", -1)
                                              ])),
                                              _: 1
                                            }),
                                            _createVNode(_component_v_col, { md: "3" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_text_field, {
                                                  modelValue: searchConditions.value.toDate,
                                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((searchConditions.value.toDate) = $event)),
                                                  type: "date"
                                                }, null, 8, ["modelValue"])
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_v_col, { md: "2" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_text_field, {
                                                  modelValue: searchConditions.value.toTime,
                                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((searchConditions.value.toTime) = $event)),
                                                  type: "time"
                                                }, null, 8, ["modelValue"])
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_v_spacer)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_col, null, {
                                              default: _withCtx(() => [
                                                _createVNode(Button, {
                                                  label: "決定",
                                                  disabled: false,
                                                  onClick: _cache[4] || (_cache[4] = ($event: any) => (search()))
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_container, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { md: "12" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card, { elevation: "3" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_title, { class: "py-0 watch__window-title" }, {
                                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                                    _createElementVNode("span", { class: "py-0" }, "ID", -1)
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_v_card_text, { class: "watch__id-list" }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(editingData.value.idList, (item, index) => {
                                      return (_openBlock(), _createElementBlock("div", { key: index }, [
                                        _createVNode(_component_v_checkbox, {
                                          modelValue: searchConditions.value.targetvehicleId,
                                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((searchConditions.value.targetvehicleId) = $event)),
                                          value: item,
                                          label: item.toString(),
                                          density: "compact",
                                          onChange: _cache[6] || (_cache[6] = ($event: any) => (showInfoByVehicleId((filter.value = true))))
                                        }, null, 8, ["modelValue", "value", "label"])
                                      ]))
                                    }), 128))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_container, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { md: "12" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card, { elevation: "3" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_title, { class: "py-0 watch__window-title" }, {
                                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                                    _createElementVNode("span", { class: "py-0" }, "行動履歴", -1)
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_v_data_table, {
                                  headers: actionHistoryTableHeader.value,
                                  items: editingData.value.actionHistoryList
                                }, null, 8, ["headers", "items"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _withDirectives(_createVNode(Loading, null, null, 512), [
      [_vShow, isLoading.value]
    ]),
    _createVNode(ErrorDialog, {
      "error-dialog": errorDialog.value,
      onOnClickCloseErrorDialog: onClickCloseErrorDialog
    }, null, 8, ["error-dialog"])
  ], 64))
}
}

})