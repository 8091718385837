import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-frame-search-card__body ml-5" }
const _hoisted_2 = { class: "search-frame-search-card__error" }
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = { class: "search-frame-search-card__error" }
const _hoisted_5 = { class: "d-flex" }

import { ref, computed, onMounted } from 'vue'

import { useSelectPoleStore, useSearchConditionStore } from '@/store/app'

import { DIALOG_ERROR_INFO } from '@/mixins/commonFunction'
import { updateNearMissesData, updateAccidentsData } from '@/mixins/communicationFunction'

import { SearchConditionStore } from '@/types/Interfaces'

import { DateTime } from 'luxon'

// ==================================
// interface
// ==================================
interface Emits {
  (e: 'start-loading'): void;
  (e: 'stop-loading'): void;
  (e: 'add-near-miss-marker'): void;
  (e: 'add-accident-marker'): void;
  (e: 'update-near-misses-list'): void;
  (e: 'update-accident-list'): void;
  (e: 'open-error-dialog', value1: string, value2: string): void;
}

// ==================================
// data
// ==================================
// 選択ポール情報ストア

export default /*@__PURE__*/_defineComponent({
  __name: 'SearchFrame',
  emits: ["start-loading", "stop-loading", "add-near-miss-marker", "add-accident-marker", "update-near-misses-list", "update-accident-list", "open-error-dialog"],
  setup(__props, { emit: __emit }) {

/**
 * SearchFrame.vue
 * 検索エリア
 * 
 * 親コンポーネント
 * @/views/Accident.vue
 */
// ==================================
// import
// ==================================
const selectPoleStore = useSelectPoleStore()

// 検索方法
const retrievalItems = [
  { title: '日付日時範囲検索', value: 0 },
  { title: '日付日時個別検索', value: 1 },
  { title: '日付範囲-日時個別検索', value: 2 },
]

const retrievalMethodValue = ref(0)

// 重複行排除可否
const isDeduplication = ref(true)

// TimeGap初期入力チェック可否
const valid = ref(true)

// 日付初期値設定
const initDateTime = DateTime.now()

// 前日
const fromDateTime: any = DateTime.fromObject({
  year: initDateTime.year,
  month: initDateTime.month,
  day: initDateTime.day,
  hour: 12,
  minute: 30,
  second: 30,
}).minus({
  day: 1,
})

// 当日
const toDateTime: any = DateTime.fromObject({
  year: initDateTime.year,
  month: initDateTime.month,
  day: initDateTime.day,
  hour: 12,
  minute: 30,
  second: 30,
})

// 検索条件初期値設定
const searchConditions = ref<SearchConditionStore>({
  dates1: [new Date(fromDateTime.ts), new Date(toDateTime.ts)],
  dates2: [new Date(toDateTime.ts)],
  dates3: [new Date(fromDateTime.ts), new Date(toDateTime.ts)],
  fromTime: new Date(toDateTime.ts),
  toTime: new Date(toDateTime.ts),
  threshold: 30,
  startDateTime: '',
  endDateTime: '',
})

const searchConditionStore = useSearchConditionStore()

// 閾値バリデーション定義
const thresholdRules = [(v: number) => !!v || 'TimeGapは必須です。', (v: number) => v >= 0 || '閾値は0以上でなければなりません', (v: number) => v <= 999.999 || '閾値は999.999以下でなければなりません', (v: number) => /^(\d{0,3}\.\d{0,3}|\d{0,3})$/.test(String(v)) || '閾値は小数点第3位まで出なければなりません']

// ==================================
// computed
// ==================================
// 時刻エラーメッセージ
const timeErrorMessage = computed(() => {
  switch (retrievalMethodValue.value) {
    case retrievalItems[1].value:
    case retrievalItems[2].value:
      if (searchConditions.value.fromTime == null) {
        return '開始日は必須です。'
      }
      if (searchConditions.value.toTime == null) {
        return '終了日は必須です。'
      }
      break
    default:
      return ''
  }
  return ''
})

// 対象日エラーメッセージ
const dateErrorMessage = computed(() => {
  switch (retrievalMethodValue.value) {
    case retrievalItems[0].value:
      if (searchConditions.value.dates1 == null || searchConditions.value.dates1.length != 2 || searchConditions.value.dates1.includes(null)) {
        return '対象日は必須です。'
      }
      break
    case retrievalItems[1].value:
      if (searchConditions.value.dates2 == null || searchConditions.value.dates2.length == 0 || searchConditions.value.dates2.includes(null)) {
        return '対象日は必須です。'
      }
      break
    case retrievalItems[2].value:
      if (searchConditions.value.dates3 == null || searchConditions.value.dates3.length == 0 || searchConditions.value.dates3.includes(null)) {
        return '対象日は必須です。'
      }
      break
    default:
      return '対象日は必須です。'
  }
  return ''
})

// 決定ボタン押下可否
const isProcessing = computed(() => {
  let tempThreshold = Number(searchConditions.value.threshold)
  if (Number.isNaN(tempThreshold) || !/^(\d{0,3}\.\d{0,3}|\d{0,3})$/.test(String(searchConditions.value.threshold))) {
    return true
  }
  if (tempThreshold < 0 || tempThreshold > 999.999) {
    return true
  }
  switch (retrievalMethodValue.value) {
    case retrievalItems[0].value:
      if (searchConditions.value.dates1 == null || searchConditions.value.dates1.length != 2 || searchConditions.value.dates1.includes(null)) {
        return true
      }
      break
    case retrievalItems[1].value:
      if (searchConditions.value.dates2 == null || searchConditions.value.dates2.length == 0 || searchConditions.value.dates2.includes(null)) {
        return true
      }
      if (searchConditions.value.fromTime == null || searchConditions.value.toTime == null) {
        return true
      }
      break
    case retrievalItems[2].value:
      if (searchConditions.value.dates3 == null || searchConditions.value.dates3.length == 0 || searchConditions.value.dates3.includes(null)) {
        return true
      }
      if (searchConditions.value.fromTime == null || searchConditions.value.toTime == null) {
        return true
      }
      break
    default:
      return true
  }
  return false
})

// ==================================
// hook
// ==================================
onMounted(() => {
  // 初期検索
  searchConditionStore.clearData()
  search()
})

// ==================================
// method
// ==================================
const emit = __emit
/**
 * ローディング処理開始
 */
const startLoading = () => {
  emit('start-loading')
}
/**
 * ローディング処理終了
 */
const stopLoading = () => {
  emit('stop-loading')
}
/**
 * ヒヤリハット情報マーカー設定
 */
const addNearMissMarker = () => {
  emit('add-near-miss-marker')
}
/**
 * 事故発生情報マーカー設定
 */
const addAccidentMarker = () => {
  emit('add-accident-marker')
}
/**
 * ヒヤリハット情報一覧更新
 */
const updateNearMissesList = () => {
  emit('update-near-misses-list')
}
/**
 * 事故発生情報一覧更新
 */
const updateAccidentList = () => {
  emit('update-accident-list')
}
/**
 * エラーダイアログを開く
 */
const openErrorDialog = () => {
  emit('open-error-dialog', DIALOG_ERROR_INFO.title.getError, DIALOG_ERROR_INFO.message.getError)
}

/**
 * 検索処理
 */
const search = () => {
  searchConditions.value.startDateTime = DateTime.now().toFormat('yyyyddMM_HHmmss')
  let fromTime = ''
  let toTime = ''
  let dates: string[] = []
  let isRange = false
  switch (retrievalMethodValue.value) {
    case retrievalItems[0].value:
      /* @ts-expect-error : elementはnullableのためエラーが発生するがdate1プロパティにnullが格納されている場合はsearchメソッドを実行できない*/
      searchConditions.value.dates1.forEach((element: Date, index: number) => {
        if (index == 0) {
          fromTime = DateTime.fromJSDate(element).toFormat('HH:mm:ss')
        } else {
          toTime = DateTime.fromJSDate(element).toFormat('HH:mm:ss')
        }
        dates.push(DateTime.fromJSDate(element).toFormat('yyyy/MM/dd'))
      })
      isRange = true
      break
    case retrievalItems[1].value:
      /* @ts-expect-error : date1と同じ */
      searchConditions.value.dates2.forEach((element: Date) => {
        dates.push(DateTime.fromJSDate(element).toFormat('yyyy/MM/dd'))
      })
      fromTime = DateTime.fromJSDate(searchConditions.value.fromTime).toFormat('HH:mm:ss')
      toTime = DateTime.fromJSDate(searchConditions.value.toTime).toFormat('HH:mm:ss')
      isRange = false

      break
    case retrievalItems[2].value:
      /* @ts-expect-error : date1と同じ */
      for (let d = searchConditions.value.dates3[0]; d <= searchConditions.value.dates3[1]; d.setDate(d.getDate() + 1)) {
        /* @ts-expect-error : date1と同じ */
        let formatedDate = d.getFullYear() + '/' + ('0' + (d.getMonth() + 1)).slice(-2) + '/' + ('0' + d.getDate()).slice(-2)
        dates.push(formatedDate)
      }
      fromTime = DateTime.fromJSDate(searchConditions.value.fromTime).toFormat('HH:mm:ss')
      toTime = DateTime.fromJSDate(searchConditions.value.toTime).toFormat('HH:mm:ss')
      isRange = false
      break
    default:
  }
  searchConditionStore.setData(searchConditions.value)
  // ヒヤリハット事故情報一覧取得
  const promise1 = updateNearMissesData(selectPoleStore.poleId, dates, fromTime, toTime, searchConditions.value.threshold, isDeduplication.value, isRange)
  // 事故発生一覧取得
  const promise2 = updateAccidentsData(selectPoleStore.poleId, dates, fromTime, toTime, isRange)
  startLoading()
  Promise.all([promise1, promise2])
    .then(() => {
      addNearMissMarker()
      addAccidentMarker()
      updateNearMissesList()
      updateAccidentList()
      searchConditions.value.endDateTime = DateTime.now().toFormat('yyyyddMM_HHmmss')
      searchConditionStore.setData(searchConditions.value)
      stopLoading()
    })
    .catch(() => {
      addNearMissMarker()
      addAccidentMarker()
      updateNearMissesList()
      updateAccidentList()
      stopLoading()
      openErrorDialog()
    })
}

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_vue_datepicker_next = _resolveComponent("vue-datepicker-next")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, {
      class: "search-frame-method-card",
      elevation: "1"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, { class: "search-frame-method-card__header py-0" }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createElementVNode("span", { class: "py-0" }, "検索方法", -1)
          ])),
          _: 1
        }),
        _createVNode(_component_v_select, {
          modelValue: retrievalMethodValue.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((retrievalMethodValue).value = $event)),
          label: "検索方法指定",
          items: retrievalItems
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_v_card, {
      class: "search-frame-search-card",
      elevation: "1"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, { class: "search-frame-search-card__header py-0" }, {
          default: _withCtx(() => _cache[11] || (_cache[11] = [
            _createElementVNode("span", { class: "py-0" }, "日付(表示範囲)", -1)
          ])),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _withDirectives(_createVNode(_component_vue_datepicker_next, {
            value: searchConditions.value.dates1,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((searchConditions.value.dates1) = $event)),
            class: "search-frame-search-card__input-dates",
            type: "datetime",
            range: "",
            placeholder: "対象日及び時刻",
            format: "YYYY/MM/DD HH:mm:ss"
          }, null, 8, ["value"]), [
            [_vShow, retrievalMethodValue.value == retrievalItems[0].value]
          ]),
          _withDirectives(_createVNode(_component_vue_datepicker_next, {
            value: searchConditions.value.dates2,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((searchConditions.value.dates2) = $event)),
            class: "search-frame-search-card__input-dates",
            type: "date",
            multiple: "",
            placeholder: "対象日",
            format: "YYYY/MM/DD"
          }, null, 8, ["value"]), [
            [_vShow, retrievalMethodValue.value == retrievalItems[1].value]
          ]),
          _withDirectives(_createVNode(_component_vue_datepicker_next, {
            value: searchConditions.value.dates3,
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((searchConditions.value.dates3) = $event)),
            class: "search-frame-search-card__input-dates",
            type: "date",
            multiple: "",
            range: "",
            placeholder: "対象日",
            format: "YYYY/MM/DD"
          }, null, 8, ["value"]), [
            [_vShow, retrievalMethodValue.value == retrievalItems[2].value]
          ]),
          _createElementVNode("p", _hoisted_2, _toDisplayString(dateErrorMessage.value), 1),
          _withDirectives(_createElementVNode("div", _hoisted_3, [
            _withDirectives(_createVNode(_component_vue_datepicker_next, {
              value: searchConditions.value.fromTime,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((searchConditions.value.fromTime) = $event)),
              type: "time",
              placeholder: "開始時刻"
            }, null, 8, ["value"]), [
              [_vShow, retrievalMethodValue.value == retrievalItems[1].value || retrievalMethodValue.value == retrievalItems[2].value]
            ]),
            _withDirectives(_createVNode(_component_vue_datepicker_next, {
              value: searchConditions.value.toTime,
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((searchConditions.value.toTime) = $event)),
              type: "time",
              placeholder: "終了時刻"
            }, null, 8, ["value"]), [
              [_vShow, retrievalMethodValue.value == retrievalItems[1].value || retrievalMethodValue.value == retrievalItems[2].value]
            ])
          ], 512), [
            [_vShow, retrievalMethodValue.value == retrievalItems[1].value || retrievalMethodValue.value == retrievalItems[2].value]
          ]),
          _createElementVNode("p", _hoisted_4, _toDisplayString(timeErrorMessage.value), 1),
          _createVNode(_component_v_form, {
            modelValue: valid.value,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((valid).value = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: searchConditions.value.threshold,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((searchConditions.value.threshold) = $event)),
                class: "search-frame-search-card__input-threshold",
                variant: "underlined",
                rules: thresholdRules,
                label: "TimeGapの閾値を入力してください",
                required: ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_v_switch, {
              modelValue: isDeduplication.value,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((isDeduplication).value = $event)),
              color: "primary",
              label: "ヒヤリハット一覧 重複行を除外する"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_v_btn, {
              disabled: isProcessing.value,
              class: "search-frame-search-card__button",
              rounded: "",
              color: isProcessing.value ? '' : 'primary',
              onClick: _cache[9] || (_cache[9] = ($event: any) => (search()))
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode(" 決定 ")
              ])),
              _: 1
            }, 8, ["disabled", "color"])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}
}

})