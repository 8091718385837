import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "direction-list" }
const _hoisted_2 = { class: "direction-list__checkbox" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "direction-list__obj-name" }

import { onMounted, watch, ref } from 'vue'

import { useAggregationFiltersStore } from '@/store/app'

import { DIRECTIONS } from '@/setting/setting'

import directionIcon from '@/assets/img/object/direction.svg'

// ==================================
// interface
// ==================================
interface Props {
  mode: number;
  dir: number;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'DirectionList',
  props: {
    mode: {},
    dir: {}
  },
  setup(__props: any) {

/**
 * DirectionList.vue
 * 方路一覧表示
 * 
 * 親コンポーネント
 * @/components/parts/aggregation/AggreMenu.vue
 */
// ==================================
// import
// ==================================
const filterStore = useAggregationFiltersStore()

const props = __props

const state = ref({
  directionList: [] as any[],
  selectedDirection: [] as any[]
})

// ==================================
// watch
// ==================================
watch(() => state.value.selectedDirection, () => {
  updateSelectedDirectionList()
})

// ==================================
// hook
// ==================================
onMounted(() => {
  initialize()
})

// ==================================
// method
// ==================================
/**
 * 初期処理
 */
const initialize = () => {
  state.value.directionList = setDirList()
  state.value.directionList.forEach(direction => {
    state.value.selectedDirection.push(direction.value)
  })
  updateSelectedDirectionList()
}

/**
 * 集計対象とIN,OUTの分類に応じて、対象のPiniaストアの値を更新する
 */
const updateSelectedDirectionList = () => {
  // 集計対象
  switch(props.mode) {
      // 通過台数
    case 0:
      // dir 0:OUT、1:IN
      if (props.dir === 1) {
        filterStore.setFilterngDirIn(state.value.selectedDirection)
      } else {
        filterStore.setFilterngDirOut(state.value.selectedDirection)
      }
      break
      // ヒヤリハット優先
    case 1:
      if (props.dir === 1) {
        filterStore.setPriorityDirIn(state.value.selectedDirection)
      } else {
        filterStore.setPriorityDirOut(state.value.selectedDirection)
      }
      break
      // ヒヤリハット非優先
    case 2:
      if (props.dir === 1) {
        filterStore.setNonPriorityDirIn(state.value.selectedDirection)
      } else {
        filterStore.setNonPriorityDirOut(state.value.selectedDirection)
      }
      break
    default:
  }
}

/**
 * 一覧表を生成する
 * @returns 方路情報リスト
 */
const setDirList = () => {
  let work: any[] = []
  for (let dir of DIRECTIONS) {
    const iconStyle = {
      width: '16px',
      height: '16px',
      transform: 'rotate(' + dir.deg + 'deg)',
      margin: '0 2px 0 0'      
    }
    work.push({
      name: dir.name,
      value: dir.deg,
      url: directionIcon,
      style: iconStyle      
    })
  }
  return work
}

return (_ctx: any,_cache: any) => {
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_table = _resolveComponent("v-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_table, {
      density: "compact",
      theme: "light"
    }, {
      default: _withCtx(() => [
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.value.directionList, (item) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: item.value
            }, [
              _createElementVNode("td", _hoisted_2, [
                _createVNode(_component_v_checkbox, {
                  modelValue: state.value.selectedDirection,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.value.selectedDirection) = $event)),
                  value: item.value,
                  density: "compact",
                  "hide-details": ""
                }, {
                  label: _withCtx(() => [
                    _createElementVNode("img", {
                      src: item.url,
                      style: _normalizeStyle(item.style)
                    }, null, 12, _hoisted_3),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1032, ["modelValue", "value"])
              ])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    })
  ]))
}
}

})