import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, onMounted } from 'vue'

import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'
import MultiCameraFootage from '@/components/parts/multiCamera/MultiCameraFootage.vue'

import { useSelectPoleStore } from '@/store/app'

import { DIALOG_ERROR_INFO, getCameraList } from '@/mixins/commonFunction'
import { getPoleList } from '@/mixins/communicationFunction'

import { CameraInfo, ErrorDialogInfo, TitleInfo } from '@/types/Interfaces'

import { MENU } from '@/setting/setting'

import * as log from 'loglevel'

// ==================================
// data
// ==================================
// 選択ポール情報ストア

export default /*@__PURE__*/_defineComponent({
  __name: 'MultiCamera',
  setup(__props) {

/**
 * MultiCamera.vue
 * マルチカメラモニター
 */
// ==================================
// import
// ==================================
const selectPoleStore = useSelectPoleStore()

const cameraFootageComponent1 = ref()
const cameraFootageComponent2 = ref()
const cameraFootageComponent3 = ref()
const cameraFootageComponent4 = ref()

// タイトル
const titleinfo = ref<TitleInfo>({
  title: selectPoleStore.name + ' マルチカメラモニター',
  pointList: [],
  menuList: MENU.realtime,
  show: {
    realtime: true,
    virtual: true,
    menu: true,
  },
})

// エラーダイアログ情報
const errorDialog = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false,
})

// 画面項目制御
const isLoading = ref<boolean>(true)

// ==================================
// hook
// ==================================
onMounted(async () => {
  // ポールIDに紐づくセンサー情報を取得
  // await updatePoleListAsPoleId(selectPoleStore.poleId)
  await getPoleList(null, selectPoleStore.poleId, null, true)
    .then(() => {
      const initCameraList1: CameraInfo[] = []
      const initCameraList2: CameraInfo[] = []
      const initCameraList3: CameraInfo[] = []
      const initCameraList4: CameraInfo[] = []
      const cameraList = getCameraList()
      cameraList.forEach((value: CameraInfo, index: number) => {
        switch (index) {
          case 0:
            initCameraList1.push(value)
            break
          case 1:
            initCameraList2.push(value)
            break
          case 2:
            initCameraList3.push(value)
            break
          default:
            initCameraList4.push(value)
        }
      })
      initCamera(initCameraList1, initCameraList2, initCameraList3, initCameraList4)
      isLoading.value = false
    })
    .catch((err) => {
      log.error(err)
      errorDialog.value.title = DIALOG_ERROR_INFO.title.getError
      errorDialog.value.message = DIALOG_ERROR_INFO.message.getErrorSensorInfo
      errorDialog.value.isShow = true
      isLoading.value = false
    })
})

// onBeforeUnmount(() => {
//   timerFunction.methods.stop();
// });

// ==================================
// method
// ==================================
/**
 * カメラリスト初期化
 * @param initCameraList1 - カメラリスト1
 * @param initCameraList2 - カメラリスト2
 * @param initCameraList3 - カメラリスト3
 * @param initCameraList4 - カメラリスト4
 */
const initCamera = (initCameraList1: CameraInfo[], initCameraList2: CameraInfo[], initCameraList3: CameraInfo[], initCameraList4: CameraInfo[]) => {
  cameraFootageComponent1.value.initCamera(initCameraList1)
  cameraFootageComponent1.value.initCamera(initCameraList2)
  cameraFootageComponent1.value.initCamera(initCameraList3)
  cameraFootageComponent1.value.initCamera(initCameraList4)
}

/**
 * エラーダイアログを閉じる
 */
const onClickCloseErrorDialog = () => {
  errorDialog.value.isShow = false
}

return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TitleHeader, { "title-info": titleinfo.value }, null, 8, ["title-info"]),
    _createVNode(_component_v_container, {
      fluid: "",
      "fill-height": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { dense: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "6" }, {
              default: _withCtx(() => [
                _createVNode(MultiCameraFootage, {
                  ref_key: "cameraFootageComponent1",
                  ref: cameraFootageComponent1,
                  "is-selct-sensor": false
                }, null, 512)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { cols: "6" }, {
              default: _withCtx(() => [
                _createVNode(MultiCameraFootage, {
                  ref_key: "cameraFootageComponent2",
                  ref: cameraFootageComponent2,
                  "is-selct-sensor": false
                }, null, 512)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { dense: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "6" }, {
              default: _withCtx(() => [
                _createVNode(MultiCameraFootage, {
                  ref_key: "cameraFootageComponent3",
                  ref: cameraFootageComponent3,
                  "is-selct-sensor": false
                }, null, 512)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { cols: "6" }, {
              default: _withCtx(() => [
                _createVNode(MultiCameraFootage, {
                  ref_key: "cameraFootageComponent4",
                  ref: cameraFootageComponent4,
                  "is-selct-sensor": true
                }, null, 512)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _withDirectives(_createVNode(Loading, null, null, 512), [
      [_vShow, isLoading.value]
    ]),
    _createVNode(ErrorDialog, {
      "error-dialog": errorDialog.value,
      onOnClickCloseErrorDialog: onClickCloseErrorDialog
    }, null, 8, ["error-dialog"])
  ], 64))
}
}

})