import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

import { computed } from 'vue'

// ==================================
// interface
// ==================================
interface Props {
  pointList: [any];
}

interface Emits {
  (e: 'on-select-point', value: any): void;
  (e: 'on-enter-point', value: any): void;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'SelectAreaList',
  props: {
    pointList: {}
  },
  emits: ["on-select-point", "on-enter-point"],
  setup(__props: any, { emit: __emit }) {

/**
 * SelectAreaList.vue
 * 地点リストコンポーネント
 * 
 * 親コンポーネント
 * @/components/parts/common/TitleHeader.vue
 */
// ==================================
// import
// ==================================
const props = __props

// ==================================
// computed
// ==================================
const pointList = computed(() => {
  return props.pointList
})

// ==================================
// method
// ==================================
const emit = __emit
/**
 * 地点リスト選択時画面中央に表示
 * @param value - 地点リスト
 */
const onSelectPoint = (value: any) => {
  emit('on-select-point', value)
}
/**
 * エリア情報設定
 * @param value - 地点リスト
 */
const onEnterPoint = (value: any) => {
  emit('on-enter-point', value)
}

return (_ctx: any,_cache: any) => {
  const _component_v_list_subheader = _resolveComponent("v-list-subheader")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createBlock(_component_v_list, {
    class: "select-area-list",
    nav: "",
    dense: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_list_subheader, { class: "select-area-list__subheader" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" 地点リスト ")
        ])),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pointList.value, (item, index) => {
        return (_openBlock(), _createBlock(_component_v_list_item, {
          key: index,
          onClick: ($event: any) => (onSelectPoint(item))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, {
              icon: "",
              size: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  onClick: ($event: any) => (onEnterPoint(item))
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" mdi-map-marker ")
                  ])),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_v_list_item_title, { class: "select-area-list__title" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128))
    ]),
    _: 1
  }))
}
}

})