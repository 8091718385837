import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

import { computed } from 'vue'

import { MenuInfo } from '@/types/Interfaces'

// ==================================
// interface
// ==================================
interface Props {
  menuList: MenuInfo[];
}

interface Emits {
  (e: 'on-select-menu', value: MenuInfo): void;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'MenuList',
  props: {
    menuList: {}
  },
  emits: ["on-select-menu"],
  setup(__props: any, { emit: __emit }) {

/**
 * MenuList.vue
 * 共通メニューコンポーネント
 * 
 * 親コンポーネント
 * @/components/parts/common/TitleHeader.vue
 */
// ==================================
// import
// ==================================
const props = __props

// ==================================
// computed
// ==================================
// メニューリスト表示
const menuList = computed(() => {
  return props.menuList
})

// ==================================
// method
// ==================================
const emit = __emit
/**
 * メニュータブから画面遷移
 * @param value - クリックしたメニュー情報
 */
const onClickMenu = (value: MenuInfo) => {
  emit('on-select-menu', value)
}

return (_ctx: any,_cache: any) => {
  const _component_v_list_subheader = _resolveComponent("v-list-subheader")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createBlock(_component_v_list, {
    nav: "",
    dense: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_list_subheader, { style: {"font-size":"15px"} }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" メニュー ")
        ])),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuList.value, (item, index) => {
        return (_openBlock(), _createBlock(_component_v_list_item, {
          key: index,
          onClick: ($event: any) => (onClickMenu(item))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list_item_title, { style: {"font-size":"15px"} }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128))
    ]),
    _: 1
  }))
}
}

})