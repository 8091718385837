import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, onMounted } from 'vue'

import SelectArea from '@/components/parts/area/SelectArea.vue'
import SelectPoleMenu from '@/components/parts/area/SelectPoleMenu.vue'
import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'

import { useAreaListStore, usePoleListStore } from '@/store/app'

import { DIALOG_ERROR_INFO } from '@/mixins/commonFunction'
import { getPoleList, updateAreaList, addressByCoordinates } from '@/mixins/communicationFunction'
import { setPoleName } from '@/mixins/mapFunction'

import { AreaInfo, ErrorDialogInfo, PoleInfo, PoleListStore, TitleInfo } from '@/types/Interfaces'

import { MENU } from '@/setting/setting'

import * as log from 'loglevel'

// ==================================
// interface
// ==================================
interface Work {
  areaId: number;
  name: string;
  latitude: number;
  longitude: number;
  latlng: [number, number];
  notificationFlag: boolean;
  poleList: PoleListStore[];
  poleNumber: number;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'Area',
  setup(__props) {

/**
 * Area.vue
 * 検証一覧
 */
// ==================================
// import
// ==================================
const poleListStore = usePoleListStore()
const areaListStore = useAreaListStore()

const selectAreaComponent = ref()

// タイトル
const titleinfo = ref<TitleInfo>({
  title: '検証一覧',
  pointList: [],
  menuList: MENU.selectArea,
  show: {
    logout: true,
    point: false,
    menu: true,
  },
})

// エラーダイアログ情報
const errorDialog = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false,
})

// 画面項目制御
const isLoading = ref<boolean>(true)

//ポール一覧メニュー情報
let areaPoleList: Work[] = []

// ==================================
// hook
// ==================================
onMounted(async() => {
  // すでにエリア情報、ポール情報を取得済みの場合、Piniaストアの情報からマーカーおよびポール一覧メニュー情報を生成する
  if (areaListStore.$state.areaList !== void 0 && areaListStore.$state.areaList.length > 0) {
    if (poleListStore.$state.poleList !== void 0 && poleListStore.$state.poleList.length > 0) {
      addAreaMarker()
      const res = await createPointList(poleListStore.$state.poleList)
      if (res !== void 0 && res.length > 0) {
        areaPoleList = res
      }
      isLoading.value = false
    } else {
      // ポール情報取得
      setPoleList()
    }
  } else {
    // エリア情報取得
    setAreaList()
  }
})

// ==================================
// method
// ==================================
/**
 * エリア一覧の設定
 */
const setAreaList = async () => {
  await updateAreaList()
    .then((res: AreaInfo[]) => {
      if (res.length === 0) {
        errorDialog.value.title = DIALOG_ERROR_INFO.title.getError
        errorDialog.value.message = DIALOG_ERROR_INFO.message.getErrorAreaNoContent
        errorDialog.value.isShow = true
      } else {
        // エリア一覧を取得したらポール一覧を取得する
        setPoleList()
      }
    })
    .catch((err) => {
      log.error(err)
      errorDialog.value.title = DIALOG_ERROR_INFO.title.getError
      errorDialog.value.message = DIALOG_ERROR_INFO.message.getErrorAreaInfo
      errorDialog.value.isShow = true
      isLoading.value = false
    })
}

/**
 * ポール一覧の取得
 */
const setPoleList = async () => {
  const getPoleFunc = getPoleList(null, null, null, false)
  getPoleFunc
  .then(async(res: PoleInfo[]) => {
    // 取得に失敗したエラーを表示
    if (res !== void 0 && res.length === 0) {
      errorDialog.value.title = DIALOG_ERROR_INFO.title.getError
      errorDialog.value.message = DIALOG_ERROR_INFO.message.getErrorAreaNoContent
      errorDialog.value.isShow = true
    } else {
      return res
    }
  })
  .then(async(res) => {
    if (res !== void 0) {
      // ポール名、ステータスを設定
      return await appendPoleData(res)
    }
  })
  .then(async(res) => {
    if (res !== void 0) {
      // Piniaストアに登録
      poleListStore.setData(res)
      // 地図上にポールのある場所にマーカーを追加する
      addAreaMarker()
      // メニューに表示するデータを生成
      return await createPointList(res)
    }
  })
  .then(res2 => {
    if (res2 !== void 0 && res2.length > 0) {
      areaPoleList = res2
    } else {
      errorDialog.value.title = DIALOG_ERROR_INFO.title.getError
      errorDialog.value.message = DIALOG_ERROR_INFO.message.getErrorAreaInfo
      errorDialog.value.isShow = true
      isLoading.value = false
    }
  })
  .then(() => {
    // 全ての処理が完了したら、ローディング状態を解除する
    isLoading.value = false
  })  
  .catch(error => {
    log.error(error)
    errorDialog.value.title = DIALOG_ERROR_INFO.title.getError
    errorDialog.value.message = DIALOG_ERROR_INFO.message.getErrorAreaInfo
    errorDialog.value.isShow = true
    isLoading.value = false  
  })
}

/**
 * ポール情報にポール名、ステータスを追加する
 * @param poleList - ポール一覧
 * @returns データを追加したポール一覧
 */
const appendPoleData = (async(poleList: PoleListStore[]): Promise<PoleListStore[]> => {
  let work: PoleListStore[] = []
  for await (let pole of poleList) {
    pole.latlng = [pole.latitude, pole.longitude]
    let response = await setAddress(pole)
    setStatus(response)
    work.push(response)
  }
  return work
})

/**
 * ポールのステータスを設定する
 * @param pole - ポール情報
 * @returns ステータス値
 */
const setStatus = (pole: PoleListStore): number => {
  // ポールのステータスを設定（0: 未稼働、1: 正常稼働、2: 異常)
  let inProgress = false
  let onError = false
  // ポール情報に紐づくセンサー一覧を調べる
  for (let sensor of pole.sensorList) {
    // いずれかのセンサーの稼働しているなら稼働中と判定する
    if (sensor.sensorStatus === 0) {
      inProgress = true
    }
    // いずれかのセンサーで異常が検知されたら異常と判定する
    if (sensor.errorCode !== 0) {
      onError = true
    }
  }
  // 未稼働で異常が検知されなければ未稼働と判定する
  if (!inProgress) {
    if (!onError) {
      return 0
    }
  } else {
    // 稼働中で異常が検知されなければ正常稼働と判定する
    if (onError) {
      return 2
    } else {
      return 1
    }
  }
  return 0
}

/**
 * ポール情報にポール名を設定、追加する
 * @param pole - ポール情報
 * @returns ポール情報
 */
const setAddress = async(pole: PoleListStore): Promise<PoleListStore> => {
  // OpenStreetMapから地点情報を取得する
  const response = await addressByCoordinates(pole.latitude, pole.longitude)
  if (response.data !== void 0 && response.data !== null) {
    // 取得に成功したら、分かれている住所情報を一つの文字列にまとめる
    const poleName = setPoleName(response.data.address)

    pole.address = poleName.address
    // メニューに表示するための市区町村名以下の住所を設定する
    // pole.name = response.data.address.neighbourhood !== void 0 ? response.data.address.neighbourhood : ''
    pole.name = poleName.name
    pole.status = setStatus(pole)
  }
  return pole
}

/**
 * エリア情報を元にマーカーを設定
 */
const addAreaMarker = () => {
  selectAreaComponent.value.addAreaMarker()
}

/**
 * 地点リスト情報生成
 * @param poleList - ポール一覧
 * @returns メニュー用ポール一覧データ
 */
const createPointList = async (poleList: PoleListStore[]): Promise<Work[]> => {
  let work: Work[] = []
  for await (let pole of poleList) {
    const areaList: AreaInfo[] = areaListStore.areaList
    const targetArea: AreaInfo | undefined = areaList.find((area: AreaInfo) => area.areaId === pole.areaId)
    if (targetArea !== void 0) {
      if (work.length === 0) {
        work.push({
          areaId: targetArea.areaId,
          name: targetArea.name,
          latitude: targetArea.latitude,
          longitude: targetArea.longitude,
          latlng: [targetArea.latitude, targetArea.longitude],
          notificationFlag: false,
          poleList: [pole],
          poleNumber: 1
        })
      } else {
        const target = work.find(val => val.areaId === pole.areaId)
        if (target === void 0) {
          work.push({
            areaId: targetArea.areaId,
            name: targetArea.name,
            latitude: targetArea.latitude,
            longitude: targetArea.longitude,
            latlng: [targetArea.latitude, targetArea.longitude],
            notificationFlag: false,
            poleList: [pole],
            poleNumber: 1
          })
        } else {
          target.poleList.push(pole)
          target.poleNumber++
        }
      }
    }
  }
  return work
}

/**
 * エリア選択時にページセレクトダイアログを表示
 * @param pole - ポール情報
 */
const pointSelected = (pole: PoleListStore) => {
  selectPoint(pole)
}

/**
 * 地点リスト選択時画面中央に表示
 * @param data - 地点リスト
 */
const selectPoint = (data: any) => {
  selectAreaComponent.value.setCenter(data)
}

/**
 * エラーダイアログを閉じる
 */
const onClickCloseErrorDialog = () => {
  errorDialog.value.isShow = false
}

/**
 * エリア情報設定
 * @param data - 地点リスト
 */
const enterPoint = (data: any) => {
  selectAreaComponent.value.enterPoint(data)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TitleHeader, {
      "title-info": titleinfo.value,
      onOnSelectPoint: selectPoint,
      onOnEnterPoint: enterPoint
    }, null, 8, ["title-info"]),
    _createVNode(SelectPoleMenu, {
      "area-pole-list": _unref(areaPoleList),
      onPoleSelected: pointSelected
    }, null, 8, ["area-pole-list"]),
    _createVNode(SelectArea, {
      ref_key: "selectAreaComponent",
      ref: selectAreaComponent
    }, null, 512),
    _createVNode(ErrorDialog, {
      "error-dialog": errorDialog.value,
      onOnClickCloseErrorDialog: onClickCloseErrorDialog
    }, null, 8, ["error-dialog"]),
    _withDirectives(_createVNode(Loading, null, null, 512), [
      [_vShow, isLoading.value]
    ])
  ], 64))
}
}

})