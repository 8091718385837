import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "near-miss-play-video" }
const _hoisted_2 = { class: "video-container" }
const _hoisted_3 = { class: "video-card__video-info" }
const _hoisted_4 = { class: "video-card__close-button-area" }

import { onMounted, onBeforeUnmount, computed, ref } from 'vue'

import * as videojs from 'video.js'
import 'video.js/dist/video-js.css'
import Player from 'video.js/dist/types/player'

// ==================================
// interface
// ==================================
interface Emits {
  (e: 'video-close'): void;
}

interface Source {
  src: string;
  type: string;
}
interface Props {
  options: {
    autoplay: boolean;
    controls: boolean;
    sources: Source[];
  }
  nearMissesInfo: {
    id: string;
    area: string;
    vehicleId1Kind: string;
    vehicleId1KindDirection: string;
    vehicleId2Kind: string;
    vehicleId2KindDirection: string;
    nearMissTime: string;
    timeGap: string;
  };
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'NearMissPlayVideo',
  props: {
    options: {},
    nearMissesInfo: {}
  },
  emits: ["video-close"],
  setup(__props: any, { emit: __emit }) {

/**
 * NearMissPlayVideo.vue
 * ヒヤリハット映像再生用モーダル
 * 
 * 親コンポーネント
 * @/components/parts/accident/NearMissList.vue
 */
// ==================================
// import
// ==================================
const props = __props

const video = ref<Player | null>(null)

// ==================================
// computed
// ==================================
// 各詳細表示
const id = computed(() => {
  return props.nearMissesInfo.id
})

const nearMissTime = computed(() => {
  return props.nearMissesInfo.nearMissTime
})

const area = computed(() => {
  return props.nearMissesInfo.area
})

const vehicleId1Kind = computed(() => {
  return props.nearMissesInfo.vehicleId1Kind
})

const vehicleId1KindDirection = computed(() => {
  return props.nearMissesInfo.vehicleId1KindDirection
})

const vehicleId2Kind = computed(() => {
  return props.nearMissesInfo.vehicleId2Kind
})

const vehicleId2KindDirection = computed(() => {
  return props.nearMissesInfo.vehicleId2KindDirection
})

const timeGap = computed(() => {
  return props.nearMissesInfo.timeGap
})

// ==================================
// hook
// ==================================
onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  video.value = videojs.default('video-player', props.options, function onPlayerReady() {})
})

onBeforeUnmount(async () => {
  if (video.value) {
    video.value.dispose()
  }
})

// ==================================
// method
// ==================================
const emit = __emit
/**
 * ビデオ表示を閉じる
 */
const videoClose = () => {
  emit('video-close')
}

/**
 * ビデオ表示を閉じる
 */
const close = () => {
  if (video.value) {
    video.value.dispose()
  }
  videoClose()
}

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_item = _resolveComponent("v-card-item")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_card, { class: "video-card" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "video-card__header" }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" ヒヤリハット映像情報 ")
                ])),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", null, "ヒヤリハットID: " + _toDisplayString(id.value), 1),
                _createElementVNode("span", null, "発生時刻: " + _toDisplayString(nearMissTime.value), 1),
                _createElementVNode("span", null, "判定エリア: " + _toDisplayString(area.value), 1),
                _createElementVNode("span", null, "車両1 : " + _toDisplayString(vehicleId1Kind.value) + " (" + _toDisplayString(vehicleId1KindDirection.value) + ")", 1),
                _createElementVNode("span", null, "車両2 : " + _toDisplayString(vehicleId2Kind.value) + " (" + _toDisplayString(vehicleId2KindDirection.value) + ")", 1),
                _createElementVNode("span", null, "TimeGap: " + _toDisplayString(timeGap.value), 1)
              ]),
              _cache[2] || (_cache[2] = _createElementVNode("div", null, [
                _createElementVNode("video", {
                  id: "video-player",
                  class: "video-js vjs-big-play-centered video-card__video-player"
                })
              ], -1)),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_v_btn, {
                  class: "video-card__close-button",
                  them: "dark",
                  color: "blue-grey",
                  onClick: close
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" 閉じる ")
                  ])),
                  _: 1
                })
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", {
      class: "close-overLay",
      onClick: close
    })
  ]))
}
}

})