import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "macro-analysis-info-data-download" }

import { onMounted, ref } from 'vue'

import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'

import { useSelectPoleStore, useMacroAnalysisExcelDataStore } from '@/store/app'

import { DIALOG_ERROR_INFO, S3BUCKETURL } from '@/mixins/commonFunction'
import { updateMacroAnalysisInfoDataForExcel } from '@/mixins/communicationFunction'

import { ErrorDialogInfo, TitleInfo } from '@/types/Interfaces'

import { MENU } from '@/setting/setting'

// ==================================
// interface
// ==================================
interface TableHeader {
  title: string;
  value: string;
  align?: 'start' | 'center' | 'end';
  sortable?: boolean;
  filterable?: boolean;
  groupable?: boolean;
  divider?: boolean;
  class?: string | string[];
  width?: string | number;
  filter?: (value: any, search: string, item: any) => boolean;
  sort?: (a: any, b: any) => number;
}

interface TableData {
  createDate: string;
  areaName: string;
  searchCondition: string;
  creationStatus: string;
  downloadDataPath: string;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'MacroAnalysisInfoDataDownload',
  setup(__props) {

/**
 * MacroAnalysisInfoDataDownload.vue
 * マクロ分析データダウンロード
 */
// ==================================
// import
// ==================================
const selectPoleStore = useSelectPoleStore()
const macroAnalysisExcelDataStore = useMacroAnalysisExcelDataStore()

const titleInfo = ref<TitleInfo>({
  title: 'マクロ分析ダウンロード',
  pointList: [],
  menuList: MENU.macroAnalysisInfoDataDownload,
  show: {
    realtime: true,
    multicamera: true,
    virtual: true,
    menu: true,
    point: true
  }
})

const poleId = ref<number>(0)

const isLoading = ref<boolean>(false)

const search = ref('')

const dialog = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false
})

const headers = ref<TableHeader[]>([
  { title: 'データ作成日時', align: 'start', sortable: true, value: 'createDate' },
  { title: 'エリア名', sortable: true, value: 'areaName' },
  { title: '地域', sortable: true, value: 'cityName' },
  { title: '条件', sortable: true, value: 'searchCondition' },
  { title: '作成ステータス', sortable: true, value: 'creationStatus' },
  { title: 'エクスポート', sortable: false, value: 'downloadDataPath' }
])

const datas = ref<TableData[]>([])

// ==================================
// hook
// ==================================
onMounted(() => {
  initialize()
})

// ==================================
// method
// ==================================
/**
 * 画面描画後の処理
 */
const initialize = () => {
  poleId.value = selectPoleStore.$state.poleId
  setData()
}

/**
 * APIからExcel出力済みのデータ一覧を取得
 */
const setData = () => {
  isLoading.value = true
  const promise = updateMacroAnalysisInfoDataForExcel(poleId.value)
  promise
    .then(() => {
      datas.value = macroAnalysisExcelDataStore.$state.data
      isLoading.value = false
    })
    .catch(() => {
      // 取得に失敗したらエラーメッセージを表示
      isLoading.value = false
      dialog.value.title = DIALOG_ERROR_INFO.title.getError
      dialog.value.message = DIALOG_ERROR_INFO.message.getErrorMacroAnalysisInfoDataForExcel
      dialog.value.isShow = true
    })
}

/**
 * Excelデータのダウンロード
 * @param item - Excel出力済みデータ情報
 */
const exportData = (item: TableData): void  => {
  const url = S3BUCKETURL.spitsDownloadBucketUrl + item.downloadDataPath
  // データに含まれるURLからExcelデータをダウンロードできるよう、アンカー要素を作って
  // 自動クリックを行ってダウンロードを実行する
  const anchorElement = document.createElement('a')
  document.body.appendChild(anchorElement)
  anchorElement.href = url
  anchorElement.click()
  anchorElement.remove()
}

return (_ctx: any,_cache: any) => {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TitleHeader, { "title-info": titleInfo.value }, null, 8, ["title-info"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_text_field, {
        modelValue: search.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
        "append-icon": "mdi-magnify",
        label: "検索",
        "single-line": "",
        "hide-details": "",
        class: "search-field"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_v_data_table, {
        headers: headers.value,
        items: datas.value,
        "items-per-page": 5,
        "footer-props": { 'items-per-page-text': '行/ページ:' },
        search: search.value,
        class: "elevation-1"
      }, {
        [`item.downloadDataPath`]: _withCtx(({ item }) => [
          (item.downloadDataPath !== null)
            ? (_openBlock(), _createBlock(_component_v_icon, {
                key: 0,
                large: "",
                class: "mr-2",
                color: "green",
                onClick: ($event: any) => (exportData(item))
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" mdi-file-excel ")
                ])),
                _: 2
              }, 1032, ["onClick"]))
            : (_openBlock(), _createBlock(_component_v_icon, {
                key: 1,
                large: "",
                class: "mr-2"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" mdi-file-excel ")
                ])),
                _: 1
              }))
        ]),
        _: 2
      }, 1032, ["headers", "items", "search"])
    ]),
    _createVNode(ErrorDialog, {
      "error-dialog": dialog.value,
      onOnClickCloseErrorDialog: _cache[1] || (_cache[1] = ($event: any) => (dialog.value.isShow = false))
    }, null, 8, ["error-dialog"]),
    _withDirectives(_createVNode(Loading, null, null, 512), [
      [_vShow, isLoading.value]
    ])
  ], 64))
}
}

})