import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "macro-analysis-detail-info-table__container" }
const _hoisted_2 = { class: "macro-analysis-detail-info-table__close-button-container" }

import { onBeforeMount, ref } from 'vue'

import MacroAnalysisHistgram from '@/components/parts/macroAnalysis/MacroAnalysisHistgram.vue'
import ModalMacroAnalysisHistgram from '@/components/parts/macroAnalysis/ModalMacroAnalysisHistgram.vue'

import { useMacroAnalysisInfoStore } from '@/store/app'

// ==================================
// interface
// ==================================
interface Emits {
  (e: 'close-section-detail'): void;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'MacroAnalysisDetailInfoTable',
  emits: ["close-section-detail"],
  setup(__props, { emit: __emit }) {

/**
 * MacroAnalysisDetailInfoTable.vue
 * マクロ分析詳細画面
 * 
 * 親コンポーネント
 * @/components/parts/macroAnalysis/MacroAnalysisTable.vue
 */
// ==================================
// import
// ==================================
const macroAnalysisInfoStore = useMacroAnalysisInfoStore()

const showDialog = ref<boolean>(true)

const isDisplay = ref<boolean>(false)

const prefix = ref({
  component: null,
  props: {}
})

// 分析詳細データ
const data = ref({
  kind: undefined,
  columns: [],
  termA: {
    number: [],
    avgSpeed: [],
    maxSpeed: [],
    mode: [],
    std: [],
    avgHist: [],
    maxHist: []
  },
  termB: {
    number: [],
    avgSpeed: [],
    maxSpeed: [],
    mode: [],
    std: [],
    avgHist: [],
    maxHist: []
  },
  diff: {
    number: [],
    avgSpeed: [],
    tTest: [],
    effectSize: [],
    avgHist: []
  }
})

const isViewGraph = ref<boolean>(false)

// グラフ表示オプション
const avgSpeedGraphSetting = ref({
  xAxes: {
    scaleLabelDisplay: true,
    labelString: '平均速度'
  },
  yAxes: {
    scaleLabelDisplay: true,
    labelString: '台数'
  },
  termA: {
    backgroundColor: 'rgba(65, 158, 143, 0.8)'
  },
  termB: {
    backgroundColor: 'rgba(160, 158, 34, 0.8)'
  }
})
const avgSpeedHistGraphSetting = ref({
  xAxes: {
    scaleLabelDisplay: true,
    labelString: '平均速度'
  },
  yAxes: {
    scaleLabelDisplay: true,
    labelString: '差分'
  },
  title: {
    display: true,
    text: '期間Aから期間Bを引いた差分値'
  },
  backgroundColor: 'rgba(236, 97, 4, 0.8)'
})
const maxSpeedGraphSetting = ref({
  xAxes: {
    scaleLabelDisplay: true,
    labelString: '最高速度'
  },
  yAxes: {
    scaleLabelDisplay: true,
    labelString: '台数'
  },
  termA: {
    backgroundColor: 'rgba(65, 158, 143, 0.8)'
  },
  termB: {
    backgroundColor: 'rgba(160, 158, 34, 0.8)'
  }
})
const sectionMaxBorder = ref({
  termA: {
    avg: {
      suggestedMax: 1
    },
    max: {
      suggestedMax: 1
    }
  },
  termB: {
    avg: {
      suggestedMax: 1
    },
    max: {
      suggestedMax: 1
    }
  },
  diff: {
    avg: {
      suggestedMax: 1,
      suggestedMin: 0,
      maxTicksLimit: 2
    }
  }
})

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  initialize()
})

// ==================================
// method
// ==================================
const emit = __emit

/**
 * 初期処理
 */
const initialize = () => {
  // 表示データからグラフ上の最大値を設定する
  data.value = macroAnalysisInfoStore.$state.macroAnalysisDetaiInfo
  sectionMaxBorder.value.termA.avg.suggestedMax = getBorderMaxData(data.value.termA.avgHist)
  sectionMaxBorder.value.termA.max.suggestedMax = getBorderMaxData(data.value.termA.maxHist)
  sectionMaxBorder.value.termB.avg.suggestedMax = getBorderMaxData(data.value.termB.avgHist)
  sectionMaxBorder.value.termB.max.suggestedMax = getBorderMaxData(data.value.termB.maxHist)
  sectionMaxBorder.value.diff.avg = getDiffBorderData(data.value.diff.avgHist)
  isViewGraph.value = true
}

/**
 * グラフで表示する上での最大値を取得する
 * @param history - 速度一覧
 * @returns 最大値
 */
const getBorderMaxData = (history: any): number => {
  let mainArray: any[] = []
  for (const item of history) {
    let subArray: any[] = Object.values(item)
    mainArray = mainArray.concat(subArray)
  }
  let maxVal = Math.max(...mainArray)
  if (maxVal === 0) {
    maxVal = 1
  }
  return maxVal
}

/**
 * 差分データを表示する際の最大、最小値を取得する
 * @param history - 速度一覧
 * @returns 最大、最小値
 */
const getDiffBorderData = (history: any): any => {
  let mainArray: any[] = []
  for (const item of history) {
    let subArray: any[] = Object.values(item)
    mainArray = mainArray.concat(subArray)
  }
  let maxVal = Math.max(...mainArray)
  let minVal = Math.min(...mainArray)

  let suggestedMaxVal = 1
  let suggestedMinVal = 0
  let maxTicksLimit = 2

  if (maxVal >= 1 && minVal >= 0) {
    suggestedMaxVal = maxVal
    suggestedMinVal = 0
  } else if (maxVal <= 0 && minVal < 0) {
    suggestedMaxVal = 0
    suggestedMinVal = minVal
  } else if (maxVal === 0 && minVal === 0) {
    suggestedMaxVal = 1
    suggestedMinVal = 0
    maxTicksLimit = 2
  } else {
    suggestedMaxVal = maxVal
    suggestedMinVal = minVal
    maxTicksLimit = 3    
  }

  return {
    suggestedMax: suggestedMaxVal,
    suggestMin: suggestedMinVal,
    maxTicksLimit: maxTicksLimit
  }
}

/**
 * 親コンポーネントからの指示でモーダルダイアログを表示する
 * @param component - グラフコンポーネント
 * @param props - グラフ表示プロパティ
 */
const showModal = (component: any, props: any) => {
  prefix.value.component = component
  prefix.value.props = props
  isDisplay.value = true
}

/**
 * 分析詳細画面を閉じる
 */
const close = () => {
  emit('close-section-detail')
  showDialog.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: showDialog.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((showDialog).value = $event)),
    class: "macro-analysis-detail-info-table",
    fullscreen: "",
    transition: "dialog-bottom-transition"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_card, {
          density: "compact",
          class: "macro-analysis-detail-info-table__card"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_table, { class: "macro-analysis-detail-info-table__table" }, {
                  default: _withCtx(() => [
                    _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _cache[2] || (_cache[2] = _createElementVNode("th", { class: "macro-analysis-detail-info-table__column1--title" }, " 期間 ", -1)),
                        _cache[3] || (_cache[3] = _createElementVNode("th", { class: "macro-analysis-detail-info-table__column2--title" }, null, -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.columns, (header, index) => {
                          return (_openBlock(), _createElementBlock("th", {
                            key: index,
                            class: "macro-analysis-detail-info-table__column1"
                          }, _toDisplayString(header), 1))
                        }), 128))
                      ])
                    ]),
                    _createElementVNode("tbody", null, [
                      _createElementVNode("tr", null, [
                        _cache[4] || (_cache[4] = _createElementVNode("td", {
                          rowspan: "7",
                          class: "macro-analysis-detail-info-table__column1--period-a"
                        }, " 期間A ", -1)),
                        _cache[5] || (_cache[5] = _createElementVNode("th", { class: "macro-analysis-detail-info-table__column2--period-a" }, " 平均速度グラフ ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.termA.avgHist, (avgSpeedHistA, index) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: index,
                            class: "macro-analysis-detail-info-table__period-a"
                          }, [
                            _createVNode(_component_v_card, { class: "macro-analysis-detail-info-table__graph" }, {
                              default: _withCtx(() => [
                                (isViewGraph.value)
                                  ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                                      key: 0,
                                      "x-axes": avgSpeedGraphSetting.value.xAxes,
                                      "y-axes": avgSpeedGraphSetting.value.yAxes,
                                      "background-color": avgSpeedGraphSetting.value.termA.backgroundColor,
                                      data: Object.values(avgSpeedHistA),
                                      max: sectionMaxBorder.value.termA.avg.suggestedMax,
                                      "is-suggested": true,
                                      onShowModal: showModal
                                    }, null, 8, ["x-axes", "y-axes", "background-color", "data", "max"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024)
                          ]))
                        }), 128))
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[6] || (_cache[6] = _createElementVNode("th", { class: "macro-analysis-detail-info-table__column2--period-a" }, " 最高速度グラフ ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.termA.maxHist, (maxSpeedHistA, index) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: index,
                            class: "macro-analysis-detail-info-table__period-a"
                          }, [
                            _createVNode(_component_v_card, { class: "macro-analysis-detail-info-table__graph" }, {
                              default: _withCtx(() => [
                                (isViewGraph.value)
                                  ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                                      key: 0,
                                      "x-axes": maxSpeedGraphSetting.value.xAxes,
                                      "y-axes": maxSpeedGraphSetting.value.yAxes,
                                      "background-color": maxSpeedGraphSetting.value.termA.backgroundColor,
                                      data: Object.values(maxSpeedHistA),
                                      "is-suggested": true,
                                      max: sectionMaxBorder.value.termA.max.suggestedMax,
                                      onShowModal: showModal
                                    }, null, 8, ["x-axes", "y-axes", "background-color", "data", "max"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024)
                          ]))
                        }), 128))
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[7] || (_cache[7] = _createElementVNode("th", { class: "macro-analysis-detail-info-table__column2--period-a" }, " 台数 ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.termA.number, (termANumber, index) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: index,
                            class: "macro-analysis-detail-info-table__period-a"
                          }, _toDisplayString(termANumber), 1))
                        }), 128))
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[8] || (_cache[8] = _createElementVNode("th", { class: "macro-analysis-detail-info-table__column2--period-a" }, " 平均速度 ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.termA.avgSpeed, (termAAvgSpeed, index) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: index,
                            class: "macro-analysis-detail-info-table__period-a"
                          }, _toDisplayString(termAAvgSpeed), 1))
                        }), 128))
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[9] || (_cache[9] = _createElementVNode("th", { class: "macro-analysis-detail-info-table__column2--period-a" }, " 最高速度 ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.termA.maxSpeed, (termAMaxSpeed, index) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: index,
                            class: "macro-analysis-detail-info-table__period-a"
                          }, _toDisplayString(termAMaxSpeed), 1))
                        }), 128))
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[10] || (_cache[10] = _createElementVNode("th", { class: "macro-analysis-detail-info-table__column2--period-a" }, " 最頻値 ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.termA.mode, (termAMode, index) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: index,
                            class: "macro-analysis-detail-info-table__period-a"
                          }, _toDisplayString(termAMode), 1))
                        }), 128))
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[11] || (_cache[11] = _createElementVNode("th", { class: "macro-analysis-detail-info-table__column2--period-a" }, " 標準偏差 ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.termA.std, (termAStd, index) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: index,
                            class: "macro-analysis-detail-info-table__period-a"
                          }, _toDisplayString(termAStd), 1))
                        }), 128))
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[12] || (_cache[12] = _createElementVNode("th", {
                          rowspan: "7",
                          class: "macro-analysis-detail-info-table__column1--period-b"
                        }, " 期間B ", -1)),
                        _cache[13] || (_cache[13] = _createElementVNode("th", { class: "macro-analysis-detail-info-table__column2--period-b" }, " 平均速度グラフ ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.termB.avgHist, (avgSpeedHistB, index) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: index,
                            class: "macro-analysis-detail-info-table__period-b"
                          }, [
                            _createVNode(_component_v_card, { class: "macro-analysis-detail-info-table__graph" }, {
                              default: _withCtx(() => [
                                (isViewGraph.value)
                                  ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                                      key: 0,
                                      "x-axes": avgSpeedGraphSetting.value.xAxes,
                                      "y-axes": avgSpeedGraphSetting.value.yAxes,
                                      "background-color": avgSpeedGraphSetting.value.termB.backgroundColor,
                                      data: Object.values(avgSpeedHistB),
                                      "is-suggested": true,
                                      max: sectionMaxBorder.value.termB.avg.suggestedMax,
                                      onShowModal: showModal
                                    }, null, 8, ["x-axes", "y-axes", "background-color", "data", "max"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024)
                          ]))
                        }), 128))
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[14] || (_cache[14] = _createElementVNode("th", { class: "macro-analysis-detail-info-table__column2--period-b" }, " 最高速度グラフ ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.termB.maxHist, (maxSpeedHistB, index) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: index,
                            class: "macro-analysis-detail-info-table__period-b"
                          }, [
                            _createVNode(_component_v_card, { class: "macro-analysis-detail-info-table__graph" }, {
                              default: _withCtx(() => [
                                (isViewGraph.value)
                                  ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                                      key: 0,
                                      "x-axes": maxSpeedGraphSetting.value.xAxes,
                                      "y-axes": maxSpeedGraphSetting.value.yAxes,
                                      "background-color": maxSpeedGraphSetting.value.termB.backgroundColor,
                                      data: Object.values(maxSpeedHistB),
                                      "is-suggested": true,
                                      max: sectionMaxBorder.value.termB.max.suggestedMax,
                                      onShowModal: showModal
                                    }, null, 8, ["x-axes", "y-axes", "background-color", "data", "max"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024)
                          ]))
                        }), 128))
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[15] || (_cache[15] = _createElementVNode("th", { class: "macro-analysis-detail-info-table__column2--period-b" }, " 台数 ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.termB.number, (termBNumber, index) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: index,
                            class: "macro-analysis-detail-info-table__period-b"
                          }, _toDisplayString(termBNumber), 1))
                        }), 128))
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[16] || (_cache[16] = _createElementVNode("th", { class: "macro-analysis-detail-info-table__column2--period-b" }, " 平均速度 ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.termB.avgSpeed, (termBAvgSpeed, index) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: index,
                            class: "macro-analysis-detail-info-table__period-b"
                          }, _toDisplayString(termBAvgSpeed), 1))
                        }), 128))
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[17] || (_cache[17] = _createElementVNode("th", { class: "macro-analysis-detail-info-table__column2--period-b" }, " 最高速度 ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.termB.maxSpeed, (termBMaxSpeed, index) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: index,
                            class: "macro-analysis-detail-info-table__period-b"
                          }, _toDisplayString(termBMaxSpeed), 1))
                        }), 128))
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[18] || (_cache[18] = _createElementVNode("th", { class: "macro-analysis-detail-info-table__column2--period-b" }, " 最頻値 ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.termB.mode, (termBMode, index) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: index,
                            class: "macro-analysis-detail-info-table__period-b"
                          }, _toDisplayString(termBMode), 1))
                        }), 128))
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[19] || (_cache[19] = _createElementVNode("th", { class: "macro-analysis-detail-info-table__column2--period-b" }, " 標準偏差 ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.termB.std, (termBStd, index) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: index,
                            class: "macro-analysis-detail-info-table__period-b"
                          }, _toDisplayString(termBStd), 1))
                        }), 128))
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[20] || (_cache[20] = _createElementVNode("th", {
                          rowspan: "5",
                          class: "macro-analysis-detail-info-table__column1--diff"
                        }, " 差分 ", -1)),
                        _cache[21] || (_cache[21] = _createElementVNode("th", { class: "macro-analysis-detail-info-table__column2--diff" }, " 平均速度グラフ ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.diff.avgHist, (diffAvgSpeedHist, index) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: index,
                            class: "macro-analysis-detail-info-table__diff"
                          }, [
                            _createVNode(_component_v_card, { class: "macro-analysis-detail-info-table__graph" }, {
                              default: _withCtx(() => [
                                (isViewGraph.value)
                                  ? (_openBlock(), _createBlock(MacroAnalysisHistgram, {
                                      key: 0,
                                      "x-axes": avgSpeedHistGraphSetting.value.xAxes,
                                      "y-axes": avgSpeedHistGraphSetting.value.yAxes,
                                      "background-color": avgSpeedHistGraphSetting.value.backgroundColor,
                                      data: Object.values(diffAvgSpeedHist),
                                      "is-suggested": true,
                                      max: sectionMaxBorder.value.diff.avg.suggestedMax,
                                      min: sectionMaxBorder.value.diff.avg.suggestedMin,
                                      "max-ticks-limit": sectionMaxBorder.value.diff.avg.maxTicksLimit,
                                      onShowModal: showModal
                                    }, null, 8, ["x-axes", "y-axes", "background-color", "data", "max", "min", "max-ticks-limit"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024)
                          ]))
                        }), 128))
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[22] || (_cache[22] = _createElementVNode("th", { class: "macro-analysis-detail-info-table__column2--diff" }, " 台数 ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.diff.number, (diffNumber, index) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: index,
                            class: "macro-analysis-detail-info-table__diff"
                          }, _toDisplayString(diffNumber), 1))
                        }), 128))
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[23] || (_cache[23] = _createElementVNode("th", { class: "macro-analysis-detail-info-table__column2--diff" }, " 平均速度 ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.diff.avgSpeed, (diffAvgSpeed, index) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: index,
                            class: "macro-analysis-detail-info-table__diff"
                          }, _toDisplayString(diffAvgSpeed), 1))
                        }), 128))
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[24] || (_cache[24] = _createElementVNode("th", { class: "macro-analysis-detail-info-table__column2--diff" }, " W-t検定 ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.diff.tTest, (diffTTest, index) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: index,
                            class: "macro-analysis-detail-info-table__diff"
                          }, _toDisplayString(diffTTest), 1))
                        }), 128))
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[25] || (_cache[25] = _createElementVNode("th", { class: "macro-analysis-detail-info-table__column2--diff" }, " 効果量 ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.diff.effectSize, (diffEffectSize, index) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: index,
                            class: "macro-analysis-detail-info-table__diff"
                          }, _toDisplayString(diffEffectSize), 1))
                        }), 128))
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_v_btn, {
                    color: "blue",
                    class: "macro-analysis-detail-info-table__close-button",
                    block: "",
                    onClick: close
                  }, {
                    default: _withCtx(() => _cache[26] || (_cache[26] = [
                      _createTextVNode(" 閉じる ")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(ModalMacroAnalysisHistgram, {
          value: isDisplay.value,
          text: "errorText",
          prefix: prefix.value,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (isDisplay.value = $event))
        }, null, 8, ["value", "prefix"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})