import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pole-list-mini" }
const _hoisted_2 = ["onClick"]

import { onBeforeMount } from 'vue'

import { usePoleListStore } from '@/store/app'

import { PoleListStore } from '@/types/Interfaces'

// ==================================
// interface
// ==================================
interface Emits {
  (e: 'update-pole-data', poleId: number): void;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'PoleListMini',
  emits: ["update-pole-data"],
  setup(__props, { emit: __emit }) {

/**
 * PoleListMini.vue
 * 集計画面用地図
 * 
 * 親コンポーネント
 * @/views/Aggregation.vue
 */
// ==================================
// import
// ==================================
const poleListStore = usePoleListStore()

const state = {
  poleListHeader: [
    { text: 'ポールID', value: 'poleId' },
    { text: 'ポール名', value: 'poleName' }    
  ] as {
    text: string,
    value: string
  }[],
  poleList: [] as PoleListStore[]
}

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  state.poleList = poleListStore.$state.poleList
})

// ==================================
// method
// ==================================
const emit = __emit

/**
 * 対象ポールが変更されたら親コンポーネントにポールIDを伝える
 * @param poleId - ポールID
 */
const setPoleData = (poleId: number) => {
  emit('update-pole-data', poleId)
}

return (_ctx: any,_cache: any) => {
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_data_table, {
      theme: "light",
      density: "compact",
      "hide-default-footer": "",
      headers: state.poleListHeader,
      items: state.poleList,
      "item-key": "poleId"
    }, {
      "item.poleId": _withCtx(({ item }) => [
        _createElementVNode("a", {
          onClick: ($event: any) => (setPoleData(item.poleId))
        }, _toDisplayString("0x" + item.poleId.toString(16)), 9, _hoisted_2)
      ]),
      _: 1
    }, 8, ["headers", "items"])
  ]))
}
}

})