import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "macro-analysis-histgram" }
const _hoisted_2 = { class: "macro-analysis-histgram__chart" }

import { computed } from 'vue'

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { Bar } from 'vue-chartjs'
// グラフ表示部品を登録
interface Props {
  xAxes: any;
  yAxes: any;
  legend?: any;
  title?: {
    display: boolean;
    text: string;
  },
  backgroundColor: string;
  labelName?: string;
  data: any;
  isSuggested: boolean;
  max: number;
  min?: number;
  maxTicksLimit?: number;
}

interface Emits {
  (e: 'showModal', component: string, props: any): void;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'MacroAnalysisHistgram',
  props: {
    xAxes: {},
    yAxes: {},
    legend: {},
    title: {},
    backgroundColor: {},
    labelName: {},
    data: {},
    isSuggested: { type: Boolean },
    max: {},
    min: {},
    maxTicksLimit: {}
  },
  emits: ["showModal"],
  setup(__props: any, { emit: __emit }) {

/**
 * MacroAnalysisHistgram.vue
 * マクロ分析棒グラフ
 * 
 * 親コンポーネント
 * @/components/parts/macroAnalysis/MacroAnalysisDetailInfoTable.vue
 * @/components/parts/macroAnalysis/MacroAnalysisTable.vue
 */
// ==================================
// import
// ==================================
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

// ==================================
// interface
// ==================================
const props = __props

// ==================================
// computed
// ==================================
/**
 * グラフデータの設定
 */
const chartData = computed(() => {
  return {
    labels: ['0-10km/h', '10-20km/h', '20-30km/h', '30-40km/h', '40-50km/h', '50-60km/h'],
    datasets: [
      {
        label: props.labelName !== void 0 ? props.labelName : '',
        backgroundColor: props.backgroundColor,
        data: props.data
      }
    ]
  }
})

/**
 * グラフ表示オプションの設定
 */
const options = computed(() => {
  return {
    title: {
      display: props.title !== void 0 ? props.title.display : '',
      text: props.title !== void 0 ? props.title.text : ''
    },
    plugins: {
      legend: {
        display: false
      }    
    },
    scales: {
      x: {
        title: {
          display: props.xAxes.scaleLabelDisplay,
          text: props.xAxes.labelString
        },
        ticks: {
          maxTicksLimit: 6
        }
      },
      y: {
        position: 'left',
        beginAtZero: true,
        min: props.min,
        max: props.max,
        title: {
          display: props.yAxes.scaleLabelDisplay,
          text: props.yAxes.labelString
        },
        ticks: {
          maxTicksLimit: props.maxTicksLimit
        }
      }
    },
    onClick: showModal
  }
})

// ==================================
// method
// ==================================
const emit = __emit

/**
 * グラフをクリックしたときにモーダル表示するよう通知
 */
const showModal = () => {
  emit('showModal', 'MacroAnalysisHistgram', props)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(Bar), {
        data: chartData.value,
        options: options.value
      }, null, 8, ["data", "options"])
    ])
  ]))
}
}

})