import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "macro-analysis" }
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = { class: "d-flex" }

import { ref, onBeforeMount, watch } from 'vue'

import Button from '@/components/parts/common/Button.vue'
import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'
import MacroAnalysisTable from '@/components/parts/macroAnalysis/MacroAnalysisTable.vue'

import { useSelectPoleStore, useMacroAnalysisInfoStore, useMacroAnalysisSearchDataStore } from '@/store/app'

import { addressByCoordinates, createMacroAnalysisInfoDataForExcel } from '@/mixins/communicationFunction'
import { setPoleName } from '@/mixins/mapFunction'

import { ErrorDialogInfo, TitleInfo } from '@/types/Interfaces'

import { MENU } from '@/setting/setting'

import { DateTime } from 'luxon'

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'MacroAnalysis',
  setup(__props) {

/**
 * MAcroAnalysis.vue
 * マクロ分析画面
 */
// ==================================
// import
// ==================================
const selectPoleStore = useSelectPoleStore()
const macroAnalysisInfoStore = useMacroAnalysisInfoStore()
const searchDataStore = useMacroAnalysisSearchDataStore()

const MacroAnalysisTableRef = ref()

const titleInfo = ref<TitleInfo>({
  title: 'マクロ分析',
  pointList: [],
  menuList: MENU.macroAnalysis,
  show: {
    realtime: true,
    multicamera: true,
    virtual: true,
    menu: true,
    point: false
  }
})

const dialog = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false
})

const isLoading = ref<boolean>(false)

const aggregationPeriod = ref({
  start: '' as string,
  end: '' as string
})
const comparisonPeriod = ref({
  start: '' as string,
  end: '' as string
})

const directionItems = ref(['1', '2', '3', '4', '5'])
const directionValue = ref('1')

const ledItems = ref(['ON', 'OFF', 'EITHER'])
const ledValue = ref('ON')

const aggregationTargetItems = ref(['自動四輪車', '自動二輪車', '自転車', '歩行者'])
const aggregationTargetValue = ref('自転車')

const aggregationMethodItems = ref(['1hour', '3hours', '6hours', '12hours', '1day', '3days', '1week', '2weeks', '1month'])
const aggregationMethodValue = ref('1hour')

// 送信用オブジェクト
const sendData = ref({
  poleId: selectPoleStore.$state.poleId as number,
  address: '' as string,
  aggregationPeriod: {
    start: '' as string,
    end: '' as string
  },
  comparisonPeriod: {
    start: '' as string,
    end: '' as string
  },
  directionValue: '' as string,
  ledValue: '' as string,
  aggregationTargetValue: '' as string,
  aggregationMethodValue: '' as string
})
const datePickerEnabled = ref({
  aggStart: false,
  aggEnd: false,
  compStart: false,
  compEnd: false
})
const pickedDate = ref({
  aggStart: new Date(),
  aggEnd: new Date(),
  compStart: new Date(),
  compEnd: new Date()
})

// ==================================
// watch
// ==================================
watch(() => aggregationPeriod.value.start, (newVal, oldVal) => {
  if (oldVal !== newVal) {
    searchDataStore.setAggregationPeriodStart(newVal)
  }
})

watch(() => aggregationPeriod.value.end, (newVal, oldVal) => {
  if (oldVal !== newVal) {
    searchDataStore.setAggregationPeriodEnd(newVal)
  }
})

watch(() => comparisonPeriod.value.start, (newVal, oldVal) => {
  if (oldVal !== newVal) {
    searchDataStore.setComparisonPeriodStart(newVal)
  }
})

watch(() => comparisonPeriod.value.end, (newVal, oldVal) => {
  if (oldVal !== newVal) {
    searchDataStore.setComparisonPeriodEnd(newVal)
  }
})

watch(() => directionValue.value, (newVal, oldVal) => {
  if (oldVal !== newVal) {
    searchDataStore.setDirectionValue(newVal)
  }
})

watch(() => ledValue.value, (newVal, oldVal) => {
  if (oldVal !== newVal) {
    searchDataStore.setLedValue(newVal)
  }
})

watch(() => aggregationTargetValue.value, (newVal, oldVal) => {
  if (oldVal !== newVal) {
    searchDataStore.setAggregationTargetValue(newVal)
  }
})

watch(() => aggregationMethodValue.value, (newVal, oldVal) => {
  if (oldVal !== newVal) {
    searchDataStore.setAggregationMethodValue(newVal)
  }
})

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  initalize()
})

// ==================================
// method
// ==================================
/**
 * 初期処理(Piniaストアから絞り込み項目を取得する)
 */
const initalize = () => {
  const today = DateTime.now()
  const todayString = getDate(today)
  const yesterday = DateTime.now().minus({ days: 1 })
  const yesterdayString = getDate(yesterday)
  // Piniaストアの値が初期値でなければ、ストアの値を設定する
  // 初期値の場合は前日から当日の間に設定する
  if (searchDataStore.$state.aggregationPeriod.start !== '') {
    aggregationPeriod.value.start = searchDataStore.$state.aggregationPeriod.start
  } else {
    aggregationPeriod.value.start = yesterdayString[0]
    searchDataStore.setAggregationPeriodStart(aggregationPeriod.value.start)
    pickedDate.value.aggStart = new Date(yesterday.year, yesterday.month - 1, yesterday.day)
  }

  if (searchDataStore.$state.aggregationPeriod.end !== '') {
    aggregationPeriod.value.end = searchDataStore.$state.aggregationPeriod.end
  } else {
    aggregationPeriod.value.end = todayString[0]
    searchDataStore.setAggregationPeriodEnd(aggregationPeriod.value.end)
  }

  if (searchDataStore.$state.comparisonPeriod.start !== '') {
    comparisonPeriod.value.start = searchDataStore.$state.comparisonPeriod.start
  } else {
    comparisonPeriod.value.start = yesterdayString[0]
    searchDataStore.setComparisonPeriodStart(comparisonPeriod.value.start)
  }

  if (searchDataStore.$state.comparisonPeriod.end !== '') {
    comparisonPeriod.value.end = searchDataStore.$state.comparisonPeriod.end
  } else {
    comparisonPeriod.value.end = todayString[0]
    searchDataStore.setComparisonPeriodEnd(comparisonPeriod.value.end)
  }

  if (searchDataStore.$state.directionValue !== '') {
    directionValue.value = searchDataStore.$state.directionValue
  } else {
    directionValue.value = directionItems.value[0]
  }
  
  if (searchDataStore.$state.ledValue !== '') {
    ledValue.value = searchDataStore.$state.ledValue
  } else {
    ledValue.value = ledItems.value[0]
  }
  
  if (searchDataStore.$state.aggregationTargetValue !== '') {
    aggregationTargetValue.value = searchDataStore.$state.aggregationTargetValue
  } else {
    aggregationTargetValue.value = aggregationTargetItems.value[0]
  }

  if (searchDataStore.$state.aggregationMethodValue !== '') {
    aggregationMethodValue.value = searchDataStore.$state.aggregationMethodValue
  } else {
    aggregationMethodValue.value = aggregationMethodItems.value[0]
  }
  getAddress()
}

/**
 * パラメータとして取得した日時から、日付度時間の文字列を出力する
 * @param date - 日時オブジェクト
 * @returns 日付、時刻の文字列
 */
const getDate = (date: DateTime): [string, string] => {
  const dateString = date.toFormat('yyyy/MM/dd')
  const timeString = date.toFormat('HH:mm:ss')
  return [dateString, timeString]
}

/**
 * ポールの住所、名称を設定する
 */
const getAddress = async() => {
  isLoading.value = true
  const latlng = selectPoleStore.$state.latlng
  const getAddressFunc = addressByCoordinates(latlng.lat, latlng.lng)
  getAddressFunc
    .then((res: any) => {
      if (res.data !== void 0 && res.data !== null) {
        const poleName = setPoleName(res.data.address)
        sendData.value.address = poleName.address
        isLoading.value = false
      }
    })
    .catch(() => {
      sendData.value.address = ''
      isLoading.value = false
    })
}

/**
 * APIへ送るパラメータを設定する
 */
const setSendData = () => {
  // Piniaストアの絞り込み項目を更新する
  const targetSearchData = {
    aggregationPeriod: aggregationPeriod.value,
    comparisonPeriod: comparisonPeriod.value,
    directionValue: directionValue.value,
    ledValue: ledValue.value,
    aggregationTargetValue: aggregationTargetValue.value,
    aggregationMethodValue: aggregationMethodValue.value
  }
  const result = searchDataStore.setMacroAnalysisSearchData(targetSearchData)
  if (!result) {
    dialog.value.title = '絞り込み項目不正'
    dialog.value.message = '絞り込み項目の集計期間、比較期間が正しいかを確認してください。'
    dialog.value.isShow = true
    return
  }
  sendData.value.aggregationPeriod = aggregationPeriod.value
  sendData.value.comparisonPeriod = comparisonPeriod.value
  sendData.value.directionValue = directionValue.value
  sendData.value.ledValue = ledValue.value
  sendData.value.aggregationMethodValue = aggregationMethodValue.value
  sendData.value.aggregationTargetValue = aggregationTargetValue.value
  getData(sendData.value)
}

/**
 * Excelシート生成
 */
const createDataExcel = () => {
  if (sendData.value.aggregationPeriod.start === void 0) {
    dialog.value.title = ''
    dialog.value.message = 'データ読込が行われていません。\nデータ読込を行ってください'
    dialog.value.isShow = true
    return
  }
  if (macroAnalysisInfoStore.$state.macroAnalysisInfo.enter.number.length === 0) {
    dialog.value.title = ''
    dialog.value.message = '現在のパラメータ設定ではデータが存在していません \r\n再度パラメータを設定後 \r\nデータ読込を行ってください'
    dialog.value.isShow = true
    return
  }
  isLoading.value = true
  let promise = createMacroAnalysisInfoDataForExcel(
    sendData.value.address,
    sendData.value.poleId,
    sendData.value.aggregationPeriod.start + ' 00:00:00',
    sendData.value.aggregationPeriod.end + ' 00:00:00',
    sendData.value.comparisonPeriod.start + ' 00:00:00',
    sendData.value.comparisonPeriod.end + ' 00:00:00',
    sendData.value.directionValue,
    sendData.value.ledValue,
    sendData.value.aggregationTargetValue,
    sendData.value.aggregationMethodValue
  )
  promise
    .then(() => {
      isLoading.value = false
      dialog.value.title = ''
      dialog.value.message = '作成依頼成功\r\nマクロ分析データダウンロード画面から\r\nファイルをダウンロードしてください\r\nファイル作成には時間がかかります。'
      dialog.value.isShow = true
    })
    .catch(() => {
      isLoading.value = false
      dialog.value.title = ''
      dialog.value.message = '作成依頼失敗'
      dialog.value.isShow = true
    })
}

/**
 * デートピッカーで指定した日付をテキストフィールドに設定する
 * @param category - 日付分類
 * @param key - 開始終了区分
 * @param date - 指定日付
 */
const setSearchDate = (category: string, key: string, date: Date) => {
  if (date !== void 0) {
    const distDate = DateTime.fromJSDate(date).toFormat('yyyy/MM/dd')
    if (category === 'aggregationPeriod') {
      if (key === 'start') {
        aggregationPeriod.value.start = distDate
        searchDataStore.setAggregationPeriodStart(distDate)
        datePickerEnabled.value.aggStart = false
      } else {
        aggregationPeriod.value.end = distDate
        searchDataStore.setAggregationPeriodEnd(distDate)
        datePickerEnabled.value.aggEnd = false
      }
    } else {
      if (key === 'start') {
        comparisonPeriod.value.start = distDate
        searchDataStore.setComparisonPeriodStart(distDate)
        datePickerEnabled.value.compStart = false
      } else {
        comparisonPeriod.value.end = distDate
        searchDataStore.setComparisonPeriodEnd(distDate)
        datePickerEnabled.value.compEnd = false
      }
    }
  }
}

/**
 * フォームで指定されたパラメータをもとに、マクロ分析データを取得する
 * @param data - パラメータ群
 */
const getData = (data: any) => {
  MacroAnalysisTableRef.value.getData(data)
}

return (_ctx: any,_cache: any) => {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_date_picker = _resolveComponent("v-date-picker")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TitleHeader, { "title-info": titleInfo.value }, null, 8, ["title-info"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { md: "3" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _cache[28] || (_cache[28] = _createElementVNode("span", null, "集計期間[A]  ", -1)),
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_v_text_field, {
                            modelValue: aggregationPeriod.value.start,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((aggregationPeriod.value.start) = $event)),
                            label: "YYYY/MM/DD",
                            "append-inner-icon": "mdi-calendar-blank",
                            "onClick:appendInner": _cache[1] || (_cache[1] = ($event: any) => (datePickerEnabled.value.aggStart = !datePickerEnabled.value.aggStart))
                          }, null, 8, ["modelValue"]),
                          _createVNode(_component_v_dialog, {
                            modelValue: datePickerEnabled.value.aggStart,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((datePickerEnabled.value.aggStart) = $event)),
                            class: "macro-analysis__date-picker-dialog"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_date_picker, {
                                modelValue: pickedDate.value.aggStart,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((pickedDate.value.aggStart) = $event)),
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (setSearchDate('aggregationPeriod', 'start', pickedDate.value.aggStart)))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }, 8, ["modelValue"]),
                          _cache[27] || (_cache[27] = _createElementVNode("p", { class: "pt-3" }, " ～  ", -1)),
                          _createVNode(_component_v_text_field, {
                            modelValue: aggregationPeriod.value.end,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((aggregationPeriod.value.end) = $event)),
                            label: "YYYY/MM/DD",
                            "append-inner-icon": "mdi-calendar-blank",
                            "onClick:appendInner": _cache[6] || (_cache[6] = ($event: any) => (datePickerEnabled.value.aggEnd = !datePickerEnabled.value.aggEnd))
                          }, null, 8, ["modelValue"]),
                          _createVNode(_component_v_dialog, {
                            modelValue: datePickerEnabled.value.aggEnd,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((datePickerEnabled.value.aggEnd) = $event)),
                            class: "macro-analysis__date-picker-dialog"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_date_picker, {
                                modelValue: pickedDate.value.aggEnd,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((pickedDate.value.aggEnd) = $event)),
                                onClick: _cache[8] || (_cache[8] = ($event: any) => (setSearchDate('aggregationPeriod', 'end', pickedDate.value.aggEnd)))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", null, [
                        _cache[30] || (_cache[30] = _createElementVNode("span", null, "比較期間[B]  ", -1)),
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_v_text_field, {
                            modelValue: comparisonPeriod.value.start,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((comparisonPeriod.value.start) = $event)),
                            label: "YYYY/MM/DD",
                            "append-inner-icon": "mdi-calendar-blank",
                            "onClick:appendInner": _cache[11] || (_cache[11] = ($event: any) => (datePickerEnabled.value.compStart = !datePickerEnabled.value.compStart))
                          }, null, 8, ["modelValue"]),
                          _createVNode(_component_v_dialog, {
                            modelValue: datePickerEnabled.value.compStart,
                            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((datePickerEnabled.value.compStart) = $event)),
                            class: "macro-analysis__date-picker-dialog"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_date_picker, {
                                modelValue: pickedDate.value.compStart,
                                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((pickedDate.value.compStart) = $event)),
                                onClick: _cache[13] || (_cache[13] = ($event: any) => (setSearchDate('comparisonPeriod', 'start', pickedDate.value.compStart)))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }, 8, ["modelValue"]),
                          _cache[29] || (_cache[29] = _createElementVNode("p", { class: "pt-3" }, " ～  ", -1)),
                          _createVNode(_component_v_text_field, {
                            modelValue: comparisonPeriod.value.end,
                            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((comparisonPeriod.value.end) = $event)),
                            label: "YYYY/MM/DD",
                            "append-inner-icon": "mdi-calendar-blank",
                            "onClick:appendInner": _cache[16] || (_cache[16] = ($event: any) => (datePickerEnabled.value.compEnd = !datePickerEnabled.value.compEnd))
                          }, null, 8, ["modelValue"]),
                          _createVNode(_component_v_dialog, {
                            modelValue: datePickerEnabled.value.compEnd,
                            "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((datePickerEnabled.value.compEnd) = $event)),
                            class: "macro-analysis__date-picker-dialog"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_date_picker, {
                                modelValue: pickedDate.value.compEnd,
                                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((pickedDate.value.compEnd) = $event)),
                                onClick: _cache[18] || (_cache[18] = ($event: any) => (setSearchDate('comparisonPeriod', 'end', pickedDate.value.compEnd)))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    md: "9",
                    class: "d-flex"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_container, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { md: "2" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_select, {
                                    modelValue: directionValue.value,
                                    "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((directionValue).value = $event)),
                                    items: directionItems.value,
                                    label: "方路",
                                    dense: ""
                                  }, null, 8, ["modelValue", "items"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { md: "2" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_select, {
                                    modelValue: ledValue.value,
                                    "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((ledValue).value = $event)),
                                    items: ledItems.value,
                                    label: "LED",
                                    dense: ""
                                  }, null, 8, ["modelValue", "items"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { md: "2" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_select, {
                                    modelValue: aggregationTargetValue.value,
                                    "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((aggregationTargetValue).value = $event)),
                                    items: aggregationTargetItems.value,
                                    label: "集計対象",
                                    dense: ""
                                  }, null, 8, ["modelValue", "items"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { md: "2" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_select, {
                                    modelValue: aggregationMethodValue.value,
                                    "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((aggregationMethodValue).value = $event)),
                                    items: aggregationMethodItems.value,
                                    label: "集計方法",
                                    dense: ""
                                  }, null, 8, ["modelValue", "items"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(Button, {
                                    label: "分析データ作成",
                                    disabled: false,
                                    onClick: _cache[24] || (_cache[24] = ($event: any) => (createDataExcel()))
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(Button, {
                                    label: "データ読込",
                                    disabled: false,
                                    onClick: _cache[25] || (_cache[25] = ($event: any) => (setSendData()))
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(MacroAnalysisTable, {
      ref_key: "MacroAnalysisTableRef",
      ref: MacroAnalysisTableRef
    }, null, 512),
    _createVNode(ErrorDialog, {
      "error-dialog": dialog.value,
      onOnClickCloseErrorDialog: _cache[26] || (_cache[26] = ($event: any) => (dialog.value.isShow = false))
    }, null, 8, ["error-dialog"]),
    _withDirectives(_createVNode(Loading, null, null, 512), [
      [_vShow, isLoading.value]
    ])
  ], 64))
}
}

})