import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "group-setting" }
const _hoisted_2 = { class: "group-setting__data" }

import { onBeforeMount, ref } from 'vue'

import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'
import GroupCreateDialog from '@/components/parts/groupSetting/GroupCreateDialog.vue'
import GroupDeleteDialog from '@/components/parts/groupSetting/GroupDeleteDialog.vue'
import GroupUpdateDialog from '@/components/parts/groupSetting/GroupUpdateDialog.vue'
import PoleGroupUpdateDialog from '@/components/parts/groupSetting/PoleGroupUpdateDialog.vue'

import { useGroupListStore, usePoleGroupListStore } from '@/store/app'

import { DIALOG_ERROR_INFO } from '@/mixins/commonFunction'
import { getGroupInfoList, getPoleGroupInfoList, apiResponseErrorCode } from '@/mixins/communicationFunction'
import { toHexNumber } from '@/utils/math'

import { ErrorDialogInfo, GroupListStore, PoleGroupData, TitleInfo } from '@/types/Interfaces'

import { MENU } from '@/setting/setting'

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'GroupSetting',
  setup(__props) {

/**
 * GroupSetting.vue
 * グループ設定
 */
// ==================================
// import
// ==================================
const groupListStore = useGroupListStore()
const poleGroupListStore = usePoleGroupListStore()

// タイトル
const titleinfo = ref<TitleInfo>({
  title: 'グループ設定',
  pointList: [],
  menuList: MENU.watch,
  show: {
    multicamera: false,
    virtual: false,
    menu: true,
  },
})

// エラーダイアログ
const errDialog = ref<ErrorDialogInfo>({
  isShow: false,
  title: '',
  message: ''
})

// ローディングアイコン表示判定
const isLoading = ref<boolean>(false)

// 一覧表検索キーワード
const groupSearch = ref('')
const poleGroupSearch = ref('')

// 各ダイアログ定義内容
const isCreateGroupDialog = ref({
  isDialog: false
})
const isUpdateGroupDialog = ref({
  isDialog: false,
  item: {
    groupid: 0,
    groupname: ''
  }
})
const isDeleteGroupDialog = ref({
  isDialog: false,
  item: {
    groupid: 0,
    groupname: ''
  }
})
const isUpdatePoleGroupDialog = ref({
  isDialog: false,
  item: {
    poleid: 0,
    polename: '',
    groupid: 0,
    groupname: ''
  }
})

// 一覧表ヘッダ
const groupHeaders = ref([
  {
    title: 'グループID',
    key: 'groupid'
  },
  { title: 'グループ名', key: 'groupname' },
  {
    title: '編集',
    key: 'edit'
  },
  {
    title: '削除',
    key: 'delete'
  }
])
const poleHeaders = ref([
  {
    title: 'ポールID',
    key: 'poleid'
  },
  { title: 'ポール名', key: 'polename' },
  { title: 'グループ名', key: 'groupname' },
  {
    title: '編集',
    key: 'edit'
  }
])

// グループ一覧
const groupItems = ref<GroupListStore[]>([])

// ポールグループ一覧
const poleItems = ref<PoleGroupData[]>([])

// グループのCRUD成否判定
const CrudStatus = ref<boolean>(false)

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  initialize()
})

// ==================================
// method
// ==================================
/**
 * 初期処理
 */
const initialize = () => {
  // 右サイドメニューを取得
  titleinfo.value.menuList = MENU.groupSetting
  // 一覧取得
  getList()
}

/**
 * グループ一覧、ポールのグループ一覧取得処理
 */
const getList = () => {
  // グループ一覧取得
  groupInfoCall()
  // ポールのグループ一覧取得
  poleGroupInfoCall()
}

/**
 * グループ一覧取得処理
 */
const groupInfoCall = () => {
  isLoading.value = true
  // 一覧取得APIを呼び出す
  let promise = getGroupInfoList()
  promise
    .then(() => {
      // Piniaストアに格納されたグループ一覧を設定する
      groupItems.value = groupListStore.$state.groupList
      isLoading.value = false
    })
    .catch(error => {
      isLoading.value = false
      // エラーメッセージを設定する
      let title = DIALOG_ERROR_INFO.title.getError
      let message = DIALOG_ERROR_INFO.message.getErrorGroupInfo
      if (error === apiResponseErrorCode.noContent) {
        message = DIALOG_ERROR_INFO.message.getErrorGroupNoContent
      }
      showErrorDialog(title, message)
    })
}

/**
 * ポールのグループ一覧取得処理
 */
const poleGroupInfoCall = () => {
  isLoading.value = true
  // 一覧取得APIを呼び出す
  const promise = getPoleGroupInfoList()
  promise
    .then(() => {
      // Piniaストアに格納されたポールグループ一覧を設定する
      poleItems.value = poleGroupListStore.$state.poleGroupList
      isLoading.value = false
    })
    .catch(error => {
      isLoading.value = false
      // エラーメッセージを設定する
      let title = DIALOG_ERROR_INFO.title.getError
      let message = DIALOG_ERROR_INFO.message.getErrorPoleGroupInfo
      if (error == apiResponseErrorCode.noContent) {
        message = DIALOG_ERROR_INFO.message.getErrorPoleGroupNoContent
      }
      showErrorDialog(title, message)
    })
}

/**
 * ダイアログ上にエラーメッセージを設定して表示
 * @param title - タイトル
 * @param message - メッセージ
 */
const showErrorDialog = (title: string, message: string) => {
  errDialog.value.title = title
  errDialog.value.message = message
  errDialog.value.isShow = true
}

/**
 * グループ作成処理
 */
const onClickCreateGroup = () => {
  isCreateGroupDialog.value.isDialog = true
}

/**
 * グループ更新処理
 * @param item - グループ情報
 */
const onClickUpdateGroup = (item: GroupListStore) => {
  isUpdateGroupDialog.value.isDialog = true
  isUpdateGroupDialog.value.item = item
}

/**
 * グループ削除処理
 * @param item - グループ情報
 */
const onClickDeleteGroup = (item: GroupListStore) => {
  isDeleteGroupDialog.value.isDialog = true
  isDeleteGroupDialog.value.item = item
}

/**
 * ポールのグループ更新処理
 * @param item - ポールのグループ情報
 */
const onClickUpdatePoleGroup = (item: PoleGroupData) => {
  isUpdatePoleGroupDialog.value.isDialog = true
  isUpdatePoleGroupDialog.value.item = item
}

/**
 * ダイアログ閉じる処理
 */
const dialogClose = () => {
  resetData()
}

/**
 * API呼び出し処理成功した後ダイアログ閉じて一覧を再取得処理
 */
const successClose = () => {
  resetData()
  // 一覧更新
  getList()
}

/**
 * 作成、更新、削除処理結果をダイアログで表示させる
 * @param result - 処理結果
 * @param title - ダイアログタイトル
 * @param message - ダイアログメッセージ
 */
const showUpdateResult = (result: boolean, title: string, message: string): void => {
  CrudStatus.value = result
  showErrorDialog(title, message)
}

/**
 * エラーダイアログを閉じたときの処理
 */
const closeErrorDialog = () => {
  errDialog.value.isShow = false
  // 処理結果に応じて一覧の表示処理の切り替えを行う
  if (CrudStatus.value) {
    successClose()
  } else {
    dialogClose()
  }
}

/**
 * リセット処理
 */
const resetData = () => {
  isCreateGroupDialog.value.isDialog = false
  isUpdateGroupDialog.value.isDialog = false
  isDeleteGroupDialog.value.isDialog = false
  isUpdatePoleGroupDialog.value.isDialog = false
}

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_v_main = _resolveComponent("v-main")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TitleHeader, { "title-info": titleinfo.value }, null, 8, ["title-info"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_v_card, { elevation: "3" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "group-setting__card py-0" }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("span", null, "グループ一覧", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: groupSearch.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((groupSearch).value = $event)),
                  "append-icon": "mdi-magnify",
                  label: "検索",
                  "single-line": "",
                  "hide-details": "",
                  clearable: ""
                }, null, 8, ["modelValue"]),
                _createVNode(_component_v_data_table, {
                  headers: groupHeaders.value,
                  items: groupItems.value,
                  search: groupSearch.value,
                  "items-per-page": 5
                }, {
                  [`item.groupid`]: _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(_unref(toHexNumber)(item.groupid)), 1)
                  ]),
                  [`item.edit`]: _withCtx(({ item }) => [
                    _createVNode(_component_v_icon, {
                      small: "",
                      class: "mr-2",
                      onClick: ($event: any) => (onClickUpdateGroup(item))
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode(" mdi-pencil ")
                      ])),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  [`item.delete`]: _withCtx(({ item }) => [
                    _createVNode(_component_v_icon, {
                      small: "",
                      onClick: ($event: any) => (onClickDeleteGroup(item))
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" mdi-delete ")
                      ])),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1032, ["headers", "items", "search"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_btn, {
          color: "primary darken-2",
          class: "group-setting__create-btn",
          onClick: onClickCreateGroup
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode(" グループ作成 ")
          ])),
          _: 1
        }),
        _createVNode(_component_v_card, {
          class: "group-setting__pole-card",
          elevation: "3"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "group-setting__card py-0" }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("span", null, "ポールのグループ一覧", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: poleGroupSearch.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((poleGroupSearch).value = $event)),
                  "append-icon": "mdi-magnify",
                  label: "検索",
                  "single-line": "",
                  "hide-details": "",
                  clearable: ""
                }, null, 8, ["modelValue"]),
                _createVNode(_component_v_data_table, {
                  headers: poleHeaders.value,
                  items: poleItems.value,
                  search: poleGroupSearch.value,
                  "items-per-page": 5
                }, {
                  [`item.poleid`]: _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(_unref(toHexNumber)(item.poleid)), 1)
                  ]),
                  [`item.edit`]: _withCtx(({ item }) => [
                    _createVNode(_component_v_icon, {
                      small: "",
                      class: "mr-2",
                      onClick: ($event: any) => (onClickUpdatePoleGroup(item))
                    }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode(" mdi-pencil ")
                      ])),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1032, ["headers", "items", "search"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_RouterView)
        ]),
        _: 1
      }),
      _createVNode(ErrorDialog, {
        "error-dialog": errDialog.value,
        onOnClickCloseErrorDialog: closeErrorDialog
      }, null, 8, ["error-dialog"]),
      _withDirectives(_createVNode(Loading, null, null, 512), [
        [_vShow, isLoading.value]
      ]),
      (isCreateGroupDialog.value)
        ? (_openBlock(), _createBlock(GroupCreateDialog, {
            key: 0,
            dialog: isCreateGroupDialog.value,
            onShowErrorDialog: showUpdateResult,
            onDialogClose: dialogClose
          }, null, 8, ["dialog"]))
        : _createCommentVNode("", true),
      (isUpdateGroupDialog.value.isDialog)
        ? (_openBlock(), _createBlock(GroupUpdateDialog, {
            key: 1,
            dialog: isUpdateGroupDialog.value,
            onShowErrorDialog: showUpdateResult,
            onDialogClose: dialogClose
          }, null, 8, ["dialog"]))
        : _createCommentVNode("", true),
      (isDeleteGroupDialog.value.isDialog)
        ? (_openBlock(), _createBlock(GroupDeleteDialog, {
            key: 2,
            dialog: isDeleteGroupDialog.value,
            onShowErrorDialog: showUpdateResult,
            onDialogClose: dialogClose
          }, null, 8, ["dialog"]))
        : _createCommentVNode("", true),
      (isUpdatePoleGroupDialog.value.isDialog)
        ? (_openBlock(), _createBlock(PoleGroupUpdateDialog, {
            key: 3,
            dialog: isUpdatePoleGroupDialog.value,
            onShowErrorDialog: showUpdateResult,
            onDialogClose: dialogClose
          }, null, 8, ["dialog"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})