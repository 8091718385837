import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "object-list" }
const _hoisted_2 = { class: "object-list__checkbox" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "object-list__obj-name" }

import { onMounted, watch, ref } from 'vue'

import { useAggregationFiltersStore } from '@/store/app'

// ==================================
// interface
// ==================================
interface Props {
  mode: number;
}

interface ObjList {
  kind: string;
  objectMarker: any;
  definition: string;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'ObjectList',
  props: {
    mode: {}
  },
  setup(__props: any) {

/**
 * ObjectList.vue
 * 物標一覧
 * 
 * 親コンポーネント
 * @/components/parts/aggregation/AggreMenu.vue
 */
// ==================================
// import
// ==================================
const filterStore = useAggregationFiltersStore()

const props = __props

// 物標一覧
const objList = ref<ObjList[]>([
  {
    kind: '-01',
    objectMarker: require('@/assets/img/object/question.svg'),
    definition: '種別不明'
  },
  {
    kind: '001',
    objectMarker: require('@/assets/img/object/human.svg'),
    definition: '歩行者'
  },
  {
    kind: '002',
    objectMarker: require('@/assets/img/object/bicycle.svg'),
    definition: '自転車'
  },
  {
    kind: '003',
    objectMarker: require('@/assets/img/object/bike.svg'),
    definition: '自動二輪車'
  },
  {
    kind: '004',
    objectMarker: require('@/assets/img/object/car.svg'),
    definition: '自動車'
  }
])

// チェックボックスフィルタリング一覧
const filteringObj = ref<string[]>([])

// ==================================
// watch
// ==================================
watch(() => filteringObj.value, () => {
  // チェックボックスのチェックが変わったら更新処理を実行する
  setObjectCheckBox()
})

// ==================================
// hook
// ==================================
onMounted(() => {
  // 物標一覧の種別コードをすべて追加する
  objList.value.forEach(obj => {
    filteringObj.value.push(obj.kind)
  })
  setObjectCheckBox()
})

// ==================================
// method
// ==================================
/**
 * 選択されたチェックボックスの値を更新する
 */
const setObjectCheckBox = () => {
  switch(props.mode) {
    // 通過台数、侵入速度
    case 0:
      filterStore.setFilterngObj(filteringObj.value)
      break
    // ヒヤリハット優先
    case 1:
      filterStore.setPriorityObj(filteringObj.value)
      break
    // ヒヤリハット非優先
    case 2:
      filterStore.setNonPriorityObj(filteringObj.value)
      break
    default:
  }
}

return (_ctx: any,_cache: any) => {
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_table = _resolveComponent("v-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_table, {
      density: "compact",
      theme: "light"
    }, {
      default: _withCtx(() => [
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(objList.value, (item) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: item.kind
            }, [
              _createElementVNode("td", _hoisted_2, [
                _createVNode(_component_v_checkbox, {
                  modelValue: filteringObj.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filteringObj).value = $event)),
                  value: item.kind,
                  density: "compact",
                  "hide-details": ""
                }, {
                  label: _withCtx(() => [
                    _createElementVNode("img", {
                      src: item.objectMarker,
                      class: "object-list__obj-icon"
                    }, null, 8, _hoisted_3),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(item.definition), 1)
                  ]),
                  _: 2
                }, 1032, ["modelValue", "value"])
              ])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    })
  ]))
}
}

})