import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "select-sensor" }
const _hoisted_2 = { class: "select-sensor__container" }

import { computed, ref } from 'vue'

import { getSensorSettingList } from '@/mixins/commonFunction'

import { SensorAndNodesInfo, SensorIdData } from '@/types/Interfaces'

// ==================================
// interface
// ==================================
interface Emits {
  (e: 'select-sensor-list-hand-over', value: SensorIdData[]): void;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'SelectSensor',
  emits: ["select-sensor-list-hand-over"],
  setup(__props, { expose: __expose, emit: __emit }) {

/**
 * SelectSensor.vue
 * センサー選択コンポーネント
 * 
 * 親コンポーネント
 * @/components/parts/realTime/VirtualVideo.vue
 * @/components/parts/virtual/VirtualVideo.vue
 */
// ==================================
// import
// ==================================
const items = ref<SensorAndNodesInfo[]>([])

const ischeck = ref<boolean>(false)

// ==================================
// computed
// ==================================
// 選択済みセンサー一覧表示
const selectSensorDisplay = computed(() => {
  let displaySelectSensorSort = []
  let sensorlist: SensorIdData[] = []
  if (items.value.length != 0) {
    for (const item of items.value) {
      for (const i of item.nodes) {
        if (i.checked) {
          displaySelectSensorSort.push(i.label)
          try {
            let sensorInfo = JSON.parse(i.id)
            sensorlist.push(sensorInfo)
          } catch {
            continue
          }
        }
      }
    }
  }
  // 選択済みのセンサー一覧情報をJson形式に変換して親コンポネントに渡す
  selectSensorListHandOver(sensorlist)
  return displaySelectSensorSort.sort()
})

// ==================================
// method
// ==================================
const emit = __emit
/**
 * 選択済みセンサー一覧情報を親コンポーネントに渡す
 * @param sensorlist - 選択済みセンサー一覧情報
 */
const selectSensorListHandOver = (sensorlist: SensorIdData[]) => {
  emit('select-sensor-list-hand-over', sensorlist)
}

/**
 * センサー初期設定
 */
const initSensorList = async () => {
  const sensorSettingList = getSensorSettingList(true)
  items.value = sensorSettingList
}

/**
 * 親要素選択時に子の要素を強制チェックする
 * @param nodes - 選択したセンサー情報
 */
const onUpdate = (nodes: SensorAndNodesInfo[]) => {
  ischeck.value = true
  for (const item of nodes) {
    if (item.checked) {
      for (const i of item.nodes) {
        i.checked = true
      }
    }
  }
  items.value = nodes
}

/**
 * センサー選択一覧表示
 */
const onclick = () => {
  ischeck.value = !ischeck.value
}

/**
 * センサー選択項目枠外押下時に選択項目を閉じる
 */
const closeVue3Tree = () => {
  ischeck.value = false
}

__expose({
  initSensorList,
})

return (_ctx: any,_cache: any) => {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_vue3_tree = _resolveComponent("vue3-tree")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (items.value.length != 0)
        ? (_openBlock(), _createBlock(_component_v_text_field, {
            key: 0,
            modelValue: selectSensorDisplay.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectSensorDisplay).value = $event)),
            variant: "underlined",
            label: "センサー選択",
            readonly: "",
            onClick: onclick
          }, null, 8, ["modelValue"]))
        : (_openBlock(), _createBlock(_component_v_text_field, {
            key: 1,
            variant: "underlined",
            label: "選択できるセンサーが存在しません",
            readonly: ""
          })),
      (ischeck.value)
        ? (_openBlock(), _createBlock(_component_vue3_tree, {
            key: 2,
            nodes: items.value,
            "use-checkbox": true,
            "use-icon": true,
            "onUpdate:nodes": onUpdate
          }, null, 8, ["nodes"]))
        : _createCommentVNode("", true)
    ]),
    (ischeck.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "select-sensor__close-overlay",
          onClick: closeVue3Tree
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})