import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { align: "center" }

import { ref } from 'vue'

import KinesisVideoStreamsWebrtc from '@/components/parts/common/KinesisVideoStreamsWebrtc.vue'

import { getCameraList } from '@/mixins/commonFunction'

import { CameraInfo } from '@/types/Interfaces'

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'CameraFootage',
  setup(__props, { expose: __expose }) {

/**
 * CameraFootage.vue
 * リアルタイムカメラコンポーネント
 * 
 * 親コンポーネント
 * @/views/RealTime.vue
 * 
 */
// ==================================
// import
// ==================================
const cameraList = ref<CameraInfo[]>([])

const selectCamera = ref<CameraInfo | undefined>()

const showCamera = ref<boolean>(false)

// ==================================
// method
// ==================================
/**
 * カメラ初期設定
 */
const initCameraList = () => {
  cameraList.value = getCameraList()
  if (cameraList.value.length != 0) {
    selectCamera.value = cameraList.value[0]
    showCamera.value = true
  }
}

__expose({
  initCameraList,
})

return (_ctx: any,_cache: any) => {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "camera-footage",
    elevation: "5",
    outlined: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, { class: "camera-footage__title py-0 d-flex" }, {
        default: _withCtx(() => [
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "py-5" }, "カメラ映像", -1)),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_select, {
            modelValue: selectCamera.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectCamera).value = $event)),
            class: "camera-footage__select",
            variant: "underlined",
            items: cameraList.value,
            theme: "dark",
            "item-title": "name",
            "item-value": "channel",
            label: "カメラ選択",
            autowidth: "",
            "return-object": ""
          }, null, 8, ["modelValue", "items"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_divider),
      _createElementVNode("div", _hoisted_1, [
        (showCamera.value)
          ? (_openBlock(), _createBlock(KinesisVideoStreamsWebrtc, {
              key: 0,
              camera: selectCamera.value,
              class: _normalizeClass('real-video-container')
            }, null, 8, ["camera"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}
}

})