import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, ref } from 'vue'

import { useAccidentListStore } from '@/store/app'

import { AccidentStore } from '@/types/Interfaces'

import { ACCIDENT_LIST_HEADER } from '@/setting/setting'

// ==================================
// interface
// ==================================
interface Search {
  accidentIdFilterValue: string;
  accidentVehicleId1FilterValue: string;
  accidentVehicleId2FilterValue: string;
  accidentLatitudeFilterValue: string;
  accidentLongitudeFilterValue: string;
  accidentTimeFilterValue: string;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'ListOfAccident',
  setup(__props, { expose: __expose }) {

/**
 * ListOfAccident.vue
 * 事故発生一覧
 * 
 * 親コンポーネント
 * @/views/Accident.vue
 */
// ==================================
// import
// ==================================
const accidentListStore = useAccidentListStore()

// 検索条件
const search = ref<Search>({
  accidentIdFilterValue: '',
  accidentVehicleId1FilterValue: '',
  accidentVehicleId2FilterValue: '',
  accidentLatitudeFilterValue: '',
  accidentLongitudeFilterValue: '',
  accidentTimeFilterValue: '',
})

// 事故発生情報リスト
const accidentList = ref<AccidentStore[]>([])

// ==================================
// computed
// ==================================
// リスト一覧生成
const accident = computed(() => {
  return filterItems(accidentList.value, search.value)
})

// ヘッダー情報
const accidentsTableHeader = computed(() => {
  return ACCIDENT_LIST_HEADER
})

// ==================================
// method
// ==================================
/**
 * リストフィルター処理
 * @param arr - 事故情報
 * @param word - 検索条件
 * @returns 検索条件を満たす事故情報リスト
 */
const filterItems = (arr: AccidentStore[], word: Search) => {
  return arr.filter(function (item: AccidentStore) {
    let accidentId = true
    let vehicleId_1 = true
    let vehicleId_2 = true
    let latitude = true
    let longitude = true
    let accidentTime = true
    if (word.accidentIdFilterValue) {
      accidentId = String(item.accidentId).toLowerCase().includes(String(word.accidentIdFilterValue).toLowerCase())
    }
    if (word.accidentVehicleId1FilterValue) {
      vehicleId_1 = String(item.vehicleId_1).toLowerCase().includes(String(word.accidentVehicleId1FilterValue).toLowerCase())
    }
    if (word.accidentVehicleId2FilterValue) {
      vehicleId_2 = String(item.vehicleId_2).toLowerCase().includes(String(word.accidentVehicleId2FilterValue).toLowerCase())
    }
    if (word.accidentLatitudeFilterValue) {
      latitude = String(item.latitude).toLowerCase().includes(String(word.accidentLatitudeFilterValue).toLowerCase())
    }
    if (word.accidentLongitudeFilterValue) {
      longitude = String(item.longitude).toLowerCase().includes(String(word.accidentLongitudeFilterValue).toLowerCase())
    }
    if (word.accidentTimeFilterValue) {
      accidentTime = String(item.accidentTime).toLowerCase().includes(String(word.accidentTimeFilterValue).toLowerCase())
    }
    return accidentId && vehicleId_1 && vehicleId_2 && latitude && longitude && accidentTime
  })
}

/**
 * リスト情報更新
 */
const updateAccidentList = () => {
  accidentList.value = accidentListStore.accidentList
}

__expose({
  updateAccidentList,
})

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "list-of-accident",
    elevation: "3"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, { class: "list-of-accident__header py-0" }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createElementVNode("span", { class: "py-0" }, "事故発生一覧", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "1" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "pa-5" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: search.value.accidentIdFilterValue,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search.value.accidentIdFilterValue) = $event)),
                    type: "text",
                    label: "ID",
                    variant: "underlined"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "1" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "pa-5" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: search.value.accidentVehicleId1FilterValue,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((search.value.accidentVehicleId1FilterValue) = $event)),
                    type: "text",
                    label: "車両1ID",
                    variant: "underlined"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "1" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "pa-5" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: search.value.accidentVehicleId2FilterValue,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((search.value.accidentVehicleId2FilterValue) = $event)),
                    type: "text",
                    label: "車両2ID",
                    variant: "underlined"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "1" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "pa-5" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: search.value.accidentLatitudeFilterValue,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((search.value.accidentLatitudeFilterValue) = $event)),
                    type: "text",
                    label: "緯度",
                    variant: "underlined"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "1" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "pa-5" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: search.value.accidentLongitudeFilterValue,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((search.value.accidentLongitudeFilterValue) = $event)),
                    type: "text",
                    label: "経度",
                    variant: "underlined"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "2" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "pa-5" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: search.value.accidentTimeFilterValue,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((search.value.accidentTimeFilterValue) = $event)),
                    type: "text",
                    label: "事故発生時刻",
                    variant: "underlined"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_data_table, {
        headers: accidentsTableHeader.value,
        items: accident.value,
        density: "compact"
      }, null, 8, ["headers", "items"])
    ]),
    _: 1
  }))
}
}

})