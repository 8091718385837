import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { key: 2 }
const _hoisted_8 = ["src"]

import { computed, watch, ref } from 'vue'

import { useLedListStore } from '@/store/app'

import { getSensorSettingList } from '@/mixins/commonFunction'
import {
  DIRECTION_ICON,
  LED_DISP_KIND,
  getLedObjectMarker,
} from '@/mixins/mapFunction'

import { LedListStore, SensorIdData } from '@/types/Interfaces'

import { LED_INFOTABLE_TYPE, LED_DISPLAY_LEVEL } from '@/setting/setting'

import imgObjectQuestion from '@/assets/img/object/question.svg'

// ==================================
// interface
// ==================================
interface Props {
  itemsPerPage: number;
  selectSensorList: SensorIdData[];
  mode: string;
}

interface ListHeader {
  title: string;
  key: string;
  sortable: boolean;
}
interface TargetLedSensorList {
  id: string;
  updatetime: number;
  isScondMinuteUpdate: boolean;
  isShow: boolean;
  resTime: number | string;
  dispKind: string;
  mark1: string;
  mark1Object: typeof imgObjectQuestion,
  mark1direction: string;
  mark1directionStyle: string | typeof imgObjectQuestion | {
    width: string;
    height: string;
    transform: string;
  }
  mark2: string;
  mark2Object: typeof imgObjectQuestion,
  mark2direction: string;
  mark2directionStyle: string | typeof imgObjectQuestion | {
    width: string;
    height: string;
    transform: string;
  }
  dispLevel: string;
}

// ==================================
// data
// ==================================
// LED表示板情報リスト

export default /*@__PURE__*/_defineComponent({
  __name: 'LedInfoTable',
  props: {
    itemsPerPage: {},
    selectSensorList: {},
    mode: {}
  },
  setup(__props: any, { expose: __expose }) {

/**
 * LedInfoTable.vue
 * LED表示板情報一覧コンポーネント
 * 
 * 親コンポーネント
 * @/components/parts/realTime/LedDisplayDataInformation.vue
 * @/components/parts/virtual/LedDisplayDataInformation.vue
 */
// ==================================
// import
// ==================================
const ledListStore = useLedListStore()

const props = __props

const listHeaders = ref<ListHeader[]>(LED_INFOTABLE_TYPE.listHeader)

const targetLedSensorList = ref<TargetLedSensorList[]>([])

const targetLedSensorDisplayList = ref<TargetLedSensorList[]>([])

// ==================================
// watch
// ==================================
watch(
  () => props.selectSensorList,
  () => {
    updateList()
  }
)

// ==================================
// computed
// ==================================
// 一覧表示数
const itemsPerPage = computed(() => {
  return props.itemsPerPage
})
// テーブル表示項目
const shownHeaders = computed(() => {
  return listHeaders.value
})

// テーブルデータ
const itemList = computed(() => {
  return targetLedSensorDisplayList.value
})

// ==================================
// method
// ==================================
/**
 * LED情報取得
 */
const initLedList = () => {
  targetLedSensorList.value = []
  const sensorSettingList = getSensorSettingList(false)
  for (const sensorList of sensorSettingList) {
    if (sensorList.label == '表示板') {
      for (const ledSensor of sensorList.nodes) {
        let sensorInfo = JSON.parse(ledSensor.id)
        let info = {
          id: sensorInfo.name,
          updatetime: new Date().getTime(),
          isScondMinuteUpdate: true,
          isShow: false,
          resTime: '',
          dispKind: '',
          mark1: '',
          mark1Object: '',
          mark1direction: '',
          mark1directionStyle: '',
          mark2: '',
          mark2Object: '',
          mark2direction: '',
          mark2directionStyle: '',
          dispLevel: '',
        }
        targetLedSensorList.value.push(info)
      }
    }
  }
}

/**
 * LED情報一覧更新
 */
const updateList = () => {
  // 最新データを設定
  ledListStore.ledList.forEach((sensorLed: LedListStore) => {
    targetLedSensorList.value.forEach((led: TargetLedSensorList) => {
      const leftPadding = '000000'
      let hexSensorId = leftPadding + sensorLed.sensorId.toString(16)
      hexSensorId = hexSensorId.slice(-6)
      if (led.id == hexSensorId) {
        led.updatetime = new Date().getTime()
        led.resTime = new Date(sensorLed.ledList[0].resTime).getTime()
        led.dispKind = sensorLed.ledList[0].dispKind
        led.mark1 = sensorLed.ledList[0].dispInfo1
        led.mark1Object = getLedObjectMarker(
          sensorLed.ledList[0].dispKind,
          sensorLed.ledList[0].dispInfo1
        )
        led.mark1direction = sensorLed.ledList[0].direction1
        led.mark1directionStyle = getLedDirectionStyle(
          sensorLed.ledList[0].direction1
        )
        led.mark2 = sensorLed.ledList[0].dispInfo2
        led.mark2Object = getLedObjectMarker(
          sensorLed.ledList[0].dispKind,
          sensorLed.ledList[0].dispInfo2
        )
        led.mark2direction = sensorLed.ledList[0].direction2
        led.mark2directionStyle = getLedDirectionStyle(
          sensorLed.ledList[0].direction2,
          sensorLed.ledList[0].dispKind
        )
        led.dispLevel = getDisplayLevel(
          sensorLed.ledList[0].dispKind,
          sensorLed.ledList[0].dispLevel
        )
      }
    })
  })
  // 2分間更新が行われていないかチェック
  targetLedSensorList.value.forEach((led: TargetLedSensorList) => {
    if (led.resTime == '') {
      led.isScondMinuteUpdate = false
    } else {
      // リアルタイム画面の場合のみフロント側で測定
      if (props.mode == 'realTime' && typeof led.resTime === 'number' ) {
        led.isScondMinuteUpdate = updatetimeCheck(led.resTime, led.updatetime)
      }
    }
  })
  // 選択した選択したセンサーを表示対象に変更
  targetLedSensorDisplayList.value = []
  props.selectSensorList.forEach((sensor) => {
    targetLedSensorList.value.forEach((led) => {
      if (led.id == sensor.name) {
        targetLedSensorDisplayList.value.push(led)
      }
    })
  })
targetLedSensorDisplayList.value.forEach((led) => {
    led.isShow = checkDisplay(led)
  })
}

/**
 * LED方向取得
 * @param direction - 回転角度
 * @param dispKind - 表示種別
 * @returns 方向指定スタイルor？アイコン
 */
const getLedDirectionStyle = (direction: string, dispKind?: string) => {
  // 表示種別によって出し分け
  if (dispKind != LED_DISP_KIND.safeDrivingSupportLawDispKind || dispKind == undefined) {
    // -01：不明 -10：なし
    if (direction == '-10') {
      return ''
    } else if (direction == '-01') {
      return imgObjectQuestion
    }
    return {
      width: '20px',
      height: '20px',
      transform: 'rotate(' + direction + 'deg)',
    }
  } else {
    let dispDirection = ''
    let targetList = null
    targetList = LED_DISPLAY_LEVEL.safeDrivingSupportLawDirection
    if (targetList != null) {
      for (const item of targetList) {
        if (direction == item.direction) {
          dispDirection = item.displayName
          break
        }
      }
    }
    if (dispDirection == '') {
      return imgObjectQuestion
    }
    return dispDirection
  }
}

/**
 * 表示レベル取得
 * @param dispKind - 表示種別
 * @param dispLevel - 表示レベルコード
 * @returns 表示レベル
 */
const getDisplayLevel = (dispKind: string, dispLevel: string) => {
  let level = ''
  // ブランクなら取得しない 000
  if (dispKind == LED_DISP_KIND.blank) {
    return level
  }
  let targetList = null
  if (dispKind == LED_DISP_KIND.safeDrivingSupportDispKind) {
    targetList = LED_DISPLAY_LEVEL.safeDrivingSupport
  } else if (dispKind == LED_DISP_KIND.safeDrivingSupportLawDispKind) {
    targetList = LED_DISPLAY_LEVEL.safeDrivingSupportLaw
  } else if (dispKind == LED_DISP_KIND.disasterDispKind) {
    targetList = LED_DISPLAY_LEVEL.disaster
  }
  if (targetList != null) {
    for (const item of targetList) {
      if (dispLevel == item.kind) {
        level = item.displayName
        break
      }
    }
  }
  return level
}

/**
 * 更新チェック
 * @param resTime - 
 * @param updatetime - 更新日時
 * @returns 更新有無
 */
const updatetimeCheck = (resTime: number, updatetime: number) => {
  let d1 = new Date(resTime)
  let diffTime = d1.getTime() - updatetime
  let diffSecond = Math.floor(diffTime / 1000)
  let isUpdated = false
  if (diffSecond <= 120) {
    isUpdated = true
  }
  return isUpdated
}

/**
 * 表示可否判定
 * @param led - LEDセンサーリスト
 * @returns 表示可否
 */
const checkDisplay = (led: TargetLedSensorList) => {
  return led.dispKind != '000' && led.isScondMinuteUpdate && led.resTime != ''
}

__expose({
  updateList,
  initLedList,
})

return (_ctx: any,_cache: any) => {
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "led-info-table" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_data_table, {
        density: "compact",
        icon: _unref(DIRECTION_ICON),
        headers: shownHeaders.value,
        items: itemList.value,
        "items-per-page": itemsPerPage.value,
        "footer-props": { itemsPerPageOptions: [itemsPerPage.value] }
      }, {
        [`item.id`]: _withCtx(({ item }) => [
          _createTextVNode(_toDisplayString(item.id), 1)
        ]),
        [`item.dispLevel`]: _withCtx(({ item }) => [
          _createTextVNode(_toDisplayString(item.isShow ? item.dispLevel : ""), 1)
        ]),
        [`item.mark1Object`]: _withCtx(({ item }) => [
          (item.mark1Object != '' && item.isShow && item.dispKind != _unref(LED_DISP_KIND).safeDrivingSupportLawDispKind)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: item.mark1Object,
                class: "led-info-table__item--black"
              }, null, 8, _hoisted_1))
            : _createCommentVNode("", true)
        ]),
        [`item.mark1directionStyle`]: _withCtx(({ item }) => [
          (item.mark1direction == '-01' && item.isShow && item.dispKind != _unref(LED_DISP_KIND).safeDrivingSupportLawDispKind)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: item.mark1directionStyle,
                class: "led-info-table__item"
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true),
          (
            item.mark1direction != '-01' &&
              item.mark1direction != '-10' &&
              item.isShow && 
              item.dispKind != _unref(LED_DISP_KIND).safeDrivingSupportLawDispKind
          )
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: _unref(DIRECTION_ICON),
                style: _normalizeStyle(item.mark1directionStyle)
              }, null, 12, _hoisted_3))
            : _createCommentVNode("", true)
        ]),
        [`item.mark2Object`]: _withCtx(({ item }) => [
          (item.mark2Object != '' && item.isShow && item.dispKind != _unref(LED_DISP_KIND).safeDrivingSupportLawDispKind)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: item.mark2Object,
                class: "led-info-table__item--black"
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true)
        ]),
        [`item.mark2directionStyle`]: _withCtx(({ item }) => [
          (item.mark2direction == '-01' && item.isShow && item.dispKind != _unref(LED_DISP_KIND).safeDrivingSupportLawDispKind)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: item.mark2directionStyle,
                class: "led-info-table__item"
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true),
          (
            item.mark2direction != '-01' &&
              item.mark2direction != '-10' &&
              item.isShow &&
              item.dispKind != _unref(LED_DISP_KIND).safeDrivingSupportLawDispKind)
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: _unref(DIRECTION_ICON),
                style: _normalizeStyle(item.mark2directionStyle)
              }, null, 12, _hoisted_6))
            : _createCommentVNode("", true),
          (item.isShow &&
            item.dispKind == _unref(LED_DISP_KIND).safeDrivingSupportLawDispKind &&
            item.mark2directionStyle != _unref(imgObjectQuestion)
          )
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(item.mark2directionStyle), 1))
            : _createCommentVNode("", true),
          (item.isShow && 
            item.dispKind == _unref(LED_DISP_KIND).safeDrivingSupportLawDispKind &&
            item.mark2directionStyle == _unref(imgObjectQuestion)
          )
            ? (_openBlock(), _createElementBlock("img", {
                key: 3,
                src: item.mark2directionStyle,
                class: "led-info-table__item"
              }, null, 8, _hoisted_8))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["icon", "headers", "items", "items-per-page", "footer-props"])
    ]),
    _: 1
  }))
}
}

})