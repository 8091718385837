import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import { computed, watch, ref } from 'vue'

// ==================================
// interface
// ==================================
interface Props {
  value: boolean;
  text: string;
  prefix: any;
}

interface Emits {
  (e: 'input', val: any): void;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'ModalMacroAnalysisHistgram',
  props: {
    value: { type: Boolean },
    text: {},
    prefix: {}
  },
  emits: ["input"],
  setup(__props: any, { emit: __emit }) {

/**
 * ModalMacroAnalysisHistgram.vue
 * モーダル表示用グラフ表示コンポーネント
 * 
 * 親コンポーネント
 * @/components/parts/macroAnalysis/MacroAnalysisDetailInfoTable.vue
 * @/components/parts/macroAnalysis/MacroAnalysisTable.vue
 */
// ==================================
// import
// ==================================
const props = __props

const resetFlag = ref<boolean>(false)

// ==================================
// watch
// ==================================
watch(() => props.value, val => {
  resetFlag.value = val
})

// ==================================
// computed
// ==================================
/**
 * モーダルダイアログ表示設定
 */
const modal = computed({
  get() {
    return props.value
  },
  set(val) {
    emit('input', val)
  }
})

// ==================================
// method
// ==================================
const emit = __emit

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: modal.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((modal).value = $event)),
    value: _ctx.value,
    class: "modal-macro-analysis-histgram"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              (resetFlag.value)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.prefix.component), _normalizeProps(_mergeProps({ key: 0 }, _ctx.prefix.props)), null, 16))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "value"]))
}
}

})