import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

import { computed, watch, ref } from 'vue'

import { usePosListStore } from '@/store/app'

import {
  getObjectNameWithVehicleId,
  getShiftName,
} from '@/mixins/commonFunction'
import {
  getObjectMarker,
  DIRECTION_ICON,
  STEERING_ICON,
} from '@/mixins/mapFunction'

import { PosInfo, SensorIdData } from '@/types/Interfaces'

// ==================================
// interface
// ==================================
interface CheckBox {
  label: string;
  value: boolean;
}
interface ListHeader {
  title: string;
  key: string;
  shown: boolean;
}
interface Props {
  itemsPerPage: number;
  selectSensorList: SensorIdData[];
  checkBoxes: CheckBox[];
  listHeader: ListHeader[];
}

interface Emits {
  (e: 'select-object', value: number[]): void;
}

interface PosList {
  mark: any;
  time: string;
  sensor: string;
  kind: string;
  name:  string;
  coordinate: string;
  speed: string;
  direction: {
    width: string;
    height: string;
    transform: string;
  }
  shift: string;
  angle: {
    width: string;
    height: string;
    transform: string;
  }
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'LocationInfoTable',
  props: {
    itemsPerPage: {},
    selectSensorList: {},
    checkBoxes: {},
    listHeader: {}
  },
  emits: ["select-object"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

/**
 * LocationInfoTable.vue
 * 交差点物標情報一覧コンポーネント
 * 
 * 親コンポーネント
 * @/components/parts/realTime/InformationInTheIntersection.vue
 * @/components/parts/virtual/InformationInTheIntersection.vue
 */
// ==================================
// import
// ==================================
const props = __props

// 物標情報リスト
const posListStore = usePosListStore()

const dialog = ref<boolean>(false)

const checkboxes = ref<CheckBox[]>(props.checkBoxes)

const listHeaders = ref<ListHeader[]>(props.listHeader)

const posList = ref<PosList[]>([])

// ==================================
// watch
// ==================================
watch(
  () => props.selectSensorList,
  () => {
    updateList()
  }
)

// ==================================
// computed
// ==================================
// 一覧表示数
const itemsPerPage = computed(() => {
  return props.itemsPerPage
})

// テーブル表示項目設定
const shownHeaders = computed(() => {
  return listHeaders.value.filter((h) => h.shown)
})

// ==================================
// method
// ==================================
const emit = __emit

/**
 * テーブル表示項目設定ダイアログ表示
 */
const columSelectModalOpen = () => {
  dialog.value = true
}

/**
 * 物標情報更新
 */
const updateList = () => {
  posList.value = []
  for (const pos of posListStore.posList) {
    for (const sensor of props.selectSensorList) {
      if (pos.sensorId === Number(sensor.value)) {
        setPosList(pos.posList, sensor.name, sensor.kind)
      }
    }
  }
}

/**
 * 物標情報設定
 * @param posListFromStore - 物標情報
 * @param sensorName - センサー名
 * @param kind - センサー種別
 */
const setPosList = (posListFromStore: PosInfo[], sensorName: string, kind: string) => {
  posListFromStore.forEach((pos: PosInfo) => {
    let mark = getObjectMarker(pos.vehicleSizeClassification, pos.vehicleRoleClassification)
    let name = getObjectNameWithVehicleId(
      pos.vehicleId,
      pos.vehicleSizeClassification,
      pos.vehicleRoleClassification
    )
    let time = pos.time.slice(5)
    let speed = pos.speed.toFixed(2) + ' m/sec'
    let coord = pos.latitude.toFixed(6) + '\n' + pos.longitude.toFixed(6)
    let shift = getShiftName(pos.transmissionState)
    let directionStyle = getDirectionStyle(pos.heading)
    let steeringStyle = getDirectionStyle(pos.heading)
    posList.value.push({
      mark: mark,
      time: time,
      sensor: sensorName,
      kind: kind,
      name: name,
      coordinate: coord,
      speed: speed,
      direction: directionStyle,
      shift: shift,
      angle: steeringStyle,
    })
  })
}

/**
 * 方向取得
 * @param direction - 回転角度
 */
const getDirectionStyle = (direction: number) => {
  return {
    width: '20px',
    height: '20px',
    transform: 'rotate(' + direction + 'deg)',
  }
}

/**
 * VIRTUAL映像をクリックした物標にフォーカスさせる
 * @param data - イベントオブジェクト
 * @param value - 緯度経度
 */
const onClickEvent = (data: any, value: any) => {
  let coord = value.item.coordinate.split('\n')
  let latlng = [Number(coord[0]), Number(coord[1])]
  emit('select-object', latlng)
}

/**
 * テーブル表示項目設定ダイアログ閉じる
 */
const columSelectModalClose = () => {
  for (let i = 0; i < checkboxes.value.length; i++) {
    listHeaders.value[i].shown = false
    if (checkboxes.value[i].value) {
      listHeaders.value[i].shown = true
    }
  }
  dialog.value = false
}

__expose({
  columSelectModalOpen,
  updateList,
})

return (_ctx: any,_cache: any) => {
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "location-info-table" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_data_table, {
        density: "compact",
        headers: shownHeaders.value,
        items: posList.value,
        icon: _unref(DIRECTION_ICON),
        "items-per-page": itemsPerPage.value,
        "footer-props": { itemsPerPageOptions: [itemsPerPage.value] },
        "onClick:row": onClickEvent
      }, {
        [`item.mark`]: _withCtx(({ item }: any) => [
          _createElementVNode("img", {
            src: item.mark,
            class: "location-info-table__item"
          }, null, 8, _hoisted_1)
        ]),
        [`item.direction`]: _withCtx(({ item }: any) => [
          _createElementVNode("img", {
            src: _unref(DIRECTION_ICON),
            style: _normalizeStyle(item.direction)
          }, null, 12, _hoisted_2)
        ]),
        [`item.angle`]: _withCtx(({ item }: any) => [
          _createElementVNode("img", {
            src: _unref(STEERING_ICON),
            style: _normalizeStyle(item.angle)
          }, null, 12, _hoisted_3)
        ]),
        _: 2
      }, 1032, ["headers", "items", "icon", "items-per-page", "footer-props"]),
      _createVNode(_component_v_dialog, {
        modelValue: dialog.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dialog).value = $event)),
        persistent: "",
        "max-width": "350"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            elevation: "20",
            raised: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "location-info-table__title" }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" 表示項目設定 ")
                ])),
                _: 1
              }),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(checkboxes.value, (check) => {
                    return (_openBlock(), _createBlock(_component_v_checkbox, {
                      key: check.label,
                      modelValue: check.value,
                      "onUpdate:modelValue": ($event: any) => ((check.value) = $event),
                      primary: "",
                      "hide-details": "",
                      label: check.label
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]))
                  }), 128))
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    variant: "flat",
                    color: "blue-grey",
                    dark: "",
                    rounded: "",
                    onClick: columSelectModalClose
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" OK ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}
}

})