import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, computed } from 'vue'

import LedInfoTable from '@/components/parts/common/LedInfoTable.vue'

import { SensorIdData } from '@/types/Interfaces'

// ==================================
// interface
// ==================================
interface Props {
  selectSensorList: SensorIdData[];
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'LedDisplayDataInformation',
  props: {
    selectSensorList: {}
  },
  setup(__props: any, { expose: __expose }) {

/**
 * LedDisplayDataInformation.vue
 * 交差点内物標情報コンポーネント
 * 
 * 親コンポーネント
 * @/views/RealTime.vue
 */
// ==================================
// import
// ==================================
const props = __props

const ledInfoTableComponent = ref()

// ==================================
// computed
// ==================================
const selectSensorList = computed(() => {
  return props.selectSensorList
})

// ==================================
// method
// ==================================
/**
 * LED情報取得
 */
const initLedList = () => {
  ledInfoTableComponent.value.initLedList()
}
/**
 * LED情報一覧更新
 */
const updateList = () => {
  ledInfoTableComponent.value.updateList()
}

__expose({
  initLedList,
  updateList,
})

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "led-display-data-information",
    elevation: "5",
    outlined: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, { class: "led-display-data-information__title py-0 d-flex" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("span", { class: "py-0" }, "LED表示板", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_v_divider),
      _createVNode(LedInfoTable, {
        ref_key: "ledInfoTableComponent",
        ref: ledInfoTableComponent,
        "select-sensor-list": selectSensorList.value,
        "items-per-page": 3,
        mode: "realTime"
      }, null, 8, ["select-sensor-list"])
    ]),
    _: 1
  }))
}
}

})