import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "aggre-menu" }

import { onBeforeMount, watch, ref } from 'vue'

import DirectionList from '@/components/parts/aggregation/DirectionList.vue'
import ObjectList from '@/components/parts/aggregation/ObjectList.vue'
import Button from '@/components/parts/common/Button.vue'

import { useAggregationFiltersStore } from '@/store/app'

import { DIRECTIONS } from '@/setting/setting'

import { DateTime } from 'luxon'

// ==================================
// interface
// ==================================
interface Props {
  targetDate: DateTime;
  selectedCategory: number;
}

interface Emits {
  (e: 'update-aggregation', flag: boolean): void;
  (e: 'update-target-date', type: number, diff: number): void;
}

interface Direction {
  name: string;
  deg: number;
}
interface State {
  dispSpanSwitch: number;
  isFilterOpen?: number;
  dateString: string;
  day: number;
  weekday: number;
  dayStringList: string[];
  isToday: boolean;
  directionList: Direction[];
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'AggreMenu',
  props: {
    targetDate: {},
    selectedCategory: {}
  },
  emits: ["update-aggregation", "update-target-date"],
  setup(__props: any, { emit: __emit }) {

/**
 * AggreMenu.vue
 * 集計メニュー
 * 
 * 親コンポーネント
 * @/components/parts/aggregation/AggreMain.vue
 */
// ==================================
// import
// ==================================
const filterStore = useAggregationFiltersStore()

const props = __props

const state = ref<State>({
  dispSpanSwitch: 0,
  isFilterOpen: undefined,
  dateString: '',
  day: 0,
  weekday: 0,
  dayStringList: ['日', '月', '火', '水', '木', '金', '土'],
  isToday: true,
  directionList: [],
})

// ==================================
// watch
// ==================================
watch(() => props.targetDate, (val: DateTime) => {
  // 現在時刻を設定
  let latest = DateTime.now()
  // 表示モードに合わせて、最新の日付の場合は「次」のボタンクリックを無効にする
  switch (state.value.dispSpanSwitch) {
    case 1:
      // 対象の日付が最新の場合、「次の日」のボタンクリックを無効にする
      state.value.isToday = latest.diff(val, 'days').days < 1
      break
    case 2:
      // 対象の日付の週が最新以上の場合、「次の週」のボタンクリックを無効にする
      state.value.isToday = latest.year <= val.year && latest.weekNumber <= val.weekNumber
      break
  }
  // 日付表示用に文字列として処理する
  state.value.dateString = val.toFormat('yyyy/MM/dd')
  state.value.day = val.day
  state.value.weekday = val.weekday - 1
})

watch(() => props.selectedCategory, (val: number) => {
    state.value.isFilterOpen = val !== 0 ? 0: void 0
})

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  initialize()
})

// ==================================
// method
// ==================================
const emit = __emit

/**
 * 初期処理
 */
const initialize = () => {
  // 親コンポーネントから送られた日付データを画面表示用に文字列処理する
  state.value.dateString = props.targetDate.toFormat('yyyy/MM/dd')
  state.value.day = props.targetDate.day
  state.value.weekday = props.targetDate.weekday - 1
  state.value.directionList = DIRECTIONS
}

/**
 * 日の更新
 * @param diff - 差分
 */
const updateDate = (diff: number) => {
  emit('update-target-date', 1, diff)
}

/**
 * 週の更新
 * @param diff - 差分
 */
const updateWeek = (diff: number) => {
  emit('update-target-date', 2, diff)
}

/**
 * 集計期間の変更に伴う処理
 * @param val - 集計期間コード
 */
const setDispSpanSwitch = (val: number) => {
  state.value.dispSpanSwitch = val
  emit('update-aggregation', false)
  filterStore.setMode(props.selectedCategory, val)
}

return (_ctx: any,_cache: any) => {
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_expansion_panels, {
      modelValue: state.value.isFilterOpen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.value.isFilterOpen) = $event)),
      theme: "dark",
      disabled: state.value.isFilterOpen !== void 0 && props.selectedCategory === 0
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_expansion_panel, {
          color: "#0041c0",
          title: "絞り込み項目"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_expansion_panel_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_container, null, {
                  default: _withCtx(() => [
                    (_ctx.selectedCategory === 1)
                      ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card, {
                                  color: "#eee",
                                  density: "compact"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_toolbar, {
                                      theme: "dark",
                                      density: "compact",
                                      color: "#c00",
                                      title: "優先"
                                    }),
                                    _createVNode(_component_v_card_text, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_row, { dense: "" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_col, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_card, {
                                                  height: "240px",
                                                  theme: "light",
                                                  density: "compact"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_toolbar, {
                                                      color: "primary",
                                                      density: "compact",
                                                      title: "物標"
                                                    }),
                                                    _createVNode(_component_v_card_text, { class: "aggre-menu__filter-list" }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(ObjectList, { mode: 1 })
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_v_col, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_card, {
                                                  height: "240px",
                                                  theme: "light",
                                                  density: "compact"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_toolbar, {
                                                      color: "primary",
                                                      density: "compact",
                                                      title: "IN方路"
                                                    }),
                                                    _createVNode(_component_v_card_text, { class: "aggre-menu__filter-list" }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(DirectionList, {
                                                          mode: 1,
                                                          dir: 1
                                                        })
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_v_col, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_card, {
                                                  height: "240px",
                                                  theme: "light",
                                                  density: "compact"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_toolbar, {
                                                      color: "primary",
                                                      density: "compact",
                                                      title: "OUT方路"
                                                    }),
                                                    _createVNode(_component_v_card_text, { class: "aggre-menu__filter-list" }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(DirectionList, {
                                                          mode: 1,
                                                          dir: 2
                                                        })
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card, {
                                  color: "#eee",
                                  density: "compact"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_toolbar, {
                                      theme: "dark",
                                      density: "compact",
                                      color: "#090",
                                      title: "非優先"
                                    }),
                                    _createVNode(_component_v_card_text, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_row, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_col, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_card, {
                                                  height: "240px",
                                                  theme: "light",
                                                  density: "compact"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_toolbar, {
                                                      color: "primary",
                                                      density: "compact",
                                                      title: "物標"
                                                    }),
                                                    _createVNode(_component_v_card_text, { class: "aggre-menu__filter-list" }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(ObjectList, { mode: 2 })
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_v_col, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_card, {
                                                  height: "240px",
                                                  theme: "light",
                                                  density: "compact"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_toolbar, {
                                                      color: "primary",
                                                      density: "compact",
                                                      title: "IN方路"
                                                    }),
                                                    _createVNode(_component_v_card_text, { class: "aggre-menu__filter-list" }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(DirectionList, {
                                                          mode: 2,
                                                          dir: 1
                                                        })
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_v_col, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_card, {
                                                  height: "240px",
                                                  theme: "light",
                                                  density: "compact"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_toolbar, {
                                                      color: "primary",
                                                      density: "compact",
                                                      title: "OUT方路"
                                                    }),
                                                    _createVNode(_component_v_card_text, { class: "aggre-menu__filter-list" }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(DirectionList, {
                                                          mode: 2,
                                                          dir: 2
                                                        })
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : (_ctx.selectedCategory > 1)
                        ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_card, {
                                            height: "240px",
                                            theme: "light",
                                            density: "compact"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_toolbar, {
                                                color: "primary",
                                                density: "compact",
                                                title: "物標"
                                              }),
                                              _createVNode(_component_v_card_text, { class: "aggre-menu__filter-list" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(ObjectList, { mode: 0 })
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_card, {
                                            height: "240px",
                                            theme: "light",
                                            density: "compact"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_toolbar, {
                                                color: "primary",
                                                density: "compact",
                                                title: "IN方路"
                                              }),
                                              _createVNode(_component_v_card_text, { class: "aggre-menu__filter-list" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(DirectionList, {
                                                    mode: 0,
                                                    dir: 1
                                                  })
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_col, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_card, {
                                            height: "240px",
                                            theme: "light",
                                            density: "compact"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_toolbar, {
                                                color: "primary",
                                                density: "compact",
                                                title: "OUT方路"
                                              }),
                                              _createVNode(_component_v_card_text, { class: "aggre-menu__filter-list" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(DirectionList, {
                                                    mode: 0,
                                                    dir: 2
                                                  })
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "disabled"]),
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createVNode(Button, {
                  label: "時間別",
                  disabled: state.value.dispSpanSwitch === 1,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (setDispSpanSwitch(1)))
                }, null, 8, ["disabled"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createVNode(Button, {
                  label: "日別",
                  disabled: state.value.dispSpanSwitch === 2,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (setDispSpanSwitch(2)))
                }, null, 8, ["disabled"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createVNode(Button, {
                  label: "月別",
                  disabled: state.value.dispSpanSwitch === 3,
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (setDispSpanSwitch(3)))
                }, null, 8, ["disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "aggre-menu__switch-button" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                (state.value.dispSpanSwitch === 1)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      block: "",
                      light: "",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (updateDate(-1)))
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode(" ＜ 前の日 ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (state.value.dispSpanSwitch === 2)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 1,
                      block: "",
                      light: "",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (updateWeek(-1)))
                    }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode(" ＜ 前の週 ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_col, { style: {"font-size":"1.6em"} }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(state.value.dateString + " (" + state.value.dayStringList[state.value.weekday] + ")"), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                (state.value.dispSpanSwitch === 1)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      block: "",
                      light: "",
                      disabled: state.value.isToday,
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (updateDate(1)))
                    }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode(" 次の日 ＞ ")
                      ])),
                      _: 1
                    }, 8, ["disabled"]))
                  : _createCommentVNode("", true),
                (state.value.dispSpanSwitch === 2)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 1,
                      block: "",
                      light: "",
                      disabled: state.value.isToday,
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (updateWeek(1)))
                    }, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode(" 次の週 ＞ ")
                      ])),
                      _: 1
                    }, 8, ["disabled"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})