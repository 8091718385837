import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, onMounted } from 'vue'

import ListOfAccident from '@/components/parts/accident/ListOfAccident.vue'
import NearMissList from '@/components/parts/accident/NearMissList.vue'
import SearchFrame from '@/components/parts/accident/SearchFrame.vue'
import VirtualVideo from '@/components/parts/accident/VirtualVideo.vue'
import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'

import { useSelectPoleStore } from '@/store/app'

import { ErrorDialogInfo, TitleInfo } from '@/types/Interfaces'

import { MENU } from '@/setting/setting'

// ==================================
// data
// ==================================
// 選択ポール情報ストア

export default /*@__PURE__*/_defineComponent({
  __name: 'Accident',
  setup(__props) {

/**
 * Accident.vue
 * ヒヤリハット
 */
// ==================================
// import
// ==================================
const selectPoleStore = useSelectPoleStore()

const virtualVideoComponent = ref()
const nearMissListComponent = ref()
const listOfAccidentComponent = ref()

// エラーダイアログ情報
const errorDialog = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false,
})

// 画面項目制御
const isLoading = ref<boolean>(false)

// タイトル
const titleinfo = ref<TitleInfo>({
  title: selectPoleStore.name + ' ヒヤリハット・事故情報',
  pointList: [],
  menuList: MENU.accidents,
  show: {
    realtime: true,
    multicamera: true,
    virtual: true,
    menu: true,
  },
})

// ==================================
// hook
// ==================================
onMounted(() => {
  addPoleMarker(selectPoleStore.latlng.lat, selectPoleStore.latlng.lng)
})

// ==================================
// method
// ==================================
/**
 * ポールマーカー設定
 * @param lat - 緯度
 * @param lng - 経度
 */
const addPoleMarker = (lat: number, lng: number) => {
  virtualVideoComponent.value.addPoleMarker(lat, lng)
}

/**
 * エラーダイアログを開く
 * @param title - エラータイトル
 * @param message - エラーメッセージ
 */
const openErrorDialog = (title: string, message: string) => {
  errorDialog.value.message = message
  errorDialog.value.title = title
  errorDialog.value.isShow = true
}

/**
 * エラーダイアログを閉じる
 */
const onClickCloseErrorDialog = () => {
  errorDialog.value.isShow = false
}

/**
 * ローディング処理開始
 */
const startLoading = () => {
  isLoading.value = true
}

/**
 * ローディング処理停止
 */
const stopLoading = () => {
  isLoading.value = false
}

/**
 * ヒヤリハット情報マーカー設定
 */
const addNearMissMarker = () => {
  virtualVideoComponent.value.addNearMissMarker()
}

/**
 * 事故発生情報マーカー設定
 */
const addAccidentMarker = () => {
  virtualVideoComponent.value.addAccidentMarker()
}

/**
 * ヒヤリハット情報一覧更新
 */
const updateNearMissesList = () => {
  nearMissListComponent.value.updateNearMissesList()
}

/**
 * 事故発生情報一覧更新
 */
const updateAccidentList = () => {
  listOfAccidentComponent.value.updateAccidentList()
}

return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TitleHeader, { "title-info": titleinfo.value }, null, 8, ["title-info"]),
    _createVNode(_component_v_container, {
      class: "accident",
      fluid: "",
      "fill-height": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { dense: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "6" }, {
              default: _withCtx(() => [
                _createVNode(VirtualVideo, {
                  ref_key: "virtualVideoComponent",
                  ref: virtualVideoComponent
                }, null, 512)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { cols: "6" }, {
              default: _withCtx(() => [
                _createVNode(SearchFrame, {
                  onOpenErrorDialog: openErrorDialog,
                  onUpdateAccidentList: updateAccidentList,
                  onUpdateNearMissesList: updateNearMissesList,
                  onStartLoading: startLoading,
                  onStopLoading: stopLoading,
                  onAddNearMissMarker: addNearMissMarker,
                  onAddAccidentMarker: addAccidentMarker
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { dense: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "20" }, {
              default: _withCtx(() => [
                _createVNode(NearMissList, {
                  ref_key: "nearMissListComponent",
                  ref: nearMissListComponent
                }, null, 512)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { dense: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "20" }, {
              default: _withCtx(() => [
                _createVNode(ListOfAccident, {
                  ref_key: "listOfAccidentComponent",
                  ref: listOfAccidentComponent
                }, null, 512)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _withDirectives(_createVNode(Loading, null, null, 512), [
          [_vShow, isLoading.value]
        ]),
        _createVNode(ErrorDialog, {
          "error-dialog": errorDialog.value,
          onOnClickCloseErrorDialog: onClickCloseErrorDialog
        }, null, 8, ["error-dialog"])
      ]),
      _: 1
    })
  ], 64))
}
}

})