import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'

import { useUserInfoStore } from '@/store/app'

import { DIALOG_ERROR_INFO } from '@/mixins/commonFunction'

import { ErrorDialogInfo, TitleInfo } from '@/types/Interfaces'

import CognitoInfo from '@/setting/aws-cognito'

// AWS Library
import { Auth } from 'aws-amplify'
import AWS from 'aws-sdk'
import * as log from 'loglevel'

// ==================================
// interface
// ==================================
interface UserInfo {
  userName: string;
  password: string;
}

// ==================================
// data
// ==================================
// ルーター定義

export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  setup(__props) {

/**
 * Login.vue
 * ログイン
 */
// ==================================
// import
// ==================================
const router = useRouter()

// ユーザー情報ストア
const userInfoStore = useUserInfoStore()

// タイトル
const titleinfo = ref<TitleInfo>({
  title: '',
  pointList: [],
  menuList: [],
  show: {
    logout: false,
    point: false,
    menu: false,
  },
})

// エラーダイアログ情報
const errorDialog = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false,
})

// ユーザー情報
const userInfo = ref<UserInfo>({
  userName: '',
  password: '',
})

// 画面項目制御
const isShowPassword = ref<boolean>(false)
const isLoading = ref<boolean>(false)

// ==================================
// hook
// ==================================
onMounted(() => {
  // ログアウト後に残っているPiniaストアを消去する
  sessionStorage.clear()
})

// ==================================
// method
// ==================================
/**
 * ログイン処理
 */
const onClickLogin = () => {
  if (loginCheck()) {
    isLoading.value = true
    signIn()
  }
}

/**
 * ログイン情報入力チェック
 */
const loginCheck = () => {
  let result = false
  if (userInfo.value.userName === '') {
    errorDialog.value.title = DIALOG_ERROR_INFO.title.authError
    errorDialog.value.message = DIALOG_ERROR_INFO.message.authErrorUser
    errorDialog.value.isShow = true
  } else if (userInfo.value.password === '') {
    errorDialog.value.title = DIALOG_ERROR_INFO.title.authError
    errorDialog.value.message = DIALOG_ERROR_INFO.message.authErrorPassword
    errorDialog.value.isShow = true
  } else {
    result = true
  }
  return result
}

/**
 * cognitoユーザーログイン処理
 */
const signIn = async () => {
  await Auth.signIn(userInfo.value.userName, userInfo.value.password)
    .then((user: any) => {
      // ユーザー情報保存
      userInfoStore.setData(user)
      setCognitoIdentityCredentials(user)
      isLoading.value = false
    })
    .catch((err: any) => {
      log.error(err)
      errorDialog.value.title = DIALOG_ERROR_INFO.title.authError
      errorDialog.value.message = DIALOG_ERROR_INFO.message.authErrorUserPassword
      errorDialog.value.isShow = true
      isLoading.value = false
    })
}

/**
 * Cognito アイデンティティユーザー認証
 * @param user - Cognitユーザー情報
 */
const setCognitoIdentityCredentials = async (user: any) => {
  await user.getSession(function (err: any, session: any) {
    if (session) {
      AWS.config.region = CognitoInfo.region
      let identityPoolId = CognitoInfo.identityPoolId
      let logins: any = {}
      const key = 'cognito-idp.' + CognitoInfo.region + '.amazonaws.com/' + CognitoInfo.userPoolId
      logins[key] = session.getIdToken().getJwtToken()
      const cognitoIdentityParams = {
        IdentityPoolId: identityPoolId,
        Logins: logins,
      }
      AWS.config.credentials = new AWS.CognitoIdentityCredentials(cognitoIdentityParams)
    } else {
      log.error(err)
    }
  })
  router.push('/selectarea')
}

/**
 * エラーダイアログを閉じる
 */
const onClickCloseErrorDialog = () => {
  errorDialog.value.isShow = false
}

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_v_main = _resolveComponent("v-main")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TitleHeader, { "title-info": titleinfo.value }, null, 8, ["title-info"]),
    _createVNode(_component_v_card, {
      class: "login mx-auto mt-10",
      width: "30vw"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createElementVNode("h1", { class: "login__title" }, " ログイン ", -1)
          ])),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_form, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: userInfo.value.userName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((userInfo.value.userName) = $event)),
                  variant: "underlined",
                  color: "primary",
                  "prepend-icon": "mdi-account-circle",
                  label: "ユーザ名"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_v_text_field, {
                  modelValue: userInfo.value.password,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((userInfo.value.password) = $event)),
                  variant: "underlined",
                  "prepend-icon": "mdi-lock",
                  label: "パスワード",
                  color: "primary",
                  type: isShowPassword.value ? 'text' : 'password',
                  "append-inner-icon": isShowPassword.value ? 'mdi-eye' : 'mdi-eye-off',
                  "onClick:appendInner": _cache[2] || (_cache[2] = ($event: any) => (isShowPassword.value = !isShowPassword.value))
                }, null, 8, ["modelValue", "type", "append-inner-icon"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_actions, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_btn, {
              variant: "flat",
              color: "blue-grey",
              onClick: onClickLogin
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" ログイン ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(ErrorDialog, {
      "error-dialog": errorDialog.value,
      onOnClickCloseErrorDialog: onClickCloseErrorDialog
    }, null, 8, ["error-dialog"]),
    _withDirectives(_createVNode(Loading, null, null, 512), [
      [_vShow, isLoading.value]
    ]),
    _createVNode(_component_v_main, null, {
      default: _withCtx(() => [
        _createVNode(_component_RouterView)
      ]),
      _: 1
    })
  ], 64))
}
}

})