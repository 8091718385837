import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, computed } from 'vue'

import LocationInfoTable from '@/components/parts/common/LocationInfoTable.vue'

import { SensorIdData } from '@/types/Interfaces'

import { LOCATION_INFOTABLE_TYPE1 } from '@/setting/setting'

// ==================================
// interface
// ==================================
interface Props {
  selectSensorList: SensorIdData[];
}

interface Emits {
  (e: 'select-object', value: number[]): void;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'InformationInTheIntersection',
  props: {
    selectSensorList: {}
  },
  emits: ["select-object"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

/**
 * InformationInTheIntersection.vue
 * 交差点内物標情報コンポーネント
 * 
 * 親コンポーネント
 * @/views/RealTime.vue
 */
// ==================================
// import
// ==================================
const props = __props

const locationInfoTableComponent = ref()

// ==================================
// computed
// ==================================
const selectSensorList = computed(() => {
  return props.selectSensorList
})

// ==================================
// method
// ==================================
const emit = __emit
/**
 * 位置情報を親コンポーネントへ渡す
 * @param data - 緯度経度
 */
const selectObject = (data: number[]) => {
  emit('select-object', data)
}

/**
 * テーブル表示項目設定ダイアログ表示
 */
const onClickTableSet = () => {
  locationInfoTableComponent.value.columSelectModalOpen()
}
/**
 * 物標情報更新
 */
const updateList = () => {
  locationInfoTableComponent.value.updateList()
}

__expose({
  updateList,
})

return (_ctx: any,_cache: any) => {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "information-in-the-intersection",
    elevation: "5",
    outlined: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, { class: "information-in-the-intersection__title py-0 d-flex" }, {
        default: _withCtx(() => [
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "py-0" }, "交差点内物標情報", -1)),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_btn, {
            variant: "flat",
            color: "blue-grey",
            dark: "",
            height: "auto",
            onClick: onClickTableSet
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("mdi-table-settings")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_divider),
      _createVNode(LocationInfoTable, {
        ref_key: "locationInfoTableComponent",
        ref: locationInfoTableComponent,
        "check-boxes": _unref(LOCATION_INFOTABLE_TYPE1).checkBoxes,
        "list-header": _unref(LOCATION_INFOTABLE_TYPE1).listHeader,
        "select-sensor-list": selectSensorList.value,
        "items-per-page": 3,
        onSelectObject: selectObject
      }, null, 8, ["check-boxes", "list-header", "select-sensor-list"])
    ]),
    _: 1
  }))
}
}

})