// ==================================
// 共通処理
// ==================================
import { usePoleListStore, usePoleInfoStore, useSelectAreaStore, useSelectPoleStore } from '@/store/app'

import { CameraInfo, LatLng, SensorAndNodesInfo, SensorInfo, SensorInfoByKind } from '@/types/Interfaces'

import {
  CAMERA_LIST,
  SENSOR_LIST,
  OBJECT_INFO_LIST,
  SHIFT_NAME_LIST,
  SERVER,
  AMBULANCE_OBJECT_INFO
} from '@/setting/setting'

import log from 'loglevel'

// エラーダイアログのタイトル及びメッセージ一覧
export const DIALOG_ERROR_INFO = {
  title: {
    authError: '認証エラー',
    getError: '取得エラー',
    streamingError: 'ストリーミングエラー',
    createError: '作成エラー',
    updateError: '編集エラー',
    deleteError: '削除エラー'
  },
  message: {
    authErrorUser: 'ユーザ名が入力されていません',
    authErrorPassword: 'パスワードが入力されていません',
    authErrorUserPassword: 'ユーザ名・パスワードが間違っています',
    authErrorOther: 'ログインできませんでした',
    authErrorSigned: '認証情報が取得できませんでした\r\n動画再生はできません',
    authErrorSession: 'セッションが切断されました',
    getError:'情報が取得できませんでした',
    getErrorAreaNoContent: 'エリア情報が存在しませんでした',
    getErrorAreaInfo: 'エリア情報が取得できませんでした',
    getErrorPoleNoContent: 'ポール情報が存在しませんでした',
    getErrorPoleInfo: 'ポール情報が取得できませんでした',
    getErrorSensorInfo: 'センサー情報が取得できませんでした',
    getErrorAccidentNoContent: '過去情報が存在しませんでした',
    getErrorAccidentInfo: '過去情報が取得できませんでした',
    getErrorCameraNoContent: 'カメラ情報が存在しませんでした',
    getErrorCameraInfo: 'カメラ情報が取得できませんでした',
    streamingErrorNotPlay: 'ストリーミングが再生できませんでした',
    getErrorNearMissInfoVideoNoContent: 'ヒヤリハット映像が存在しませんでした',
    getErrorNearMissInfoVideoInfo: 'ヒヤリハット映像が取得できませんでした',
    getErrorMacroAnalysisInfo: 'マクロ分析データが取得できませんでした',
    getErrorMacroAnalysisInfoNoContent: 'マクロ分析データが存在しませんでした',
    getErrorMacroAnalysisDetailInfo:
      'マクロ分析詳細データが取得できませんでした',
    getErrorMacroAnalysisDetailInfoNoContent:
      'マクロ分析詳細データが存在しませんでした',
    getErrorMacroAnalysisInfoDataForExcel:
      'マクロ分析ダウンロードデータが取得できませんでした',
    getErrorGroupNoContent: 'グループ情報が存在しませんでした',
    getErrorGroupInfo: 'グループ情報が取得できませんでした',
    getErrorPoleGroupNoContent: 'ポールのグループ情報が存在しませんでした',
    getErrorPoleGroupInfo: 'ポールのグループ情報が取得できませんでした',
    createErrorGroupInfo: 'グループ作成に失敗しました',
    updateErrorGroupInfo: 'グループ編集に失敗しました',
    deleteErrorGroupInfo: 'グループ削除に失敗しました',
    updateErrorPoleGroupInfo: 'ポールのグループ編集に失敗しました'
  },
}
// S3バケットURL一覧
export const S3BUCKETURL = {
  // S3バケットspits-video-bucket(過去事故情報映像格納場所)
  movieUrl: process.env.VUE_APP_MOVIE_URL,
  wovenDir: 'woven/',
  // S3バケットspits-video-strage-bucket(ヒヤリハット事故映像格納場所)
  videoStrageBucketUrl: process.env.VUE_APP_VIDEO_STORAGE_BUCKET_URL,
  // S3バケットspits-download-bucket(マクロ分析データ(Excel)格納場所)
  spitsDownloadBucketUrl: process.env.VUE_APP_SPITS_DOWNLOAD_BUCKET_URL
}


interface SensorAndCameraInfo {
  sensorId: number;
  poleId: number;
  cameraKind: number;
}
/**
 * カメラ機器種別一覧情報取得
 * @returns カメラ機器種別一覧
 */
export const getCameraList = (): CameraInfo[] => {
  const cameralist: CameraInfo[] = []
  const poleInfoStore = usePoleInfoStore()
  const list = poleInfoStore.sensorList
  if (list.length == 0) {
    return cameralist
  }
  const settingCameraList = CAMERA_LIST
  const sensorList: SensorAndCameraInfo[] = []
  list.forEach((sensor: SensorInfo) => {
    const sensorKind = sensor.sensorId >> 16
    settingCameraList.forEach((camera) => {
      if (camera.kind == sensorKind) {
        sensorList.push({
          sensorId: sensor.sensorId,
          poleId: poleInfoStore.poleId,
          cameraKind: camera.kind,
        })
      }
    })
  })
  if (sensorList.length == 0) {
    return cameralist
  }
  sensorList.sort(function (a: SensorAndCameraInfo, b: SensorAndCameraInfo) {
    return a.cameraKind < b.cameraKind ? -1 : 1
  })
  let count = 0
  let oldKind: number | undefined = undefined

  sensorList.forEach((camera: SensorAndCameraInfo) => {
    if (oldKind != camera.cameraKind) {
      oldKind = undefined
      count = 0
    }
    let name = getCameraName(camera.cameraKind)
    const kindCount = sensorList.reduce(
      (total: number, item: SensorAndCameraInfo) =>
        total + (item.cameraKind == camera.cameraKind ? 1 : 0),
      0
    )
    if (camera.cameraKind == 20) {
      const vehicleId = camera.sensorId & 255
      name += ' [Vheicle ID:' + vehicleId.toString() + ']'
    } else if (kindCount > 1) {
      count += 1
      name += ' ' + count.toString()
    }
    cameralist.push({
      sensorId: camera.sensorId,
      poleId: camera.poleId,
      cameraKind: camera.cameraKind,
      name: name,
    })
    oldKind = camera.cameraKind
  })
  return cameralist
}

/**
 * カメラ機器種別名取得
 * @param kind - カメラ機器種別ID
 * @returns カメラ機器種別名
 */
export const getCameraName = (kind: number): string => {
  let name = ''
  for (const item of CAMERA_LIST) {
    if (kind == item.kind) {
      name = item.name
      break
    }
  }
  return name
}

/**
 * センサー機器種別一覧取得
 * @param isChecked - 初期選択可否
 * @returns センサー機器種別一覧 、初期選択値
 */
export const getSensorSettingList = (isChecked: boolean): SensorAndNodesInfo[] => {
  const sensorList: SensorAndNodesInfo[] = []
  const poleInfoStore = usePoleInfoStore()
  const list = poleInfoStore.sensorList
  if (list.length == 0) {
    return sensorList
  }
  list.sort(function (a: SensorInfo, b: SensorInfo) {
    return a.sensorId < b.sensorId ? -1 : 1
  })
  let sensorKind: number | undefined = undefined
  let index = 0
  let isFirst = true
  list.forEach((sensor: SensorInfo) => {
    const setting = getSensorSetting(sensor.sensorId)
    if (setting.kind !== 0) {
      if (sensorKind != setting.kind) {
        if (isFirst) {
          isFirst = false
        } else {
          index += 1
        }
        sensorList.push({
          id: index.toString(),
          label: setting.name,
          checked: isChecked,
          nodes: [],
        })
        sensorKind = setting.kind
      }
      const leftPadding = '000000'
      let hexSensorId = leftPadding + sensor.sensorId.toString(16)
      hexSensorId = hexSensorId.slice(-6)
      const idData = {
        name: hexSensorId,
        value: String(sensor.sensorId),
        color: setting.color,
        kind: setting.name,
      }
      sensorList[index].nodes.push({
        id: JSON.stringify(idData),
        label: hexSensorId,
        checked: isChecked,
      })
    }
  })
  return sensorList
}
/**
 * センサー機器種別情報取得
 * @param sensorId - センサーID
 * @returns センサー機器種別情報
 */
export const getSensorSetting = (sensorId: number): SensorInfoByKind => {
  let sensorSetting = {
    kind: 0,
    name: '',
    color: '',
  };
  const sensorKind = sensorId >> 16
  for (const item of SENSOR_LIST) {
    if (sensorKind == item.kind) {
      sensorSetting = item
      break
    }
  }
  return sensorSetting
}

/**
 * 車両ID付き物標オブジェクト名取得
 * @param vehicleId - 車両ID
 * @param sizeKind - 物標オブジェクトサイズ種別ID
 * @param roleKind - 物標オブジェクト用途種別ID
 * @returns 車両ID付き物標オブジェクト名
 */
export const getObjectNameWithVehicleId = (vehicleId: number, sizeKind: number, roleKind: number): string => {
  let name = '(' + vehicleId + ')' + 'その他'
  if (roleKind == AMBULANCE_OBJECT_INFO.kind) {
    name = '(' + vehicleId + ')' + AMBULANCE_OBJECT_INFO.name
    return name
  }
  for (const item of OBJECT_INFO_LIST) {
    if (sizeKind == item.kind) {
      name = '(' + vehicleId + ')' + item.name
      break
    }
  }
  return name
}

/**
 * 車両のシフトポジション情報取得
 * @param state
 * @returns 車両のシフトポジション情報(N：ニュートラル P：パーキング D：ドライブ R：リバース 4～6：予約 -：不定)
 */
export const getShiftName = (state: number): string => {
  let name = '-'
  for (const item of SHIFT_NAME_LIST) {
    if (state == item.state) {
      name = item.name
      break
    }
  }
  return name
}

/**
 * Piniaステートにエリア情報を登録
 * @param areaId - エリアID
 * @param name - エリア名
 * @param latlng - 緯度、経度
 * @returns 処理結果
 */
export const setSelectArea = (areaId: number, name: string, latlng: LatLng): boolean => {
  try {
    const selectAreaStore = useSelectAreaStore()
    selectAreaStore.setData(areaId, name, latlng)
    return true
  } catch (error) {
    log.error('setSelectArea error ' + error)
    return false
  }
}

/**
 * ポール情報設定
 * @param poleId - ポールID
 * @param address - ポール地域情報
 * @returns 処理結果
 */
export const setSelectPole = (poleId: number , address: string): boolean => {
  try {
    let sensorList: SensorInfo[] = []
    let latlng: any = {}
    let areaId = 0
    const poleListStore = usePoleListStore() 
    const poleList = poleListStore.$state.poleList
    const poleInfoStore = usePoleInfoStore()
    const poleInfo = poleInfoStore.$state
    const selectPoleStore = useSelectPoleStore()
    const selectPole = selectPoleStore.$state

    if (SERVER > 2) {
      poleId = 1
      areaId = poleList[0].areaId
      sensorList = poleList[0].sensorList
      latlng = [poleList[0].latitude, poleList[0].longitude]
    } else {
      // 特定のポール情報が設定されていた場合、その内容を優先的に使用する
      if (poleInfo !== void 0 && poleInfo !== null) {
        areaId = poleInfo.areaId
        sensorList = poleInfo.sensorList
        latlng = [poleInfo.latitude, poleInfo.longitude]
      } else {
        // ポール一覧から対象のポールIDを持つ情報を取得する
        for (const item of poleList) {
          if (poleId === item.poleId) {
            areaId = item.areaId
            sensorList = item.sensorList
            latlng = [item.latitude, item.longitude]
            break
          }
        }
      }
      if (areaId === void 0) {
        areaId = selectPole.areaId
        latlng = selectPole.latlng
      }
    }
    selectPoleStore.setData(poleId, areaId, address, latlng, sensorList)
    return true
  } catch (error) {
    log.error('setSelectPole error ' + error)
    return false
  }
}