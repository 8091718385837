import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loading" }
const _hoisted_2 = { class: "loading__spacer" }


export default /*@__PURE__*/_defineComponent({
  __name: 'Loading',
  setup(__props) {

/**
 * Loading.vue
 * 共通ローディングコンポーネント
 * 
 * 親コンポーネント
 * @/components/parts/groupSetting/GroupCreateDialog.vue
 * @/components/parts/groupSetting/GroupDeleteDialog.vue
 * @/components/parts/groupSetting/GroupUpdateDialog.vue
 * @/components/parts/groupSetting/PoleGroupUpdateDialog.vue
 * @/components/parts/macroAnalysis/MacroAnalysisTable.vue
 * @/views/Accident.vue
 * @/views/Aggregation.vue
 * @/views/Area.vue
 * @/views/Collections.vue
 * @/views/GroupSetting.vue
 * @/views/HistoryList.vue
 * @/views/Login.vue
 * @/views/MacroAnalysis.vue
 * @/views/MacroAnalysisInfoDataDownload.vue
 * @/views/MultiCamera.vue
 * @/views/RealTime.vue
 * @/views/Virtual.vue
 * @/views/Watch.vue
 */

return (_ctx: any,_cache: any) => {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_progress_circular, {
        size: 70,
        width: 10,
        color: "purple",
        indeterminate: ""
      })
    ])
  ]))
}
}

})