import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 0,
  class: "near-miss-list-video-player"
}

import { computed, ref } from 'vue'

import NearMissPlayVideo from '@/components/parts/accident/NearMissPlayVideo.vue'

import { useNearMissListStore, useSelectPoleStore, useSearchConditionStore } from '@/store/app'

import { S3BUCKETURL } from '@/mixins/commonFunction'
import { CSV_DOWNLOAD_ICON } from '@/mixins/mapFunction'

import { NearMissStore } from '@/types/Interfaces'

import { NEAR_MISSES_LIST_HEADER, POLE_ON_GRAFANA } from '@/setting/setting'

import { DateTime } from 'luxon'

// ==================================
// interface
// ==================================
interface Search {
  nearMissIdFilterValue: string;
  nearMissAreaFilterValue: string;
  nearMissVehicleId1FilterValue: string;
  nearMissVehicleId1KindFilterValue: string;
  nearMissVehicleId1DirectionFilterValue: string;
  nearMissVehicleId2FilterValue: string;
  nearMissVehicleId2KindFilterValue: string;
  nearMissVehicleId2DirectionFilterValue: string;
  nearMissLatitudeFilterValue: string;
  nearMissLongitudeFilterValue: string;
  nearMissTimeFilterValue: string;
  nearMissTimeGapFilterValue: string;
}

interface Source {
  src: string;
  type: string;
}

interface VideoOption {
  isShowMovie: boolean;
  options: {
    autoplay: boolean;
    controls: boolean;
    sources: Source[];
  }
  nearMissesInfo: {
    id: string;
    area: string;
    vehicleId1Kind: string;
    vehicleId1KindDirection: string;
    vehicleId2Kind: string;
    vehicleId2KindDirection: string;
    nearMissTime: string;
    timeGap: string;
  };
}

interface PoleInfo {
  poleId: number;
  name: string;
  path: string;
  nearMissPath: string;
}

// ==================================
// data
// ==================================
// 選択ポール情報ストア

export default /*@__PURE__*/_defineComponent({
  __name: 'NearMissList',
  setup(__props, { expose: __expose }) {

/**
 * NearMissList.vue
 * ヒヤリハット一覧
 * 
 * 親コンポーネント
 * @/views/Accident.vue
 */
// ==================================
// import
// ==================================
const selectPoleStore = useSelectPoleStore()

// ヒヤリハット一覧情報ストア
const nearMissListStore = useNearMissListStore()

const searchConditionsStore = useSearchConditionStore()

// 検索条件
const search = ref<Search>({
  nearMissIdFilterValue: '',
  nearMissAreaFilterValue: '',
  nearMissVehicleId1FilterValue: '',
  nearMissVehicleId1KindFilterValue: '',
  nearMissVehicleId1DirectionFilterValue: '',
  nearMissVehicleId2FilterValue: '',
  nearMissVehicleId2KindFilterValue: '',
  nearMissVehicleId2DirectionFilterValue: '',
  nearMissLatitudeFilterValue: '',
  nearMissLongitudeFilterValue: '',
  nearMissTimeFilterValue: '',
  nearMissTimeGapFilterValue: '',
})

// ヒヤリハット一覧情報リスト
const nearMissesList = ref<NearMissStore[]>([])

// ビデオ関連情報
const videoOption = ref<VideoOption>({
  isShowMovie: false,
  options: {
    autoplay: false,
    controls: true,
    sources: [
      {
        src: '',
        type: 'video/mp4',
      },
    ],
  },
  nearMissesInfo: {
    id: '',
    area: '',
    vehicleId1Kind: '',
    vehicleId1KindDirection: '',
    vehicleId2Kind: '',
    vehicleId2KindDirection: '',
    nearMissTime: '',
    timeGap: '',
  },
})

// ==================================
// computed
// ==================================
// リスト一覧生成
const nearMisses = computed(() => {
  return filterItems(nearMissesList.value, search.value)
})

// ヘッダー情報
const nearMissesTableHeader = computed(() => {
  return NEAR_MISSES_LIST_HEADER
})

// ==================================
// method
// ==================================
/**
 * リストフィルター処理
 * @param arr - ヒヤリハット情報
 * @param word - 検索条件
 * @returns 検索条件を満たすヒヤリハット情報リスト
 */
const filterItems = (arr: NearMissStore[], word: Search) => {
  return arr.filter((item: NearMissStore) => {
    let nearMissId = true
    let area = true
    let vehicleId_1 = true
    let vehicleId_1_Kind = true
    let vehicleId_1_direction = true
    let vehicleId_2 = true
    let vehicleId_2_kind = true
    let vehicleId_2_direction = true
    let latitude = true
    let longitude = true
    let nearMisstime = true
    let timeGap = true

    if (word.nearMissIdFilterValue) {
      nearMissId = String(item.nearMissId).toLowerCase().includes(String(word.nearMissIdFilterValue).toLowerCase())
    }
    if (word.nearMissAreaFilterValue) {
      area = String(item.area).toLowerCase().includes(String(word.nearMissAreaFilterValue).toLowerCase())
    }
    if (word.nearMissVehicleId1FilterValue) {
      vehicleId_1 = String(item.vehicleId_1).toLowerCase().includes(String(word.nearMissVehicleId1FilterValue).toLowerCase())
    }
    if (word.nearMissVehicleId1KindFilterValue) {
      vehicleId_1_Kind = String(item.vehicleId_1_Kind).toLowerCase().includes(String(word.nearMissVehicleId1KindFilterValue).toLowerCase())
    }
    if (word.nearMissVehicleId1DirectionFilterValue) {
      vehicleId_1_direction = String(item.vehicleId_1_direction).toLowerCase().includes(String(word.nearMissVehicleId1DirectionFilterValue).toLowerCase())
    }
    if (word.nearMissVehicleId2FilterValue) {
      vehicleId_2 = String(item.vehicleId_2).toLowerCase().includes(String(word.nearMissVehicleId2FilterValue).toLowerCase())
    }
    if (word.nearMissVehicleId2KindFilterValue) {
      vehicleId_2_kind = String(item.vehicleId_2_kind).toLowerCase().includes(String(word.nearMissVehicleId2KindFilterValue).toLowerCase())
    }
    if (word.nearMissVehicleId2DirectionFilterValue) {
      vehicleId_2_direction = String(item.vehicleId_2_direction).toLowerCase().includes(String(word.nearMissVehicleId2DirectionFilterValue).toLowerCase())
    }
    if (word.nearMissLatitudeFilterValue) {
      latitude = String(item.latitude).toLowerCase().includes(String(word.nearMissLatitudeFilterValue).toLowerCase())
    }
    if (word.nearMissLongitudeFilterValue) {
      longitude = String(item.longitude).toLowerCase().includes(String(word.nearMissLongitudeFilterValue).toLowerCase())
    }
    if (word.nearMissTimeFilterValue) {
      nearMisstime = String(item.nearMisstime).toLowerCase().includes(String(word.nearMissTimeFilterValue).toLowerCase())
    }
    if (word.nearMissTimeGapFilterValue) {
      timeGap = String(item.timeGap).toLowerCase().includes(String(word.nearMissTimeGapFilterValue).toLowerCase())
    }
    return nearMissId && area && vehicleId_1 && vehicleId_1_Kind && vehicleId_1_direction && vehicleId_2 && vehicleId_2_kind && vehicleId_2_direction && latitude && longitude && nearMisstime && timeGap
  })
}

/**
 * リスト情報更新
 */
const updateNearMissesList = () => {
  nearMissesList.value = nearMissListStore.nearMissList
}

/**
 * TimeGapを小数点第3位まで残す
 * @param timeGap - TimeGap値
 * @returns 成型後TimeGap値
 */
const createTimegap4Disp = (timeGap: number) => {
  let n = 3
  return Math.floor(Number(timeGap) * Math.pow(10, n)) / Math.pow(10, n)
}

/**
 * 映像表示
 * @param item - ヒヤリハット情報
 */
const videoPlay = (item: NearMissStore) => {
  const videUrl = S3BUCKETURL.videoStrageBucketUrl + item.videoPath
  videoOption.value.options.sources[0].src = videUrl
  videoOption.value.nearMissesInfo.id = String(item.nearMissId)
  videoOption.value.nearMissesInfo.area = item.area
  videoOption.value.nearMissesInfo.vehicleId1Kind = item.vehicleId_1_Kind
  videoOption.value.nearMissesInfo.vehicleId1KindDirection = item.vehicleId_1_direction
  videoOption.value.nearMissesInfo.vehicleId2Kind = item.vehicleId_2_kind
  videoOption.value.nearMissesInfo.vehicleId2KindDirection = item.vehicleId_2_direction
  videoOption.value.nearMissesInfo.nearMissTime = item.nearMisstime.split('.')[0]
  videoOption.value.nearMissesInfo.timeGap = String(item.timeGap)
  videoOption.value.isShowMovie = true
}

/**
 * 映像を閉じる
 */
const videoClose = () => {
  videoOption.value.isShowMovie = false
}

/**
 * 映像ダウンロード
 * @param item - ヒヤリハット情報
 */
const downloadData = (item: NearMissStore) => {
  const url = S3BUCKETURL.videoStrageBucketUrl + item.videoPath
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.href = url
  a.click()
  a.remove()
}

/**
 * ヒヤリハット一覧をCSVファイルとして出力する
 */
const DownloadCsv = () => {
  const searchConditions = searchConditionsStore.$state.searchCondition
  // CSVファイル内容を作成する
  const csvSubHeader = '\ufeff' + ',,優先方路,,非優先方路,,,\n'
  const csvHeader = 'Date,Id,物標,方路,物標,方路,エリア,TG<' + search.value.nearMissTimeGapFilterValue + '\n'
  let csv = csvSubHeader + csvHeader
  nearMissesList.value.forEach((data: NearMissStore) => {
    let line =
      data['nearMisstime'] +
      ',' +
      data['nearMissId'] +
      ',' +
      data['vehicleId_1_Kind'] +
      ',' +
      data['vehicleId_1_direction'] +
      ',' +
      data['vehicleId_2_kind'] +
      ',' +
      data['vehicleId_2_direction'] +
      ',' +
      data['area'] +
      ',' +
      data['timeGap'] +
      '\n'
    csv += line
  })
  // ページ内部にCSVファイルへのリンクを作成し、クリックアクションを入れて自動ダウンロードさせる
  const blob = new Blob([csv], { type: 'text/csv' })
  let link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  if ('startDateTime' in searchConditions && 'endDateTime' in searchConditions) {
    link.download = 'ヒヤリハット一覧' + '_' + searchConditions.startDateTime + '-' + searchConditions.endDateTime + '.csv'
  }
  link.click()
}

/**
 * ヒヤリハットGrafana遷移
 * @param item - ヒヤリハット情報
 */
const viewNearMissinfo = (item: NearMissStore) => {
  const baseUrl = 'https://g-339943402d.grafana-workspace.ap-northeast-1.amazonaws.com'
  const tableName = 't_corrected_pos_info'
  const sensorId = '13633281'
  const type1 = 'speed'
  const type2 = 'acceleration'
  const vid1 = item.vehicleId_2
  const vid2 = item.vehicleId_1
  const class1 = checkTargetType(item.vehicleId_2_kind, true)
  const class2 = checkTargetType(item.vehicleId_1_Kind, false)
  const utcTimestamp = DateTime.utc(Number(item.nearMisstime))
  const tenSecondsBefore = utcTimestamp.minus({ seconds: 10 })
  const tenSecondsAfter = utcTimestamp.plus({ seconds: 10 })
  const poleList = POLE_ON_GRAFANA.poleInfo
  poleList.forEach((pole: PoleInfo) => {
    if (selectPoleStore.$state.poleId == pole.poleId) {
      const dashboardSlug = pole.poleId
      let dashboardUrl = `${baseUrl}/d/${dashboardSlug}&from=${tenSecondsBefore}&to=${tenSecondsAfter}&var-poleid=${selectPoleStore.$state.poleId}&var-table_name1=${tableName}&var-sensorid1=${sensorId}&${class1}&var-vid1=${vid1}&var-table_name2=${tableName}&var-sensorid2=${sensorId}&${class2}&var-vid2=${vid2}&var-val_type1=${type1}&var-val_type2=${type2}`
      window.open(dashboardUrl, '_blank')
    }
  })
}

/**
 * 物標種別判定処理
 * @param kind - 物標種別
 * @param isClass1 - 車両1or2判別フラグ
 * @returns URLパラメータ
 */
const checkTargetType = (kind: string, isClass1: boolean) => {
  let result = undefined
  let classNumber = undefined
  if (isClass1) {
    classNumber = '1'
  } else {
    classNumber = '2'
  }
  switch (kind) {
    case 'Vehicle':
      result = `var-class${classNumber}=0&var-class${classNumber}=1&var-class${classNumber}=2`
      break
    case 'Motorcycle':
      result = `var-class${classNumber}=3`
      break
    case 'Bicycle':
      result = `var-class${classNumber}=4`
      break
    case 'Pedestrian':
      result = `var-class${classNumber}=6`
      break
  }
  return result
}

__expose({
  updateNearMissesList,
})

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, {
      class: "near-miss-list-main",
      elevation: "1"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, { class: "near-miss-list-main__header py-0" }, {
          default: _withCtx(() => [
            _cache[12] || (_cache[12] = _createElementVNode("span", { class: "py-0" }, "ヒヤリハット一覧", -1)),
            _createVNode(_component_v_btn, {
              class: "near-miss-list-main__csv-button",
              variant: "text",
              color: "primary",
              onClick: DownloadCsv
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  class: "near-miss-list-main__csv-icon",
                  src: _unref(CSV_DOWNLOAD_ICON)
                }, null, 8, _hoisted_1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { dense: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              class: "near-miss-list-main__search-input--narrow",
              cols: "1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "pa-5" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      modelValue: search.value.nearMissIdFilterValue,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search.value.nearMissIdFilterValue) = $event)),
                      type: "text",
                      label: "ID",
                      variant: "underlined"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              class: "near-miss-list-main__search-input",
              cols: "1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "pa-5" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      modelValue: search.value.nearMissAreaFilterValue,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((search.value.nearMissAreaFilterValue) = $event)),
                      type: "text",
                      label: "判定エリア",
                      variant: "underlined"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              class: "near-miss-list-main__search-input",
              cols: "1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "pa-5" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      modelValue: search.value.nearMissVehicleId1FilterValue,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((search.value.nearMissVehicleId1FilterValue) = $event)),
                      type: "text",
                      label: "車両1ID",
                      variant: "underlined"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              class: "near-miss-list-main__search-input",
              cols: "1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "pa-5" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      modelValue: search.value.nearMissVehicleId1KindFilterValue,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((search.value.nearMissVehicleId1KindFilterValue) = $event)),
                      type: "text",
                      label: "車両1物標種別",
                      variant: "underlined"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              class: "near-miss-list-main__search-input",
              cols: "1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "pa-5" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      modelValue: search.value.nearMissVehicleId1DirectionFilterValue,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((search.value.nearMissVehicleId1DirectionFilterValue) = $event)),
                      type: "text",
                      label: "車両1方路",
                      variant: "underlined"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              class: "near-miss-list-main__search-input",
              cols: "1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "pa-5" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      modelValue: search.value.nearMissVehicleId2FilterValue,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((search.value.nearMissVehicleId2FilterValue) = $event)),
                      type: "text",
                      label: "車両2ID",
                      variant: "underlined"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              class: "near-miss-list-main__search-input",
              cols: "1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "pa-5" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      modelValue: search.value.nearMissVehicleId2KindFilterValue,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((search.value.nearMissVehicleId2KindFilterValue) = $event)),
                      type: "text",
                      label: "車両2物標種別",
                      variant: "underlined"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              class: "near-miss-list-main__search-input",
              cols: "1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "pa-5" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      modelValue: search.value.nearMissVehicleId2DirectionFilterValue,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((search.value.nearMissVehicleId2DirectionFilterValue) = $event)),
                      type: "text",
                      label: "車両2方路",
                      variant: "underlined"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              class: "near-miss-list-main__search-input",
              cols: "1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "pa-5" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      modelValue: search.value.nearMissLatitudeFilterValue,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((search.value.nearMissLatitudeFilterValue) = $event)),
                      type: "text",
                      label: "緯度",
                      variant: "underlined"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              class: "near-miss-list-main__search-input",
              cols: "1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "pa-5" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      modelValue: search.value.nearMissLongitudeFilterValue,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((search.value.nearMissLongitudeFilterValue) = $event)),
                      type: "text",
                      label: "経度",
                      variant: "underlined"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { cols: "2" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "pa-5" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      modelValue: search.value.nearMissTimeFilterValue,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((search.value.nearMissTimeFilterValue) = $event)),
                      type: "text",
                      label: "発生時刻",
                      variant: "underlined"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { cols: "1" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "pa-5" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      modelValue: search.value.nearMissTimeGapFilterValue,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((search.value.nearMissTimeGapFilterValue) = $event)),
                      type: "text",
                      label: "TimeGap",
                      variant: "underlined"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_data_table, {
          headers: nearMissesTableHeader.value,
          items: nearMisses.value,
          density: "compact"
        }, {
          [`item.nearMisstime`]: _withCtx(({ item }) => [
            _createTextVNode(_toDisplayString(item.nearMisstime), 1)
          ]),
          [`item.timeGap`]: _withCtx(({ item }) => [
            _createTextVNode(_toDisplayString(createTimegap4Disp(item.timeGap)), 1)
          ]),
          [`item.videoPath`]: _withCtx(({ item }) => [
            (item.videoPath == undefined || item.videoPath == null || item.videoPath == '')
              ? (_openBlock(), _createBlock(_component_v_icon, {
                  key: 0,
                  class: "mr-2",
                  small: "",
                  color: "blue-grey"
                }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode(" mdi-video ")
                  ])),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_v_icon, {
                  key: 1,
                  class: "mr-2",
                  small: "",
                  color: "purple",
                  onClick: ($event: any) => (videoPlay(item))
                }, {
                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createTextVNode(" mdi-video ")
                  ])),
                  _: 2
                }, 1032, ["onClick"]))
          ]),
          [`item.downloadVidePath`]: _withCtx(({ item }) => [
            (item.downloadVidePath == undefined || item.downloadVidePath == null || item.downloadVidePath == '')
              ? (_openBlock(), _createBlock(_component_v_icon, {
                  key: 0,
                  class: "mr-2",
                  dense: ""
                }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createTextVNode(" mdi-multimedia ")
                  ])),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_v_icon, {
                  key: 1,
                  class: "mr-2",
                  dense: "",
                  color: "blue",
                  onClick: ($event: any) => (downloadData(item))
                }, {
                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                    _createTextVNode(" mdi-multimedia ")
                  ])),
                  _: 2
                }, 1032, ["onClick"]))
          ]),
          [`item.nearMissinfo`]: _withCtx(({ item }) => [
            _createVNode(_component_v_icon, {
              class: "mr-2",
              small: "",
              onClick: ($event: any) => (viewNearMissinfo(item))
            }, {
              default: _withCtx(() => _cache[17] || (_cache[17] = [
                _createTextVNode(" mdi-details ")
              ])),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 2
        }, 1032, ["headers", "items"])
      ]),
      _: 1
    }),
    (videoOption.value.isShowMovie)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (videoOption.value.isShowMovie)
            ? (_openBlock(), _createBlock(NearMissPlayVideo, {
                key: 0,
                options: videoOption.value.options,
                "near-misses-info": videoOption.value.nearMissesInfo,
                onVideoClose: videoClose
              }, null, 8, ["options", "near-misses-info"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})