import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onBeforeMount, onMounted, watch, ref } from 'vue'

import {
  useSelectPoleStore,
  usePoleInfoStore,
  usePosListStore,
} from '@/store/app'

import { getSensorSetting } from '@/mixins/commonFunction'
import {
  DEFAULT_MARKER_ICON,
  LED_MARKER_ICON,
  getTileLayer,
  getMapMarker,
} from '@/mixins/mapFunction'

import { SensorIdData } from '@/types/Interfaces'

import { VIRTUAL_MAP } from '@/setting/setting'

import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-semicircle'
import 'leaflet-rotatedmarker'

// ==================================
// interface
// ==================================
interface Props {
  selectSensorList: SensorIdData[];
}

// ==================================
// data
// ==================================
const PHI_AREA_ID = 2146830336

// ==================================
// watch
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'VirtualMap',
  props: {
    selectSensorList: {}
  },
  setup(__props: any, { expose: __expose }) {

/**
 * VirtualMap.vue
 * ポール選択後に表示する地図コンポーネント
 * 
 * 親コンポーネント
 * @/components/parts/realTime/VirtualVideo.vue
 * @/components/parts/virtual/VirtualVideo.vue
 */
// ==================================
// import
// ==================================
const props = __props

// 選択ポール情報ストア
const selectPoleStore = useSelectPoleStore()

// ポール情報ストア
const poleInfoStore = usePoleInfoStore()

// 物標情報リスト
const posListStore = usePosListStore()

const map = ref<any>()

const poleMarkers = ref<Array<any>>([])

const objectMarkers = ref<Array<any>>([])

const sensorAreaMarkers = ref<Array<any>>([])

const ledMarkers = ref<Array<any>>([])

const targetArea = ref<number>(0)

// フィリピンのエリアID(暫定対応)
// ※汎用化する場合は別途検討予定
watch(
  () => props.selectSensorList,
  () => {
    updateSensorAreaAndLedPole()
    updatePosObject()
  }
)

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  targetArea.value = selectPoleStore.$state.areaId
})

onMounted(() => {
  // マップ生成
  L.Icon.Default.mergeOptions(DEFAULT_MARKER_ICON)
  map.value = L.map('virtual-map', {
    dragging: true,
    touchZoom: true,
    scrollWheelZoom: true,
    doubleClickZoom: true,
    boxZoom: true,
    tap: true,
    keyboard: true,
    zoomControl: true,
    minZoom: VIRTUAL_MAP.zoom.min,
    maxZoom: VIRTUAL_MAP.zoom.max,
  }).setView(
    [selectPoleStore.latlng.lat, selectPoleStore.latlng.lng],
    VIRTUAL_MAP.zoom.default
  )
  L.control
    .layers(getTileLayer(map.value, VIRTUAL_MAP.zoom.max, undefined))
    .addTo(map.value)
})

// ==================================
// method
// ==================================
/**
 * ポールマーカー設定
 * @param lat - 緯度
 * @param lng - 経度
 */
const addPoleMarker = (lat: number, lng: number) => {
  poleMarkers.value.push(
    L.marker([lat, lng], {
      icon: L.icon(DEFAULT_MARKER_ICON),
    }).addTo(map.value)
  )
}
/**
 * ポールマーカー設定削除
 * 現状使用していないが使うタイミングでコメントはずしてください
 */
// const removePoleMarker = () => {
//   for (const item of poleMarkers.value) {
//     map.value.removeLayer(item);
//   }
//   // 一度データを初期化
//   poleMarkers.value.splice(0);
// };

/**
 * センサーのエリア範囲設定
 */
const addSensorArea = () => {
  for (const sensor of poleInfoStore.sensorList) {
    let TypeAndcheckedlist = checkSensorTypeAndchecked(sensor.sensorId)
    let type = TypeAndcheckedlist[0]
    let ischeck = TypeAndcheckedlist[1]
    if (ischeck && type != '表示板') {
      let settingSensor: any = getSensorSetting(sensor.sensorId)
      addSensorAreaMarker(sensor, settingSensor.color, settingSensor.name)
    } else if (ischeck && type == '表示板') {
      addLedMarker(sensor.latitude, sensor.longitude)
    }
  }
}

/**
 * センサーのエリアマーカ設定
 * @param sensor - センサー情報
 * @param color - マーカー色
 * @param name - センサー名
 */
const addSensorAreaMarker = (sensor: any, color: string, name: any) => {
  let directionMin = sensor.detectDirectionMin
  let directionMax = sensor.detectDirectionMax
  let radius = sensor.detectDistanceMax
  if (directionMin == 65535 || directionMax == 65535 || radius == 65535) {
    return
  }
  if (sensor.detectDirectionMin > sensor.detectDirectionMax) {
    directionMax += 360
  }
  let semicircle = L.semiCircle([sensor.latitude, sensor.longitude], {
    radius: radius,
    startAngle: directionMin,
    stopAngle: directionMax,
    color: color,
    opacity: 0.0,
    fillOpacity: 0.3,
    weight: 1,
  })
  semicircle.bindTooltip(name, {
    className: 'sensor-area',
    direction: 'center',
    offset: L.point(20, -50),
    opacity: 0.6,
  })
  semicircle.addTo(map.value)

  sensorAreaMarkers.value.push({
    sensorId: sensor.sensorId,
    marker: semicircle,
  })
}

/**
 * 選択済みのセンサー情報取得
 * @param sensorId - センサーID
 */
const checkSensorTypeAndchecked = (sensorId: number) => {
  let type = ''
  let results = false
  for (const sensor of props.selectSensorList) {
    if (sensorId == Number(sensor.value)) {
      type = sensor.kind
      results = true
      break
    }
  }
  return [type, results]
}

/**
 * センサーエリア範囲設定削除
 */
const removeSensorAreaMarker = () => {
  sensorAreaMarkers.value.forEach((marker: any) => {
    map.value.removeLayer(marker.marker)
  })
  // 一度データを初期化
  sensorAreaMarkers.value.splice(0)
}

/**
 * LED表示板マーカ設定
 * @param lat - 緯度
 * @param lng - 経度
 */
const addLedMarker = (lat: number, lng: number) => {
  ledMarkers.value.push(
    L.marker([lat, lng], {
      icon: L.icon(LED_MARKER_ICON),
    }).addTo(map.value)
  )
}

/**
 * LED表示板マーカ削除
 */
const removeLedMarker = () => {
  for (const item of ledMarkers.value) {
    map.value.removeLayer(item)
  }

  // 一度データを初期化
  ledMarkers.value.splice(0)
}

/**
 * センサーエリア範囲及びLED表示板位置更新
 */
const updateSensorAreaAndLedPole = () => {
  removeSensorAreaMarker()
  removeLedMarker()
  addSensorArea()
}

/**
 * 物標を更新
 */
const updatePosObject = () => {
  removePosObjectMarker()
  for (const pos of posListStore.posList) {
    for (const sensor of props.selectSensorList) {
      if (pos.sensorId == Number(sensor.value)) {
        updateObjectMarker(pos.posList, pos.sensorId, sensor.name)
        break
      }
    }
  }
}

/**
 * 物標を更新
 * @param posList - 物標一覧
 * @param sensorId - センサーID
 * @param name - センサー名
 */
const updateObjectMarker = (posList: any, sensorId: number, name: string) => {
  posList.forEach((pos: any) => {
    addObjectMarker(
      sensorId,
      pos.vehicleId,
      pos.vehicleRoleClassification,
      [pos.latitude, pos.longitude],
      getMapMarker(pos.vehicleSizeClassification, pos.vehicleRoleClassification, false),
      name
    )
  })
}

/**
 * 物標をマップに追加
 * @param sensorId - センサーID
 * @param vehicleId - 物標ID
 * @param roleKind - 物標アイコン用途種別ID
 * @param latlng - 座標
 * @param icon - アイコンURL
 * @param name - センサー名
 */
const addObjectMarker = (
  sensorId: number,
  vehicleId: number,
  roleKind: number,
  latlng: any,
  icon: any,
  name: string
) => {
  // アイコンの色と吹き出しの色を設定する
  const colorClass = setIconColor(name, roleKind)
  const tooltipShape = setTootipShape(name, roleKind)
  let Icon = L.icon({
    iconUrl: icon,
    iconSize: [20, 20], // アイコンサイズ
    className: colorClass
  })
  let direction = 0
  let marker = L.marker(latlng, {
    icon: Icon,
    rotationAngle: direction,
    rotationOrigin: 'center center',
  })
  marker.bindTooltip(vehicleId.toString(), {
    // className: 'marker-object',
    className: tooltipShape,
    direction: 'center',
    offset: L.point(10, -25),
    permanent: true,
    opacity: 0.6,
  })
  marker.addTo(map.value)

  objectMarkers.value.push({
    sensorId: sensorId,
    vehicleId: vehicleId,
    marker: marker,
  })
}

/**
 * 物標マーカー削除
 */
const removePosObjectMarker = () => {
  for (const item of objectMarkers.value) {
    map.value.removeLayer(item.marker)
  }
  // 一度データを初期化
  objectMarkers.value.splice(0)
}

/**
 * マップ表示の中央位置を設定
 * @param latlng - 緯度経度
 */
const setCenter = (latlng: number[]) => {
  map.value.setView(latlng, map.value.getZoom())
}

/**
 * 物標アイコンの色を設定する
 * @param name - センサー名(センサーIDの16進数表記)
 * @param roleKind - 物標アイコン用途種別ID
 * @returns アイコンの色設定を行うスタイル名
 */
const setIconColor = (name: string, roleKind: number): string => {
  // フィリピンのポールにのみ、色分け対応を行う
  if (targetArea.value === PHI_AREA_ID) {
    switch (name) {
      // CMOS
      case '050e01':
        return 'blue-icon'
      // LiDAR
      case '020c01':
        return 'cyan-icon'
      // それ以外
      default:
        return roleKind === 1 ? 'red-icon' : 'black-icon'
    }
    // フィリピン以外のエリアでは黒表示にする
  } else {
    return roleKind === 1 ? 'red-icon' : 'black-icon'
  }
}

/**
 * ツールチップ（吹き出し）の色を設定する
 * @param name - センサー名(センサーIDの16進数表記)
 * @param roleKind - 物標アイコン用途種別ID
 * @return 色設定を行うスタイル名
 */
const setTootipShape = (name: string, roleKind: number): string => {
  if (targetArea.value === PHI_AREA_ID) {
    switch (name) {
      case '050e01':
        return 'default_tooltip'
      case '020c01':
        return 'diamond_tooltip'
      default:
        return roleKind === 1 ? 'marker-ambulance' : 'marker-object'
    }
  } else {
    return roleKind === 1 ? 'marker-ambulance' : 'marker-object'
  }
}

__expose({
  addPoleMarker,
  updatePosObject,
  setCenter,
})

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_v_main = _resolveComponent("v-main")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { id: "virtual-map" }, null, -1)),
    _createVNode(_component_v_main, null, {
      default: _withCtx(() => [
        _createVNode(_component_RouterView)
      ]),
      _: 1
    })
  ], 64))
}
}

})