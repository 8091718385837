import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

interface Props {
  label: string;
  disabled: boolean;
}

interface Emits {
  (e: 'click'): void;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'Button',
  props: {
    label: {},
    disabled: { type: Boolean }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

/**
 * Button.vue
 * 汎用ボタン
 * 
 * 親コンポーネント
 * @/components/parts/aggregation/AggreMenu.vue
 * @/views/Aggregation.vue
 * @/views/MacroAnalysis.vue
 * @/views/Watch.vue
 */
// ==================================
// interface
// ==================================
const props = __props

// ==================================
// method
// ==================================
const emit = __emit
/**
 * クリック
 */
const onclick = () => {
  emit('click')
}

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, {
    disabled: props.disabled,
    theme: "light",
    rounded: "",
    color: "primary",
    block: "",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (onclick()))
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.label), 1)
    ]),
    _: 1
  }, 8, ["disabled"]))
}
}

})