import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  align: "center",
  class: "multi-camera-footage__video-frame"
}

import { computed, ref } from 'vue'

import KinesisVideoStreamsWebrtc from '@/components/parts/common/KinesisVideoStreamsWebrtc.vue'

// ==================================
// interface
// ==================================
interface Props {
  isSelctSensor: boolean;
}

interface CameraInfo {
  sensorId: number;
  poleId: number;
  cameraKind: number;
  name: string;
}

// ==================================
// data
// ==================================

export default /*@__PURE__*/_defineComponent({
  __name: 'MultiCameraFootage',
  props: {
    isSelctSensor: { type: Boolean }
  },
  setup(__props: any, { expose: __expose }) {

/**
 * MultiCameraFootage.vue
 * マルチカメラコンポーネント 
 * 
 * 親コンポーネント
 * @/views/MultiCamera.vue
 */
// ==================================
// import
// ==================================
const props = __props

const cameraList = ref<string[] | CameraInfo[]>(['選択可能なカメラが存在しません'])

const selectCamera = ref<string | CameraInfo>('選択可能なカメラが存在しません')

const showCamera = ref< boolean>(false)

// ==================================
// computed
// ==================================
const isSelctSensor = computed(() => {
  return !props.isSelctSensor
})

// ==================================
// methods
// ==================================
/**
 * カメラ初期設定
 * @param cameraListFromParent - カメラ情報
 */
const initCamera = (cameraListFromParent: CameraInfo[]) => {
  if (cameraListFromParent.length != 0) {
    cameraList.value = cameraListFromParent
    selectCamera.value = cameraList.value[0]
    showCamera.value = true
  }
}

__expose({
  initCamera,
})

return (_ctx: any,_cache: any) => {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "multi-camera-footage",
    elevation: "5",
    outlined: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, { class: "multi-camera-footage__title py-0 d-flex" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_select, {
            modelValue: selectCamera.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectCamera).value = $event)),
            class: "multi-camera-footage__select",
            variant: "underlined",
            items: cameraList.value,
            theme: "dark",
            "item-title": "name",
            "item-value": "channel",
            label: "カメラ選択",
            autowidth: "",
            "return-object": "",
            disabled: isSelctSensor.value
          }, null, 8, ["modelValue", "items", "disabled"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_divider),
      _createElementVNode("div", _hoisted_1, [
        (showCamera.value)
          ? (_openBlock(), _createBlock(KinesisVideoStreamsWebrtc, {
              key: 0,
              camera: selectCamera.value,
              class: _normalizeClass('multi-video-container')
            }, null, 8, ["camera"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}
}

})